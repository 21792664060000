import React, {useEffect, useState, useCallback} from "react";
import { DataGridPremium, GridColDef, GridRowProps, GridRowModel } from "@mui/x-data-grid-premium";


const columns : GridColDef[] = [
    {field : "name", headerName : "이름", width : 250},
    {field : "subject", headerName : "과목", width : 500, editable : true}
];

const RegisterSubject : React.FC<any> = (props : any) => {

    const [rows, setRows] = useState<GridRowProps[]>([]);
    const [update, setUpdate] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        setLoading(true);

        fetch("https://peetsunbae.com/dashboard/question/offlinequestion/getteachersubject", {
            method : "GET",
            credentials : "include"
        }).then((response : any) => {
            response.json()
            .then((result : any) => {
                
                if(result.message === "success"){
                    const newRows : any = [];

                    result.data.forEach((each : any) => {

                        const oneRow : any = {};
                        oneRow.id = each.userId;
                        oneRow.name = each.name;
                        oneRow.subject = each.subject;
                        oneRow.subjectId = each.subjectId;

                        newRows.push(oneRow);

                    })

                    setRows([...newRows]);
                    setLoading(false);
                }
            })
        })

    }, [update]);

    const handleEditCommit = React.useCallback(
        async (newRow: GridRowModel, old: any) => {
            var field = "";


            

            for (const [key, value] of Object.entries(newRow)) {
                if (value !== old[key]) {
                    field = key;
                }
            }

            const value = newRow[field] ? newRow[field] : null;
            const userId = newRow.id;
            const subject = newRow.subject ? newRow.subject : "";


            
            
            
            

            if (!field) {
                
                return newRow;
            }

            if(field !== "subject"){
                
                return;
            }

            if (!userId) {
                
                return newRow;
            }

            const data = {
                userId,
                subject
            }

            

            fetch("https://peetsunbae.com/dashboard/question/offlinequestion/getteachersubject", {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify(data)
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        if (result.message === "success") {
                            setUpdate(Math.random());
                            props.handleSubjectUpdate();
                        }
                    })
            })

            return newRow;

        }, []
    );

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        
        alert(error.message);
    }, []);

    return (
        <div style={{padding : "18px"}}>
            <div style={{ height: 500, width: '100%', backgroundColor: "white", marginTop: "12px" }}>
                <DataGridPremium
                rows={rows}
                columns={columns}
                density="compact"
                processRowUpdate={handleEditCommit}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                loading={loading}
                />
            </div>
        </div>
    );

}

export default RegisterSubject;