import React, { useEffect, useState, useRef, useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";
import InnerHeader from "../innerheader";
import styles from "./imageeditor.module.css";
import { ReactComponent as ArrowLeftLight } from "../../../../src/svg/arrow-left-light.svg";
import { ReactComponent as Pen } from "../../../../src/svg/mobiletotal/pen-thin.svg";
import { ReactComponent as HighLighter } from "../../../../src/svg/mobiletotal/highlighter-thin.svg";
import { ReactComponent as Eraser } from "../../../../src/svg/mobiletotal/eraser-thin.svg";

import { ReactComponent as Crop } from "../../../../src/svg/mobiletotal/crop-simple-thin.svg";
import { ReactComponent as Rotate } from "../../../../src/svg/mobiletotal/arrows-rotate-thin.svg";

import { ReactComponent as Check } from "../../../../src/svg/mobiletotal/check-thin.svg";
import { ReactComponent as Xmark } from "../../../../src/svg/mobiletotal/xmark-thin.svg";


import { IonModal } from "@ionic/react";
import { Capacitor } from "@capacitor/core";
import { wait } from "../wait";

interface coordinate {
    x: number,
    y: number
}

const ImageEditor: React.FC<any> = (props) => {

    const [platform, setPlatform] = useState("");
    const [bodyHeight, setBodyHeight] = useState(0);

    const [isOnEdit, setIsOnEdit] = useState(false);
    const [currentAction, setCurrentAction] = useState("")



    const headerRef = useRef<HTMLDivElement | null>(null);
    const footerRef = useRef<HTMLDivElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const canvasRef2 = useRef<HTMLCanvasElement | null>(null);

    const [currentColor, setCurrentColor] = useState("");
    const [lineWidth, setLineWidth] = useState(5);


    //pen 관련 state----------
    const [isPainting, setIsPainting] = useState(false);
    const [coordinate, setCoordinate] = useState<coordinate | undefined>();
    //--------------------

    //eraser관련 state---------
    const [isErasing, setIsErasing] = useState(false);
    //------------------------

    

    const totalRef = useCallback((node) => {
        if (!node) {
            
            return;
        }


        if (!props.src) {
            
            return;
        }

        drawImage(props.src);
    }, [props.src]);


    const getTargetWidthHeight = (newImage: HTMLImageElement) => {

        
        

        const newImageRatio = newImage.width / newImage.height;

        const totalHeight = window.innerHeight;
        const valueTop = +getComputedStyle(document.documentElement).getPropertyValue("--sat").split("p")[0];
        const headerHeight = headerRef.current!.offsetHeight;
        const footerHeight = footerRef.current!.offsetHeight;

        
        
        
        



        const bodyHeight = totalHeight - valueTop - headerHeight - footerHeight;

        setBodyHeight(bodyHeight);

        const bodyWidth = window.innerWidth;
        const bodyRatio = bodyWidth / bodyHeight;

        var targetWidth;
        var targetHeight;

        if (newImageRatio > bodyRatio) {
            targetWidth = bodyWidth;
            targetHeight = bodyWidth / newImageRatio;
        } else {
            targetHeight = bodyHeight;
            targetWidth = bodyHeight * newImageRatio;
        }

        return { targetWidth, targetHeight }

    }



    const drawImage = async (src: string) => {

        

        if (!canvasRef || !canvasRef.current) {
            return;
        }

        

        if (!headerRef || !headerRef.current) {
            return;
        }

        

        const newImage = new Image();
        newImage.src = src;

        

        newImage.onload = ((e) => {


            const { targetWidth, targetHeight } = getTargetWidthHeight(newImage);

            const canvas = canvasRef.current;
            const canvas2 = canvasRef2.current;

            if (!canvas || !canvas2) {
                return;
            }

            
            

            canvas.width = targetWidth * window.devicePixelRatio;
            canvas.height = targetHeight * window.devicePixelRatio;

            canvas2.width = targetWidth * window.devicePixelRatio;
            canvas2.height = targetHeight * window.devicePixelRatio;

            canvas.style.width = `${targetWidth}px`;
            canvas.style.height = `${targetHeight}px`;

            canvas2.style.width = `${targetWidth}px`;
            canvas2.style.height = `${targetHeight}px`;

            const ctx = canvas.getContext("2d");

            if (!ctx) {
                return;
            }


            ctx.drawImage(newImage, 0, 0, targetWidth * window.devicePixelRatio, targetHeight * window.devicePixelRatio);

        })

    }

    //플랫폼 정보 확인
    useEffect(() => {
        const platform = Capacitor.getPlatform();

        if (platform) {
            setPlatform(platform);
        }

        
        

    }, []);

    useEffect(() => {

        setIsOnEdit(false);
        setCurrentAction("");

    }, [props.isModalOpen]);

    const handleColorChange = (color : string) => {


        switch(color) {
            case "black" :
                setCurrentColor("#000000");
                break;
            case "pink" :
                setCurrentColor("#e91e63");
                break;
            case "purple" :
                setCurrentColor("#673ab7");

                break;
            case "yellow" :
                setCurrentColor("#ffeb3b");
                break;
            case "h_green" :
                setCurrentColor("rgba(167, 232, 200, 0.3)");
                break;
            case "h_red" :
                setCurrentColor("rgba(248, 182, 168, 0.3)");
                break;
            case "h_yellow" :
                setCurrentColor("rgba(255, 255, 180, 0.3)");
                break;
            case "h_orange" :
                setCurrentColor("rgba(255, 173, 42, 0.3)");
                break;
        }
    }


    const handleActionChange = (type: string) => {

        setIsOnEdit(true);

        switch (type) {
            case "pen":
                setCurrentColor("#000000");
                setCurrentAction("pen");
                setLineWidth(5);
                break;
            case "highlighter":
                setCurrentColor("rgba(167, 232, 200, 0.3)");
                setCurrentAction("highlighter");
                setLineWidth(30);
                break;
            case "eraser":
                setCurrentAction("eraser");
                break;
            case "crop":
                setCurrentAction("crop");
                break;
            case "rotate":
                setCurrentAction("rotate");
                break;
        }

    }


    const handleActionEnd = (type: string) => {

        switch (type) {
            case "cancel":
                setCurrentAction("");
                break;

            case "confirm":
                setCurrentAction("");
                break;
        }

        setCurrentColor("");
        setLineWidth(5);
        setIsOnEdit(false);
    }













    //펜사용용 함수들------------------------------------------------

    const drawLine = (orginalCoordinate: coordinate, newCoordinate: coordinate) => {
        
        

        if (!canvasRef2 || !canvasRef2.current) {
            return;
        }

        const canvas = canvasRef2.current;
        const ctx = canvas.getContext("2d");

        if (!ctx) {
            return;
        }

        ctx.strokeStyle = currentColor;
        ctx.lineWidth = lineWidth;
        ctx.lineJoin = "round";

        ctx.beginPath();
        ctx.moveTo(orginalCoordinate.x, orginalCoordinate.y);
        ctx.lineTo(newCoordinate.x, newCoordinate.y);
        ctx.closePath();

        ctx.stroke();
    }

    const eraseLine = (orginalCoordinate: coordinate, newCoordinate: coordinate) => {

        if (!canvasRef2 || !canvasRef2.current) {
            return;
        }

        const canvas = canvasRef2.current;
        const ctx = canvas.getContext("2d");

        if (!ctx) {
            return;
        }

        ctx.clearRect(orginalCoordinate.x, orginalCoordinate.y, (newCoordinate.x - orginalCoordinate.x) * 2, (newCoordinate.y - orginalCoordinate.y) * 2);
    }


    const getCoordinate = (e: TouchEvent): coordinate | undefined => {
        if (!canvasRef2 || !canvasRef2.current) {
            return;
        }

        const canvas = canvasRef2.current;
        const canvasRect = canvas.getBoundingClientRect();

        
        

        
        

        return {
            x: (e.touches[0].clientX - canvasRect.left) * window.devicePixelRatio,
            y: (e.touches[0].clientY - canvasRect.top) * window.devicePixelRatio
        }

    }





    const startPaint = useCallback((e: TouchEvent) => {

        if (!canvasRef2 || !canvasRef2.current) {
            
            return;
        }

        const coordinate = getCoordinate(e);

        if (!coordinate) {
            
            return;
        }

        

        setIsPainting(true);
        setCoordinate(coordinate);

    }, []);

    const startErase = useCallback((e: TouchEvent) => {

        if (!canvasRef2 || !canvasRef2.current) {
            
            return;
        }

        const coordinate = getCoordinate(e);

        if (!coordinate) {
            
            return;
        }

        

        setIsErasing(true);
        setCoordinate(coordinate);

    }, []);

    const paint = useCallback((e: TouchEvent) => {

        if (!canvasRef2 || !canvasRef2.current) {
            return;
        }

        

        e.preventDefault();
        e.stopPropagation();

        if (isPainting) {
            const newCoordinate = getCoordinate(e);

            if (coordinate && newCoordinate) {
                drawLine(coordinate, newCoordinate);
                setCoordinate(newCoordinate);
            }
        }

        

    }, [coordinate, isPainting]);



    const erase = useCallback((e : TouchEvent) => {

        if (!canvasRef2 || !canvasRef2.current) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();

        if (isErasing) {
            const newCoordinate = getCoordinate(e);

            if (coordinate && newCoordinate) {
                eraseLine(coordinate, newCoordinate);
                setCoordinate(newCoordinate);
            }
        }

        

    }, [coordinate, isErasing])

    const endPaint = useCallback((e: TouchEvent) => {

        setIsPainting(false);

    }, []);

    const endErase = useCallback((e: TouchEvent) => {

        setIsErasing(false);

    }, []);






    useEffect(() => {

        if (!canvasRef2 || !canvasRef2.current) {
            return;
        }

        if (currentAction !== "pen" && currentAction !== "highlighter") {
            return;
        }

        const canvas = canvasRef2.current;

        
        

        canvas.addEventListener("touchstart", startPaint);
        canvas.addEventListener("touchmove", paint);
        canvas.addEventListener("touchend", endPaint);
        canvas.addEventListener("touchcancel", endPaint);

        return () => {
            canvas.removeEventListener("touchstart", startPaint);
            canvas.removeEventListener("touchmove", paint);
            canvas.removeEventListener("touchend", endPaint);
            canvas.removeEventListener("touchcancel", endPaint);
        }

    }, [startPaint, paint, endPaint, currentAction]);

    //---------------------------------------------------------------------------

    //erase 관련 함수들---------------------------------------------------------------




    useEffect(() => {
        if (!canvasRef2 || !canvasRef2.current) {
            return;
        }

        if (currentAction !== "eraser") {
            return;
        }

        const canvas = canvasRef2.current;

        canvas.addEventListener("touchstart", startErase);
        canvas.addEventListener("touchmove", erase);
        canvas.addEventListener("touchend", endErase);
        canvas.addEventListener("touchcancel", endErase);

        return () => {
            canvas.removeEventListener("touchstart", startErase);
            canvas.removeEventListener("touchmove", erase);
            canvas.removeEventListener("touchend", endErase);
            canvas.removeEventListener("touchcancel", endErase);
        }


    }, [startPaint, erase, endErase, currentAction])

    //--------------------------------------------------------------------------

    const clearCanvas = () => {
        if (!canvasRef2 || !canvasRef2.current) {
            return;
        }

        const canvas = canvasRef2.current;

        const ctx = canvas.getContext("2d");

        if (!ctx) {
            return;
        }

        ctx.clearRect(0, 0, canvas.width, canvas.height);

    }


    const submit = () => {

        if (isOnEdit) {
            return;
        }

        if (!canvasRef || !canvasRef.current || !canvasRef2 || !canvasRef2.current) {
            return;
        }

        const canvas = canvasRef.current;
        const canvas2 = canvasRef2.current;

        const tempCanvas = document.createElement("canvas");
        tempCanvas.width = canvas.width;
        tempCanvas.height = canvas.height;

        const ctx = tempCanvas.getContext("2d");

        if (!ctx) {
            return;
        }

        ctx.drawImage(canvas, 0, 0);
        ctx.drawImage(canvas2, 0, 0);


        tempCanvas.toBlob((blob) => {
            if (!blob) {
                return;
            }

            const url = URL.createObjectURL(blob);

            props.registerImage(url, blob);
        }, "image/png", 1);

    }




    return (
        <IonModal isOpen={props.isModalOpen}>
            <div ref={totalRef} style={{ paddingTop: "env(safe-area-inset-top)" }}>
                <div ref={headerRef} className={styles.headerDiv}>
                    <div className={`${styles.header} ${platform === "ios" ? styles.ios : ""}`}>
                        <div className={styles.backBtnDiv}
                            onClick={() => { props.handleClose() }}
                        >
                            <ArrowLeftLight
                                className={styles.arrowLeft}
                            />
                        </div>

                        <div className={styles.titleText}>

                        </div>

                        <div className={`${styles.submit} ${isOnEdit ? styles.isOnEdit : ""}`} onClick={submit} >
                            확인
                        </div>
                    </div>
                </div>
                <div className={styles.body} style={{ height: `${bodyHeight}px` }}>
                    <div className={styles.canvasContainer}>

                        <canvas ref={canvasRef2} className={styles.canvas2}>

                        </canvas>
                        <canvas ref={canvasRef} className={styles.canvas1}>

                        </canvas>
                    </div>
                </div>

                {
                    !isOnEdit &&
                    <div className={`${styles.footer} ${platform === "ios" ? styles.ios : ""}`} ref={footerRef} style={{ paddingBottom: `${platform === "ios" ? "env(safe-area-inset-bottom)" : "1rem"}` }}>
                        <div onClick={(e) => { handleActionChange("pen"); }}>
                            <Pen fill="white" className={styles.pen} />
                        </div>
                        <div onClick={(e) => {handleActionChange("highlighter");}}>
                            <HighLighter fill="white" className={styles.pen} />
                        </div>
                        <div onClick={(e) => {handleActionChange("eraser")}}>
                            <Eraser fill="white" className={styles.pen} />
                        </div>
                        {/* <div>
                            <Crop fill="white" className={styles.pen} />
                        </div>
                        <div>
                            <Rotate fill="white" className={styles.pen} />
                        </div> */}
                    </div>
                }

                {
                    isOnEdit &&
                    <div className={`${`${styles.footer} ${styles.isOnEdit}`} ${platform === "ios" ? styles.ios : ""}`} ref={footerRef} style={{ paddingBottom: `${platform === "ios" ? "env(safe-area-inset-bottom)" : "1rem"}` }}>
                        <div onClick={(e) => { handleActionEnd("cancel") }}>
                            
                        </div>
                        <div className={styles.centerDiv}>
                            {
                                currentAction === "pen" &&
                                <div className={styles.elements}>
                                    <div className={styles.elementContainer} onClick={(e) => {handleColorChange("black")}}>
                                        <div className={`${styles.element} ${currentColor === "#000000" ? styles.active : ""}`} style={{ backgroundColor: "#212121" }}>

                                        </div>
                                    </div>
                                    <div className={styles.elementContainer} onClick={(e) => {handleColorChange("pink")}}>
                                        <div className={`${styles.element} ${currentColor === "#e91e63" ? styles.active : ""}`} style={{ backgroundColor: "#e91e63" }}>

                                        </div>
                                    </div>
                                    <div className={styles.elementContainer} onClick={(e) => {handleColorChange("purple")}}>
                                        <div className={`${styles.element} ${currentColor === "#673ab7" ? styles.active : ""}`} style={{ backgroundColor: "#673ab7" }}>

                                        </div>
                                    </div>
                                    <div className={styles.elementContainer} onClick={(e) => {handleColorChange("yellow")}}>
                                        <div className={`${styles.element} ${currentColor === "#ffeb3b" ? styles.active : ""}`} style={{ backgroundColor: "#ffeb3b" }}>

                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                currentAction === "highlighter" &&
                                <div className={styles.elements}>
                                    <div className={styles.elementContainer} onClick={(e) => {handleColorChange("h_green")}}>
                                        <div className={`${styles.element} ${currentColor === "rgba(167, 232, 200, 0.3)" ? styles.active : ""}`} style={{ backgroundColor: "rgba(167, 232, 200, 1)" }}>

                                        </div>
                                    </div>
                                    <div className={styles.elementContainer} onClick={(e) => {handleColorChange("h_red")}}>
                                        <div className={`${styles.element} ${currentColor === "rgba(248, 182, 168, 0.3)" ? styles.active : ""}`} style={{ backgroundColor: "rgba(248, 182, 168, 1)" }}>

                                        </div>
                                    </div>
                                    <div className={styles.elementContainer} onClick={(e) => {handleColorChange("h_yellow")}}>
                                        <div className={`${styles.element} ${currentColor === "rgba(255, 255, 180, 0.3)" ? styles.active : ""}`} style={{ backgroundColor: "rgba(255, 255, 180, 1)" }}>

                                        </div>
                                    </div>
                                    <div className={styles.elementContainer} onClick={(e) => {handleColorChange("h_orange")}}>
                                        <div className={`${styles.element} ${currentColor === "rgba(255, 173, 42, 0.3)" ? styles.active : ""}`} style={{ backgroundColor: "rgba(255, 173, 42, 1)" }}>

                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div onClick={(e) => { handleActionEnd("confirm") }}>
                            <Check fill="white" className={styles.mark2} />
                        </div>
                    </div>
                }

            </div>
        </IonModal>
    );
}

export default ImageEditor;