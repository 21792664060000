import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { DataGridPremium, GridFilterModel } from '@mui/x-data-grid-premium';
import React, { useEffect, useState } from 'react';

const column = [
    { field: "name", headerName: "이름", width: 150 },
    { field: "previousRecord", headerName: "이전 기록", width: 250 },
    { field: "afterRecord", headerName: "이후 기록", width: 250 },
    { field: "rtsp", headerName: "RTSP", width: 800 }
]

const CatchBreak: React.FC = (props: any) => {

    const [rows, setRows] = useState<any>([]);
    const [targetDate, settargetDate] = useState(new Date());
    const [suspiciousList, setSuspiciousList] = useState([]);
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [
            { id: 2, field: 'previousRecord', operator: 'contains', value: "in" }
        ],
    });
    const [loading, setLoading] = useState<boolean>(false);

    const getAccessControlData = async (targetDateTime: number) => {

        try {
            setLoading(true);
            const response = await fetch("https://peetsunbae.com/dashboard/report/daechiAllAttendance?targetDate=" + targetDateTime, {
                method: "GET",
                credentials: "include",
            })

            const result = await response.json();

            

            const data = result.data;

            const newSuspiciousList: any = [];

            const yesterdayData = result.yesterdayOutsideData;

            yesterdayData.forEach((each: any) => {

                if (each.userId === 5) {
                    return;
                }

                if (each.value !== "student") {
                    return;
                }

                if (each.name === "윤종웅(학생용)" || each.name === "고용범" || each.name === "고용범2") {
                    return;
                }

                const oneRow: any = {};

                const lastRecord = each.records[each.records.length - 1];

                oneRow.name = each.name;
                oneRow.previousRecord = lastRecord;

                

                newSuspiciousList.push(oneRow);

            })

            data.forEach((each: any) => {

                if (each.value !== "student") {
                    return;
                }

                if (each.name === "윤종웅(학생용)" || each.name === "고용범" || each.name === "고용범2") {
                    return;
                }


                const name = each.name;
                var previousRecord: any = null;

                each.records.forEach((eachRecord: any) => {

                    if (previousRecord) {

                        if (previousRecord.direction === eachRecord.direction) {

                            const previousRecordTime = +previousRecord.time;
                            const eachRecordTime = +eachRecord.time;

                            //1분 이상 차이나면
                            if (eachRecordTime - previousRecordTime > 60000) {
                                

                                const oneRow: any = {};

                                oneRow.name = name;
                                oneRow.previousRecord = previousRecord;
                                oneRow.afterRecord = eachRecord;

                                newSuspiciousList.push(oneRow);

                            }

                        }

                    }

                    previousRecord = eachRecord;

                })

            })



            

            const newRows: any = [];

            newSuspiciousList.forEach((each: any, index: number) => {


                const oneRow: any = {};
                oneRow.id = index + 1;
                oneRow.name = each.name;

                if (each.afterRecord) {
                    const afterRecordDate = new Date(+each.afterRecord.time);
                    const afterRecordTime = afterRecordDate.getHours() + ":" + afterRecordDate.getMinutes() + ":" + afterRecordDate.getSeconds();
                    const afterRecordDirection = each.afterRecord.direction === "outside" ? "in" : "out";

                    const afterRecordLocation = each.afterRecord.kinds === "daechi" ? "2층" : each.afterRecord.kinds === "daechi2" ? "3층" : "-";


                    const afterRecord = afterRecordDirection + " " + (afterRecordTime) + " (" + afterRecordLocation + ")";


                    oneRow.afterRecord = afterRecord;
                }

                if(!each.afterRecord){
                    oneRow.afterRecord = "전 날 마지막 기록이 in 임";
                }

                const previousRecordDate = new Date(+each.previousRecord.time);
                const previousRecordTime = previousRecordDate.getHours() + ":" + previousRecordDate.getMinutes() + ":" + previousRecordDate.getSeconds();
                const previousRecordDirection = each.previousRecord.direction === "outside" ? "in" : "out";

                const previousRecordLocation = each.previousRecord.kinds === "daechi" ? "2층" : each.previousRecord.kinds === "daechi2" ? "3층" : "-";

                const previousRecord = previousRecordDirection + " " + (previousRecordTime) + " (" + previousRecordLocation + ")";


                oneRow.previousRecord = previousRecord;

                if(!each.afterRecord){
                    oneRow.previousRecord += " (전날 마지막 기록)";
                }


                const koreaTimeOffset = 9 * 60 * 60 * 1000;
                const previousRecordBeforeTenSeconds = new Date(+each.previousRecord.time - 10000 + koreaTimeOffset);
                const previousRecordAfterTenSeconds = new Date(+each.previousRecord.time + 10000 + koreaTimeOffset);

                //YYYYMMDDthhmmssz
                const previousRecordBeforeTenSecondsTimeString = previousRecordBeforeTenSeconds.toISOString().replace(/-/g, "").replace(/:/g, "").replace("T", "t").replace("Z", "z").split(".")[0] + "z";
                const previousRecordAfterTenSecondsTimeString = previousRecordAfterTenSeconds.toISOString().replace(/-/g, "").replace(/:/g, "").replace("T", "t").replace("Z", "z").split(".")[0] + "z";

                const hikvisionRtspUrl = `
                rtsp://182.220.139.154:5006/Streaming/tracks/2101?starttime=${previousRecordBeforeTenSecondsTimeString}&endtime=${previousRecordAfterTenSecondsTimeString}
                `;

                if (each.previousRecord.kinds === "daechi2")
                    oneRow.rtsp = hikvisionRtspUrl;

                newRows.push(oneRow);

            });

            setLoading(false);

            setRows([...newRows]);

        } catch (e) {
            
        }

    }

    useEffect(() => {
        getAccessControlData(targetDate.getTime());
    }, [targetDate]);

    const onClick = (direction: string) => {

        if (direction === "left") {
            const newDate = new Date(targetDate.getTime() - 24 * 60 * 60 * 1000);
            settargetDate(newDate);
        } else {
            const newDate = new Date(targetDate.getTime() + 24 * 60 * 60 * 1000);
            settargetDate(newDate);
        }

    }


    return (
        <div>

            <div style={{ marginTop: "24px" }}>
                {
                    targetDate &&
                    `${targetDate.getFullYear()}/${targetDate.getMonth() + 1}/${targetDate.getDate()}`
                }
            </div>

            <div style={{ marginTop: "12px", marginBottom: "24px" }}>
                <IconButton
                    onClick={() => onClick("left")}
                >
                    <ArrowBackIos />
                </IconButton>
                <IconButton
                    onClick={() => onClick("right")}
                >
                    <ArrowForwardIos />
                </IconButton>
            </div>


            <DataGridPremium
                rows={rows}
                columns={column}
                filterModel={filterModel}
                onFilterModelChange={(model) => {
                    setFilterModel(model);
                }}
                loading={loading}
            />
        </div>
    )
}

export default CatchBreak;