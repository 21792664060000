import React, { useEffect, useRef, useState } from "react"
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "1350px",
    height: "850px",
    bgcolor: '#303030',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: "auto",
    cursor: "grab",
    "@media (max-width : 1024px)" : {
        "@media (orientation : landscape)" : {
            width : "1000px",
            height : "650px"
        },
        "@media (orientation : portrait)" : {
            width : "1150px"
        }
    }
};

interface props {
    handleClose : () => void;
    src : string;
    open : boolean
}




const ImageModal: React.FC<props> = (props) => {

    // const [open, setOpen] = React.useState(false);
    const [src, setSrc] = useState("");
    const answerRefs = useRef<any>([]);
    answerRefs.current = [];
    const dragRef = useRef<HTMLDivElement>(null);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const [startScrollLeft, setStartScrollLeft] = useState(0);
    const [startScrollTop, setStartScrollTop] = useState(0);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [percent, setPercent] = useState(100);
    const [isLandscape, setIsLandScape] = useState(true);
    const [modalImgStyle, setModalImageStyle] = useState<any>({ width: "auto", height: "auto", transform: "rotate(0deg)" });
    const [walkx, setWalkx] = useState(0);
    const [walky, setWalky] = useState(0);
    // const handleOpen = () => { setOpen(true); }
    const handleClose = () => { props.handleClose(); setIsMouseDown(false); }

    const textStyle = {
        fontFamily: "Apple_B"
    }

    useEffect(() => {
        if(!props.open){
            return;
        }

        if(props.src){
            setSrc(props.src);
        }

    }, [props.src, props.open])

    useEffect(() => {

        if(!src){
            return;
        }

        

        show(src);

    }, [src]);


    const show = (src : string) => {
        
        setPercent(100);

        if(!src.split("/public/")[1]){
            return;
        }

        setSrc(`https://peetsunbae.com/${src.split("/public/")[1]}`);
        const img = new Image();
        img.src = `https://peetsunbae.com/${src.split("/public/")[1]}`;
        img.onload = function () {
            
            
            if (img.width > 1.5 * img.height) {
                
                setIsLandScape(true);
                setModalImageStyle(
                    (prevStyle: any) => {
                        return {
                            ...prevStyle,
                            width: "1200px",
                            transform: "rotate(0deg)",
                        }
                    }
                )
            } else {
                setIsLandScape(false);
                
                setModalImageStyle(
                    (prevStyle: any) => {
                        return {
                            ...prevStyle,
                            height: "800px",
                            transform: "rotate(0deg)",
                        }
                    }
                )
            }

        }
    }

    const letsPlus = () => {
        const newPercent = percent + 10;
        if (isLandscape) {
            const newWidth = +modalImgStyle.width.split("px")[0] * 1.1;

            setModalImageStyle(
                (prevStyle: any) => {
                    return {
                        ...prevStyle,
                        height: "auto",
                        width: `${newWidth}px`,
                    }
                }
            )

            setPercent(newPercent);
        } else {
            
            const newHeight = +modalImgStyle.height.split("px")[0] * 1.1;

            setModalImageStyle(
                (prevStyle: any) => {
                    return {
                        ...prevStyle,
                        width: "auto",
                        height: `${newHeight}px`,
                    }
                }
            )
            setPercent(newPercent);
        }
    }

    const letsMaximize = () => {
        if (isLandscape) {
            setModalImageStyle(
                (prevStyle: any) => {
                    return {
                        ...prevStyle,
                        width: "1200px",
                        height: "auto"
                    }
                }
            )
            setPercent(100);
        } else {
            setModalImageStyle(
                (prevStyle: any) => {
                    return {
                        ...prevStyle,
                        height: "800px",
                        width: "auto"
                    }
                }
            )
            setPercent(100);
        }
    }

    const letsMinus = () => {
        const newPercent = percent - 10;
        if (isLandscape) {
            const newWidth = +modalImgStyle.width.split("px")[0] * 0.9;

            setModalImageStyle(
                (prevStyle: any) => {
                    return {
                        ...prevStyle,
                        width: `${newWidth}px`,
                    }
                }
            )
            setPercent(newPercent);
        } else {
            
            const newHeight = +modalImgStyle.height.split("px")[0] * 0.9;

            setModalImageStyle(
                (prevStyle: any) => {
                    return {
                        ...prevStyle,
                        height: `${newHeight}px`,
                    }
                }
            )
            setPercent(newPercent);
        }
    }

    const letsRotate = () => {
        var newDegree = modalImgStyle.transform;

        newDegree = newDegree.replace("rotate(", "");
    
        newDegree = +newDegree.replace(/deg\).*$/gm, "");
        newDegree -= 90;


        setModalImageStyle(
            (prevStyle: any) => {
                return {
                    ...prevStyle,
                    transform: `rotate(${newDegree}deg)`,
                }
            }
        )

    }


    const handleDown = (e: any) => {
        if (!dragRef.current?.contains(e.target)) {
            return;
        }
        setIsMouseDown(true);
        setStartX(e.pageX - dragRef.current.offsetLeft);
        setStartY(e.pageY - dragRef.current.offsetTop);
        setStartScrollLeft(dragRef.current.scrollLeft);
        setStartScrollTop(dragRef.current.scrollTop);

    }

    const handleMove = (e: any) => {

        if (isMouseDown) {
            e.preventDefault();

            const mouseX = e.pageX - dragRef.current!.offsetLeft;
            const mouseY = e.pageY - dragRef.current!.offsetTop;
            // Distance of the mouse from the origin of the last mousedown event
            const walkX = mouseX - startX;
            const walkY = mouseY - startY;
            // Set element scroll
            setWalkx(walkX);
            setWalky(walkY);
            dragRef.current!.scrollLeft = startScrollLeft - walkX;
            dragRef.current!.scrollTop = startScrollTop - walkY;
        }
    }

    const handleMouseUp = (e: any) => {
        
        setIsMouseDown(false);
    }






    return (
        <div className="questions">
            <Modal
                disableScrollLock={true}
                open={props.open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <>
                    <Box sx={style} ref={dragRef} onMouseMove={handleMove} onMouseUp={handleMouseUp} onMouseDown={handleDown}>
                        <div className="modalDiv" ref={dragRef} onMouseMove={handleMove} onMouseUp={handleMouseUp} onMouseDown={handleDown}>

                            <div className="imageWrapper">
                                <img style={modalImgStyle} className="modalImg" src={src} alt="question" />
                            </div>
                        </div>
                    </Box>
                    <div className="imgOperator">
                        <img onClick={letsMinus} className="minus" src="img/minus-circle-light.svg" alt="minus" />
                        <div className="percentDiv">
                            {percent}%
                        </div>
                        <img onClick={letsPlus} className="plus" src="img/plus-circle-light.svg" alt="plus" />
                        <img onClick={letsMaximize} className="maximize" src="img/expand-arrows-light.svg" alt="maximize" />
                        <img onClick={letsRotate} className="rotate" src="img/undo-light.svg" alt="rotate" />
                    </div>
                </>
            </Modal>
        </div>
    )
}

export default ImageModal;