import React, { useEffect, useState } from "react";
import { resourceLimits } from "worker_threads";
import styles from "../dashboard/componentsStyle/regularparentpermit.module.css";
import { ReactComponent as LogoSvg } from '../svg/newlogo.svg';
import { Backdrop, CircularProgress, ToggleButton, ToggleButtonGroup } from '@mui/material';

const WifiPermitForParent: React.FC<any> = (props) => {

    const [id, setId] = useState();
    const [permitState, setPermitState] = useState<any>();
    const [loading, setLoading] = useState(false);


    useEffect(() => {

        setLoading(true);

        

        const meta = document.createElement("meta");
        meta.name = "viewport";
        meta.content = "width=device-width, initial-scale=1";
        document.getElementsByTagName("head")[0].appendChild(meta);


        const url = window.location.href;
        var query = url.split("?wifipermitId=")[1];

        const id = query.split("&pw=")[0];
        query = query.split("&pw=")[1];

        const pw = query.split("&permit=")[0];
        const permit = query.split("&permit=")[1];

        


        if (+pw === (500 * (+id)) + 10) {
            fetch(`https://peetsunbae.com/dashboard/wifipermit/parentpermit`, {
                method: "post",
                headers: { "content-type": "application/json" },
                body: JSON.stringify({
                    id, permit, pw
                })
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        

                        if(result.message === "ALREADY_DECIDED"){
                            setPermitState(99);
                            setLoading(false);
                            return;
                        }

                        if(result.message !== "success") {
                            return;
                        }

                        if (permit === "1") {
                            setPermitState(1);
                        }
                
                        if (permit === "2") {
                            setPermitState(2);
                        }

                        setLoading(false);
                        
                    })
            })
        }

    }, []);

    return (
        <div className={styles.main}>

            {
                (permitState && permitState === 1 && !loading) &&
                <div className={styles.text}>
                    방화벽 해제 신청이<br></br><span style={{ color: "#1b49af" }}>승인 처리</span> 되었습니다.
                </div>
            }

            {
                (permitState && permitState === 2 && !loading) &&
                <div className={styles.text}>
                    방화벽 해제 신청이<br></br><span style={{ color: "red" }}>승인 거절</span>처리 되었습니다.
                </div>
            }

            {
                (permitState && permitState === 99 && !loading) &&
                <div className={styles.text}>
                    이미 <span style={{ color: "red" }}>처리 완료 된</span><br></br>결과 입니다.
                </div>
            }

            <div className={styles.logoDiv}>
                <LogoSvg className={styles.newlogo} />
            </div>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default WifiPermitForParent;