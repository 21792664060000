import React, { useEffect, useState } from "react";
import styles from "../styles/manageconsult.module.css";
import { getFetch } from "../../../../../fetch/fetch";
import { max, set } from "lodash";
import { Autocomplete, Backdrop, Checkbox, CircularProgress, FormControlLabel, TextField } from "@mui/material";
import totalLocationData from "../../../../../data/locationdata";
import { Button } from "@mui/joy";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SkeletonComp from "../../etc/skeletoncomp";

const universityListExample = [
    "가천대학교",
    "가톨릭대학교",
    "강원대학교",
    "건국대학교",
    "건양대학교",
    "경기대학교",
    "경남대학교",
    "경북대학교",
    "경상국립대학교",
    "경희대학교",
    "고려대학교",
    "광운대학교",
    "국민대학교",
    "단국대학교",
    "덕성여자대학교",
    "대구가톨릭대학교",
    "대구한의대학교",
    "동국대학교",
    "동덕여자대학교",
    "명지대학교",
    "부산대학교",
    "삼육대학교",
    "상명대학교",
    "서울과학기술대학교",
    "서울대학교",
    "서울시립대학교",
    "성균관대학교",
    "성신여자대학교",
    "세종대학교",
    "숙명여자대학교",
    "순천대학교",
    "순천향대학교",
    "숭실대학교",
    "아주대학교",
    "연세대학교",
    "용인대학교",
    "울산대학교",
    "을지대학교",
    "이화여자대학교",
    "인하대학교",
    "전남대학교",
    "전북대학교",
    "제주대학교",
    "조선대학교",
    "중앙대학교",
    "차의과대학교",
    "충남대학교",
    "충북대학교",
    "포항공과대학교",
    "한국외국어대학교",
    "한국체육대학교",
    "한국항공대학교",
    "한양대학교",
    "홍익대학교"
]

const totalInputListExample: any = [
    {
        title: "[사진] 1번 내용을 입력해주세요. (15글자 이내)*",
        kind: "text",
        id: 1,
        maxLength: 15,
        value: ""
    },
    {
        title: "[사진] 2번 내용을 입력해주세요. (20글자 이내)*",
        kind: "text",
        id: 2,
        maxLength: 20,
        value: ""
    },
    {
        title: "[사진] 3번 내용을 입력해주세요. (5글자 이내)*",
        kind: "text",
        id: 3,
        maxLength: 5,
        value: ""
    },
    {
        title: "[사진] 4번 내용을 입력해주세요.",
        kind: "multiText",
        multi: [
            {
                title: "첫번째줄 (31자 이내)*",
                id: 4,
                maxLength: 31,
                value: ""
            },
            {
                title: "두번째줄 (31자 이내)*",
                id: 5,
                maxLength: 31,
                value: ""
            }
        ]
    },
    {
        title: "[사진] 5번 내용을 입력해주세요.",
        kind: "multiMultiText",
        multi: [
            {
                title: "국어",
                multi: [
                    {
                        title: "강사1 (7자 이내)*",
                        id: 6,
                        maxLength: 7,
                        value: "",
                        isRequire: true
                    },
                    {
                        title: "강사2 (7자 이내) - 없으면 빈칸",
                        id: 7,
                        maxLength: 7,
                        value: "",
                        isRequire: false
                    }
                ]
            },
            //국어, 수학, 영어, 탐구
            {
                title: "수학",
                multi: [
                    {
                        title: "강사1 (7자 이내)*",
                        id: 8,
                        maxLength: 7,
                        value: "",
                        isRequire: true
                    },
                    {
                        title: "강사2 (7자 이내) - 없으면 빈칸",
                        id: 9,
                        maxLength: 7,
                        value: "",
                        isRequire: false
                    }
                ]
            },
            {
                title: "영어",
                multi: [
                    {
                        title: "강사1 (7자 이내)*",
                        id: 10,
                        maxLength: 7,
                        value: "",
                        isRequire: true
                    },
                    {
                        title: "강사2 (7자 이내) - 없으면 빈칸",
                        id: 11,
                        maxLength: 7,
                        value: "",
                        isRequire: false
                    }
                ]
            },
            {
                title: "탐구",
                multi: [
                    {
                        title: "강사1 (7자 이내)*",
                        id: 12,
                        maxLength: 7,
                        value: "",
                        isRequire: true
                    },
                    {
                        title: "강사2 (7자 이내) - 없으면 빈칸",
                        id: 13,
                        maxLength: 7,
                        value: "",
                        isRequire: false
                    }
                ]
            }
        ]
    },
    {
        title: "[사진] 6번 내용 학교를 선택해주세요*",
        kind: "select",
        id: 14,
        value: null
    },
    {
        title: "수능선배 학생들에게 어떤 담임멘토가 되어주실 건가요?*",
        kind: "text",
        id: 15,
        value: "",
        rows: 5
    },
    {
        title: " 학습지도시 가장 중요하게 생각하는 것은?*",
        kind: "text",
        id: 16,
        value: "",
        rows: 5
    },
    {
        title: "어떤 유형의 학생을 맡았을 때 구체적으로 어떤 도움을 주실 수 있나요?*",
        kind: "text",
        id: 17,
        value: "",
        rows: 5
    },
    {
        title: "이런 학생이라면 저를 추천 합니다.*",
        kind: "text",
        id: 18,
        value: "",
        rows: 5
    },
    {
        title: "본인은 어떤 스타일로 공부하여 합격하셨나요?*",
        kind: "text",
        id: 19,
        value: "",
        rows: 5
    },
]



const WriteMentorDescription: React.FC<any> = (props) => {

    const [mentorList, setMentorList] = useState<any>([]);
    const [universityList, setUniversityList] = useState<any>([]);
    const [loading1, setLoading1] = useState(false);
    const [selectedMentor, setSelectedMentor] = useState<any>(null);
    const [totalInputList, setTotalInputList] = useState<any>([...totalInputListExample]);
    const [locationList, setLocationList] = useState<string[]>([]);
    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    const notify1 = () => toast.success("저장 성공!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "colored"
    });

    const notify2 = () => toast.error("전송 실패!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "colored"
    });

    useEffect(() => {

        if(!selectedMentor){
            return;
        }

        const userId = selectedMentor.id;

        getMentorDescriptionInfo(userId);


    }, [selectedMentor]);

    const getMentorDescriptionInfo = async (userId : number) => {

        if(!userId){
            return;
        }

        //mentorDescriptionInfo?userId=userId

        try{

            setLoading2(true);

            const response = await fetch(`https://suneungsunbae.com/api/upload/mentorDescriptionInfo?userId=${userId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            });

            const result = await response.json();

            

            if(result.message === "success"){

                if(result.data.length === 0){

                    setLocationList([]);
                    setTotalInputList([...totalInputListExample]);
                    setLoading2(false);
                    return;
                }

                const data = result.data[0];


                const locationList = data.locationList;
                const totalInputList = data.totalInputList;

                setLocationList([...locationList]);
                setTotalInputList([...totalInputList]);

            }

            setLoading2(false);

        }catch(e){

            

        }

    }

    useEffect(() => {

        //locationList가 한개라도 true AND totalInputList의 value가 모두 채워져있으면 disabled를 false로 바꾼다.
        var isLocationListTrue = false;

        locationList.forEach((each: any) => {
            if (each) {
                isLocationListTrue = true;
            }
        });

        var isAllValueFilled = {
            text: false,
            multiText: false,
            multiMultiText: false,
            select: false
        };

        var count = 0;

        totalInputList.forEach((each: any) => {
            if (each.kind === "text" && !each.value) {
                count++;
            }
        });

        if (count === 0) {
            isAllValueFilled.text = true;
        }

        count = 0;

        totalInputList.forEach((each: any) => {
            if (each.kind === "multiText") {
                each.multi.forEach((each2: any) => {
                    if (!each2.value) {
                        count++;
                    }
                });
            }
        });

        if (count === 0) {
            isAllValueFilled.multiText = true;
        }

        count = 0;

        totalInputList.forEach((each: any) => {
            if (each.kind === "multiMultiText") {
                each.multi.forEach((each2: any) => {
                    each2.multi.forEach((each3: any) => {
                        if (!each3.value && each3.isRequire) {
                            count++;
                        }
                    });
                });
            }
        });

        if (count === 0) {
            isAllValueFilled.multiMultiText = true;
        }

        count = 0;

        totalInputList.forEach((each: any) => {
            if (each.kind === "select" && !each.value) {
                count++;
            }
        });

        if (count === 0) {
            isAllValueFilled.select = true;
        }

        if (selectedMentor && isLocationListTrue && isAllValueFilled.text && isAllValueFilled.multiText && isAllValueFilled.multiMultiText && isAllValueFilled.select) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }

    }, [locationList, totalInputList, selectedMentor]);


    useEffect(() => {

        //mentorList를 가져온다.
        getMentorList();


    }, []);

    useEffect(() => {

        const array: any = [];

        universityListExample.forEach((each: any) => {

            const row: any = {};
            row.label = each;
            array.push(row);
        });

        setUniversityList([...array]);

    }, []);

    const getMentorList = async () => {

        try {

            setLoading1(true);

            const result: any = await getFetch("/dashboard/report/getmentorlist");

            

            if (result.message !== "success") {
                return;
            }

            const rows: any = [];

            result.data.forEach((each: any, index: number) => {
                var data: any = {};
                data.id = each.id;
                data.label = each.name + " (" + each.id + ")";
                data.phoneNumber = each.phoneNumber;
                data.value = each.value;
                data.key = each.id ? each.id + index : Math.random();

                rows.push(data);
            })

            

            setMentorList([...rows]);

            setLoading1(false);

        } catch (e) {
            
            setLoading1(false);
        }

    }

    function hasNoNumbers(text: string) {
        // \d는 '숫자'에 해당하는 정규식 패턴입니다.
        const containsNumber = /\d/.test(text);

        if (containsNumber) {
            // 숫자가 하나라도 있으면 false
            return false;
        } else {
            // 숫자가 전혀 없으면 true
            return true;
        }
    }

    const handleChange = (e: any, maxLength: number, each: any) => {

        if (e.target.value.length > maxLength) {
            return;
        }

        const id = each.id;
        const value = e.target.value;

        const newTotalInputList = totalInputList;

        newTotalInputList.forEach((each2: any) => {

            if (each2.id === id) {
                each2.value = value;
            }

            if (each2.kind === "multiText") {
                each2.multi.forEach((each3: any) => {
                    if (each3.id === id) {
                        each3.value = value;
                    }
                })
            }

            if (each2.kind === "multiMultiText") {
                each2.multi.forEach((each3: any) => {
                    each3.multi.forEach((each4: any) => {
                        if (each4.id === id) {
                            each4.value = value;
                        }
                    })
                })
            }

        });

        setTotalInputList([...newTotalInputList]);

    }

    const submit = async () => {

        
        
        

        try{

            setLoading(true);

            const data = {
                userId : selectedMentor.id,
                teacherId : props.user.id,
                locationList,
                totalInputList
            }

            const response = await fetch("https://suneungsunbae.com/api/upload/mentorDescription", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            });

            const result = await response.json();

            

            if(result.message === "success"){
                notify1();

                setSelectedMentor(null);
                setLocationList([]);
                setTotalInputList([...totalInputListExample]);

            }else{
                notify2();
            }

            setLoading(false);

        }catch(e){
            notify2();
            
            setLoading(false);
        }

    }

    return (
        <div>
            <div className={styles.page}>
                <div className={styles.flexPage}>
                    <div className={styles.flexChild1}>
                        <div className={styles.questionBox}>
                            <div className={styles.title}>
                                멘토를 선택해주세요.
                            </div>
                            <div className={styles.autocompleteWrapper}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={mentorList}
                                    value={selectedMentor}
                                    onChange={(event: any, newValue: any) => {
                                        
                                        setSelectedMentor(newValue);
                                    }}
                                    renderInput={(params: any) => <TextField {...params}
                                    />}
                                    size="small"
                                />
                            </div>
                        </div>
                        <div className={styles.withImage}>
                            <div>
                                <img src="/img/mentor/examplelist.webp" alt="examplelist"
                                    style={{
                                        width: "100%",
                                        height: "auto"
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles.flexChild2}>
                        {
                            (selectedMentor && loading2) &&
                            <SkeletonComp />
                        }
                        {
                            (selectedMentor && !loading2) &&
                            <>
                                <div className={styles.questionBox}>
                                    <div className={styles.title}>
                                        <span>Q1.</span>멘토가 일하는 지점을 선택해주세요.*
                                    </div>
                                    <div>
                                        {/* <FormControlLabel
                                label={<span className={styles.justText}>강남점</span>}
                                control={<CheckBox checked={locationList.includes("강남점")} onChange={(e : any) => {handleCheck} /> */}
                                        {
                                            totalLocationData.filter((each: any) =>
                                                (each.academy.includes("sunbae") || each.academy.includes("bundangsunbae")) && each.english !== "total" && hasNoNumbers(each.english)
                                            ).map((each: any) => (
                                                <FormControlLabel
                                                    label={<span className={styles.justText}>{each.korean}</span>}
                                                    control={<Checkbox checked={locationList.includes(each.english)} onChange={(e: any) => {
                                                        if (locationList.includes(each.english)) {
                                                            setLocationList(locationList.filter((each2: any) => each2 !== each.english));
                                                        } else {
                                                            setLocationList([...locationList, each.english]);
                                                        }
                                                    }} />}
                                                />
                                            ))
                                        }
                                    </div>
                                </div>
                                {/* <div className={styles.questionBox}>
                            <div className={styles.title}>
                                [사진] 1번 내용을 입력해주세요. (15글자 이내)
                            </div>
                            <div className={styles.textfieldWrapper}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    data-id="1"
                                />
                            </div>
                        </div> */}
                                {
                                    totalInputList.map((each: any, index: number) => {

                                        var isMultiLine = false;
                                        var rows = 1;
                                        var maxLength = 9999999;

                                        if (each.rows) {
                                            isMultiLine = true;
                                            rows = each.rows;
                                        }

                                        if (each.maxLength) {
                                            maxLength = each.maxLength;
                                        }

                                        return (
                                            <div className={styles.questionBox}>
                                                <div className={styles.title}>
                                                    <span>Q{index + 2}.</span> {each.title}
                                                </div>
                                                {
                                                    each.kind === "text" &&
                                                    <div className={styles.textfieldWrapper}>
                                                        <TextField
                                                            rows={rows}
                                                            multiline={isMultiLine}
                                                            variant="outlined"
                                                            fullWidth
                                                            size="small"
                                                            onChange={(e: any) => {
                                                                handleChange(e, maxLength, each);
                                                            }}
                                                            value={each.value}
                                                            helperText={`${each.value.length}${maxLength !== 9999999 ? "/" + maxLength : ""}`}
                                                        />
                                                    </div>
                                                }
                                                {
                                                    each.kind === "multiText" &&
                                                    <div>
                                                        {
                                                            each.multi.map((each2: any) => {
                                                                return (
                                                                    <div className={`${styles.textfieldWrapper} ${styles.multiTextWrapper}`}>
                                                                        <div className={styles.multiTextTitle}>
                                                                            - {each2.title}
                                                                        </div>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            size="small"
                                                                            onChange={(e: any) => {
                                                                                handleChange(e, each2.maxLength, each2);
                                                                            }}
                                                                            value={each2.value}
                                                                            multiline={isMultiLine}
                                                                            rows={rows}
                                                                            helperText={`${each2.value.length}/${each2.maxLength}`}
                                                                        />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                }
                                                {
                                                    each.kind === "multiMultiText" &&
                                                    <div>
                                                        {
                                                            each.multi.map((each2: any) => {
                                                                return (
                                                                    <div className={styles.multiMultiText}>
                                                                        <div className={styles.multiTextTitle2}>
                                                                            - {each2.title}
                                                                        </div>
                                                                        <div className={styles.multiMultiText2}>
                                                                            {
                                                                                each2.multi.map((each3: any) => {
                                                                                    return (
                                                                                        <div className={`${styles.multiMultiTextWrapper}`}>
                                                                                            <div className={styles.multiTextTitle}>
                                                                                                {each3.title}
                                                                                            </div>
                                                                                            <TextField
                                                                                                variant="outlined"
                                                                                                fullWidth
                                                                                                size="small"
                                                                                                onChange={(e: any) => {
                                                                                                    handleChange(e, each3.maxLength, each3);
                                                                                                }}
                                                                                                value={each3.value}
                                                                                                multiline={isMultiLine}
                                                                                                rows={rows}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                }
                                                {
                                                    each.kind === "select" &&
                                                    <div className={styles.textfieldWrapper}>
                                                        <Autocomplete
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            options={universityList}
                                                            value={each.value}
                                                            onChange={(event: any, newValue: any) => {
                                                                const newTotalInputList = totalInputList;

                                                                newTotalInputList.forEach((each2: any) => {
                                                                    if (each2.id === each.id) {
                                                                        each2.value = newValue.label;
                                                                    }
                                                                });

                                                                setTotalInputList([...newTotalInputList]);
                                                            }}
                                                            renderInput={(params: any) => <TextField {...params}
                                                            />}
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </div>
                                                }


                                            </div>
                                        )
                                    })
                                }

                                <div className={styles.submitButtonWrapper}>
                                    <Button color="primary" sx={{
                                        width: "650px",
                                        marginTop: "20px"
                                    }}
                                        size="lg"
                                        disabled={disabled}
                                        onClick={submit}
                                    >
                                        제출하기
                                    </Button>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>



            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default WriteMentorDescription;