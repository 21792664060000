import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import styles from "../componentsStyle/diagnosis.module.css"

import { Alert, LinearProgress, Stack } from '@mui/material';
import { Helmet } from "react-helmet";





const DiagnosisForParent: React.FC<any> = (props) => {


    const [index, setIndex] = useState(0);
    const [loading, setLoading] = useState(true);

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const [value, setValue] = useState<string[]>(new Array(100).fill(""));
    const [teacherName, setTeacherName] = useState("");
    const [studentName, setStudentName] = useState("");

    const textFieldIndex = [
        6, 7, 8, 10, 11, 12, 14, 15, 16, 19, 20, 21, 23, 24, 25, 27, 28, 29, 31, 32, 33, 36, 37, 38, 40, 41, 42, 44
    ]



    const titleFieldIndex = [
        {
            index: 0,
            text: "과목"
        },
        {
            index: 1,
            text: "진단"
        },
        {
            index: 2,
            text: "추천"
        },
        {
            index: 3,
            text: "추천"
        },
        {
            index: 4,
            text: "국어"
        },
        {
            index: 5,
            text: "문학"
        },
        {
            index: 9,
            text: "독서"
        },
        {
            index: 13,
            text: "선택"
        },
        {
            index: 17,
            text: "수학"
        },
        {
            index: 18,
            text: "수1"
        },
        {
            index: 22,
            text: "수2"
        },
        {
            index: 26,
            text: "선택"
        },
        {
            index: 30,
            text: "영어"
        },
        {
            index: 34,
            text: "탐구"
        },
        {
            index: 35,
            text: "선택"
        },
        {
            index: 39,
            text: "선택"
        },
        {
            index: 43,
            text: "추가사항"
        },
    ]

    const getData = async (diagnosisId: number) => {

        setLoading(true);

        var token = "";

        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        fetch("https://peetsunbae.com/dashboard/chart/diagnosisgetforparent?diagnosisId=" + diagnosisId, {
            method: "GET",
            headers: {
                "Authorization": token
            },
            credentials: "include"
        }
        ).then((response) => {
            response.json()
                .then((result) => {
                    
                    if (result.message === "success") {
                        const newValue = result.data.data;
                        setValue([...newValue]);
                        setTeacherName(result.data.teacherName);
                        setStudentName(result.data.studentName);
                    } else {
                        const newValue: any = new Array(100).fill("");
                        setValue([...newValue]);
                    }

                    setLoading(false);
                })
        }).catch((e) => {
            
            setLoading(false);
        })
    }


    useEffect(() => {
        const id = query.get("id");

        if (!id) {
            
            return;
        }

        getData(+id);

    }, []);

    return (
        <div className={styles.main}>

                <div className={styles.manageDivTitle}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span>학습 진단({new Date().getMonth() + 1}월 {new Date().getDate()}일) - {teacherName}</span>
                    </div>
                    <div>
                        {
                            studentName
                        }
                    </div>
                </div>

                <div className={styles.manageDivTable}>
                    {
                        new Array(45).fill(undefined).map((each: undefined, index: number) => {

                            var isTextField = false;

                            if (textFieldIndex.includes(index)) {
                                isTextField = true;
                            }

                            var text = "";

                            titleFieldIndex.forEach((each) => {
                                if (each.index === index) {
                                    text = each.text;
                                }
                            })

                            return (
                                <div key={index} className={`${styles.cell} ${styles["cell" + index]} ${!isTextField ? styles.titleTableCell : styles.textFieldCell}`}>
                                    {text}
                                    {
                                        isTextField
                                        &&
                                        <textarea value={value[index]} onChange={(e) => { return; }} className={styles.textarea} />
                                    }
                                </div>
                            )

                        })
                    }
                </div>

            {loading &&
                <div className={styles.linearProgress}>
                    <LinearProgress />
                </div>
            }
        </div>
    );
}

export default DiagnosisForParent;