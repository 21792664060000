import React, { useEffect, useState } from 'react';
import styles from "../../componentsStyle/jobattendance.module.css";
import totalLocationData from '../../../data/locationdata';
import { getFetch, postFetch } from '../../../fetch/fetch';
import { Button, LinearProgress } from '@mui/joy';
import { fi, fil } from 'date-fns/locale';
import { DataGridPremium, GridRowModel, GridToolbar } from '@mui/x-data-grid-premium';
import { CalendarToday } from '@mui/icons-material';
import { set } from 'lodash';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

const JobAttendance: React.FC<any> = (props) => {

    const [userData, setUserData] = useState<any>(null);
    const [mentorList, setMentorList] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [loading2, setLoading2] = useState<boolean>(false);

    const [alignment, setAlignment] = React.useState<string | null>('total');

    const [selectedId, setSelectedId] = useState<any>(null);
    const [rows, setRows] = useState<any>([]);
    const [columns, setColumns] = useState<any>([
        { field : "targetDate", headerName : "적용 날짜", width : 100, editable : true, sortable : true, type : "date",
            filterable : true,
            renderCell: (params : any) => {
                if (!params.value) {
                    return (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <CalendarToday style={{ cursor: "pointer" }} fontSize="small" color="disabled" />
                        </div>
                    )
                } else {
                    const date = new Date(params.value);
                    const dateString = `${date.getMonth() + 1}월 ${date.getDate()}일`;

                    return (
                        <div>
                            {dateString}
                        </div>
                    )
                }
            }
        },
        { field : "description", headerName : "메모 내용", width : 300, editable : true},
        { field : "author", headerName : "작성자", width : 100 },
        { field : "createdAt", headerName : "작성일", width : 130 },
    ]);
    const [update, setUpdate] = useState(0);
    const [filterModel, setFilterModel] = useState<any>({
        items : [
            {
                id : 2, field : "targetDate", value : null, operatorValue : "is"
            }
        ]
    });

    useEffect(() => {

        jobAttendanceStart();

    }, []);

    useEffect(() => {

        getJobChange();

    }, [update]);

    const jobAttendanceStart = async () => {

        try {

            setLoading(true);

            const result: any = await getFetch("/dashboard/avatar/jobattendance");

            

            const data = result.data;

            //기록없음, 기록 있는데 in인지 out인지, 메모

            data.forEach((each: any) => {

                var name = each.name;

                //name에서 띄어쓰기 뒷부분 제거, split이용
                const split = name.split(" ")[0];
                each.name = split;

                const accessControl = each.accessControl;

                if (!accessControl || accessControl.length === 0) {
                    each.currentStatus = "never";
                    each.currentStatusString = "기록없음";
                } else {

                    const last = accessControl[0];

                    const lastDirection = last.direction;
                    const currentStatus = lastDirection === "inside" ? "out" : lastDirection === "outside" ? "in" : "";

                    each.currentStatus = currentStatus;

                    const lastDate = new Date(+last.time);
                    const lasthour = lastDate.getHours();
                    const lastHourString = lasthour < 10 ? `0${lasthour}` : `${lasthour}`;
                    const lastminute = lastDate.getMinutes();
                    const lastMinuteString = lastminute < 10 ? `0${lastminute}` : `${lastminute}`;
                    const timeString = `${currentStatus} ${lastHourString}:${lastMinuteString}`;

                    each.currentStatusString = timeString;

                }
            })

            setUserData([...data]);

            const totalMentorList = data.filter((each: any) => each.job === "mentor");

            const newMentorList = [];

            //newMentorList에 10개씩 이중 배열로 저장
            for (let i = 0; i < totalMentorList.length; i += 10) {
                newMentorList.push(totalMentorList.slice(i, i + 10));
            }

            

            setMentorList([...newMentorList]);

            setLoading(false);

        } catch (e) {
            
        }

    }

    const data1 = ["오전", "오후", "저녁"];
    const data11 = ["주간", "야간"];

    const getJobChange = async () => {
        
        try{

            setLoading2(true);

            const result : any = await getFetch("/dashboard/avatar/jobchange");
            

            const data = result.data;

            const newRows : any = [];

            data.forEach((each : any) => {

                const oneRow : any = {};
                oneRow.id = each.id;
                oneRow.author = each.name;
                oneRow.description = each.description;
                
                const date = new Date(each.updatedAt);
                const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;

                oneRow.createdAt = dateString;
                if(each.targetDate){
                const targetDate = new Date(each.targetDate);
                oneRow.targetDate = targetDate;
                }

                newRows.push(oneRow);

            })

            setRows([...newRows]);

            setLoading2(false);

        }catch(e){
            
        }

    }

    const deleteJobChange = async () => {

        if(!selectedId){
            return;
        }

        if(!window.confirm("메모를 삭제하시겠습니까?")){
            return;
        }

        try{

            setLoading2(true);

            const result : any = await postFetch("/dashboard/avatar/jobchangedelete", {id : selectedId});
    
            
            
            if(result.message !== "success"){
                alert("삭제에 실패했습니다.");
            }
            setUpdate(Math.random());

        }catch(e){
            
        }

    }

    const addJobChange = async () => {

        try{

            if(!window.confirm("새로운 열을 추가하시겠습니까?")){
                return;
            }

            setLoading2(true);

            const result = await postFetch("/dashboard/avatar/jobchange", {});

            

            setUpdate(Math.random());


        }catch(e){
            
        }

    }

    const handleEditCommit = React.useCallback(
        async (newRow: GridRowModel, old: any) => {

            var field = "";

            

            for (const [key, value] of Object.entries(newRow)) {
                if (value !== old[key]) {
                    field = key;
                }
            }

            const value = newRow[field] ? newRow[field] : null;
            const id = newRow.id;

            
            
            
            

            if (!field) {
                
                return newRow;
            }

            if (!id) {
                
                return newRow;
            }

            const data = {
                value : field !== "targetDate" ? value : new Date(value).getTime(),
                id,
                field
            }

            

            try {

                fetch("https://peetsunbae.com/dashboard/avatar/jobchange", {
                    method : "put",
                    headers : {
                        "Content-Type" : "application/json"
                    },
                    credentials : "include",
                    body : JSON.stringify(data)
                }).then(response => {
                    response.json().then((result) => {
                        
                        if(result.message !== "success"){
                            alert("수정에 실패했습니다.");
                            setUpdate(Math.random());
                        }
                    })
                })

            } catch (e) {
                
            }

            return newRow;

        }, []
    );

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        
        alert(error.message);
    }, []);

    const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {

        

        if(!newAlignment){
            return;
        }

        setAlignment(newAlignment);

        const newFilterModel = filterModel;


        if(newAlignment === "today"){
            
            const today = new Date();
            const todayString = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;

            const value = todayString;

            newFilterModel.items.forEach((each : any) => {
                if(each.id === 2){
                    each.value = value;
                }
            })
        
        }

        //newAlignment가 total이면 null로 바꿔줌
        if(newAlignment === "total"){
            newFilterModel.items.forEach((each : any) => {
                if(each.id === 2){
                    each.value = null;
                }
            })
        }


        setFilterModel({...newFilterModel});

    }

    return (
        <>
            {
                loading ?
                    <div style={{ width: "1200px", margin: "0 auto", marginTop: "30px" }}>
                        <LinearProgress color="neutral" />
                    </div>
                    :
                    ""
            }
            {
                !loading &&
                <div className={styles.main}>

                    <div className={styles.mainBlock}>
                        <div className={styles.firstLine}>
                            <div className={styles.firstTable}>
                                <div className={styles.title}>
                                    데스크
                                </div>
                                <div>
                                    <table>
                                        <tr>
                                            <th></th>
                                            {
                                                (props.user && props.user.academy) &&
                                                totalLocationData.map((each, index) => {

                                                    const academy = props.user.academy;

                                                    if (!each.academy.includes(academy)) {
                                                        return null;
                                                    }

                                                    if (each.english === "total") {
                                                        return null;
                                                    }

                                                    return (
                                                        <th key={index}>{each.korean}</th>
                                                    )

                                                })
                                            }
                                        </tr>
                                        {
                                            data1.map((each, index) => {

                                                const data2 = [1, 2, 3];

                                                return (
                                                    data2.map((each2, index2) => {

                                                        return (
                                                            <tr key={index2}>
                                                                <th>{each2 === 1 ? each : ""}</th>
                                                                {
                                                                    (props.user && props.user.academy) &&
                                                                    totalLocationData.map((each3, index3) => {

                                                                        const academy = props.user.academy;

                                                                        if (!each3.academy.includes(academy)) {
                                                                            return null;
                                                                        }

                                                                        if (each3.english === "total") {
                                                                            return null;
                                                                        }

                                                                        return (
                                                                            <td key={index3}
                                                                                className={`${each2 === 1 ? styles.name : ""}
                                                                ${each2 === 2 ? styles.attendance : ""}
                                                                ${each2 === 3 ? styles.memo : ""}
                                                                `}
                                                                            >
                                                                                {
                                                                                    userData && userData.map((each4: any, index4: number) => {

                                                                                        const job = "desk" + (index + 1);
                                                                                        
                                                                                        const location = each3.korean;

                                                                                        if (each4.job !== job || each4.location !== location) {
                                                                                            return null;
                                                                                        }

                                                                                        const currentStatus = each4.currentStatus;

                                                                                        if (each2 === 1) {
                                                                                            return (
                                                                                                <span key={index4}
                                                                                                    className={currentStatus === "in" ? styles.in : styles.out}
                                                                                                >
                                                                                                    {each4.name}
                                                                                                </span>
                                                                                            )
                                                                                        }



                                                                                        if (each2 === 2) {
                                                                                            return (
                                                                                                <span key={index4}
                                                                                                    className={currentStatus === "in" ? styles.in : styles.out}
                                                                                                >
                                                                                                    {each4.currentStatusString}
                                                                                                </span>
                                                                                            )
                                                                                        }

                                                                                    })
                                                                                }
                                                                            </td>
                                                                        )

                                                                    })
                                                                }
                                                            </tr>
                                                        )

                                                    })
                                                )

                                            })
                                        }
                                    </table>
                                </div>
                            </div>
                            <div>
                                <div className={styles.title}>
                                    사감
                                </div>
                                <table>
                                    <tr>
                                        <th></th>
                                        {
                                            (props.user && props.user.academy) &&
                                            totalLocationData.map((each, index) => {

                                                const academy = props.user.academy;

                                                if (!each.academy.includes(academy)) {
                                                    return null;
                                                }

                                                if (each.english === "total") {
                                                    return null;
                                                }

                                                return (
                                                    <th key={index}>{each.korean}</th>
                                                )

                                            })
                                        }
                                    </tr>
                                    {
                                        data11.map((each, index) => {

                                            const data2 = [1, 2, 3];

                                            return (
                                                data2.map((each2, index2) => {

                                                    return (
                                                        <tr key={index2}>
                                                            <th>{each2 === 1 ? each : ""}</th>
                                                            {
                                                                (props.user && props.user.academy) &&
                                                                totalLocationData.map((each3, index3) => {

                                                                    const academy = props.user.academy;

                                                                    if (!each3.academy.includes(academy)) {
                                                                        return null;
                                                                    }

                                                                    if (each3.english === "total") {
                                                                        return null;
                                                                    }

                                                                    return (
                                                                        <td key={index3}
                                                                            className={`${each2 === 1 ? styles.name : ""}
                                                                ${each2 === 2 ? styles.attendance : ""}
                                                                ${each2 === 3 ? styles.memo : ""}
                                                                `}
                                                                        >
                                                                            {
                                                                                userData && userData.map((each4: any, index4: number) => {

                                                                                    const job = "sagam" + (index + 1);
                                                                                    
                                                                                    const location = each3.korean;

                                                                                    if (each4.job !== job || each4.location !== location) {
                                                                                        return null;
                                                                                    }

                                                                                    const currentStatus = each4.currentStatus;

                                                                                    if (each2 === 1) {
                                                                                        return (
                                                                                            <span key={index4}
                                                                                                className={currentStatus === "in" ? styles.in : styles.out}
                                                                                            >
                                                                                                {each4.name}
                                                                                            </span>
                                                                                        )
                                                                                    }



                                                                                    if (each2 === 2) {
                                                                                        return (
                                                                                            <span key={index4}
                                                                                                className={currentStatus === "in" ? styles.in : styles.out}
                                                                                            >
                                                                                                {each4.currentStatusString}
                                                                                            </span>
                                                                                        )
                                                                                    }

                                                                                })
                                                                            }
                                                                        </td>
                                                                    )

                                                                })
                                                            }
                                                        </tr>
                                                    )

                                                })
                                            )

                                        })
                                    }
                                </table>
                            </div>
                        </div>
                        <div className={styles.secondLine}>
                            <div>
                                <div className={styles.title}>
                                    기타 근무
                                </div>
                                <table>
                                    {
                                        [1, 2, 3].map((each, index) => (
                                            <tr key={index}
                                                className={`${each === 1 ? styles.name : ""}
                                    ${each === 2 ? styles.attendance : ""}
                                    ${each === 3 ? styles.memo : ""}
                                    `}
                                            >
                                                {
                                                    (userData) && userData.map((each2: any, index2: number) => {

                                                        const job = "etc";
                                                        const currentStatus = each2.currentStatus;

                                                        if (each2.job !== job) {
                                                            return null;
                                                        }

                                                        //each가 1이면 이름, 2면 출입, 3이면 메모
                                                        if (each === 1) {
                                                            return (
                                                                <td key={index2}
                                                                    className={
                                                                        currentStatus === "in" ? styles.in : styles.out
                                                                    }
                                                                >
                                                                    {each2.name}
                                                                </td>
                                                            )
                                                        }

                                                        if (each === 2) {
                                                            return (
                                                                <td key={index2}
                                                                    className={
                                                                        currentStatus === "in" ? styles.in : styles.out
                                                                    }>
                                                                    {each2.currentStatusString}
                                                                </td>
                                                            )
                                                        }

                                                        if (each === 3) {
                                                            return (
                                                                <td key={index2}>
                                                                    {each2.memo}
                                                                </td>
                                                            )
                                                        }


                                                    })
                                                }
                                            </tr>
                                        ))
                                    }
                                </table>
                            </div>
                        </div>
                        <div className={styles.thirdLine}>
                            <div className={styles.title}>
                                멘토
                            </div>
                            <table>
                                {
                                    mentorList &&
                                    mentorList.map((each: any, index: number) => (

                                        [1, 2, 3].map((each2, index2) => {
                                            return (
                                                <tr key={index2}>
                                                    {
                                                        each.map((each3: any, index3: number) => {

                                                            const currentStatus = each3.currentStatus;

                                                            return (
                                                                <td key={index3}
                                                                    className={`${each2 === 1 ? styles.name : ""}
                                                        ${each2 === 2 ? styles.attendance : ""}
                                                        ${each2 === 3 ? styles.memo : ""}
                                                        `}
                                                                >
                                                                    {
                                                                        each3.job === "mentor" &&
                                                                        <span>
                                                                            {
                                                                                each2 === 1 &&
                                                                                <span
                                                                                    className={
                                                                                        currentStatus === "in" ? styles.in : styles.out
                                                                                    }
                                                                                >
                                                                                    {each3.name}
                                                                                </span>
                                                                            }
                                                                            {
                                                                                each2 === 2 &&
                                                                                <span
                                                                                    className={
                                                                                        currentStatus === "in" ? styles.in : styles.out
                                                                                    }
                                                                                >
                                                                                    {each3.currentStatusString}
                                                                                </span>
                                                                            }
                                                                        </span>
                                                                    }
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    )
                                    )
                                }
                            </table>
                        </div>
                    </div>

                    <div className={styles.secondBlock}>

                            <div className={styles.secondBlockBtns}>
                                <div>
                                    <ToggleButtonGroup
                                        color="primary"
                                        value={alignment}
                                        exclusive
                                        onChange={handleChange}
                                    >
                                        <ToggleButton value="total">전체</ToggleButton>
                                        <ToggleButton value="today">오늘만</ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                                <div>
                                <Button
                                    variant="soft"
                                    color='primary'
                                    sx={{
                                        marginRight : "10px"
                                    }}
                                    onClick={addJobChange}
                                >
                                    메모 추가
                                </Button>
                                <Button
                                    variant="soft"
                                    color="warning"
                                    disabled={!selectedId}
                                    onClick={deleteJobChange}
                                >
                                    메모 삭제
                                </Button>
                            </div>
                        </div>
                        <div style={{
                            width : "650px",
                            height : "500px",
                        }}>
                            <DataGridPremium
                            components={{ Toolbar: GridToolbar }}
                                rows={rows}
                                columns={columns}
                                density='compact'
                                hideFooter={true}
                                loading={loading2}
                                processRowUpdate={handleEditCommit}
                                onProcessRowUpdateError={handleProcessRowUpdateError}
                                onRowClick={(params) => {
                                    
                                    setSelectedId(params.id);
                                }}
                                onFilterModelChange={(model) => {
                                    
                                    setFilterModel(model);
                                }}
                                filterModel={filterModel}
                            />
                        </div>
                    </div>

                </div>
            }

        </>
    )

}

export default JobAttendance;

