import { ActionPerformed, PushNotificationSchema, PushNotifications, Token } from "@capacitor/push-notifications";
import { Capacitor } from "@capacitor/core";
import { useHistory } from "react-router-dom";
import { NativeSettings, AndroidSettings, IOSSettings } from "capacitor-native-settings";
import { Preferences } from "@capacitor/preferences";
import { checkMobileMainUpdate } from "../etc/functions";

const nativePushRegistartion = (history: any, presentAlert: any) => {


  if (!Capacitor.isNativePlatform()) {
    
    return;
  }

  

  // Request permission to use push notifications
  // iOS will prompt user and return if they granted permission or not
  // Android will just grant without prompting
  PushNotifications.requestPermissions().then(async (result) => {
    
    if (result.receive === 'granted') {
      // Register with Apple / Google to receive push via APNS/FCM
      

      PushNotifications.register();
    } else if (result.receive === "denied") {

      presentAlert({
        header: "푸시 메세지 수신이 필요합니다",
        subHeader: "빠르게 학원 정보를 받기 위해 푸시 메세지 설정을 허가로 바꿔주세요.",
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
          },
          {
            text: "OK",
            role: "confirm",
            handler: async () => {
              NativeSettings.open({
                optionAndroid: AndroidSettings.ApplicationDetails,
                optionIOS: IOSSettings.App
              });

              setTimeout(() => {

                PushNotifications.requestPermissions().then(async (result) => {
                  
                  if (result.receive === 'granted') {
                    // Register with Apple / Google to receive push via APNS/FCM
                    

                    PushNotifications.register();
                  }
                }
                )

              }, 60000);

            }
          }
        ]
      })



    } else {
      
      
      // Show some error
    }
  });

  const controlChannel = async () => {
    await PushNotifications.createChannel({
      id : "sunbaepush",
      name : "수능선배 알람",
      description : "원활한 업무 처리를 위해 켜주시기를 부탁드립니다.",
      sound : "default",
      importance : 4,
      visibility : 1,
      vibration : true,
    })

    

  }

  // On success, we should be able to receive notifications
  PushNotifications.addListener('registration',
    async (token: Token) => {
      
      
      

      const { value } = await Preferences.get({ key: "token" });
      
      const userIdToken : any = value;
      
      fetch("https://peetsunbae.com/push/registration", {
        method : "post",
        headers : {
          "content-type" : "application/json",
          "Authorization" : userIdToken
        },
        body : JSON.stringify({
          token : token.value
        })
      }).then((response : any) => {
        response.json()
        .then((result : any) => {
          
        })
      })

      if(Capacitor.getPlatform() === "android"){
      controlChannel();
      }

    }
  );

  // Some issue with our setup and push will not work
  PushNotifications.addListener('registrationError',
    (error: any) => {
      alert('Error on registration: ' + JSON.stringify(error));
    }
  );

  // Show us the notification payload if the app is open on our device
  PushNotifications.addListener('pushNotificationReceived',
    (notification: PushNotificationSchema) => {
      // alert('Push received: ' + JSON.stringify(notification));
    }
  );

  // Method called when tapping on a notification
  PushNotifications.addListener('pushNotificationActionPerformed',
    (notification: ActionPerformed) => {

      PushNotifications.removeAllDeliveredNotifications();

      // alert('Push action performed: ' + JSON.stringify(notification));
      // 
      const data = notification.notification.data;
      if(data.kind === "chat"){
        history.push("mobiledashboard/envelope");
      }
      if(data.kind === "notification"){
        history.push("/mobiledashboard/notificationlist");
      }
      if(data.kind === "onlineQuestion"){
        history.push("/mobiledashboard/onlinequestion");
      }
      if(data.kind === "opinion"){
        history.push("/mobiledashboard/opinion");
      }
      if(data.kind === "edit"){
        history.push("/mobiledashboard/edit");
      }
      if(data.kind === "regular"){
        history.push("/mobiledashboard/regular");
      }
    }
  );

}

export default nativePushRegistartion;