import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import New from "./new";
import Exit from "./exit";
import Change from "./change";

const NewRecruit: React.FC<any> = (props) => {
    const [value, setValue] = React.useState('1');
    const [loading, setLoading] = useState(false);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    useEffect(() => {

        setLoading(true);
        
        async function start() {
            var token = "";
            if (window.electron) {
                token = await window.electron.sendMessageApi.getToken();
            }

            fetch("https://peetsunbae.com/dashboard/chart/users", {
                method: "GET",
                headers: { "Authorization": token },
                credentials: "include",
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        
                    })
            })
        }

        start();

    }, [])


    return (
        <div>
            <div style={{width : "1400px", margin : "0 auto"}}>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example" centered>
                                <Tab label={<span style={{ fontFamily: "Apple_SB" }}>신규 안내</span>} value="1" />
                                <Tab label={<span style={{ fontFamily: "Apple_SB" }}>퇴원</span>} value="2" />
                                <Tab label={<span style={{ fontFamily: "Apple_SB" }}>자리 이동</span>} value="3" />
                            </TabList>
                        </Box>
                    </TabContext>
                </Box>

                {
                    value === "1"
                        ?
                        <New user={props.user} socket={props.socket} />
                        :
                        ""
                }
                {
                    value === "2"
                    ?
                    <Exit user={props.user} />
                    :
                    ""
                }
                {
                    value === "3"
                    ?
                    <Change user={props.user} />
                    :
                    ""
                }

            </div>
        </div>
    );

}

export default NewRecruit;