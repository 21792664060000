import React, { useEffect, useState } from "react";
import { createStyles, makeStyles } from '@mui/styles';
import {
    DataGridPremium,
    GridColDef,
    GridRowsProp,
    GridRowSelectionModel,
    useGridApiRef,
    GridGroupNode,
    GRID_ROOT_GROUP_ID,
    GridCellSelectionModel,
    GridFilterModel,
    GridLogicOperator,
    GridRowModel,
    GridToolbar,
    GridRowClassNameParams,
    GridTreeNode
} from '@mui/x-data-grid-premium';
import { createTheme, darken, lighten } from '@mui/material/styles';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Button, Checkbox } from "@mui/joy";
import ImageIcon from '@mui/icons-material/Image';
import { sub } from "date-fns";
import { set } from "lodash";
import BlockIcon from '@mui/icons-material/Block';
import UploadIcon from '@mui/icons-material/Upload';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImageUploadModal from "./components/imageuploadmodal";
import ImageModal from "../use/imagemodal";
import { Backdrop, CircularProgress, InputAdornment, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import { GridCellParams } from "@mui/x-data-grid";
import EditIcon from '@mui/icons-material/Edit';
import totalLocationData from "../../../data/locationdata";




const defaultTheme = createTheme();
const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

        return {
            root: {
                '& .completed': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .super-app-theme--확인': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .super-app-theme--미확인': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .duplicate': {
                    color : "#ff7f00"
                },
                '& .completed2': {
                    color : "#1b49af"
                },
                '& .autoinput': {
                    color : "rgba(0, 0, 0, 0.26)"
                },
            },
        };
    },
    { defaultTheme },
);

// [{value : 1, label : 1}, {value : 2, label : 2} --- 이렇게 해서 75번까지 만들기]
const seatExample: any = [];

for (let i = 1; i <= 80; i++) {
    seatExample.push({ value: `${i}`, label: `${i}` });
}



const Exit: React.FC<any> = (props) => {
    const classes = useStyles2();
    const apiRef = useGridApiRef();

    const [gangnamUserList, setGangnamUserList] = useState<any>([]);
    const [dachiUserList, setDachiUserList] = useState<any>([]);
    const [teacherList, setTeacherList] = useState<any>([]);


    const [selectedRow, setSelectedRow] = useState<any>();

    const [selectedLocation, setSelectedLocation] = useState("");

    const [disabled, setDisabled] = useState(false);
    const [disabled2, setDisabled2] = useState(false);

    const [deleteBtnLoading, setDeleteBtnLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [rows, setRows] = useState<any>([]);

    const [imageUploadModalOpen, setImageUploadModalOpen] = useState(false);

    const [forImageUploadSelectedId, setForImageUploadSelectedId] = useState();

    const handleImageUploadModalClose = () => {
        setImageUploadModalOpen(false);
    }

    const [open3, setOpen3] = useState(false);
    const [imageSrc, setImageSrc] = useState("");
    const [searchText, setSearchText] = useState("");

    const [alignment, setAlignment] = useState("incomplete");
    const [alignment2, setAlignment2] = useState("total");

    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [
            {
                id: 1,
                field: "name",
                operator: "contains",
                value: ""
            },
            {
                id: 2,
                field: "justForFilter",
                operator: "contains",
                value: "미완료"
            },
            {
                id : 3,
                field : "location",
                operator : "isAnyOf",
                value : [""]
            }
        ]
    });

    const columns: GridColDef[] =
        [
            { field: 'name', headerName: '이름', width: 100, filterable: true, editable: false },
            { field: 'location', headerName: '지점', width: 100, filterable: true, editable: false },
            { field: 'createdAt', headerName: "todo시작일", width: 100 },
            { field: 'etc', headerName: "특이사항", width: 100, editable : true , sortable : false, renderCell : (params) => { 

                if(params.value){
                    return (
                        <div>
                            {params.value}
                        </div>
                    )
                }else{

                    //정가운데에 edit icon 넣기
                    return (
                        <div style={{display : "flex", alignItems : "center", justifyContent : "center"}}>
                            <EditIcon color="disabled" />
                        </div>
                    )
                }
            }},
            {
                field: "exitDate", width: 120, headerName: "퇴원 예정일", type: "date", editable: true, sortable: false,
                renderCell: (params) => {
                    if (!params.value) {
                        return (
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <CalendarTodayIcon style={{ cursor: "pointer" }} fontSize="small" color="disabled" />
                            </div>
                        )
                    } else {
                        const date = new Date(params.value);
                        const dateString = `${date.getMonth() + 1}월 ${date.getDate()}일`;

                        return (
                            <div>
                                {dateString}
                            </div>
                        )
                    }
                }
            },
            { field: 'refundChargedMoneyAccount', headerName: "도시락비 환불 계좌", width: 100, editable : true, filterable : false, sortable : false, renderCell : (params) => { 

                if(params.value){
                    return (
                        <div>
                            {params.value}
                        </div>
                    )
                }else{

                    //정가운데에 edit icon 넣기
                    return (
                        <div style={{display : "flex", alignItems : "center", justifyContent : "center"}}>
                            <EditIcon color="disabled" />
                        </div>
                    )
                }
            }},
            {
                field: 'refundChargedMoney', headerName: '도시락비 환불', width: 120, sortable : false, filterable: true, editable: true, renderCell: (params) => {

                    const id = params.row.id;
                    const userId = params.row.userId;

                    if (params.value) {

                        //params.value = "11__고용범__1634696828761" 꼴;

                        const name = params.value.split("__")[1];
                        const time = params.value.split("__")[2];
                        const date = new Date(+time);

                        //datestring으로 ~월 ~일 ~시 ~분 꼴로 만들기
                        const dateString = `${date.getMonth() + 1}/${date.getDate()}일 ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;

                        return (
                            <span>
                                {name} {dateString} &nbsp; 
                                <Button variant="soft" size="sm" color="neutral" 
                                sx={{
                                    marginLeft : "30px"
                                }}
                                onClick={(e: any) => {
                                    submitCancel(id, params.field);
                                }}
                                >
                                    취소하기
                                </Button>
                            </span>
                        )
                    } else {
                        return (
                            <Button variant="soft" size="sm" color="neutral"
                                onClick={(e: any) => {
                                    submitCheck(id, params.field);
                                }}
                            >
                                <BlockIcon />
                            </Button>
                        )
                    }

                }
            },
            {
                field: 'refundRegisterMoney', headerName: '등록비 환불', width: 120, filterable: true, editable: true, sortable : false, renderCell: (params) => {

                    const id = params.row.id;
                    const userId = params.row.userId;

                    if (params.value) {

                        //params.value = "11__고용범__1634696828761" 꼴;

                        const name = params.value.split("__")[1];
                        const time = params.value.split("__")[2];
                        const date = new Date(+time);

                        //datestring으로 ~월 ~일 ~시 ~분 꼴로 만들기
                        const dateString = `${date.getMonth() + 1}/${date.getDate()}일 ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;

                        return (
                            <span>
                                {name} {dateString} &nbsp; 
                                <Button variant="soft" size="sm" color="neutral" 
                                sx={{
                                    marginLeft : "30px"
                                }}
                                onClick={(e: any) => {
                                    submitCancel(id, params.field);
                                }}
                                >
                                    취소하기
                                </Button>
                            </span>
                        )
                    } else {
                        return (
                            <Button variant="soft" size="sm" color="neutral"
                                onClick={(e: any) => {
                                    submitCheck(id, params.field);
                                }}
                            >
                                <BlockIcon />
                            </Button>
                        )
                    }

                }
            },
            {
                field: "refundRegisterMoneyType", width: 100, headerName: "등록비 환불유형", type: 'singleSelect', sortable : false, valueOptions: [{ value: "1", label: "1/3" },{ value: "2", label: "2/3" },{ value: "3", label: "1/2" },{ value: "4", label: "일할계산" }, { value: "5", label: "해당없음" },], editable: true
            },
            {
                field: "refundChargedMoneyDate", width: 120, headerName: "등록비 환불일시", type: "date", sortable : false, editable: true,
                renderCell: (params) => {
                    if (!params.value) {
                        return (
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <CalendarTodayIcon style={{ cursor: "pointer" }} fontSize="small" color="disabled" />
                            </div>
                        )
                    } else {
                        const date = new Date(params.value);
                        const dateString = `${date.getMonth() + 1}월 ${date.getDate()}일`;

                        return (
                            <div>
                                {dateString}
                            </div>
                        )
                    }
                }
            },
            {
                field: "refundContents", width: 100, headerName: "컨텐츠신청료 환불여부", type: 'singleSelect', sortable : false, valueOptions: [{ value: "1", label: "환불있음" },{ value: "2", label: "환불없음" }], editable: true
            },
            {
                field: "refundContentsDate", width: 120, headerName: "컨텐츠신청료 환불일시", type: "date", sortable : false, editable: true,
                renderCell: (params) => {
                    if (!params.value) {
                        return (
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <CalendarTodayIcon style={{ cursor: "pointer" }} fontSize="small" color="disabled" />
                            </div>
                        )
                    } else {
                        const date = new Date(params.value);
                        const dateString = `${date.getMonth() + 1}월 ${date.getDate()}일`;

                        return (
                            <div>
                                {dateString}
                            </div>
                        )
                    }
                }
            },
            {
                field: 'deleteBiostar', headerName: 'BIOSTAR 삭제', width: 120, filterable: true, sortable : false, editable: true, renderCell: (params) => {

                    const id = params.row.id;
                    const userId = params.row.userId;

                    if (params.value) {

                        //params.value = "11__고용범__1634696828761" 꼴;

                        const name = params.value.split("__")[1];
                        const time = params.value.split("__")[2];
                        const date = new Date(+time);

                        //datestring으로 ~월 ~일 ~시 ~분 꼴로 만들기
                        const dateString = `${date.getMonth() + 1}/${date.getDate()}일 ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;

                        return (
                            <span>
                                {name} {dateString} &nbsp; 
                                <Button variant="soft" size="sm" color="neutral" 
                                sx={{
                                    marginLeft : "30px"
                                }}
                                onClick={(e: any) => {
                                    submitCancel(id, params.field);
                                }}
                                >
                                    취소하기
                                </Button>
                            </span>
                        )
                    } else {
                        return (
                            <Button variant="soft" size="sm" color="neutral"
                                onClick={(e: any) => {
                                    submitCheck(id, params.field);
                                }}
                            >
                                <BlockIcon />
                            </Button>
                        )
                    }

                }
            },
            {
                field: 'deletePrintAdmin', headerName: '프린트 계정삭제', width: 120, filterable: true, sortable : false, editable: true, renderCell: (params) => {

                    const id = params.row.id;
                    const userId = params.row.userId;

                    if (params.value) {

                        //params.value = "11__고용범__1634696828761" 꼴;

                        const name = params.value.split("__")[1];
                        const time = params.value.split("__")[2];
                        const date = new Date(+time);

                        //datestring으로 ~월 ~일 ~시 ~분 꼴로 만들기
                        const dateString = `${date.getMonth() + 1}/${date.getDate()}일 ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;

                        return (
                            <span>
                                {name} {dateString} &nbsp; 
                                <Button variant="soft" size="sm" color="neutral" 
                                sx={{
                                    marginLeft : "30px"
                                }}
                                onClick={(e: any) => {
                                    submitCancel(id, params.field);
                                }}
                                >
                                    취소하기
                                </Button>
                            </span>
                        )
                    } else {
                        return (
                            <Button variant="soft" size="sm" color="neutral"
                                onClick={(e: any) => {
                                    submitCheck(id, params.field);
                                }}
                            >
                                <BlockIcon />
                            </Button>
                        )
                    }

                }
            },
            {
                field: 'deleteStudentFile', headerName: '학생파일 치우기', width: 120, filterable: true, sortable : false, editable: true, renderCell: (params) => {

                    const id = params.row.id;
                    const userId = params.row.userId;

                    if (params.value) {

                        //params.value = "11__고용범__1634696828761" 꼴;

                        const name = params.value.split("__")[1];
                        const time = params.value.split("__")[2];
                        const date = new Date(+time);

                        //datestring으로 ~월 ~일 ~시 ~분 꼴로 만들기
                        const dateString = `${date.getMonth() + 1}/${date.getDate()}일 ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;

                        return (
                            <span>
                                {name} {dateString} &nbsp; 
                                <Button variant="soft" size="sm" color="neutral" 
                                sx={{
                                    marginLeft : "30px"
                                }}
                                onClick={(e: any) => {
                                    submitCancel(id, params.field);
                                }}
                                >
                                    취소하기
                                </Button>
                            </span>
                        )
                    } else {
                        return (
                            <Button variant="soft" size="sm" color="neutral"
                                onClick={(e: any) => {
                                    submitCheck(id, params.field);
                                }}
                            >
                                <BlockIcon />
                            </Button>
                        )
                    }

                }
            },
            {
                field: 'uploadKakao', headerName: '카톡방 업로드', width: 120, filterable: true, sortable : false, editable: true, renderCell: (params) => {

                    const id = params.row.id;
                    const userId = params.row.userId;

                    if (params.value) {

                        //params.value = "11__고용범__1634696828761" 꼴;

                        const name = params.value.split("__")[1];
                        const time = params.value.split("__")[2];
                        const date = new Date(+time);

                        //datestring으로 ~월 ~일 ~시 ~분 꼴로 만들기
                        const dateString = `${date.getMonth() + 1}/${date.getDate()}일 ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;

                        return (
                            <span>
                                {name} {dateString} &nbsp; 
                                <Button variant="soft" size="sm" color="neutral" 
                                sx={{
                                    marginLeft : "30px"
                                }}
                                onClick={(e: any) => {
                                    submitCancel(id, params.field);
                                }}
                                >
                                    취소하기
                                </Button>
                            </span>
                        )
                    } else {
                        return (
                            <Button variant="soft" size="sm" color="neutral"
                                onClick={(e: any) => {
                                    submitCheck(id, params.field);
                                }}
                            >
                                <BlockIcon />
                            </Button>
                        )
                    }

                }
            },
            {
                field: 'initCabinet', headerName: '사물함 초기화', width: 120, filterable: true, sortable : false, editable: true, renderCell: (params) => {

                    const id = params.row.id;
                    const userId = params.row.userId;

                    if (params.value) {

                        //params.value = "11__고용범__1634696828761" 꼴;

                        const name = params.value.split("__")[1];
                        const time = params.value.split("__")[2];
                        const date = new Date(+time);

                        //datestring으로 ~월 ~일 ~시 ~분 꼴로 만들기
                        const dateString = `${date.getMonth() + 1}/${date.getDate()}일 ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;

                        return (
                            <span>
                                {name} {dateString} &nbsp; 
                                <Button variant="soft" size="sm" color="neutral" 
                                sx={{
                                    marginLeft : "30px"
                                }}
                                onClick={(e: any) => {
                                    submitCancel(id, params.field);
                                }}
                                >
                                    취소하기
                                </Button>
                            </span>
                        )
                    } else {
                        return (
                            <Button variant="soft" size="sm" color="neutral"
                                onClick={(e: any) => {
                                    submitCheck(id, params.field);
                                }}
                            >
                                <BlockIcon />
                            </Button>
                        )
                    }

                }
            },
            {
                field: 'notNow', headerName: '비재원생 처리', width: 150, filterable: true, sortable : false, editable: true, renderCell: (params) => {

                    const id = params.row.id;
                    const userId = params.row.userId;

                    if (params.value) {

                        //params.value = "11__고용범__1634696828761" 꼴;

                        const name = params.value.split("__")[1];
                        const time = params.value.split("__")[2];
                        const date = new Date(+time);

                        //datestring으로 ~월 ~일 ~시 ~분 꼴로 만들기
                        const dateString = `${date.getMonth() + 1}/${date.getDate()}일 ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;

                        return (
                            <span>
                                {name} {dateString}
                            </span>
                        )
                    } else {
                        return (
                            <Button variant="soft" size="sm" color="warning"
                                onClick={(e: any) => {

                                    if(!window.confirm("비재원생 처리하시겠습니까?")){
                                        return;
                                    }

                                    submitCheck(id, params.field);
                                }}
                            >
                                재원생 해제시키기
                            </Button>
                        )
                    }

                }
            },
            {
                field: 'seatPhoto', headerName: '좌석사진', width: 100, filterable: true, sortable : false, renderCell: (params) => {

                    const id = params.row.id;
                    const userId = params.row.userId;

                    if (params.value) {

                        return (
                            <div
                                onClick={(e: any) => {

                                    setImageSrc(params.value);
                                    setOpen3(true);

                                }}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                    height: "100%",
                                    cursor: "pointer"
                                }}>
                                <ImageIcon color="success" />
                            </div>
                        )

                    } else {
                        return (
                            <div>
                            </div>
                        )
                    }

                }
            },
            {
                field: 'finalCheck', headerName: "관리자 확인", width: 100, renderCell: (params) => {

                    const id = params.row.id;
                    const userId = params.row.userId;

                    const userName = props.user.name;

                    if (params.value) {

                        //params.value = "11__고용범__1634696828761" 꼴;

                        const name = params.value.split("__")[1];
                        const time = params.value.split("__")[2];
                        const date = new Date(+time);

                        //datestring으로 ~월 ~일 ~시 ~분 꼴로 만들기
                        const dateString = `${date.getMonth() + 1}/${date.getDate()}일 ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;

                        return (
                            <span>
                                {name} {dateString}
                                <Button variant="soft" size="sm" color="neutral" 
                                sx={{
                                    marginLeft : "30px"
                                }}
                                onClick={(e: any) => {
                                    submitCancel(id, params.field);
                                }}
                                >
                                    취소하기
                                </Button>
                            </span>
                        )
                    } else if (userName !== "고용범" && userName !== "박가을 원장" && userName !== "윤종웅 (경희대 약대)" && userName !== "심윤주 부원장" && userName !== "박가을 (대치점 원장)") {
                        return (
                            <div>
                                관리자 권한 필요
                            </div>
                        )
                    } else {
                        return (
                            <Button variant="soft" size="sm" color="success"
                                onClick={(e: any) => {
                                    submitCheck(id, params.field);
                                }}
                            >
                                완료시키기
                            </Button>
                        )
                    }
                }
            },
            {
                field : "justForFilter", headerName : "필터", width : 2, filterable : true
            }
        ]



    const [loading, setLoading] = useState(false);

    useEffect(() => {

        const totalFilter = ["-"];

        totalLocationData.forEach((each) => {

            if(each.english === "total"){
                return;
            }

            totalFilter.push(each.korean);
        });

        const newFilterModel = filterModel;

        newFilterModel.items.forEach((each : any) => {
            if(each.id === 3){
                each.value = totalFilter;
            }
        })

        setFilterModel({...newFilterModel});

    }, []);


    const notify1 = () => toast.success("삭제 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "dark"
    });

    const notify2 = () => toast.success("업로드 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored"
    });

    const notify3 = () => toast.success("수정 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored"
    });

    const notify4 = () => toast.success("재전송 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored"
    });

    useEffect(() => {

        if (selectedRow) {
            setDisabled(false);

            const name = selectedRow.name;

            if (name.includes("중복")) {
                setDisabled2(false);
            }else{
                setDisabled2(true);
            }

        } else {
            setDisabled(true);
            setDisabled2(true);
        }



    }, [selectedRow]);


    const submitCancel = async (id: number, column: string) => {

        if (!id || !column) {
            return;
        }

        try {

            setBtnLoading(true);

            const response = await fetch("https://peetsunbae.com/dashboard/avatar/exit/submitcancel", {
                method: "POST",
                credentials: "include",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    id, column
                })
            });

            const result = await response.json();

            if (result.message === "success") {
                getNewTodoList();
                setBtnLoading(false);
            }

        } catch (e) {
            
        }

    }


    useEffect(() => {

        getNewTodoList();

    }, []);

    const submitCheck = async (id: number, column: string) => {

        if (!id || !column) {
            return;
        }

        try {

            setBtnLoading(true);

            const response = await fetch("https://peetsunbae.com/dashboard/avatar/exit/submitcheck", {
                method: "POST",
                credentials: "include",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    id, column
                })
            });

            const result = await response.json();

            if (result.message === "success") {
                getNewTodoList();
                setBtnLoading(false);
            }

        } catch (e) {
            
        }

    }

    const getNewTodoList = async () => {

        try {

            const response = await fetch("https://peetsunbae.com/dashboard/avatar/exittodolist", {
                method: "GET",
                credentials: "include",
            });

            const result = await response.json();

            if (result.message === "success") {
                

                const data = result.data;

                const newRows: any = [];

                data.forEach((each: any) => {

                    const oneRow: any = {};

                    oneRow.id = each.id;
                    oneRow.userId = each.userId;
                    oneRow.location = each.location;

                    if(!oneRow.location){
                        oneRow.location = "-";
                    }

                    oneRow.name = each.name;
                    const createdAtDate = new Date(each.createdAt);
                    //datestring으로 ~/~ ~:~ 꼴로 만들기
                    const createdAtDateString = `${createdAtDate.getMonth() + 1}/${createdAtDate.getDate()} ${createdAtDate.getHours() < 10 ? "0" + createdAtDate.getHours() : createdAtDate.getHours()}:${createdAtDate.getMinutes() < 10 ? "0" + createdAtDate.getMinutes() : createdAtDate.getMinutes()}`;
                    oneRow.createdAt = createdAtDateString;
                    oneRow.etc = each.etc;

                    if (each.exitDate) {
                        const exitDate = new Date(each.exitDate);
                        oneRow.exitDate = exitDate;
                    }

                    oneRow.refundChargedMoneyAccount = each.refundChargedMoneyAccount;
                    oneRow.refundChargedMoney = each.refundChargedMoney;
                    oneRow.refundRegisterMoney = each.refundRegisterMoney;
                    oneRow.refundRegisterMoneyType = each.refundRegisterMoneyType;

                    if(each.refundChargedMoneyDate){
                        const refundChargedMoneyDate = new Date(each.refundChargedMoneyDate);
                        oneRow.refundChargedMoneyDate = refundChargedMoneyDate;
                    }

                    oneRow.refundContents = each.refundContents;

                    if(each.refundContentsDate){
                        const refundContentsDate = new Date(each.refundContentsDate);
                        oneRow.refundContentsDate = refundContentsDate;
                    }

                    oneRow.deleteBiostar = each.deleteBiostar;
                    oneRow.deletePrintAdmin = each.deletePrintAdmin;
                    oneRow.deleteStudentFile = each.deleteStudentFile;
                    oneRow.uploadKakao = each.uploadKakao;
                    oneRow.initCabinet = each.initCabinet;
                    oneRow.seatPhoto = each.seatPhoto;
                    oneRow.finalCheck = each.finalCheck;
                    oneRow.notNow = each.notNow;

                    if(each.finalCheck){
                        oneRow.justForFilter = "완료됨";
                    }else{
                        oneRow.justForFilter = "미완료";
                    }


                    newRows.push(oneRow);

                })

                setRows([...newRows]);
            }

        } catch (e) {
            
        }

    }


    const handleEditCommit = React.useCallback(
        async (newRow: GridRowModel, old: any) => {


            if(newRow.justForFilter === "완료됨"){
                alert("완료된 이후에는 수정이 불가능합니다.");
                return;
            }


            var field = "";


            

            for (const [key, value] of Object.entries(newRow)) {
                if (value !== old[key]) {
                    field = key;
                }
            }

            const value = newRow[field] ? newRow[field] : null;
            const id = newRow.id;
            const userId = newRow.userId;

            
            
            
            
            

            if (!field) {
                
                return newRow;
            }

            if (!id) {
                
                return newRow;
            }

            const data = {
                value,
                id,
                userId,
                field
            }

            

            try {

                const response = await fetch("https://peetsunbae.com/dashboard/avatar/exit/edit", {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        "content-type": "application/json"
                    },
                    body: JSON.stringify(data)
                });

                const result = await response.json();

                if (result.message === "success") {
                    getNewTodoList();
                }

            } catch (e) {
                
            }

            return newRow;

        }, []
    );

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        
        alert(error.message);
    }, []);

    const deleteRow = async () => {

        const id = selectedRow.sqlId;

        if (!id) {
            return;
        }

        try {

            if (!window.confirm("정말로 삭제하시겠습니까?")) {
                return;
            }

            setBtnLoading(true);

            const response = await fetch("https://peetsunbae.com/dashboard/avatar/exit/delete", {
                method: "POST",
                credentials: "include",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    id
                })
            });

            const result = await response.json();

            if (result.message === "success") {
                notify1();
                getNewTodoList();
                setBtnLoading(false);
            }

        } catch (e) {
            
        }

    }



    const handleAlignmentChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {

        if (!newAlignment) {
            return;
        }

        setAlignment(newAlignment);

        //filtermodel중 id가 2인 것을 찾아서 newAlignment가 incomplete면 "미완료", total이면 ""로 바꾸기

        const newFilterModel = filterModel;

        newFilterModel.items.forEach((each : any) => {
            if(each.id === 2){
                if(newAlignment === "incomplete"){
                    each.value = "미완료";
                }else{
                    each.value = "";
                }
            }
        })

        setFilterModel({...newFilterModel});

    }

    const handleAlignmentChange2 = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {

        if (!newAlignment) {
            return;
        }

        setAlignment2(newAlignment);

        //filtermodel중 id가 2인 것을 찾아서 newAlignment가 incomplete면 "미완료", total이면 ""로 바꾸기

        const newFilterModel = filterModel;

        totalLocationData.forEach((each) => {

            if(each.english === newAlignment){
             
                newFilterModel.items.forEach((each2 : any) => {
                    if(each2.id === 3){

                        const array : any = [];

                        if(newAlignment === "total"){

                            array.push("-");
                            //array.push(null);
                            // array.push(undefined);

                            totalLocationData.forEach((each3) => {

                                if(each3.english !== "total"){
                                    array.push(each3.korean);
                                }

                            });

                        }else{
                            array.push(each.korean);
                        }

                        each2.value = array;


                    }
                });

            }
        });

        setFilterModel({...newFilterModel});

    }

    const handleSearchTextChange = (e : any) => {
        
        setSearchText(e.target.value);

        const newFilterModel = filterModel;

        newFilterModel.items.forEach((each : any) => {
            if(each.id === 1){
                each.value = e.target.value;
            }
        })

        setFilterModel({...newFilterModel});

    }

    return (
        <div>
            <div style={{height : "12px"}}>

            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", marginBottom: "8px", marginTop: "12px" }}>
                <div>
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleAlignmentChange}
                        aria-label="Platform"
                        sx={{ marginRight: "36px" }}
                    >
                        <ToggleButton value="incomplete">미완료</ToggleButton>
                        <ToggleButton value="total">전체</ToggleButton>
                    </ToggleButtonGroup>

                    <ToggleButtonGroup
                        color="primary"
                        value={alignment2}
                        exclusive
                        onChange={handleAlignmentChange2}
                        aria-label="Platform"
                        sx={{ marginRight: "36px" }}
                    >
                        {
                            props.user && totalLocationData.filter((each) => each.academy.includes(props.user.academy)).map((each, index) => {
                                return (
                                    <ToggleButton value={each.english} key={index}>{each.korean}</ToggleButton>
                                )
                            })
                        }
                    </ToggleButtonGroup>

                    <TextField sx={{ fontSize: "24px", marginLeft : "12px" }} label="이름 검색" variant="standard" value={searchText} onChange={handleSearchTextChange} />
                </div>
                <div>
                    <Button variant="soft" size="sm" color="neutral" loading={deleteBtnLoading} disabled={disabled} style={{ marginRight: "12px" }}
                        onClick={(e: any) => {
                            if (!selectedRow) {
                                return;
                            }
                            setForImageUploadSelectedId(selectedRow.sqlId);
                            setImageUploadModalOpen(true);
                        }}
                    >
                        좌석 사진 업로드
                    </Button>
                    <Button variant="soft" size="sm" color="danger" loading={deleteBtnLoading} disabled={disabled} onClick={deleteRow}>
                        삭제하기
                    </Button>
                </div>
            </div>
            <div className={classes.root} style={{ height: 500, width: '100%', backgroundColor: "white", marginTop: "24px" }}>
                <DataGridPremium
                    filterModel={filterModel}
                    onFilterModelChange={(model) => {
                        
                        setFilterModel(model);
                    }}
                    slots={{ toolbar: GridToolbar }}
                    loading={loading}
                    density="compact"
                    columns={columns}
                    getCellClassName={(params: any) => {
                        
                        if(params.field === "name" && params.value){
                            //params.value에 "중복"이 들어가면 duplicate라는 클래스를 준다.

                            if(params.value.includes("중복")){
                                return "duplicate";
                            }
                        }

                        if(params.field === "sendCard"){
                            
                            if(params.value.includes("제출 완료")){
                                return "completed2";
                            }
                        
                        }
                        
                        return ""
                    }}
                    getRowClassName={(params: GridRowClassNameParams<any>) => {

                        if(params.row.justForFilter === "완료됨"){
                            return "completed";
                        }

                        return ""
                    }}
                    rows={rows}
                    processRowUpdate={handleEditCommit}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                    apiRef={apiRef}
                    initialState={{
                        pinnedColumns : { left : ["name"]}
                    }}
                    onRowSelectionModelChange={(newSelectionModel) => {
                        const data: any = apiRef.current.getSelectedRows();
                        if (!data) {
                            return;
                        }
                        
                        

                        

                        data.forEach((value: any, index: number) => {

                            const newSelectedInfo = {
                                sqlId: value.id,
                                name : value.name,
                                userId : value.userId
                            }

                            // switch (value.location) {
                            //     case "강남점":
                            //         setSelectedLocation("강남점");
                            //         break;
                            //     case "대치점":
                            //         setSelectedLocation("대치점");
                            //         break;
                            // }

                            totalLocationData.forEach((each) => {
                                if(each.korean === value.location){
                                    setSelectedLocation(each.korean);
                                }
                            })

                            
                            setSelectedRow(newSelectedInfo);
                        })
                    }}
                />
            </div>



            <ImageUploadModal open={imageUploadModalOpen} handleClose={handleImageUploadModalClose} update={getNewTodoList} id={forImageUploadSelectedId} success={notify2} kind="exit" />

            <ImageModal
                open={open3}
                handleClose={() => { setOpen3(false) }}
                src={imageSrc}
            />

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={btnLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </div>
    )

}

export default Exit;