import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import styles from "./mobilerestaurantstatus.module.css";
import { IonLoading } from "@ionic/react";
import { ReactComponent as Trash } from "../../../src/svg/trash-can-regular.svg";
import { ReactComponent as CircleDollar } from "../../../src/svg/wallet-outline.svg";

import { returnDayString } from "../etc/functions";

import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";

import { IonButton, useIonActionSheet } from '@ionic/react';
import { IonToast } from '@ionic/react';
import { IonModal } from "@ionic/react";
import InnerHeader from "../etc/innerheader";
import InnerHeaderForModal from "../etc/innerheaderformodal";

import TextField from '@mui/material/TextField';
import BottomButton from "../etc/bottomButton";
import { Skeleton } from "@mui/material";



const MobileRestaurantStatus: React.FC<any> = (props) => {

    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const topRef = useRef<any>(null);

    const [today, setToday] = useState(new Date());
    const [checkResult, setCheckResult] = useState([]);
    const [descriptionHeight, setDescriptionHeight] = useState(0);

    const [update, setUpdate] = useState(0);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");

    const [present] = useIonActionSheet();
    const [chargedMoney, setChargedMoney] = useState(0);
    const [availableCharge, setAvailableCharge] = useState(0);
    const [availableChargeString, setAvailableChargeString] = useState("");

    const [value, setValue] = useState(0);
    const [valueString, setValueString] = useState("");
    const [name, setName] = useState("");

    const [isOpen, setIsOpen] = useState(false);
    const [isExcessive, setIsExcessive] = useState(false);

    useEffect(() => {

        if (!props.socket) {
            return;
        }

        props.socket.on("newAmount", () => {
            

            // new window.Notification("새로운 메세지가 왔습니다.", {body : `메세지를 확인해주세요.`});

            setUpdate(Math.random());
        })

        return function cleanup() { props.socket.off("newAmount"); }

    }, [props.socket]);


    useEffect(() => {

        

        

        if(!topRef || !topRef.current || !props.statusDivHeight){
            return;
        }

        const caculateBodyHeight = () => {

            

            if(!topRef || !topRef.current || !props.statusDivHeight){
                return;
            }

            const totalHeight = props.statusDivHeight;
            const topHeight = topRef.current.offsetHeight;

            const descriptionHeight = totalHeight - topHeight - (2.469 * 16);

            
            
            

            setDescriptionHeight(descriptionHeight);
        }

        caculateBodyHeight();

        window.addEventListener("resize", () => caculateBodyHeight());

        return window.removeEventListener("resize", () => caculateBodyHeight());


    }, [topRef, props.statusDivHeight]);



    useEffect(() => {

        setLoading(true);

        const start = async () => {

            var token: any = "";

            if (Capacitor.isNativePlatform()) {
                const { value } = await Preferences.get({ key: "token" });
                token = value;
            }

            if (window.electron) {
                token = await window.electron.sendMessageApi.getToken();
            }

            fetch("https://peetsunbae.com/dashboard/restaurant/checkMobile", {
                method: "GET",
                headers: { "Authorization": token },
                credentials: "include",
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        if (result.message === "success") {
                            
                            setCheckResult(result.data);
                            setLoading(false);
                            setChargedMoney(result.chargedMoney);
                            setAvailableCharge(100000 - result.chargedMoney);
                            setAvailableChargeString(`최대 보유 금액 100,000원. 현재 충전 가능액 ${(100000 - result.chargedMoney).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`);
                        }
                    })
            }).catch((e: any) => {
                
                setLoading(false);
            })
        }

        start();

    }, [update]);


    const confirmDelete = (id: number) => {
        present({
            header: "도시락 취소 안내",
            subHeader: "도시락 주문을 삭제할까요?",
            buttons: [{
                text: "삭제",
                role: "destructive"
            },
            {
                role: "cancel",
                text: "아니오",
            }
            ],
            onDidDismiss: ({ detail }) => {
                
                switch (detail.role) {
                    case "destructive":
                        deleteMeal(id);
                        break;
                    case "cancel":
                        break;
                }
            },
        })
    }


    const deleteMeal = async (id: number) => {

        if (!id) {
            alert("id가 없습니다");
            return;
        }

        setLoading(true);
        var token: any = "";

        if (window.electron) {
            token = window.electron.sendMessageApi.getToken();
        }

        if (Capacitor.isNativePlatform()) {
            const { value } = await Preferences.get({ key: "token" });
            token = value;
        }

        fetch("https://peetsunbae.com/dashboard/restaurant/check", {
            method: "DELETE",
            headers: { "Authorization": token, "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
                id: id
            })
        }).then((response) => {
            response.json()
                .then((result) => {
                    
                    if (result.message === "success") {
                        setUpdate(Math.random());
                    }
                    if (result.message === "TIME_OUT") {
                        setToastMessage("🥵 환불 시간이 지났습니다");
                        setShowToast(true);
                        setLoading(false);
                    }
                })
        }).catch((error) => {
            
        })

    }

    const handleClose = () => {
        
        setValue(0);
        setIsExcessive(false);
        setName("");
        setIsOpen(false);
    }

    const handleChange = (e: any, type: string) => {

        switch (type) {
            case "amount":
                const amountValue = e.target.value.replaceAll(",", "");

                if (e.target.value.length === 1 && e.target.value === "0") {
                    return;
                }
                if (+amountValue > +availableCharge) {
                    setIsExcessive(true);
                } else {
                    setIsExcessive(false);
                }

                setValue(amountValue);
                break;
            case "name":
                setName(e.target.value);
                break;
        }


    }

    useEffect(() => {
        if (value) {
            const amountString = value.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            setValueString(amountString);
        } else {
            setValueString("");
        }

    }, [value]);

    const submit = async () => {



        
        

        if (!value) {
            setToastMessage("🥵 충전금액을 입력해주세요");
            setShowToast(true);
            return;
        }

        if (isExcessive) {
            setToastMessage("🥵 최대 충전 한도를 초과한 금액입니다");
            setShowToast(true);
            return;
        }

        if (!name) {
            setToastMessage("🥵 입금자명을 입력해주세요");
            setShowToast(true);
            return;
        }

        setLoading(true);

        var token: any = "";

        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        if (Capacitor.isNativePlatform()) {
            const { value } = await Preferences.get({ key: "token" });
            token = value;
        }


        fetch("https://peetsunbae.com/dashboard/restaurant/charge", {
            method: "POST",
            headers: { "Authorization": token, "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
                amount: value,
                name: name
            })
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    
                    if (result.message === "success") {
                        

                        history.push({
                            pathname: "/mobiledashboard/rechargetext",
                            state: {
                                amount: value,
                                name: name,
                            }
                        })

                        setLoading(false);
                    } else {
                        
                        setLoading(false);
                    }
                })
        })

    }

    return (
        <div className={styles.statusBody}>
            <div className={styles.statusTop} ref={topRef}>
                <div className={styles.statusTitle}>
                    도시락 신청 현황
                </div>
                <div className={styles.statusNumber}>
                    {today.getMonth() + 1}월 {today.getDate()}일 {returnDayString(today.getDay())}요일 기준
                </div>
            </div>

            {
                loading
                &&
                <div style={{ width: "90.6%", margin: "0 auto" }}>
                    <div style={{ height: "20px" }}>
                    </div>
                    <Skeleton variant="rectangular" width="100%" height={140} />
                    <div style={{ height: "20px" }}>
                    </div>
                    <Skeleton variant="rectangular" width="100%" height={140} />
                    <div style={{ height: "20px" }}>
                    </div>
                    <Skeleton variant="rectangular" width="100%" height={140} />
                    <div style={{ height: "20px" }}>
                    </div>
                </div>
            }


            <div className={styles.description} style={{ height: `${descriptionHeight}px`, overflow: "scroll" }}>
                <div className={styles.descriptionBody}>

                    <div className={`${styles.eachDescriptionBox} ${styles.cash}`}>
                        <div className={styles.iconDiv}>
                            <CircleDollar fill="#304ffe" className={styles.circleDollar} />
                        </div>
                        <div className={styles.cashDescriptionDiv}>
                            <div className={styles.cashDescription}>
                                충전금 잔액
                            </div>
                            <div className={styles.cashTitle}>
                                {chargedMoney.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
                            </div>
                        </div>
                        <div className={styles.btnDiv} onClick={(e: any) => { setIsOpen(true); }}>
                            <div className={styles.cashBtn}>
                                충전
                            </div>
                        </div>
                    </div>

                    {
                        (checkResult && checkResult.length > 0) &&
                        checkResult.map((eachCheckResult: any, index: number) => {

                            const parentPermit = eachCheckResult.parentpermit;

                            


                            const targetDate = new Date(eachCheckResult.targetDate);

                            var targetDateDay = returnDayString(targetDate.getDay());

                            const targetDateString = `${targetDate.getMonth() + 1}월 ${targetDate.getDate()}일 ${targetDateDay}요일 ${eachCheckResult.whenType === "lunch" ? "점심" : "저녁"}`;

                            return (
                                <div key={eachCheckResult.id}>
                                    <div className={`${styles.eachDescription} ${checkResult.length - 1 === index ? styles.last : ""}`}>
                                        <div className={styles.eachDescriptionDate}>
                                            {targetDateString}
                                        </div>
                                        <div className={styles.eachDescriptionBox}>
                                            <div className={styles.eachDescriptionText}>
                                                {eachCheckResult.restaurantName}
                                            </div>
                                            <div className={styles.eachDescriptionTargetDate}>
                                                {eachCheckResult.mealName}
                                            </div>
                                            <div className={styles.eachDescriptionKindDiv}>
                                                <div className={styles.eachDescriptionKindText}>
                                                    {eachCheckResult.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
                                                </div>
                                            </div>
                                            <div className={styles.trashCanDiv} onClick={(e: any) => { confirmDelete(eachCheckResult.id); }}>
                                                <Trash fill="#251e1c" className={styles.trash} />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            );

                        })
                    }

                </div>
            </div>




            <IonToast
                isOpen={showToast}
                onDidDismiss={() => { setShowToast(false); setToastMessage(""); }}
                message={toastMessage}
                duration={1500}
                position="top"
                color="danger"
            />

            {/* <IonLoading
                cssClass='my-custom-class'
                isOpen={loading}
                onDidDismiss={() => setLoading(false)}
                message={'잠시만 기다려주세요'}
            /> */}

            <IonModal isOpen={isOpen}>
                <InnerHeaderForModal handleClose={handleClose} />
                <div className={styles.modalBody}>
                    <div className={styles.modalTitle}>
                        얼마를 충전할까요?
                    </div>
                    <div className={styles.inputDiv}>
                        <TextField color={isExcessive ? "error" : "primary"} value={valueString ? valueString : ""} onChange={(e: any) => { handleChange(e, "amount") }} type="tel" inputProps={{ style: { fontSize: 20, color: isExcessive ? "#d32f2f" : "black" } }} InputLabelProps={{ style: { fontSize: 20 } }} id="standard-basic" label="충전금액 입력" variant="standard" helperText={availableChargeString} fullWidth />
                    </div>
                    <div className={styles.inputDiv}>
                        <TextField value={name} onChange={(e: any) => { handleChange(e, "name") }} inputProps={{ style: { fontSize: 20 } }} InputLabelProps={{ style: { fontSize: 20 } }} id="standard-basic" label="입금자명" variant="standard" helperText="실제 입금 내역과 금액, 입금자명이 일치해야 반영됩니다" fullWidth />
                    </div>
                </div>

                <BottomButton text="충전 신청하기" submit={submit} />
            </IonModal>

        </div>
    );
}

export default MobileRestaurantStatus;