import React, { useState, useEffect, useRef } from "react";
import { GridRenderCellParams, GridRowsProp, GridColDef, GridToolbar, useGridApiRef, GridFilterModel, DataGridPremium } from '@mui/x-data-grid-premium';
import { createTheme, darken, lighten } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { TextField } from "@mui/material";
import { DataGrid, GridColumnVisibilityModel } from '@mui/x-data-grid';
import CustomInput from "./etc/custominput";


const defaultTheme = createTheme();
const useStyles2 = makeStyles(
  (theme) => {
    const getBackgroundColor = (color: any) =>
      theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

    const getHoverBackgroundColor = (color: any) =>
      theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

    return {
      root: {
        '& .super-app-theme--처리완료': {
          backgroundColor: getBackgroundColor(theme.palette.info.main),
          '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
          },
        },
        '& .super-app-theme--Filled': {
          backgroundColor: getBackgroundColor(theme.palette.success.main),
          '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
          },
        },
        '& .super-app-theme--확인': {
          backgroundColor: getBackgroundColor(theme.palette.warning.main),
          '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
          },
        },
        '& .super-app-theme--미확인': {
          backgroundColor: getBackgroundColor(theme.palette.error.main),
          '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
          },
        },
        '& .success': {
          color : theme.palette.info.main,
        },
        '& .fail': {
          color : theme.palette.error.main,
        },
        '& .unsuccess': {
          color : theme.palette.secondary.main,
        },
      },
    };
  },
  { defaultTheme },
);




interface GridCellExpandProps {
  value: string;
  width: number;
}


const useStyles = makeStyles(() =>
  createStyles({
    root: {
      alignItems: 'center',
      lineHeight: '24px',
      width: '100%',
      height: '100%',
      position: 'relative',
      display: 'flex',
      '& .cellValue': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  }),
);


function isOverflown(element: Element): boolean {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}


const GridCellExpand = React.memo(function GridCellExpand(
  props: GridCellExpandProps,
) {
  const { width, value } = props;
  const wrapper = React.useRef<HTMLDivElement | null>(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current!);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent: KeyboardEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <div
      ref={wrapper}
      className={classes.root}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        ref={cellDiv}
        style={{
          height: 1,
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <div ref={cellValue} className="cellValue">
        {value}
      </div>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current!.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </div>
  );
});

function renderCellExpand(params: GridRenderCellParams<any>) {
  return (
    <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
  );
}





const ManageRegularTable: React.FC<any> = (props) => {

    const [name, setName] = useState("");
    const [rows, setRows] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [
            { id: 2, field: 'name', operator: 'contains', value: "" },
        ],
    });
    const classes = useStyles2();
    const [alignment, setAliment] = useState("total");

    const columns: GridColDef[] = [
        { field: 'name', headerName: '이름', width: alignment === "total" ? 80 : 150},
        { field: 'monday', headerName: '월요일', width: alignment === "total" ? 180 : 500, renderCell: renderCellExpand, filterable: false },
        { field: 'tuesday', headerName: '화요일', width: alignment === "total" ? 180 : 500, editable: true, renderCell: renderCellExpand, filterable: false },
        { field: 'wednesday', headerName: '수요일', width: alignment === "total" ? 180 : 500,renderCell: renderCellExpand, filterable: false },
        { field: 'thursday', headerName: '목요일', width: alignment === "total" ? 180 : 500,renderCell: renderCellExpand, filterable: false },
        { field: 'friday', headerName: '금요일', width: alignment === "total" ? 180 : 500,renderCell : renderCellExpand, filterable : false },
        { field: 'saturday', headerName: '토요일', width:alignment === "total" ? 180 : 500,renderCell : renderCellExpand, filterable : false },
        { field: 'etc', headerName: '기타', width:alignment === "total" ? 180 : 500,renderCell : renderCellExpand, filterable : false },
        { field: 'status', headerName: '승인상태', width: 120, filterable: true },
        { field: 'modifiedAt', headerName: '최종 수정일', width: 100, filterable : false},
      ];

    const [columnVisibilityModel, setColumnVisibilityModel] =
        React.useState<GridColumnVisibilityModel>({
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            saturday: true
        });

    useEffect(() => {

        const newColumnVisibilityModel = columnVisibilityModel;

        for (const [key, value] of Object.entries(newColumnVisibilityModel)){

            if(alignment === "total"){
                newColumnVisibilityModel[key] = true;
            }else{
                if(alignment === key){
                    newColumnVisibilityModel[key] = true;
                }else{
                    newColumnVisibilityModel[key] = false;
                }
            }
        }


        setColumnVisibilityModel({...newColumnVisibilityModel});

    }, [alignment]);



    const handleAlignmentChange = (e: any, newAlignment: string) => {

        if(!newAlignment){
            return;
        }

        setAliment(newAlignment);
    }

    const nameChange = (e : any) => {
        setName(e.target.value);

        const newFilterModel = filterModel;

        newFilterModel.items.forEach((each) => {
            if(each.id === 2){
                each.value = e.target.value
            }
        })

        setFilterModel({...newFilterModel});
    }


    useEffect(() => {

        
        

        if(!props.data){
            return;
        }

        props.data.sort((after : any, before : any) => {
            if(after.name < before.name){
                return -1;
            }
        })

        const newRows : any = [];

        const data = props.data;

        data.forEach((eachData : any) => {

            if(alignment !== "total"){
                if(!eachData.data[alignment] && !eachData.data["etc"]){
                    return;
                }
            }

            
            if(props.alignment2 === "gangnam"){
              if(eachData.location !== "강남점"){
                  return;
              }
          }

          if(props.alignment2 === "daechi"){
              if(eachData.location !== "대치점"){
                  return;
              }
          }

            const oneRow : any = {};
            oneRow.id = eachData.id;
            oneRow.name = eachData.name;
            oneRow.monday = eachData.data.monday;
            oneRow.tuesday = eachData.data.tuesday;
            oneRow.wednesday = eachData.data.wednesday;
            oneRow.thursday = eachData.data.thursday;
            oneRow.friday = eachData.data.friday;
            oneRow.saturday = eachData.data.saturday;
            oneRow.etc = eachData.data.etc;

            oneRow.status = "제출 단계";

            if(eachData.staffpermit === 1){
                oneRow.status = "사감 승인 완료"
            }

            if(eachData.staffpermit === 2){
                oneRow.status = "사감 승인 거절"
            }

            if(eachData.parentpermit === 1){
                oneRow.status = "학부모 승인 완료"
            }

            if(eachData.parentpermit === 2){
                oneRow.status = "학부모 승인 거절"
            }

            const newDate = new Date(eachData.modifiedAt);

            oneRow.modifiedAt = `${newDate.getFullYear()}/${newDate.getMonth() + 1}/${newDate.getDate()}`;

            newRows.push(oneRow);

        }, []);

        setRows([...newRows]);




    }, [props.data, alignment, props.alignment2]);

    return (
        <div style={{ width: 1650, margin: "0 auto", marginTop: "24px" }}>
            <div style={{display : "flex", justifyContent : "space-between"}}>
                <div>
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleAlignmentChange}
                    >
                        <ToggleButton value="total">전체</ToggleButton>
                        <ToggleButton value="monday">월요일</ToggleButton>
                        <ToggleButton value="tuesday">화요일</ToggleButton>
                        <ToggleButton value="wednesday">수요일</ToggleButton>
                        <ToggleButton value="thursday">목요일</ToggleButton>
                        <ToggleButton value="friday">금요일</ToggleButton>
                        <ToggleButton value="saturday">토요일</ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <div>
                  <CustomInput value={name} onChange={nameChange} placeholder="이름을 검색하세요" />
                </div>
            </div>
            <div className={classes.root} style={{ height: 500, width: '100%', backgroundColor: "white", marginTop : "12px" }}>
                <DataGridPremium loading={loading} rows={rows} columns={columns}
                    density='compact'
                    // components={{ Toolbar: GridToolbar }}
                    filterModel={filterModel}
                    onFilterModelChange={(model) => setFilterModel(model)}
                    getRowClassName={(params: any) => {
                        return ""
                    }
                    }
                    getCellClassName={(params: any) => {
                        if (params.field != "status") {
                            return '';
                        } else if (!params.value) {
                            return '';
                        } else {
                            if(params.value === "학부모 승인 완료"){
                                return "success"
                            }else if(params.value === "학부모 승인 거절"){
                               return "fail"
                            }else if(params.value === "사감 승인 거절"){
                              return "unsuccess"
                            }else{
                                return ""
                            }
                        }
                    }}
                    disableRowSelectionOnClick={true}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                      setColumnVisibilityModel(newModel)
                    }
                    components={{ Toolbar: GridToolbar }}
                />
            </div>

            <div style={{height : "80px"}}>

            </div>
        </div>
    );
}

export default ManageRegularTable;