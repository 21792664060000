import React, { useEffect, useState } from "react";
import { createStyles, makeStyles } from '@mui/styles';
import {
    DataGridPremium,
    GridColDef,
    GridRowsProp,
    GridRowSelectionModel,
    useGridApiRef,
    GridGroupNode,
    GRID_ROOT_GROUP_ID,
    GridCellSelectionModel,
    GridFilterModel,
    GridLogicOperator,
    GridRowModel,
    GridToolbar,
    GridRowClassNameParams,
    GridTreeNode
} from '@mui/x-data-grid-premium';
import { createTheme, darken, lighten } from '@mui/material/styles';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Button, Checkbox } from "@mui/joy";
import ImageIcon from '@mui/icons-material/Image';
import { sub } from "date-fns";
import { set } from "lodash";
import BlockIcon from '@mui/icons-material/Block';
import UploadIcon from '@mui/icons-material/Upload';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImageUploadModal from "./components/imageuploadmodal";
import ImageModal from "../use/imagemodal";
import { Backdrop, CircularProgress, InputAdornment, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import { GridCellParams } from "@mui/x-data-grid";
import EditIcon from '@mui/icons-material/Edit';
import totalLocationData from "../../../data/locationdata";




const defaultTheme = createTheme();
const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

        return {
            root: {
                '& .completed': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .super-app-theme--확인': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .super-app-theme--미확인': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .duplicate': {
                    color: "#ff7f00"
                },
                '& .completed2': {
                    color: "#1b49af"
                },
                '& .letsselect': {
                    color: "rgba(0, 0, 0, 0.26)"
                },
                '& .autoinput': {
                    color: "rgba(0, 0, 0, 0.26)"
                },

            },
        };
    },
    { defaultTheme },
);

// [{value : 1, label : 1}, {value : 2, label : 2} --- 이렇게 해서 75번까지 만들기]
const seatExample: any = [];

for (let i = 1; i <= 290; i++) {
    seatExample.push({ value: `${i}`, label: `${i}` });
}





const New: React.FC<any> = (props) => {
    const classes = useStyles2();
    const apiRef = useGridApiRef();

    const [firstLoading, setFirstLoading] = useState(false);

    const [gangnamUserList, setGangnamUserList] = useState<any>([]);
    const [dachiUserList, setDachiUserList] = useState<any>([]);
    const [teacherList, setTeacherList] = useState<any>([]);
    const [biostarList, setBiostarList] = useState<any>([]);


    const [selectedRow, setSelectedRow] = useState<any>();

    const [selectedLocation, setSelectedLocation] = useState("");

    const [disabled, setDisabled] = useState(false);
    const [disabled2, setDisabled2] = useState(false);
    const [disabled3, setDisabled3] = useState(false);

    const [deleteBtnLoading, setDeleteBtnLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [rows, setRows] = useState<any>([]);

    const [imageUploadModalOpen, setImageUploadModalOpen] = useState(false);

    const [forImageUploadSelectedId, setForImageUploadSelectedId] = useState();

    const handleImageUploadModalClose = () => {
        setImageUploadModalOpen(false);
    }

    const [open3, setOpen3] = useState(false);
    const [imageSrc, setImageSrc] = useState("");
    const [searchText, setSearchText] = useState("");

    const [alignment, setAlignment] = useState("incomplete");
    const [alignment2, setAlignment2] = useState("total");

    const [serverBiostarUserList, setServerBiostarUserList] = useState<any>([]);

    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [
            {
                id: 1,
                field: "name",
                operator: "contains",
                value: ""
            },
            {
                id: 2,
                field: "justForFilter",
                operator: "contains",
                value: "미완료"
            },
            {
                id: 3,
                field: "location",
                operator: "isAnyOf",
                // value: ["", "강남점", "대치점", "대치3층", "대치6층", null, undefined],
                value: []
            }
        ]
    });

    const [columns, setColumns] = useState<GridColDef[]>(
        [
            { field: 'name', headerName: '이름', width: 100, filterable: true, editable: false },
            { field: 'createdAt', headerName: "todo시작일", width: 100 },
            {
                field: 'etc', headerName: "특이사항", width: 100, editable: true, renderCell: (params) => {

                    if (params.value) {
                        return (
                            <div>
                                {params.value}
                            </div>
                        )
                    } else {

                        //정가운데에 edit icon 넣기
                        return (
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <EditIcon color="disabled" />
                            </div>
                        )
                    }
                }
            },
            { field: 'teacher', headerName: "담임멘토", width: 100, editable: true, type: "singleSelect", valueOptions: teacherList },
            {
                field: "teacherDescription", headerName: "담임 배정이유", width: 100, editable: true, renderCell: (params) => {

                    if (params.value) {
                        return (
                            <div>
                                {params.value}
                            </div>
                        )
                    } else {

                        //정가운데에 edit icon 넣기
                        return (
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <EditIcon color="disabled" />
                            </div>
                        )
                    }
                }
            },
            {
                field: 'fingerprint', headerName: '지문등록', width: 120, filterable: true, editable: true, renderCell: (params) => {

                    const id = params.row.id;
                    const userId = params.row.userId;

                    if (params.value) {

                        //params.value = "11__고용범__1634696828761" 꼴;

                        const name = params.value.split("__")[1];
                        const time = params.value.split("__")[2];
                        const date = new Date(+time);

                        //datestring으로 ~월 ~일 ~시 ~분 꼴로 만들기
                        const dateString = `${date.getMonth() + 1}/${date.getDate()}일 ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;

                        return (
                            <span>
                                {name} {dateString} &nbsp;
                                <Button variant="soft" size="sm" color="neutral"
                                    sx={{
                                        marginLeft: "30px"
                                    }}
                                    onClick={(e: any) => {
                                        submitCancel(id, params.field);
                                    }}
                                >
                                    취소하기
                                </Button>
                            </span>
                        )
                    } else {
                        return (
                            <Button variant="soft" size="sm" color="neutral"
                                onClick={(e: any) => {
                                    submitCheck(id, params.field);
                                }}
                            >
                                <BlockIcon />
                            </Button>
                        )
                    }

                }
            },
            {
                field: "location", width: 100, headerName: "지점", type: 'singleSelect', valueOptions: totalLocationData.filter((each) => each.english !== "total").map((each) => {

                        return {
                            value: each.korean,
                            label: each.korean
                        }

                })
                , editable: true, sortable: false, filterable: true
            },
            {
                field: "due", width: 120, headerName: "등원일", type: "date", editable: true, sortable: false,
                renderCell: (params) => {
                    if (!params.value) {
                        return (
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <CalendarTodayIcon style={{ cursor: "pointer" }} fontSize="small" color="disabled" />
                            </div>
                        )
                    } else {
                        const date = new Date(params.value);
                        const dateString = `${date.getMonth() + 1}월 ${date.getDate()}일`;

                        return (
                            <div>
                                {dateString}
                            </div>
                        )
                    }
                }
            },
            {
                field: "seat", width: 100, headerName: "좌석번호", type: 'singleSelect', valueOptions: seatExample, editable: true, sortable: false
            },
            {
                field: "fingerprintId", width: 100, headerName: "지문인식번호", editable: true, sortable: false
                //valueOptions: selectedLocation === "강남점" ? gangnamUserList : selectedLocation === "대치점" ? dachiUserList : []
            },
            {
                field: "fingerprintName", width: 180, headerName: "지문인식번호 이름", editable: false, sortable: false
            },
            {
                field: "sendCard", headerName: "학생카드 설문", width: 120, editable: false, sortable: false
            },
            {
                field: "parentPhoneNumber", headerName: "학부모 번호", width: 120, editable: true, sortable: false
            },
            {
                field: "sendedParentPhoneCert", headerName: "인증 전송 기록", width: 120, editable: true, sortable: false
            },
            {
                field: "parentCert", headerName: "학부모 인증 확인", width: 120, editable: false, sortable: false
            },
            {
                field: "registerPay", headerName: "등록비 기준액", width: 120, editable: true, sortable: false, type: "singleSelect", valueOptions: [{ value: 580000, label: "580,000원" }, { value: 620000, label: "620,000원" }, { value: 680000, label: "680,000원" }, { value: 720000, label: "720,000원" }]
            },
            {
                field: 'enterRegisterPay', headerName: '(출석부)등록비 입력', width: 150, filterable: true, renderCell: (params) => {

                    const id = params.row.id;
                    const userId = params.row.userId;

                    if (params.value) {

                        //params.value = "11__고용범__1634696828761" 꼴;

                        const name = params.value.split("__")[1];
                        const time = params.value.split("__")[2];
                        const date = new Date(+time);

                        //datestring으로 ~월 ~일 ~시 ~분 꼴로 만들기
                        const dateString = `${date.getMonth() + 1}/${date.getDate()}일 ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;

                        return (
                            <span>
                                {name} {dateString} &nbsp;
                                <Button variant="soft" size="sm" color="neutral"
                                    sx={{
                                        marginLeft: "30px"
                                    }}
                                    onClick={(e: any) => {
                                        submitCancel(id, params.field);
                                    }}
                                >
                                    취소하기
                                </Button>
                            </span>
                        )
                    } else {
                        return (
                            <Button variant="soft" size="sm" color="neutral"
                                onClick={(e: any) => {
                                    submitCheck(id, params.field);
                                }}
                            >
                                <BlockIcon />
                            </Button>
                        )
                    }

                }
            },
            {
                field: "registerPayIngStatus", headerName: "등록비결제 진행", width: 120, editable: true, sortable: false, type: "singleSelect", valueOptions: [{ value: "notYet", label: "미납" }, { value: "card", label: "결제완료(카드)" }, { value: "draw", label: "결제완료(계좌)" }, { value: "cash", label: "결제완료(현금)" },]
            },
            {
                field: 'addContents', headerName: '컨텐츠 추가 확인', width: 150, filterable: true, renderCell: (params) => {

                    const id = params.row.id;
                    const userId = params.row.userId;

                    if (params.value) {

                        //params.value = "11__고용범__1634696828761" 꼴;

                        const name = params.value.split("__")[1];
                        const time = params.value.split("__")[2];
                        const date = new Date(+time);

                        //datestring으로 ~월 ~일 ~시 ~분 꼴로 만들기
                        const dateString = `${date.getMonth() + 1}/${date.getDate()}일 ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;

                        return (
                            <span>
                                {name} {dateString} &nbsp;
                                <Button variant="soft" size="sm" color="neutral"
                                    sx={{
                                        marginLeft: "30px"
                                    }}
                                    onClick={(e: any) => {
                                        submitCancel(id, params.field);
                                    }}
                                >
                                    취소하기
                                </Button>
                            </span>
                        )
                    } else {
                        return (
                            <Button variant="soft" size="sm" color="neutral"
                                onClick={(e: any) => {
                                    submitCheck(id, params.field);
                                }}
                            >
                                <BlockIcon />
                            </Button>
                        )
                    }

                }
            },
            {
                field: 'seatTable', headerName: '좌석표 확인', width: 150, filterable: true, renderCell: (params) => {

                    const id = params.row.id;
                    const userId = params.row.userId;

                    if (params.value) {

                        //params.value = "11__고용범__1634696828761" 꼴;

                        const name = params.value.split("__")[1];
                        const time = params.value.split("__")[2];
                        const date = new Date(+time);

                        //datestring으로 ~월 ~일 ~시 ~분 꼴로 만들기
                        const dateString = `${date.getMonth() + 1}/${date.getDate()}일 ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;

                        return (
                            <span>
                                {name} {dateString} &nbsp;
                                <Button variant="soft" size="sm" color="neutral"
                                    sx={{
                                        marginLeft: "30px"
                                    }}
                                    onClick={(e: any) => {
                                        submitCancel(id, params.field);
                                    }}
                                >
                                    취소하기
                                </Button>
                            </span>
                        )
                    } else {
                        return (
                            <Button variant="soft" size="sm" color="neutral"
                                onClick={(e: any) => {
                                    submitCheck(id, params.field);
                                }}
                            >
                                <BlockIcon />
                            </Button>
                        )
                    }

                }
            },
            {
                field: 'introduceNew', headerName: '신규 안내', width: 150, filterable: true, renderCell: (params) => {

                    const id = params.row.id;
                    const userId = params.row.userId;

                    if (params.value) {

                        //params.value = "11__고용범__1634696828761" 꼴;

                        const name = params.value.split("__")[1];
                        const time = params.value.split("__")[2];
                        const date = new Date(+time);

                        //datestring으로 ~월 ~일 ~시 ~분 꼴로 만들기
                        const dateString = `${date.getMonth() + 1}/${date.getDate()}일 ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;

                        return (
                            <span>
                                {name} {dateString} &nbsp;
                                <Button variant="soft" size="sm" color="neutral"
                                    sx={{
                                        marginLeft: "30px"
                                    }}
                                    onClick={(e: any) => {
                                        submitCancel(id, params.field);
                                    }}
                                >
                                    취소하기
                                </Button>
                            </span>
                        )
                    } else {
                        return (
                            <Button variant="soft" size="sm" color="neutral"
                                onClick={(e: any) => {
                                    submitCheck(id, params.field);
                                }}
                            >
                                <BlockIcon />
                            </Button>
                        )
                    }

                }
            },
            {
                field: 'applicationRegistaration', headerName: '서약서동의', width: 150, filterable: true, renderCell: (params) => {

                    const id = params.row.id;
                    const userId = params.row.userId;

                    if (params.value) {

                        //params.value = "11__고용범__1634696828761" 꼴;

                        const name = params.value.split("__")[1];
                        const time = params.value.split("__")[2];
                        const date = new Date(+time);

                        //datestring으로 ~월 ~일 ~시 ~분 꼴로 만들기
                        const dateString = `${date.getMonth() + 1}/${date.getDate()}일 ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;

                        return (
                            <span>
                                {name} {dateString} &nbsp;
                                <Button variant="soft" size="sm" color="neutral"
                                    sx={{
                                        marginLeft: "30px"
                                    }}
                                    onClick={(e: any) => {
                                        submitCancel(id, params.field);
                                    }}
                                >
                                    취소하기
                                </Button>
                            </span>
                        )
                    } else {
                        return (
                            <Button variant="soft" size="sm" color="neutral"
                                onClick={(e: any) => {
                                    submitCheck(id, params.field);
                                }}
                            >
                                <BlockIcon />
                            </Button>
                        )
                    }

                }
            },
            {
                field: 'printAdmin', headerName: '프린트 계정생성', width: 150, filterable: true, renderCell: (params) => {

                    const id = params.row.id;
                    const userId = params.row.userId;

                    if (params.value) {

                        //params.value = "11__고용범__1634696828761" 꼴;

                        const name = params.value.split("__")[1];
                        const time = params.value.split("__")[2];
                        const date = new Date(+time);

                        //datestring으로 ~월 ~일 ~시 ~분 꼴로 만들기
                        const dateString = `${date.getMonth() + 1}/${date.getDate()}일 ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;

                        return (
                            <span>
                                {name} {dateString} &nbsp;
                                <Button variant="soft" size="sm" color="neutral"
                                    sx={{
                                        marginLeft: "30px"
                                    }}
                                    onClick={(e: any) => {
                                        submitCancel(id, params.field);
                                    }}
                                >
                                    취소하기
                                </Button>
                            </span>
                        )
                    } else {
                        return (
                            <Button variant="soft" size="sm" color="neutral"
                                onClick={(e: any) => {
                                    submitCheck(id, params.field);
                                }}
                            >
                                <BlockIcon />
                            </Button>
                        )
                    }

                }
            },
            {
                field: 'studentFile', headerName: '학생 파일', width: 150, filterable: true, renderCell: (params) => {

                    const id = params.row.id;
                    const userId = params.row.userId;

                    if (params.value) {

                        //params.value = "11__고용범__1634696828761" 꼴;

                        const name = params.value.split("__")[1];
                        const time = params.value.split("__")[2];
                        const date = new Date(+time);

                        //datestring으로 ~월 ~일 ~시 ~분 꼴로 만들기
                        const dateString = `${date.getMonth() + 1}/${date.getDate()}일 ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;

                        return (
                            <span>
                                {name} {dateString} &nbsp;
                                <Button variant="soft" size="sm" color="neutral"
                                    sx={{
                                        marginLeft: "30px"
                                    }}
                                    onClick={(e: any) => {
                                        submitCancel(id, params.field);
                                    }}
                                >
                                    취소하기
                                </Button>
                            </span>
                        )
                    } else {
                        return (
                            <Button variant="soft" size="sm" color="neutral"
                                onClick={(e: any) => {
                                    submitCheck(id, params.field);
                                }}
                            >
                                <BlockIcon />
                            </Button>
                        )
                    }

                }
            },
            {
                field: 'cabinet', headerName: '사물함배정', width: 150, filterable: true, renderCell: (params) => {

                    const id = params.row.id;
                    const userId = params.row.userId;

                    if (params.value) {

                        //params.value = "11__고용범__1634696828761" 꼴;

                        const name = params.value.split("__")[1];
                        const time = params.value.split("__")[2];
                        const date = new Date(+time);

                        //datestring으로 ~월 ~일 ~시 ~분 꼴로 만들기
                        const dateString = `${date.getMonth() + 1}/${date.getDate()}일 ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;

                        return (
                            <span>
                                {name} {dateString} &nbsp;
                                <Button variant="soft" size="sm" color="neutral"
                                    sx={{
                                        marginLeft: "30px"
                                    }}
                                    onClick={(e: any) => {
                                        submitCancel(id, params.field);
                                    }}
                                >
                                    취소하기
                                </Button>
                            </span>
                        )
                    } else {
                        return (
                            <Button variant="soft" size="sm" color="neutral"
                                onClick={(e: any) => {
                                    submitCheck(id, params.field);
                                }}
                            >
                                <BlockIcon />
                            </Button>
                        )
                    }

                }
            },
            // {
            //     field: 'seatPhotoUpload', headerName: '사진 올리기', width: 100, filterable: true, renderCell: (params) => {

            //         const id = params.row.id;
            //         const userId = params.row.userId;

            //         return (
            //             <div
            //                 onClick={(e: any) => {
            //                     setForImageUploadSelectedId(id);
            //                     setImageUploadModalOpen(true);
            //                 }}
            //                 style={{
            //                     display: "flex",
            //                     alignItems: "center",
            //                     justifyContent: "center",
            //                     width: "100%",
            //                     height: "100%",
            //                     cursor: "pointer"
            //                 }}>
            //                 <UploadIcon />
            //             </div>
            //         )

            //     }
            // },

            {
                field: 'seatPhoto', headerName: '좌석사진', width: 100, filterable: true, renderCell: (params) => {

                    const id = params.row.id;
                    const userId = params.row.userId;

                    if (params.value) {

                        return (
                            <div
                                onClick={(e: any) => {

                                    setImageSrc(params.value);
                                    setOpen3(true);

                                }}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                    height: "100%",
                                    cursor: "pointer"
                                }}>
                                <ImageIcon color="success" />
                            </div>
                        )

                    } else {
                        return (
                            <div>
                            </div>
                        )
                    }

                }
            },
            {
                field: 'finalCheck', headerName: "관리자 확인", width: 100, renderCell: (params) => {

                    const id = params.row.id;
                    const userId = params.row.userId;

                    const userName = props.user.name;

                    if (params.value) {

                        //params.value = "11__고용범__1634696828761" 꼴;

                        const name = params.value.split("__")[1];
                        const time = params.value.split("__")[2];
                        const date = new Date(+time);

                        //datestring으로 ~월 ~일 ~시 ~분 꼴로 만들기
                        const dateString = `${date.getMonth() + 1}/${date.getDate()}일 ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;

                        return (
                            <span>
                                {name} {dateString} &nbsp;
                                <Button variant="soft" size="sm" color="neutral"
                                    sx={{
                                        marginLeft: "30px"
                                    }}
                                    onClick={(e: any) => {
                                        submitCancel(id, params.field);
                                    }}
                                >
                                    취소하기
                                </Button>
                            </span>
                        )
                    } else if (userName !== "고용범" && userName !== "박가을 원장" && userName !== "윤종웅 (경희대 약대)" && userName !== "심윤주 부원장" && userName !== "박가을 (대치점 원장)") {
                        return (
                            <div>
                                관리자 권한 필요
                            </div>
                        )
                    } else {
                        return (
                            <Button variant="soft" size="sm" color="success"
                                onClick={(e: any) => {
                                    submitCheck(id, params.field);
                                }}
                            >
                                완료시키기
                            </Button>
                        )
                    }
                }
            },
            {
                field: "justForFilter", headerName: "필터", width: 2, filterable: true
            }
        ]
    )



    const [loading, setLoading] = useState(false);


    const notify1 = () => toast.success("삭제 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "dark"
    });

    const notify2 = () => toast.success("업로드 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored"
    });

    const notify3 = () => toast.success("수정 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored"
    });

    const notify4 = () => toast.success("전송 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored"
    });

    useEffect(() => {

        if (selectedRow) {
            setDisabled(false);

            const name = selectedRow.name;

            if (name && name.includes("중복")) {
                setDisabled2(false);
            } else {
                setDisabled2(true);
            }

            const parentPhoneNumber = selectedRow.parentPhoneNumber;

            if (parentPhoneNumber) {
                setDisabled3(false);
            } else {
                setDisabled3(true);
            }

        } else {
            setDisabled(true);
            setDisabled2(true);
            setDisabled3(true);
        }



    }, [selectedRow]);


    useEffect(() => {

        

        if(props.user){

            

            const newColumns : any = columns;

            newColumns.forEach((each : any) => {
                if(each.field === "location"){
                    each.valueOptions = [...totalLocationData.filter((each) => {
                        if(each.english === "total"){
                            return false;
                        }
    
                        if(!props.user){
                            return true;
                        }
    
                        if(each.academy.includes(props.user.academy)){
                            return true;
                        }
    
                        return false;
                    }).map((each) => {

                        
    
                        return {
                            value : each.korean,
                            label : each.korean
                        }
                    })] ;
                }
            })

            if(props.user.academy === "mom"){
                
                const newRow = {
                    field : "phoneSeat",
                    headerName : "핸드폰 자리",
                    width : 100,
                    editable : true,
                    sortable : true,
                }

                newColumns.splice(9, 0, newRow);

            }

            setColumns([...newColumns]);
        }

    }, [props.user]);

    useEffect(() => {

        const newColums : any = columns;

        newColums.forEach((each : any) => {

            if(each.field === "teacher"){
                each.valueOptions = teacherList;
            }
        })

        setColumns([...newColums]);

    }, [teacherList]);


    useEffect(() => {

        fetchTeacherList();

    }, []);

    const sendCardUrl  = () => {

        if(!selectedRow){
            return;
        }

        const selectedUserId = selectedRow.userId;
        const selectedUserName = selectedRow.name;

        if(!selectedUserId || !selectedUserName){
            return;
        }

        if(!window.confirm(`${selectedUserName} 학생에게 학생카드 설문 링크를 보내시겠습니까?`)){
            return;
        }

        setBtnLoading(true);

        fetch("https://peetsunbae.com/dashboard/avatar/sendcardurl", {
            method: "POST",
            headers: { "content-type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
                studentId : selectedUserId
            })
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    
                    if(result.message === "success"){
                        setBtnLoading(false);
                        notify1();

                        if(!result.roomNumber){
                            
                            return;
                        }
    
                        const chatRoomId = result.roomNumber;
    
                        props.socket.emit("newTeacherMessage", chatRoomId);
                    }
                })
        })

    }

    const fetchTeacherList = async () => {

        try {

            const response = await fetch("https://peetsunbae.com/dashboard/avatar/teachers", {
                method: "GET",
                credentials: "include"
            });

            const result = await response.json();

            const data = result.data;

            if (result.message === "success") {

                const teacherArray: any = [];

                teacherArray.push({
                    value: 1,
                    label: "상담X"
                })

                data.forEach((each: any) => {

                    const newRow = {
                        value: each.id,
                        label: each.name
                    }

                    teacherArray.push(newRow);

                });

                 

                setTeacherList([...teacherArray]);

            }

        } catch (e) {
            
        }

    }


    const updateDuplicateName = async (e: any) => {

        if (!window.confirm("중복 이름에 숫자를 더해서 수정하시겠습니까?")) {
            return;
        }

        if (!selectedRow) {
            return;
        }

        const userId = selectedRow.userId;

        if (!userId) {
            return;
        }

        try {

            setBtnLoading(true);

            const response = await fetch("https://peetsunbae.com/dashboard/avatar/new/updateduplicatename", {
                method: "POST",
                credentials: "include",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    userId
                })
            });

            const result = await response.json();

            if (result.message === "success") {
                getNewTodoList();
                setBtnLoading(false);
                notify3();
            }

        } catch (e) {
            
        }
    }

    const fetchBiostartUserList = async () => {

        try {

            const newBiostartList: any = [];

            const result = await fetch("https://peetsunbae.com/dashboard/avatar/biostar/userlist2", {
                method: "GET",
                credentials: "include",
            });

            const jsonResult = await result.json();

            

            if (jsonResult.message === "success") {

                const receivedBiostarUserList = jsonResult.biostarUserList;

                receivedBiostarUserList.forEach((each: any) => {

                    const newRow = {
                        value: each.user_id,
                        name: each.name
                    }

                    newBiostartList.push(newRow);

                })

            }

            return newBiostartList;

        } catch (e) {

            

        }

    }


    const start = async () => {

        setFirstLoading(true);
        await getNewTodoList();
        setFirstLoading(false);
    }



    useEffect(() => {

        start();

    }, []);

    const submitCancel = async (id: number, column: string) => {

        if (!id || !column) {
            return;
        }

        try {

            setBtnLoading(true);

            const response = await fetch("https://peetsunbae.com/dashboard/avatar/new/submitcancel", {
                method: "POST",
                credentials: "include",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    id, column
                })
            });

            const result = await response.json();

            if (result.message === "success") {
                getNewTodoList();
                setBtnLoading(false);
            }

        } catch (e) {
            
        }

    }

    const submitCheck = async (id: number, column: string) => {

        if (!id || !column) {
            return;
        }

        try {

            setBtnLoading(true);

            const response = await fetch("https://peetsunbae.com/dashboard/avatar/new/submitcheck", {
                method: "POST",
                credentials: "include",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    id, column
                })
            });

            const result = await response.json();

            if (result.message === "success") {
                getNewTodoList();
                setBtnLoading(false);
            }

        } catch (e) {
            
        }

    }


    const getBiostarLog = async () => {

        const bioStartUserList = await fetchBiostartUserList();

        setServerBiostarUserList([...bioStartUserList]);

        setRows((prev: any) => {

            const newRows = prev.map((each: any) => {

                const oneRow = each;

                if (oneRow.fingerprintId) {
                    oneRow.fingerprintName = "";

                    bioStartUserList.forEach((eachBiostarUser: any) => {

                        if (eachBiostarUser.value === oneRow.fingerprintId) {
                            oneRow.fingerprintName += eachBiostarUser.name;
                        }
                    })
                }

                return oneRow;

            })

            return newRows;

        });

        // if(rows.length === 0){
        //     
        //     
        //     return;
        // }

        // rows.forEach((oneRow: any) => {

        //     if (oneRow.fingerprintId) {
        //         oneRow.fingerprintName = "";

        //         bioStartUserList.forEach((eachBiostarUser: any) => {

        //             if (eachBiostarUser.value === oneRow.fingerprintId) {
        //                 oneRow.fingerprintName += eachBiostarUser.name;
        //             }
        //         })
        //     }

        // })

        // setRows([...rows]);
    }


    const getNewTodoList = async () => {

        try {

            //const bioStartUserList = await fetchBiostartUserList();

            const response = await fetch("https://peetsunbae.com/dashboard/avatar/newtodolist", {
                method: "GET",
                credentials: "include",
            });

            const result = await response.json();

            if (result.message === "success") {
                

                const data = result.data;

                const newRows: any = [];

                data.forEach((each: any) => {

                    const oneRow: any = {};

                    oneRow.id = each.id;
                    oneRow.userId = each.userId;
                    oneRow.name = each.name;
                    oneRow.phoneSeat = each.phoneSeat;
                    oneRow.fingerprint = each.fingerprint;

                    const createdAtDate = new Date(each.createdAt);
                    //datestring으로 ~/~ ~:~ 꼴로 만들기
                    const createdAtDateString = `${createdAtDate.getMonth() + 1}/${createdAtDate.getDate()} ${createdAtDate.getHours() < 10 ? "0" + createdAtDate.getHours() : createdAtDate.getHours()}:${createdAtDate.getMinutes() < 10 ? "0" + createdAtDate.getMinutes() : createdAtDate.getMinutes()}`;
                    oneRow.createdAt = createdAtDateString;

                    if (each.studentCardCreatedAt) {
                        const date = new Date(each.studentCardCreatedAt);
                        //datestring으로 ~/~ ~:~ 꼴로 만들기
                        const dateString = `${date.getMonth() + 1}/${date.getDate()} ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;
                        oneRow.sendCard = dateString;
                    }

                    if (each.studentCardSubmittedAt) {
                        const date = new Date(each.studentCardSubmittedAt);
                        //datestring으로 ~/~ ~:~ 꼴로 만들기
                        const dateString = `${date.getMonth() + 1}/${date.getDate()} ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;
                        oneRow.sendCard = "제출 완료 " + dateString + " (" + oneRow.sendCard + " 전송)";
                    }

                    if (!oneRow.sendCard) {
                        oneRow.sendCard = "[자동 입력]";
                    }

                    oneRow.parentPhoneNumber = each.parentPhoneNumber;

                    if (each.parentCertCreatedAt) {

                        

                        switch (each.parentGender) {
                            case "01":
                                each.parentGender = "남";
                                break;
                            case "02":
                                each.parentGender = "여";
                                break;
                        }

                        //each.parentBirthday 앞에 2글자 지우고 끝까지 가져오기
                        const year = each.parentBirthday.substring(2, each.parentBirthday.length);


                        const value = `${year}/${each.parentName}/${each.parentGender}/${each.parentPhoneNumber}`;

                        oneRow.parentCert = value;

                        
                        

                    }

                    if (!oneRow.parentCert) {
                        oneRow.parentCert = "[자동 입력]";
                    }

                    oneRow.location = each.location;
                    //oneRow.due = each.firstCome;

                    if (each.firstCome) {

                        
                        
                        const firstComeDate = new Date(each.firstCome);
                        oneRow.due = firstComeDate;

                    }

                    oneRow.seat = each.seat;
                    if (!each.seat) {
                        oneRow.seat = "0";
                    }

                    oneRow.fingerprintId = `${each.fingerprintId}`;

                    // if (oneRow.fingerprintId) {
                    //     oneRow.fingerprintName = "";

                    //     bioStartUserList.forEach((eachBiostarUser: any) => {

                    //         if (eachBiostarUser.value === oneRow.fingerprintId) {
                    //             oneRow.fingerprintName += eachBiostarUser.name;
                    //         }
                    //     })
                    // }
                    oneRow.fingerprintName = "";

                    if(serverBiostarUserList.length > 0){
                        serverBiostarUserList.forEach((eachBiostarUser: any) => {

                            if (eachBiostarUser.value === oneRow.fingerprintId) {
                                oneRow.fingerprintName = eachBiostarUser.name;
                            }
                        })
                    }

                    if (!oneRow.fingerprintName) {
                        oneRow.fingerprintName = "[자동 입력]";
                    }

                    if (!each.registerPayIngStatus) {
                        
                        oneRow.registerPayIngStatus = "notYet";
                    } else {
                        oneRow.registerPayIngStatus = each.registerPayIngStatus;
                    }

                    oneRow.registerPay = each.userRegisterPay;
                    oneRow.enterRegisterPay = each.enterRegisterPay;
                    oneRow.addContents = each.addContents;
                    oneRow.seatTable = each.seatTable;
                    oneRow.introduceNew = each.introduceNew;
                    oneRow.printAdmin = each.printAdmin;
                    oneRow.studentFile = each.studentFile;
                    oneRow.contacts = each.contacts;
                    oneRow.cabinet = each.cabinet;
                    oneRow.seatPhoto = each.seatPhoto;
                    oneRow.finalCheck = each.finalCheck;
                    oneRow.etc = each.etc;
                    oneRow.teacher = each.teacherId;
                    oneRow.teacherDescription = each.teacherDescription;
                    oneRow.applicationRegistaration = each.applicationRegistaration;

                    oneRow.sendedParentPhoneCert = each.sendedParentPhoneCert.length + "회 전송";

                    //1번 이상 전송 됬다면 가장 최근 전송 날짜가 몇일 전인지 표시
                    if (each.sendedParentPhoneCert.length > 0) {

                        const lastSendedParentPhoneCert = each.sendedParentPhoneCert[each.sendedParentPhoneCert.length - 1];
                        const createdDate = new Date(lastSendedParentPhoneCert.createdAt);
                        const currentDate = new Date();
                        const diff = Math.floor((currentDate.getTime() - createdDate.getTime()) / (1000 * 3600 * 24));

                        // (?일 전) 을 포함한 문자열로 만들기
                        const value = `${each.sendedParentPhoneCert.length}회 전송 (${diff}일 전)`;

                        oneRow.sendedParentPhoneCert = value;

                    }

                    if (!oneRow.sendedParentPhoneCert) {
                        oneRow.sendedParentPhoneCert = "[자동 입력]";
                    }

                    if (each.finalCheck) {
                        oneRow.justForFilter = "완료됨";
                    } else {
                        oneRow.justForFilter = "미완료";
                    }


                    if (each.isDuplicated) {
                        oneRow.name += " (중복 이름)";
                    }

                    oneRow.fingerprintName = "loading..."

                    newRows.push(oneRow);

                })

                setRows([...newRows]);

                getBiostarLog();

            }

        } catch (e) {
            
        }

    }


    const handleEditCommit = React.useCallback(
        async (newRow: GridRowModel, old: any) => {


            if (newRow.justForFilter === "완료됨") {
                alert("완료된 이후에는 수정이 불가능합니다.");
                return;
            }


            var field = "";


            

            for (const [key, value] of Object.entries(newRow)) {
                if (value !== old[key]) {
                    field = key;
                }
            }

            const value = newRow[field] ? newRow[field] : null;
            const id = newRow.id;
            const userId = newRow.userId;

            
            
            
            
            

            if (!field) {
                
                return newRow;
            }

            if (!id) {
                
                return newRow;
            }

            const data = {
                value,
                id,
                userId,
                field
            }

            

            try {

                // const response = await fetch("https://peetsunbae.com/dashboard/avatar/new/edit", {
                //     method: "POST",
                //     credentials: "include",
                //     headers: {
                //         "content-type": "application/json"
                //     },
                //     body: JSON.stringify(data)
                // });

                // const result = await response.json();

                // if (result.message === "success") {
                //     getNewTodoList();
                // }

                fetch("https://peetsunbae.com/dashboard/avatar/new/edit", {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        "content-type": "application/json"
                    },
                    body: JSON.stringify(data)
                }).then((response) => response.json())
                    .then((result) => {
                        if (result.message === "success") {
                            getNewTodoList();
                        }
                        if(result.message === "alreadyExistPhoneSeat"){

                            alert("이미 사용중인 핸드폰 자리입니다.");
                            getNewTodoList();

                        }
                    })


            } catch (e) {
                
            }

            return newRow;

        }, []
    );

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        
        alert(error.message);
    }, []);

    const deleteRow = async () => {

        const id = selectedRow.sqlId;

        if (!id) {
            return;
        }

        try {

            if (!window.confirm("정말로 삭제하시겠습니까?")) {
                return;
            }

            setBtnLoading(true);

            const response = await fetch("https://peetsunbae.com/dashboard/avatar/new/delete", {
                method: "POST",
                credentials: "include",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    id
                })
            });

            const result = await response.json();

            if (result.message === "success") {
                notify1();
                getNewTodoList();
                setBtnLoading(false);
            }

        } catch (e) {
            
        }

    }

    const reRequestParentCert = async () => {

        if (!selectedRow) {
            return;
        }

        const userId = selectedRow.userId;
        const id = selectedRow.sqlId;

        if (!userId) {
            return;
        }

        try {

            if (!window.confirm("정말로 학부모인증을 전송하시겠습니까?")) {
                return;
            }

            setBtnLoading(true);

            const response = await fetch("https://peetsunbae.com/dashboard/avatar/new/rerequestparentcert", {
                method: "POST",
                credentials: "include",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    userId,
                    id
                })
            });

            const result = await response.json();

            if (result.message === "success") {
                setBtnLoading(false);
                notify4();
            }

            if (result.message === "noParentPhoneNumber") {
                setBtnLoading(false);
                alert("학부모 번호가 없습니다.");
            }

        } catch (e) {
            
        }

    }

    const handleAlignmentChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {

        if (!newAlignment) {
            return;
        }

        setAlignment(newAlignment);

        //filtermodel중 id가 2인 것을 찾아서 newAlignment가 incomplete면 "미완료", total이면 ""로 바꾸기

        const newFilterModel = filterModel;

        newFilterModel.items.forEach((each: any) => {
            if (each.id === 2) {
                if (newAlignment === "incomplete") {
                    each.value = "미완료";
                } else {
                    each.value = "";
                }
            }
        })

        setFilterModel({ ...newFilterModel });

    }

    useEffect(() => {


    }, []);

    const handleAlignmentChange2 = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {

        if (!newAlignment) {
            return;
        }

        setAlignment2(newAlignment);

        //filtermodel중 id가 2인 것을 찾아서 newAlignment가 incomplete면 "미완료", total이면 ""로 바꾸기

        const newFilterModel = filterModel;

        // newFilterModel.items.forEach((each : any) => {
        //     if(each.id === 3){
        //         if(newAlignment === "total"){
        //             each.value = ["", "강남점", "대치점", "대치3층", "대치6층", null, undefined];
        //         }else if(newAlignment === "gangnam"){
        //             each.value = ["강남점"];
        //         }else if(newAlignment === "daechi"){
        //             each.value = ["대치점"];
        //         }else if(newAlignment === "daechi2"){
        //             each.value = ["대치3층"];
        //         }else if(newAlignment === "daechi3"){
        //             each.value = ["대치6층"];
        //         }
        //     }
        // })

        totalLocationData.forEach((each) => {

            if (each.english === newAlignment) {

                newFilterModel.items.forEach((each2: any) => {
                    if (each2.id === 3) {

                        const array: any = [];

                        if (newAlignment === "total") {

                            

                        } else {
                            array.push(each.korean);
                        }

                        each2.value = array;


                    }
                });

            }
        });

        setFilterModel({ ...newFilterModel });

    }

    const handleSearchTextChange = (e: any) => {

        setSearchText(e.target.value);

        const newFilterModel = filterModel;

        newFilterModel.items.forEach((each: any) => {
            if (each.id === 1) {
                each.value = e.target.value;
            }
        })

        setFilterModel({ ...newFilterModel });

    }



    return (
        <div>
            <div style={{ height: "12px" }}>

            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", marginBottom: "8px", marginTop: "12px" }}>
                <div>
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleAlignmentChange}
                        aria-label="Platform"
                        sx={{ marginRight: "36px" }}
                    >
                        <ToggleButton value="incomplete">미완료</ToggleButton>
                        <ToggleButton value="total">전체</ToggleButton>
                    </ToggleButtonGroup>

                    <ToggleButtonGroup
                        color="primary"
                        value={alignment2}
                        exclusive
                        onChange={handleAlignmentChange2}
                        aria-label="Platform"
                        sx={{ marginRight: "36px" }}
                    >
                        {/* <ToggleButton value="total">전체</ToggleButton>
                        <ToggleButton value="gangnam">강남점</ToggleButton>
                        <ToggleButton value="daechi">대치점</ToggleButton>
                        <ToggleButton value="daechi2">대치3층</ToggleButton>
                        <ToggleButton value="daechi3">대치6층</ToggleButton> */}
                        {
                            props.user && totalLocationData.filter((each) => each.academy.includes(props.user.academy)).map((each, index) => {
                                return (
                                    <ToggleButton key={index} value={each.english}>{each.korean}</ToggleButton>
                                )
                            })
                        }
                    </ToggleButtonGroup>

                    <TextField sx={{ fontSize: "24px", marginLeft: "12px" }} label="이름 검색" variant="standard" value={searchText} onChange={handleSearchTextChange} />
                </div>
                <div>
                    <Button variant="soft" size="sm" color="neutral" loading={deleteBtnLoading} disabled={disabled} style={{ marginRight: "12px" }}
                        onClick={sendCardUrl}
                    >
                        프로필 링크 보내기
                    </Button>
                    <Button variant="soft" size="sm" color="neutral" loading={deleteBtnLoading} disabled={disabled2} style={{ marginRight: "12px" }}
                        onClick={updateDuplicateName}
                    >
                        중복이름 수정하기
                    </Button>
                    <Button variant="soft" size="sm" color="neutral" loading={deleteBtnLoading} disabled={disabled3} style={{ marginRight: "12px" }}
                        onClick={reRequestParentCert}
                    >
                        학부모 인증 보내기
                    </Button>
                    <Button variant="soft" size="sm" color="neutral" loading={deleteBtnLoading} disabled={disabled} style={{ marginRight: "12px" }}
                        onClick={(e: any) => {
                            if (!selectedRow) {
                                return;
                            }

                            setForImageUploadSelectedId(selectedRow.sqlId);
                            setImageUploadModalOpen(true);
                        }}
                    >
                        좌석 사진 업로드
                    </Button>
                    <Button variant="soft" size="sm" color="danger" loading={deleteBtnLoading} disabled={disabled} onClick={deleteRow}>
                        삭제하기
                    </Button>
                </div>
            </div>
            <div className={classes.root} style={{ height: 500, width: '100%', backgroundColor: "white", marginTop: "24px" }}>
                <DataGridPremium
                    filterModel={filterModel}
                    onFilterModelChange={(model) => {
                        
                        setFilterModel(model);
                    }}
                    slots={{ toolbar: GridToolbar }}
                    loading={firstLoading}
                    density="compact"
                    columns={columns}
                    getCellClassName={(params: any) => {

                        if (!params.value) {
                            return "";
                        }

                        if (params.value === "[자동 입력]") {
                            return "autoinput";
                        }

                        if (params.field === "name") {
                            //params.value에 "중복"이 들어가면 duplicate라는 클래스를 준다.
                            if (params.value && params.value.includes("중복")) {
                                return "duplicate";
                            }
                        }

                        if (params.field === "sendCard") {

                            if (params.value && params.value.includes("제출 완료")) {
                                return "completed2";
                            }

                        }

                        if (params.field === "seat") {

                            if (params.value === "0") {
                                return "letsselect";
                            }

                        }

                        return ""
                    }}
                    getRowClassName={(params: GridRowClassNameParams<any>) => {

                        if (params.row.justForFilter === "완료됨") {
                            return "completed";
                        }

                        return ""
                    }}
                    rows={rows}
                    processRowUpdate={handleEditCommit}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                    apiRef={apiRef}
                    initialState={{
                        pinnedColumns: { left: ["name"] }
                    }}
                    onRowSelectionModelChange={(newSelectionModel) => {
                        const data: any = apiRef.current.getSelectedRows();
                        if (!data) {
                            return;
                        }
                        
                        

                        

                        data.forEach((value: any, index: number) => {

                            const newSelectedInfo = {
                                sqlId: value.id,
                                name: value.name,
                                userId: value.userId,
                                parentPhoneNumber: value.parentPhoneNumber
                            }

                            // switch (value.location) {
                            //     case "강남점":
                            //         setSelectedLocation("강남점");
                            //         break;
                            //     case "대치점":
                            //         setSelectedLocation("대치점");
                            //         break;
                            //     case "대치3층":
                            //         setSelectedLocation("대치3층");
                            //         break;
                            // }

                            totalLocationData.forEach((each) => {
                                if (each.korean === value.location) {
                                    setSelectedLocation(each.korean);
                                }
                            })

                            
                            setSelectedRow(newSelectedInfo);
                        })
                    }}
                    isCellEditable={(params) => {
                    
                        if(params.field === "phoneSeat" && (params.row.location === "송도_지정석" || !params.row.location)){
                            return false;
                        }

                        return true;
                    }}
                />
            </div>



            <ImageUploadModal open={imageUploadModalOpen} handleClose={handleImageUploadModalClose} update={getNewTodoList} id={forImageUploadSelectedId} success={notify2} kind="new" />

            <ImageModal
                open={open3}
                handleClose={() => { setOpen3(false) }}
                src={imageSrc}
            />

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={btnLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </div>
    )

}

export default New;