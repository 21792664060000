import React, { useEffect, useState, useRef } from "react";
import styles from "./mobilemyopinion.module.css";
import { ReactComponent as ArrowLeftLight } from "../../../src/svg/arrow-left-light.svg";
import { ReactComponent as PenToSqure } from "../../../src/svg/pen-to-square-regular.svg";
import { ReactComponent as ChevronRight } from "../../../src/svg/chevron-right-regular.svg";
import { useHistory } from "react-router-dom";
import { Capacitor } from "@capacitor/core";
import { useIonAlert, IonLoading, IonApp } from "@ionic/react";
import { Preferences } from "@capacitor/preferences";
import Avatar from '@mui/material/Avatar';
import { LinearProgress } from "@mui/material";



const MobileMyOpinion: React.FC<any> = (props) => {

    const headerRef = useRef<any>(null);
    const [bodyHeight, setBodyHeight] = useState<number>(0);


    const [platform, setPlatform] = useState<any>();
    const history = useHistory();
    const [loading, setLoading] = useState<any>(false);
    const [userName, setUserName] = useState("");

    const [data, setData] = useState<any>();

    const [firstCount, setFirstCount] = useState(0);
    const [secondCount, setSecondCount] = useState(0);
    const [thirdCount, setThirdCount] = useState(0);


    //플랫폼 정보 확인
    useEffect(() => {
        const platform = Capacitor.getPlatform();

        if (platform) {
            setPlatform(platform);
        }

    }, []);

    useEffect(() => {

        if (!headerRef) {
            return;
        }

        const value = +getComputedStyle(document.documentElement).getPropertyValue("--sat").split("p")[0];

        const caculateBodyHeight = () => {

            if (!headerRef || !headerRef.current) {
                return;
            }

            const totalHeight = window.innerHeight;
            const safeareaTop = value;
            const headerHeight = headerRef.current.offsetHeight;

            const lastHeight = totalHeight - safeareaTop - headerHeight
            setBodyHeight(lastHeight);
        }

        caculateBodyHeight();

        window.addEventListener("resize", () => caculateBodyHeight());

        return window.removeEventListener("resize", () => caculateBodyHeight());

    }, [headerRef]);


    useEffect(() => {
        start();
    }, []);


    async function start() {

        setLoading(true);

        var token: any = "";
        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        if (Capacitor.isNativePlatform()) {
            const { value } = await Preferences.get({ key: "token" });
            token = value;
        }

        fetch(`https://peetsunbae.com/dashboard/search/myopinion`, {
            method: "GET",
            headers: { "Authorization": token },
            credentials: "include",
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    
                    if (result.message === "success") {
                        const data = result.data;

                        var newFirstCount = 0;
                        var newSecondCount = 0;
                        var newThirdCount = 0;

                        data.forEach((oneRow: any, index: number) => {
                            oneRow.over = Math.floor((new Date().getTime() - new Date(oneRow.createdAt).getTime()) / 86400000) + "일";

                            switch (oneRow.status) {
                                case "미확인":
                                    newFirstCount++;
                                    break;
                                case "확인":
                                    newSecondCount++;
                                    break;
                                case "처리완료":
                                    newThirdCount++;
                                    break;
                            }

                            switch (oneRow.kindValue) {
                                case "live":
                                    oneRow.kind = "생활";
                                    break;
                                case "change":
                                    oneRow.kind = "자리변경";
                                    break;
                                case "consult":
                                    oneRow.kind = "담임상담";
                                    break;
                                case "question":
                                    oneRow.kind = "질의응답";
                                    break;
                                case "coaching":
                                    oneRow.kind = "과외";
                                    break;
                                case "ceo":
                                    oneRow.kind = "대표에게";
                                    break;
                                case "etc":
                                    oneRow.kind = "기타";
                                    break;
                            }


                            if (index === 0) {
                                setUserName(oneRow.name);
                            }
                        })

                        setFirstCount(newFirstCount);
                        setSecondCount(newSecondCount);
                        setThirdCount(newThirdCount);

                        

                        setData(data);


                    }
                    setLoading(false);

                })
        })
    }





    return (

        <div style={{ paddingTop: "env(safe-area-inset-top)" }}>
            <div className={styles.headerDiv}>
                <div className={`${styles.header} ${platform === "ios" ? styles.ios : ""}`} ref={headerRef}>
                    <div className={styles.backBtnDiv}
                        onClick={() => { history.push("/mobiledashboard") }}
                    >
                        <ArrowLeftLight
                            className={styles.arrowLeft}
                        />
                    </div>

                    <div className={styles.titleText}>
                        나의 의견 &nbsp;<span className={styles.imageNumber}>{data && data.length}</span>
                    </div>


                    <div className={`${styles.submit}`} onClick={() => { history.push("/mobiledashboard/opinion/write"); }}>
                        <PenToSqure className={styles.pentosqure} />
                    </div>
                </div>
            </div>
            {
                loading
                &&
                <LinearProgress />
            }

            {
                !loading
                &&
                <div className={styles.bodyDiv}>
                    <div className={styles.body} style={{ height: `${bodyHeight}px`, overflow: "scroll" }}>
                        <div className={styles.wrap}>
                            <div className={styles.bodyTitle}>
                                관리자 미확인({firstCount})
                            </div>
                            {
                                data && data.map((eachData: any, index: number) => {
                                    if (eachData.status === "미확인") {
                                        return (
                                            <div id={eachData.id} className={styles.box} onClick={() => {
                                                history.push({
                                                    pathname: "/mobiledashboard/opinion/detail",
                                                    state: { id: eachData.id }
                                                })
                                            }}>
                                                <div className={styles.avatarDiv}>
                                                    <div className={styles.innerAvatarDiv}>
                                                        <Avatar variant="rounded" sx={{ borderRadius: "0.7rem", bgcolor: "#3d50b0", width: "3rem", height: "3rem" }}>
                                                            <span className={styles.name}>
                                                                {
                                                                    userName &&
                                                                    userName.slice(userName.length - 2)
                                                                }
                                                            </span>
                                                        </Avatar>
                                                        <div className={styles.descriptionBox}>
                                                            <div className={styles.descriptionKind}>
                                                                {eachData.kind}
                                                            </div>
                                                            <div className={styles.descriptionEtc}>
                                                                {
                                                                    eachData.named === "named" ?
                                                                        "실명제출" : "익명제출"
                                                                } · {eachData.over} 전
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={styles.chevronBox}>
                                                        <ChevronRight fill={"#98989c"} className={styles.chevronRight} />
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                })
                            }
                        </div>
                        <div className={styles.wrap}>
                            <div className={styles.bodyTitle}>
                                관리자 확인({secondCount})
                            </div>
                            {
                                data && data.map((eachData: any, index: number) => {
                                    if (eachData.status === "확인") {
                                        return (
                                            <div id={eachData.id} className={styles.box} onClick={() => {
                                                history.push({
                                                    pathname: "/mobiledashboard/opinion/detail",
                                                    state: { id: eachData.id }
                                                })
                                            }}>
                                                <div className={styles.avatarDiv}>
                                                    <div className={styles.innerAvatarDiv}>
                                                        <Avatar variant="rounded" sx={{ borderRadius: "0.7rem", bgcolor: "#3d50b0", width: "3rem", height: "3rem" }}>
                                                            <span className={styles.name}>
                                                                {
                                                                    userName &&
                                                                    userName.slice(userName.length - 2)
                                                                }
                                                            </span>
                                                        </Avatar>
                                                        <div className={styles.descriptionBox}>
                                                            <div className={styles.descriptionKind}>
                                                                {eachData.kind}
                                                            </div>
                                                            <div className={styles.descriptionEtc}>
                                                                {
                                                                    eachData.named === "named" ?
                                                                        "실명제출" : "익명제출"
                                                                } · {eachData.over} 전
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={styles.chevronBox}>
                                                        <ChevronRight fill={"#98989c"} className={styles.chevronRight} />
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                })
                            }
                        </div>
                        <div className={styles.wrap}>
                            <div className={styles.bodyTitle}>
                                의견 처리완료({thirdCount})
                            </div>
                            {
                                data && data.map((eachData: any, index: number) => {
                                    if (eachData.status === "처리완료") {
                                        return (
                                            <div id={eachData.id} className={styles.box} onClick={() => {
                                                history.push({
                                                    pathname: "/mobiledashboard/opinion/detail",
                                                    state: { id: eachData.id }
                                                })
                                            }}>
                                                <div className={styles.avatarDiv}>
                                                    <div className={styles.innerAvatarDiv}>
                                                        <Avatar variant="rounded" sx={{ borderRadius: "0.7rem", bgcolor: "#3d50b0", width: "3rem", height: "3rem" }}>
                                                            <span className={styles.name}>
                                                                {
                                                                    userName &&
                                                                    userName.slice(userName.length - 2)
                                                                }
                                                            </span>
                                                        </Avatar>
                                                        <div className={styles.descriptionBox}>
                                                            <div className={styles.descriptionKind}>
                                                                {eachData.kind}
                                                            </div>
                                                            <div className={styles.descriptionEtc}>
                                                                {
                                                                    eachData.named === "named" ?
                                                                        "실명제출" : "익명제출"
                                                                } · {eachData.over} 전
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={styles.chevronBox}>
                                                        <ChevronRight fill={"#98989c"} className={styles.chevronRight} />
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
            }



            {/* <IonLoading
                cssClass='my-custom-class'
                isOpen={loading}
                onDidDismiss={() => setLoading(false)}
                message={'잠시만 기다려주세요'}
            /> */}
        </div>

    );
}

export default MobileMyOpinion;