import React, { useEffect, useState } from 'react';
import { dateToDateString } from '../../etc/etcfunctions';
import { DataGridPremium, GridFilterModel, GridRowModel } from '@mui/x-data-grid-premium';
import { dayNumberToKorean, englishLocationToKorean } from '../../../../../data/locationFuntion';
import { Button } from '@mui/joy';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Backdrop, CircularProgress, ToggleButton, ToggleButtonGroup } from '@mui/material';
import totalLocationData from '../../../../../data/locationdata';

const BookedList: React.FC<any> = (props) => {

    const [rows, setRows] = useState<any>([]);
    const [columns, setColumns] = useState([
        { field: "location", headerName: "지점", width: 100, filterable: true },
        { field: "kind", headerName: "종류", width: 100 },
        { field: "date", headerName: "예약일", width: 150 },
        { field: "time", headerName: "예약시간", width: 150 },
        { field: "name", headerName: "이름", width: 100 },
        { field: "phone", headerName: "전화번호", width: 150 },
        { field: "memo", headerName: "메모", width: 200, editable: true },
        { field: "createdAt", headerName: "신청시간", width: 180 }
    ]);
    const [selectedRowId, setSelectedRowId] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [alignment, setAlignment] = useState('total');
    const [alignment2, setAlignment2] = useState("current");

    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [
            { id: 2, field: 'location', operator: 'contains', value: "" },
        ],
    });

    const notify1 = () => toast.success("삭제 성공!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "colored"
    });

    const notify2 = () => toast.error("전송 실패!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "colored"
    });

    const getBookedList = async () => {

        try {

            const response = await fetch("https://suneungsunbae.com/api/booking/totalData", {
                method: "post",
                headers: {
                    "Content-Type": "application/json"
                },
            });

            const result = await response.json();

            const data = result.data;

            var newRows: any = [];

            data.forEach((each: any) => {

                const oneRow: any = {};
                oneRow.id = each.id;
                oneRow.location = englishLocationToKorean(each.location);

                const date = new Date(each.date);
                oneRow.date = dateToDateString(date);

                const day = date.getDay();
                dayNumberToKorean(day);
                oneRow.date += ` (${dayNumberToKorean(day)})`;

                var ampm = "오전";
                var hours = Math.floor(each.time / 60);
                var minutes = each.time % 60;

                const realDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes);

                oneRow.dateData = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes);

                if (hours >= 12) {
                    ampm = "오후";
                    if (hours > 12)
                        hours -= 12;
                }

                oneRow.time = `${ampm} ${hours}시`;

                if (minutes > 0) {
                    oneRow.time += ` ${minutes}분`;
                }

                oneRow.name = each.name;
                oneRow.memo = each.memo;
                oneRow.phone = each.telephoneNumber;
                oneRow.timeNumber = realDate.getTime();
                oneRow.kind = each.kind === 0 ? "방문 상담" : each.kind === 1 ? "전화 상담" : "시설 구경";
                oneRow.createdAt = dateToDateString(new Date(each.createdAt), true);

                newRows.push(oneRow);

            });

            if (alignment2 === "current") {
                newRows = newRows.filter((each: any) => {
                    const current = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0);
                    return each.dateData.getTime() >= current.getTime();
                })
            } else {
                newRows = newRows.filter((each: any) => {
                    const current = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0);
                    return each.dateData.getTime() < current.getTime();
                })
            }


            newRows.sort((a: any, b: any) => {
                if(alignment2 === "current") 
                return a.timeNumber - b.timeNumber;
                if(alignment2 === "past")
                return b.timeNumber - a.timeNumber;
            });



            setRows([...newRows]);

        } catch (e) {
            
        }

    }

    useEffect(() => {
        getBookedList();
    }, [alignment2]);

    const deleteRow = async () => {

        try {

            if (!selectedRowId) return;

            if (!window.confirm("정말 삭제하시겠습니까?")) return;

            setLoading(true);

            const idArray = [selectedRowId];

            const response = await fetch("https://suneungsunbae.com/api/booking/parkgaeul", {
                method: "delete",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    selectionModel: idArray
                })
            });

            const result = await response.json();

            if (result.message === "success") {
                notify1();
                getBookedList();
            }

            setLoading(false);

        } catch (e) {
            
            setLoading(false);
            notify2();
        }
    }

    const handleAlignmentChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {

        if (!newAlignment) return;

        setAlignment(newAlignment);

        const newFilterModel = filterModel;

        newFilterModel.items[0].value = newAlignment;

        if (newAlignment === "total") {
            newFilterModel.items[0].value = "";
        }

        setFilterModel({ ...newFilterModel });

    };

    const handleAlignmentChange2 = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {

        if (!newAlignment) return;

        setAlignment2(newAlignment);

    };

    const handleEditCommit = React.useCallback(
        async (newRow: GridRowModel, old: any) => {

            var field = "";

            

            for (const [key, value] of Object.entries(newRow)) {
                if (value !== old[key]) {
                    field = key;
                }
            }

            const value = newRow[field] ? newRow[field] : null;
            const id = newRow.id;

            
            
            
            

            if (!field) {
                
                return newRow;
            }

            if (!id) {
                
                return newRow;
            }

            const data = {
                value,
                id,
                field
            }

            

            try {

                fetch("https://suneungsunbae.com/api/booking/memo", {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                }).then(response => response.json()).then(result => {
                    getBookedList();
                })

            } catch (e) {
                
            }

            return newRow;

        }, []
    );

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        
        alert(error.message);
    }, []);

    return (
        <div>
            <div>
                <div style={{
                    marginBottom: "16px"
                }}>
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment2}
                        exclusive
                        onChange={handleAlignmentChange2}
                    >
                        <ToggleButton value="current">현재내역</ToggleButton>
                        <ToggleButton value="past">과거내역</ToggleButton>
                    </ToggleButtonGroup>
                </div>
            </div>
            <div style={{
                width: "1500px",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px"
            }}>
                <div>
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleAlignmentChange}
                    >
                        <ToggleButton value="total">전체</ToggleButton>
                        <ToggleButton value="강남점">강남점</ToggleButton>
                        <ToggleButton value="대치점">대치점</ToggleButton>
                        <ToggleButton value="분당점">분당점</ToggleButton>
                        <ToggleButton value="송파점">송파점</ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <div>
                    <Button
                        color='warning'
                        variant='soft'
                        disabled={!selectedRowId}
                        onClick={deleteRow}
                    >
                        삭제하기
                    </Button>
                </div>
            </div>
            <div style={{
                backgroundColor: "white",
                width: "1500px",
                height : "500px",
            }}>
                <DataGridPremium
                    rows={rows}
                    columns={columns}
                    density='compact'
                    onRowClick={(params) => {
                        
                        setSelectedRowId(params.row.id);
                    }}
                    filterModel={filterModel}
                    onFilterModelChange={(model) => {
                        setFilterModel(model);
                    }}
                    processRowUpdate={handleEditCommit}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                />
            </div>


            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default BookedList;