import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { Button } from "@mui/joy";
import { DataGridPremium} from "@mui/x-data-grid-premium";
import { makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GridRowProps, GridRowsProp } from "@mui/x-data-grid";

const defaultTheme = createTheme();

const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

        return {
            root: {
                '& .super-app-theme--처리완료': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .super-app-theme--확인': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .super-app-theme--미확인': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .clickable': {
                    cursor: "pointer",
                    '&:hover': {
                        color: "blue"
                    }
                },
                '& .status3': {

                },
                '& .inactivated': {
                    backgroundColor: getBackgroundColor(theme.palette.grey[500]),
                },
                '& .complete': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                },
                '& .incomplete': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                },
                '& .passivecomplete': {
                    backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                },
                '& .status5': {
                    backgroudColor: "red"
                }
            },
        };
    },
    { defaultTheme },
);



const columns = [
    { field: 'name', headerName: '이름', width: 100 },
    { field: 'amount', headerName: '환불금액', width: 100 },
    { field: 'date', headerName: '환불일자', width: 200 },
    { field: 'teacher', headerName: '책임자', width: 200 },
]


const LetsRefund: React.FC<any> = (props) => {

    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState<any>([]);
    const [update, setUpdate] = useState(0);
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [disabled, setDisabled] = useState(true);
    const [rows, setRows] = useState<GridRowProps[]>([]);


    const classes = useStyles2();

    useEffect(() => {

        setLoading(true);
        
        async function start() {

            var token = "";

            if (window.electron) {
                token = await window.electron.sendMessageApi.getToken();
            }

            fetch("https://peetsunbae.com/dashboard/restaurant/users2", {
                method: "GET",
                headers: { "Authorization": token },
                credentials: "include",
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        
                        const rows: any = [];
                        result.data.forEach((each: any, index: number) => {
                            var data: any = {};
                            data.id = each.id;
                            data.label = `${each.name} - 보유금액 ${each.amount ? each.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}원`;
                            data.phoneNumber = each.phoneNumber;
                            data.amount = each.amount;
                            data.value = each.value;
                            data.key = index;
                            rows.push(data);
                        })
                        setUsers([...rows]);
                        setLoading(false);

                    })
            })
        }

        start();

    }, [update])

    const onchange = async (e: any, value: any) => {
        if (!users) {
            return;
        }

        

        
        setSelectedUser(value);
    }

    useEffect(() => {

        //selectedUser.amount 0이거나 "0"이면 disabled true

        if (selectedUser && selectedUser.amount && selectedUser.amount !== 0 && selectedUser.amount !== "0") {
            setDisabled(false);
        } else {
            setDisabled(true);
        }

    }, [selectedUser]);


    useEffect(() => {

        getRefundData();

    }, [update])

    const getRefundData = () => {

        setLoading(true);

        fetch("https://peetsunbae.com/dashboard/restaurant/refund", {
            method: "GET",
            credentials: "include",
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    
                    const rows: any = [];
                    var reversedData = result.data.reverse();
                    reversedData.forEach((each: any, index: number) => {
                        var data: any = {};
                        data.id = each.id;
                        data.name = each.name;
                        data.amount = each.amount;

                        const date = new Date(each.createdAt);
                        const dateTimeString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours() < 0 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}:${date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()}`;
                        data.date = dateTimeString;

                        data.teacher = each.teacher;
                        data.key = index;
                        rows.push(data);
                    }
                    )
                    setRows([...rows]);
                    setLoading(false);
                })
        })
    }

    const refund = async (e : any) => {

        if(!selectedUser){
            
            return;
        }


        const amount = selectedUser.amount;
        //amount가 0이거나 null이거나 "0"인 경우 return
        if(!amount || amount === 0 || amount === "0"){
            return;
        }


        //amount에 3자리마다 , 찍기
        const amountString = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
 
        if(window.confirm(`${selectedUser.label.split(" ")[0]}의 ${amountString}원을 환불하시겠습니까?`) === false){
            return;
        }

        fetch("https://peetsunbae.com/dashboard/restaurant/totalrefund", {
            method : "post",
            credentials : "include",
            headers : {
                "Content-Type" : "application/json"
            },
            body : JSON.stringify({
                userId : selectedUser.id,
                amount : selectedUser.amount
            })
        }).then((response : any) => {
            response.json()
            .then((result : any) => {
                
                if(result.message === "success"){
                    setSelectedUser(null);
                    notify1();
                    setUpdate(Math.random());
                }
                if(result.message === "noChargedMoney" || result.message === "notSameMoney"){
                    notify2();
                }
            })
        })

    }

    const notify1 = () => toast.success("전액 환불 성공!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "colored"
    });


    const notify2 = () => toast.error("충전금액에 변동이 있어 환불 실패 !", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "colored"
    });

    return (
        <div>
            <div style={{ marginTop: "32px" }}>
                <div style={{ display: "flex" }}>
                    <Autocomplete
                        onChange={onchange}
                        value={selectedUser}
                        disablePortal
                        id="combo-box-demo"
                        options={users}
                        sx={{ width: "350px" }}
                        renderInput={(params) => <TextField {...params} placeholder="학생을 선택하세요" />}
                    />
                    <Button variant="soft" style={{ marginLeft: "12px" }} disabled={disabled} onClick={refund} >
                        전액 환불하기
                    </Button>
                </div>
            </div>

            <div className={classes.root} style={{ height: 650, width: '1500px', backgroundColor: "white", marginTop: "24px" }}>
                <DataGridPremium
                    rows={rows}
                    columns={columns}
                    loading={loading}
                    density="compact"
                />
            </div>


        </div>
    );
}

export default LetsRefund;