import React from 'react';

const CustomInput: React.FC<any> = (props) => {
  const { value, onChange, placeholder } = props;

  return (
    <input
      type="text"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      style={{
        border: 'none',
        borderBottom: '1px solid #000',
        outline: 'none',
        padding: '8px 0',
        fontSize: '16px',
        backgroundColor: 'transparent',
      }}
    />
  );
};

export default CustomInput;