const menu = [
    //--------------------------------- 학생, 학부모 뺀곳---------------
    {
        academy : ["sunbae", "mom", "molip", "bundangsunbae"],
        value : ["teacher", "parent", "student","staff"],
        name : "home",
        description : "홈"
    },
    {
        academy : ["sunbae", "mom", "molip", "bundangsunbae"],
        value : ["teacher", "parent", "staff", "student"],
        name : "notification",
        description : "공지사항"
    },
    {
        academy : ["sunbae", "mom", "molip", "bundangsunbae"],
        value : ["teacher", "student", "staff"],
        name : "alarm",
        description : "출석 기록"
    },
    {
        academy : ["sunbae", "mom", "molip", "bundangsunbae"],
        value : ["teacher", "parent", "staff", "student"],
        name : "edit",
        description : "지각/결석 사유 제출"
    },
    {
        academy : ["sunbae", "mom", "molip", "bundangsunbae"],
        value : ["parent", "student", "staff", "teacher"],
        name : "book",
        description : "상담일지"
    },
    {
        academy : ["sunbae", "mom", "molip", "bundangsunbae"],
        value : ["teacher", "student", "staff"],
        name : "question",
        description : "질의응답"
    },
    {
        academy : ["sunbae", "mom", "molip", "bundangsunbae"],
        value : ["teacher", "parent", "staff", "student"],
        name : "restaurant",
        description : "도시락신청"
    },
    {
        academy : ["sunbae", "mom", "molip", "bundangsunbae"],
        value : ["teacher", "parent", "staff", "student"],
        name : "envelope",
        description : "메시지"
    },
    {
        academy : ["sunbae", "mom", "molip", "bundangsunbae"],
        value : ["parent", "student"],
        name : "heart",
        description : "가족채팅방"
    },
    {
        academy : ["sunbae", "mom", "molip", "bundangsunbae"],
        value : ["teacher", "parent", "staff", "student"],
        name : "search",
        description : "의견 보내기"
    },
    {
        academy : ["sunbae", "mom", "molip", "bundangsunbae"],
        value : ["teacher", "staff", "student"],
        name : "text",
        description : "영단어 시험"
    },
    {
        academy : ["mom", "molip"],
        value : ["teacher", "staff", "student"],
        name : "checklist",
        description : "계획표 검사"
    },

    //------------------------------------------------------
    {
        academy : ["sunbae", "mom", "molip", "bundangsunbae"],
        value : ["teacher", "staff"],
        name : "chart",
        description : "상담일지 적기"
    },
    {
        academy : ["sunbae", "mom", "molip", "bundangsunbae"],
        value : ["teacher", "staff"],
        name : "diagnosis",
        description : "학습 진단"
    },
    {
        academy : ["sunbae", "mom", "molip", "bundangsunbae"],
        value : ["teacher", "staff"],
        name : "attendance",
        job : ["desk", "etc", "manager", "sagam"],
        description : "업무 관리"
    },
    {
        academy : ["sunbae", "mom", "molip", "bundangsunbae"],
        value : ["staff"],
        name : "출석 관리 보고",
        description : "홈"
    },
    {
        academy : ["sunbae", "mom", "molip", "bundangsunbae"],
        value : ["teacher", "staff"],
        name : "avatar",
        description : "학생정보"
    },
    {
        academy : ["sunbae", "bundangsunbae"],
        value : ["teacher", "staff"],
        name : "money",
        job : ["desk", "etc", "manager", "sagam"],
        description : "결제 관리" 
    },
    {
        academy : ["sunbae", "mom", "molip", "bundangsunbae"],
        value : ["teacher", "staff"],
        name : "check",
        job : ["desk", "etc", "manager", "sagam"],
        description : "ToDo"
    },
    {
        academy : ["sunbae", "mom", "molip", "bundangsunbae"],
        value : ["teacher", "staff"],
        name : "wifipermit",
        description : "방화벽 해제"
    },
    {
        academy : ["sunbae", "mom", "molip", "bundangsunbae"],
        value : ["student", "parent"],
        name : "wifipermitregister",
        description : "방화벽 해제"
    },
    {
        academy : ["sunbae", "mom", "molip", "bundangsunbae"],
        value : ["teacher", "staff"],
        name : "iot",
        job : ["desk", "etc", "manager", "sagam"],
        description : "IoT"
    },
    {
        academy : ["sunbae", "bundangsunbae"],
        value : ["teacher", "staff"],
        name : "booking",
        description : "상담 예약 관리"
    },
]

export default menu;




