import React, {useEffect, useState} from "react";
import styles from "./mobileupdate.module.css"
import { useLocation } from "react-router-dom";
import { Capacitor } from "@capacitor/core";

const MobileUpdate = (props : any) => {

    const [targetUrl, setTargetUrl] = useState<string>("");

    const location = useLocation();

    

    useEffect(() => {

        
        

        if(!location.state){
            return;
        }

        const {androidUrl, iosUrl} = location.state as any;

        if(Capacitor.getPlatform() === "android"){
            setTargetUrl(androidUrl);
        }

        if(Capacitor.getPlatform() === "ios"){
            setTargetUrl(iosUrl);
        }

    }, [location.state, location]);

    
    return (
        <div>
            Hello World !
        </div>
    );
}

export default MobileUpdate;