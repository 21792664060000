import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import Footer from "../etc/footer";
import InnerHeader from "../etc/innerheader";
import styles from "./notification.module.css";
import { Capacitor } from "@capacitor/core";
import { getFetch } from "../../fetch/fetch";
import { IonApp, IonAvatar, IonModal } from "@ionic/react";
import { returnDayString } from "../etc/functions";
import NotificationDetail from "./notificationdetail";
import { Avatar, LinearProgress } from "@mui/material";
import { ReactComponent as ShapeLogoSvg } from '../../../src/svg/onlyshape.svg';
import { set } from "lodash";
import { englishAcademyNameToKorean, renderLogoSvg } from "../../data/locationFuntion";

const NotificationList: React.FC<any> = (props) => {

    const headerRef = useRef<HTMLDivElement>(null);
    const footerRef = useRef<HTMLDivElement>(null);
    const [bodyHeight, setBodyHeight] = useState(0);
    const [platform, setPlatform] = useState("");
    const [page, setPage] = useState(1);
    const [data, setData] = useState<any[]>();
    const [selectedId, setSelectedId] = useState<number>();
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const [update, setUpdate] = useState(0);


    useEffect(() => {

        if (!isOpen) {
            setUpdate(Math.random());
        }

    }, [isOpen]);

    const handleClsoe = () => {
        setIsOpen(false);
        setSelectedId(0);
    }

    //플랫폼 정보 확인
    useEffect(() => {
        const platform = Capacitor.getPlatform();

        if (platform) {
            setPlatform(platform);
        }

       


    }, []);

    const caculateBodyHeight = () => {

        setTimeout(() => {

            if (!headerRef || !headerRef.current) {
                return;
            }

            if (!footerRef || !footerRef.current) {
                return;
            }

            const value = +getComputedStyle(document.documentElement).getPropertyValue("--sat").split("p")[0];
            const totalHeight = window.innerHeight;
            const safeareaTop = value;
            const headerHeight = headerRef.current.offsetHeight;
            const footerHeight = footerRef.current.offsetHeight;

            const lastHeight = totalHeight - safeareaTop - headerHeight - footerHeight;

            
            
            setBodyHeight(lastHeight);

        }, 50);
    }

    useEffect(() => {

        caculateBodyHeight();

        window.addEventListener("resize", () => caculateBodyHeight());

        return window.removeEventListener("resize", () => caculateBodyHeight());

    }, [headerRef, footerRef]);


    const getNotifications = async () => {

        setLoading(true);
        try {
            const result: any = await getFetch(`/dashboard/notification/mobileget?page=${page}`);
            setData(result.message);
        } catch (e) {
            
        }
        setLoading(false);
    }


    useEffect(() => {

        getNotifications();

    }, []);

    useEffect(() => {

        if (!selectedId) {
            return;
        }

        if (!data) {
            return;
        }

        const newData = [...data];

        newData.forEach((eachData) => {
            if (eachData.id === selectedId) {
                eachData.isRead = true;
            }
        })

        setData([...newData]);


    }, [selectedId]);


    return (
        <IonApp>
            <div>
                <div style={{ paddingTop: "env(safe-area-inset-top)" }}>
                    <div ref={headerRef} className={`${styles.topDiv}`}>
                        <div className={styles.bodyDiv}>
                            <div className={`${styles.body} ${platform === "ios" ? styles.ios : ""}`}>
                                <div className={styles.title}>
                                    공지 사항
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        loading
                        &&
                        <LinearProgress />
                    }
                </div>
                {
                    !loading
                    &&
                    <div className={styles.main} style={{ height: `${bodyHeight}px` }}>
                        {
                            data && data.map((eachData: any, index: number) => {

                                

                                const dayString = returnDayString(eachData.createdAt.day);


                                return (
                                    <div key={eachData.id} className={styles.notificationWrapper} onClick={(e) => { setSelectedId(eachData.id); setIsOpen(true);  }}>
                                        <div className={styles.eachNotification}>
                                            {/* <div className={styles.eachNotificationKindDivWrapper}>
                                            <div className={styles.eachNotificationKindDiv}>
                                                <div className={styles.eachNotificationKindAll}>
                                                    All
                                                </div>
                                                <div className={styles.eachNotificationKindText}>
                                                    [공통] 공지사항
                                                </div>
                                            </div>
                                        </div> */}
                                            <div className={styles.authorDiv}>
                                                <div className={styles.avatarDiv}>
                                                    {/* {
                                                        !eachData.authorAvatar ?
                                                            <div className={`${styles.profileContainer} ${styles.ofStudent}`}>
                                                                <img src="img/user-solid.svg" alt="user" className={styles.avatar} />
                                                            </div>
                                                            :
                                                            ""
                                                    }
                                                    {
                                                        eachData.authorAvatar ?
                                                            <IonAvatar className={styles.profileContainer}>
                                                                <img alt="" src={`/img/avatar/Avatar-${eachData.authorAvatar}.png`} className={styles.ionAvatarImg} />
                                                            </IonAvatar>
                                                            :
                                                            ""
                                                    } */}
                                                    <Avatar sx={{ bgcolor: "#fff", border: "#bdbdbd 1px solid", marginRight: "0.5rem" }}>
                                                                {
                                                                    (props.user && props.user.academy) &&
                                                                    renderLogoSvg(props.user.academy, "shape", styles.shapeLogo)
                                                                }
                                                    </Avatar>
                                                    <div className={styles.avatarNameDiv}>
                                                        <div className={styles.avatarName}>
                                                            {
                                                                    (props.user && props.user.academy) &&
                                                                    englishAcademyNameToKorean(props.user.academy)
                                                            }
                                                            {
                                                                !eachData.isRead
                                                                &&
                                                                <div className={styles.new}>N</div>
                                                            }
                                                        </div>
                                                        <div className={styles.uploadDate}>
                                                            {eachData.createdAt.month}월 {eachData.createdAt.date}일 ({dayString}) {eachData.createdAt.hours > 11 ? "오후" : "오전"} {eachData.createdAt.hours === 0 ? 12 : eachData.createdAt.hours < 13 ? eachData.createdAt.hours : eachData.createdAt.hours - 12}:{eachData.createdAt.minutes < 10 ? "0" + eachData.createdAt.minutes : eachData.createdAt.minutes}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.notificationTitle}>
                                                    {
                                                        eachData.title
                                                    }
                                                </div>
                                                {
                                                    eachData.images.length > 0
                                                    &&
                                                    <div className={styles.notificationImgDiv}>
                                                        <img className={styles.notificationImg} src={`https://peetsunbae.com/${eachData.images[0].split("/public/")[1]}`} alt="notificationImg" />
                                                    </div>
                                                }
                                                {/* <div className={styles.notificationViews}>
                                                <div className={styles.notificationViewBox}>
                                                    😃&nbsp;&nbsp;{eachData.number}
                                                </div>
                                            </div> */}
                                            </div>
                                        </div>
                                        <div className={styles.border}>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                }

                <Footer unreadMessage={props.unreadMessage} notificationUpdate={update} unread={props.unread} footerRef={footerRef} location={"notification"} />
            </div>

            <IonModal
                isOpen={isOpen}
                onDidDismiss={() => { setIsOpen(false); }}
            >
                <NotificationDetail
                    isOpen={isOpen}
                    id={selectedId}
                    handleClose={handleClsoe}
                    user={props.user}
                />
            </IonModal>

        </IonApp>
    );
}

export default NotificationList;