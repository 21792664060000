import React, { useEffect, useState, useCallback } from "react";
import {
    DataGridPremium,
    GridColDef,
    GridRowsProp,
    GridRowSelectionModel,
    useGridApiRef,
    GridGroupNode,
    GRID_ROOT_GROUP_ID,
    GridCellSelectionModel,
    GridFilterModel,
    GridLogicOperator,
    GridRowModel,
    GridRowClassNameParams,
    GridRenderCellParams,
    useGridApiContext,
    useGridSelector,
    gridDetailPanelExpandedRowIdsSelector,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from '@mui/x-data-grid-premium';
import { makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
import { renderCellExpand } from "./suddenData";
import { IconButton } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";


const defaultTheme = createTheme();

const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);


        return {
            root: {
                '& .super-app-theme--처리완료': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .once': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .once_in': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    color : "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    }
                },
                '& .never': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .never_in': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    color : "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .clickable': {
                    cursor: "pointer",
                    '&:hover': {
                        color: "blue"
                    }
                },
                '& .enter': {
                    color : "blue"
                },
                '& .exit': {
                    color : "red"
                },
                '& .phone_memo': {
                    color : "black"
                },
                '& .status2': {
                    backgroudColor: "red"
                },
                '& .status1': {
                    backgroudColor: "red"
                },
                '& .status4': {
                    backgroudColor: "red"
                },
                '& .status5': {
                    backgroudColor: "red"
                }
            },
        };
    },
    { defaultTheme },
);

const noData : any = [
    {
        id : 1, description : "데이터가 없습니다"
    }
]

function CustomDetailPanelToggle(props: Pick<GridRenderCellParams, 'id' | 'value'>) {
    const { id, value: isExpanded } : any = props;
    const apiRef = useGridApiContext();

  
    // To avoid calling ´getDetailPanelContent` all the time, the following selector
    // gives an object with the detail panel content for each row id.
    const contentCache = useGridSelector(
      apiRef,
      gridDetailPanelExpandedRowIdsSelector,
    );

    const currentRow = apiRef.current.getRow(id);

    var isSms = false;

    if(currentRow.kind && currentRow.kind.indexOf("문자") !== -1){
        isSms = true;
    }

    var isDurationZero = true;
    //currentRow.description 이 '통화시간 - 0s' 안 포함하고 있으면 isDurationZero = false;
    if(currentRow.description && currentRow.description.indexOf("통화시간 - 0s") === -1){
        isDurationZero = false;
    }
  
    // If the value is not a valid React element, it means that the row has no detail panel.
    const hasDetail = React.isValidElement(contentCache[id]);

    if(!isSms && !isDurationZero){

        return (
            <IconButton
              size="small"
              tabIndex={-1}
              disabled={false}
              aria-label={isExpanded ? 'Close' : 'Open'}
            >
              <ExpandMore
                sx={{
                  transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                  transition: (theme) =>
                    theme.transitions.create('transform', {
                      duration: theme.transitions.duration.shortest,
                    }),
                }}
                fontSize="inherit"
              />
            </IconButton>
          );
    }else{
        return (
            <></>
        )
    }
  

  }
  

const PhoneData : React.FC<any> =(props) => {

    const getDetailPanelContent = useCallback(
        ({row} : any) => 
        {

            return (
                <div>
                    <div
                    style={{
                        paddingTop : "15px",
                        paddingLeft : "15px",
                        paddingRight : "15px",
                        paddingBottom : "15px",
                    }}
                    >
                        {
                            (row && row.contents && row.contents.length > 0) && row.contents.map((eachContent : any, index : number) => {

                                return (
                                    <div key={Math.random()} style={{
                                        marginBottom : "15px",
                                        fontFamily : "Apple_R",
                                        lineHeight : 1.3,
                                        width : "300px"
                                    }}>
                                        {eachContent.msg}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            )
        },
        [],
    );

    const classes = useStyles2();
    const [rows, setRows] = useState<GridRowsProp[]>([]);
    const [columns, setColumns] = useState<GridColDef[]>([
        { field : "kind", headerName : "종류", width : 60 },
        { field : "who", headerName : "대상", width : 60 },
        { field : "description", headerName : "내용", width : 200, renderCell : renderCellExpand },
        {
            ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
            renderCell : (params) => {
                return <CustomDetailPanelToggle id={params.id} value={params.value} />   
            }  
        },
        { field : "date", headerName : "날짜", width : 150, renderCell : renderCellExpand },
    ]);



    useEffect(() => {

        if(!props.phoneLogData){
            setRows([...noData]);
            return;
        }

        const phoneLogData = props.phoneLogData;

        

        makeRows(phoneLogData);


    }, [props.phoneLogData]);

    const makeRows = (phoneLogData : any) => {

        const newRows : any = [];

        phoneLogData.forEach((eachPhoneLog : any, index : number) => {

            const oneRow : any = {};
            
            const codelength = 15;

            const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let guid = '';
            for (let i = 0; i < codelength; i++) {
                guid += chars.charAt(Math.floor(Math.random() * chars.length));
            }

            oneRow.id = guid;

            oneRow.sqlId = eachPhoneLog.id;

            switch(eachPhoneLog.kind){
                case "sms" :
                    oneRow.kind = "문자";
                    break;
                default :
                    oneRow.kind = "전화";
                    break;
            }

            if(eachPhoneLog.type === 1){
                oneRow.kind += "▼"
            }
            
            if(eachPhoneLog.type === 2){
                oneRow.kind += "△"
            }

            if(eachPhoneLog.kind === "sms"){
                if(eachPhoneLog.phoneNumber === props.studentPhoneNumber){
                    oneRow.who = "학생";
                }else{
                    oneRow.who = "학부모";
                }
            }

            if(eachPhoneLog.kind !== "sms"){
                if(eachPhoneLog.number === props.studentPhoneNumber){
                    oneRow.who = "학생";
                }else{
                    oneRow.who = "학부모";
                }
            }

            if(eachPhoneLog.kind === "sms"){
                oneRow.description = eachPhoneLog.text;
            }

            if(eachPhoneLog.kind !== "sms"){
                var phoneDescription = "";

                // 통화시간 - 0s, 통화대기시간 - 0s

                phoneDescription += "통화시간 - " + eachPhoneLog.duration + "s, ";

                const waitTimeMilliSecond = (+eachPhoneLog.last_modified) - (+eachPhoneLog.dateTime) - (+eachPhoneLog.duration * 1000);
                const waitTimeSecond = Math.floor(waitTimeMilliSecond / 1000);

                phoneDescription += "통화대기시간 - " + waitTimeSecond + "s";

                oneRow.description = phoneDescription;

                oneRow.contents = eachPhoneLog.contents;

            }


            const date = new Date(eachPhoneLog.timeNumber);

            //MM월 dd일 HH시 mm분

            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const hour = date.getHours();
            const minute = date.getMinutes();

            const today = new Date();
            var isToday = false;

            if(today.getFullYear() === year && today.getMonth() + 1 === month && today.getDate() === day){
                isToday = true;
            }

            if(isToday){
                var dateString = "오늘 " + hour + "시 " + minute + "분";
            }else{
                var dateString = month + "월 " + day + "일 " + hour + "시 " + minute + "분";
            }

            oneRow.date = dateString;

            newRows.push(oneRow);

        })

        setRows([...newRows]);

    }


    return (
        <div className={classes.root}
        style={{
            width : "550px",
            height : "200px",
            marginTop : "10px"
        }}
        >
                        <div
            style={{
                display : "flex",
                justifyContent : "center",
                paddingBottom : "10px",
                fontFamily : "Apple_SB",
                paddingTop : "36px",
            }}
            >
                문자/전화
            </div>
            <DataGridPremium
            sx={{
                backgroundColor : "white"
            }}
            loading={props.loading}
            rows={rows}
            columns={columns}
            density="compact"
            hideFooter
            getDetailPanelContent={getDetailPanelContent}
            getDetailPanelHeight={({ row }) => 'auto'}
            getCellClassName={(props : any) => {

                if(props.field === "direction"){
                    if(props.value === "enter"){
                        return "enter";
                    }else if(props.value === "exit"){
                        return "exit";
                    }
                }

                return "";
            }}

            />
        </div>
    )

}

export default PhoneData;