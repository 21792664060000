import React, { useEffect, useState } from "react";
import styles from "../componentsStyle/check.module.css";

import { makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Add from '@mui/icons-material/Add';
import Typography from '@mui/joy/Typography';
import { CssVarsProvider, Radio, RadioGroup, Textarea } from "@mui/joy";
import Autocomplete from '@mui/material/Autocomplete';

import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import koLocale from 'date-fns/locale/ko';
import TextField from '@mui/material/TextField';


import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import ExpandLessSharpIcon from '@mui/icons-material/ExpandLessSharp';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AddLinkSharpIcon from '@mui/icons-material/AddLinkSharp';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    DataGridPremium,
    GridColDef,
    GridRowsProp,
    GridRowSelectionModel,
    useGridApiRef,
    GridGroupNode,
    GRID_ROOT_GROUP_ID,
    GridCellSelectionModel,
    GridFilterModel,
    GridLogicOperator,
    GridRowModel
} from '@mui/x-data-grid-premium';
import { Badge, Box, InputAdornment, LinearProgress, SwipeableDrawer, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { GridPreProcessEditCellProps, GridRowParams, GridToolbar } from "@mui/x-data-grid";
import { eachHourOfInterval } from "date-fns/esm";
import ChatForTodos from "./controls/chatforTodos";
import { AccountCircle, CheckCircle } from "@mui/icons-material";
import { setTimeout } from "timers";



const defaultTheme = createTheme();

const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

        return {
            root: {
                '& .super-app-theme--처리완료': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .super-app-theme--확인': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .super-app-theme--미확인': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .clickable': {
                    cursor: "pointer",
                    '&:hover': {
                        color: "blue"
                    }
                },
                '& .status3': {

                },
                '& .status2': {
                    backgroudColor: "red"
                },
                '& .status1': {
                    backgroudColor: "red"
                },
                '& .status4': {
                    backgroudColor: "red"
                },
                '& .status5': {
                    backgroudColor: "red"
                }
            },
        };
    },
    { defaultTheme },
);



const Check: React.FC<any> = (props) => {


    const columns: GridColDef[] = [
        {
            field: "groupChevron", width: 60, headerName: "그룹",
            renderCell: (params: any) => {
                // 
                if (params.value === "groupLeader") {

                    const groupId = +params.row.id.split("_")[1];
                    if (openedId.includes(`${groupId}`)) {
                        return (
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className={styles.expand}>
                                <ExpandLessSharpIcon fontSize="medium" />
                            </div>
                        );
                    } else {
                        return (
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className={styles.expand}>
                                <ExpandMoreSharpIcon fontSize="medium" />
                            </div>
                        )
                    }


                } else {
                    return (
                        <div>

                        </div>
                    )
                }
            },
        },
        {
            field: "targetUsers", width: 100, headerName: "책임자",

            renderCell: (params: any) => {

                if (params.value) {

                    var names = "";

                    params.value.forEach((each: any, index: number) => {

                        if (index > 0) {
                            names = names + ", "
                        }

                        names = names + each.label.split(" ")[0];

                    });

                    return (
                        <div>
                            {names}
                        </div>
                    )

                } else {
                    return (
                        <div>

                        </div>
                    );
                }

            },
            sortable: false
        },
        {
            field: "title", width: 150, headerName: "제목",
            sortable: false, editable: true,
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                if (params.props.value) {
                    return { ...params.props, error: false }
                } else {
                    return { ...params.props, error: true }

                }
            }
        },
        {
            field: "review", width: 60, headerName: "댓글",
            renderCell: (params: any) => {
                if(!params.value && params.value !== 0){
                    return (
                        <div>
                        </div>
                    )
                }else if(params.value === 0){
                    return (
                        <div style={{ display: "flex", alignItems: "baseline", cursor: "pointer", width: "100%", justifyContent : "center" }}>
                            <ChatBubbleOutlineIcon fontSize="small" color="disabled" onClick={() => { toggleDrawer(true);  }} />
                        </div>
                    );
                }else if(params.value > 0 && params.row.isReviewRead){
                    return (
                        <div style={{ display: "flex", alignItems: "baseline", cursor: "pointer", width: "100%", justifyContent : "center" }}  onClick={() => { toggleDrawer(true);  }} >
                            <Badge badgeContent={params.value} color="primary">
                            </Badge>
                        </div>
                    );

                }else if(params.value > 0 && !params.row.isReviewRead){
                    return (
                        <div style={{ display: "flex", alignItems: "baseline", cursor: "pointer", width: "100%", justifyContent : "center" }}  onClick={() => { toggleDrawer(true);  }} >
                            <Badge badgeContent={params.value} color="error">
                            </Badge>
                        </div>
                    );

                }


            },
            sortable: false

        },
        {
            field: "description", width: 300, headerName: "내용", editable: true,
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                if (params.props.value) {
                    return { ...params.props, error: false }
                } else {
                    return { ...params.props, error: true }

                }
            }
        },

        {
            field: "status", width: 100, headerName: "진행상태", type: 'singleSelect', valueOptions: [{ value: 1, label: "미확인" }, { value: 2, label: "확인" }, { value: 3, label: "완료" }, {value : 4, label : "보류중"}], editable: true, sortable: false
        },
        {
            field: "changedUser", width: 150, headerName: "처리한 사람", sortable: false
        },
        {
            field: "priority", width: 100, headerName: "우선 순위", type: "singleSelect", valueOptions: [{ value: 1, label: "낮음" }, { value: 2, label: "보통" }, { value: 3, label: "중요" }], editable: true, sortable: false
        },
        {
            field: "date", width: 150, headerName: "업로드 날짜"
        },
        {
            field: "due", width: 120, headerName: "기한", type: "date", editable: true, sortable: false,
            renderCell: (params) => {
                if (!params.value && params.row.type !== "groupElement" && params.row.type !== "groupNull") {
                    return (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <CalendarTodayIcon style={{ cursor: "pointer" }} fontSize="small" color="disabled" />
                        </div>
                    )
                } else if (params.row.type === "groupElement" || params.row.type === "groupNull") {
                    return (
                        <div>

                        </div>
                    );
                } else {

                    const date = new Date(params.value);

                    const dateString = `${date.getMonth() + 1}월 ${date.getDate()}일`;

                    return (
                        <div>
                            {dateString}
                        </div>
                    )
                }
            }
        },
        {
            field: "link", width: 100, headerName: "참고링크", editable: true, sortable: false, renderCell: (params) => {

                if (!params.value && params.row.type !== "groupElement" && params.row.type !== "groupNull") {
                    return (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <AddLinkSharpIcon style={{ cursor: "pointer" }} fontSize="small" color="disabled" />
                        </div>
                    )
                } else if (params.row.type === "groupElement" || params.row.type === "groupNull") {
                    return (
                        <div>

                        </div>
                    );
                } else {


                    return (
                        <div>
                            <a href={params.value} target="_blank">
                                {params.value}
                            </a>
                        </div>
                    )
                }

            }
        },
        {
            field: "author", width: 100, headerName: "작성자", editable: true, sortable: false
        },
        {
            field: "just", width: 10, headerName: "", sortable: false, filterable: true
        }
    ]





    const classes = useStyles2();

    const [originalData, setOriginalData] = useState<any>();

    const [loading, setLoading] = useState(false);

    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);

    // const [originalData, setOriginalData] = useState<any>();

    const [kinds, setKinds] = useState("single");
    const [priority, setPriority] = useState("medium");
    const [toDoNumber, setToDoNumber] = useState(1);
    const [deadline, setDeadline] = useState(new Date());
    const [selectedUser, setSelectedUser] = useState<any>();
    const [users, setUsers] = useState<any>();
    const [disabled, setDisabled] = useState(true);
    const [disabled2, setDisabled2] = useState(true);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState(new Array(50).fill(undefined));
    const [rows, setRows] = useState<any>([]);
    const [update, setUpdate] = useState<number>(0);
    const [update2, setUpdate2] = useState<number>(0);
    const [selectedInformation, setSelectedInformation] = useState<any>();

    const [selectedRow, setSelectedRow] = useState<any>();
    const [selectedTargetUsers, setSelectedTargetUsers] = useState<any>();

    const [upDisabled, setUpDisabled] = useState(true);
    const [addDisabled, setAddDisabled] = useState(true);
    const [deleteDisabled, setDeleteDisabled] = useState(true);

    const [rowData, setRowData] = useState(0);

    const [cellSelectionModel, setCellSelectionModel] = useState<GridCellSelectionModel>({});

    const apiRef = useGridApiRef();

    const [openedId, setOpenedId] = useState<any>([]);

    const [addElement, setAddElement] = useState("");

    const [selectedTitle, setSelectedTitle] = useState("");

    const [searchName, setSearchName] = useState("");
    const [searchText, setSearchText] = useState("");

    const [alignment, setAlignment] = useState("incomplete");

    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [
            {
                id: 1,
                field: "just",
                operator: "contains",
                value: "p"
            },
            {
                id: 2,
                field: "just",
                operator: "isAnyOf",
                value: []
            },
        ],
        logicOperator: GridLogicOperator.Or
    });



    const handleAlignmentChange = (e : any, newAlignment : string) => {

        if(!newAlignment){
            return;
        }

        setAlignment(newAlignment);

    }

    const handleSearchNameChange = (e : any) => {
        setSearchName(e.target.value);
    }

    const handleSearchTextChange = (e : any) => {
        setSearchText(e.target.value);
    }


    const toggleDrawer = (open: boolean) => {
        setOpen3(open);
    }



    const handleFilterModel = (id: string) => {

        const newFilterModel: any = filterModel;

        const currentIdsArray = newFilterModel.items[1].value;

        if (currentIdsArray.includes(id)) {

            newFilterModel.items.forEach((each: any) => {
                if (each.id === 2) {
                    const index = each.value.indexOf(id);
                    if (index > -1) {
                        each.value.splice(index, 1);
                        
                        setOpenedId([...each.value]);
                    }
                }
            })

        } else {

            newFilterModel.items.forEach((each: any) => {
                if (each.id === 2) {
                    each.value.push(id);
                    
                    setOpenedId([...each.value]);
                }
            })

        }

        setFilterModel({ ...newFilterModel });

    }

    const handleUpdate = () => {
        setUpdate(Math.random());
    }

    const notify1 = () => toast.success("업로드 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored"
    });

    const notify2 = () => toast.success("삭제 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "dark"
    });

    const notify3 = () => toast.success("항목 추가 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored"
    });

    const notify4 = () => toast.success("항목 올리기 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored"
    });

    const notify5 = () => toast.success("운영진에게만 보이기 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored"
    });

    useEffect(() => {

        if(update2 === 0){
            return;
        }

        if(!originalData){
            return;
        }

        const newData = [...originalData];

        

        newData.forEach((eachData : any) => {

            if(eachData.id === selectedRow.sqlId){
                eachData.isReviewRead = true;
            }

        })

        makeTable([...newData]);
        

    }, [update2]);

    const handleUpdate2 = () => {

        setUpdate2(Math.random());

    }


    useEffect(() => {

        
        

        props.activateMenuList("check");

    }, []);

    useEffect(() => {

        setToDoNumber(1);

    }, [kinds]);

    useEffect(() => {

        makeTeacherList();

    }, []);

    useEffect(() => {

        var scrollPosition : any = 0

        if(update === 0){
            setLoading(true);
        }else{
            scrollPosition = apiRef.current.getScrollPosition();
        }

        getTodos(scrollPosition);

    }, [update]);

    useEffect(() => {

        if (selectedUser && selectedUser.length > 0 && title) {

            var count = 0;

            for (var i = 0; i < toDoNumber; i++) {
                if (description[i]) {
                    count++;
                }
            }

            if (count === toDoNumber) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }

        } else {
            setDisabled(true);
        }


    }, [selectedUser, title, description, toDoNumber, open]);

    useEffect(() => {

        if (!selectedRow) {
            setUpDisabled(true);
            setAddDisabled(true);
            setDeleteDisabled(true);
        } else if (selectedRow.type === "groupNull") {
            setUpDisabled(true);
            setAddDisabled(true);
            setDeleteDisabled(true);
        } else if (selectedRow.type === "single") {
            setUpDisabled(false);
            setAddDisabled(true);
            setDeleteDisabled(false);
        } else if (selectedRow.type === "groupElement") {
            setUpDisabled(true);
            setAddDisabled(false);
            setDeleteDisabled(false)
        } else if (selectedRow.type === "groupLeader") {
            setUpDisabled(false);
            setAddDisabled(true);
            setDeleteDisabled(false);
        } else {
            setUpDisabled(true);
            setAddDisabled(true);
            setDeleteDisabled(true);
        }


    }, [selectedRow]);

    const makeTeacherList = async () => {
        const result: any = await getTeachers();

        
        const rows: any = [];
        result.data.forEach((each: any, index: number) => {
            var data: any = {};
            data.id = each.id;
            data.label = each.name;
            data.phoneNumber = each.phoneNumber;
            data.value = each.value;
            data.key = index;
            rows.push(data);
        })
        setUsers([...rows]);
    }

    const getTeachers = async () => {
        return new Promise((resolve, reject) => {

            fetch("https://peetsunbae.com/dashboard/avatar/teachers", {
                method: "GET",
                credentials: "include"
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        resolve(result);
                    })
                    .catch((err: any) => {
                        reject(err);
                    })
            })

        })
    }

    

    useEffect(() => {

        if(!originalData){
            
            return;
        }

        const newData = [...originalData];
        const newRows : any = [];
        
        

        const filteredData = newData.filter((eachData) => {
            
            var count1 = 0;

            eachData.targetUsers.forEach((eachUser : any) => {
                if(eachUser.label.includes(searchName)){
                    count1++;
                }
            })

            var count2 = 0;

            if(eachData.title.includes(searchText)){
                count2++;
            }

            eachData.description.forEach((eachDescription : any) => {

                if(eachDescription.description.includes(searchText)){
                    count2++;
                }

            })

            

            if(count1 > 0 && count2 > 0){
                return true;
            }else{
                return false;
            }
        })

        

        filteredData.forEach((eachData : any) => {

            const oneRow : any = {};

            oneRow.secret = eachData.secret;
            oneRow.createdAt = eachData.createdAt;
            oneRow.due = eachData.due;
            oneRow.id = eachData.id;
            oneRow.kinds = eachData.kinds;
            oneRow.link = eachData.link;
            oneRow.name = eachData.name;
            oneRow.priority = eachData.priority;
            oneRow.reviewCount = eachData.reviewCount;
            oneRow.targetUsers = eachData.targetUsers;
            oneRow.title = eachData.title;
            oneRow.userId = eachData.userId;
            oneRow.isReviewRead = eachData.isReviewRead;
            oneRow.description = [];


            if(alignment === "total"){
                eachData.description.forEach((eachDescription : any) => {

                    oneRow.description.push(eachDescription);

                })
            }

            if(alignment === "incomplete"){
                eachData.description.forEach((eachDescription : any) => {

                    if(eachDescription.status !== 3){
                        oneRow.description.push(eachDescription);
                    }

                })
            }

            if(alignment === "unread"){

                eachData.description.forEach((eachDescription : any) => {

                    if(eachDescription.status === 1){
                        oneRow.description.push(eachDescription);
                    }

                })

            }

            newRows.push(oneRow);

        })


        makeTable([...newRows]);
        

    }, [searchName, alignment, searchText]);

    const makeTable = async (newData : any, scrollPosition : any = 0) => {

        const data : any = [];

        

        const filteredData = newData.filter((eachData : any) => {
            
            var count1 = 0;

            eachData.targetUsers.forEach((eachUser : any) => {
                if(eachUser.label.includes(searchName)){
                    count1++;
                }
            })

            var count2 = 0;

            if(eachData.title.includes(searchText)){
                count2++;
            }

            eachData.description.forEach((eachDescription : any) => {

                if(eachDescription.description.includes(searchText)){
                    count2++;
                }

            })

            

            if(count1 > 0 && count2 > 0){
                return true;
            }else{
                return false;
            }
        })

        filteredData.forEach((eachData : any) => {

            const oneRow : any = {};

            oneRow.secret = eachData.secret;
            oneRow.createdAt = eachData.createdAt;
            oneRow.due = eachData.due;
            oneRow.id = eachData.id;
            oneRow.kinds = eachData.kinds;
            oneRow.link = eachData.link;
            oneRow.name = eachData.name;
            oneRow.priority = eachData.priority;
            oneRow.reviewCount = eachData.reviewCount;
            oneRow.targetUsers = eachData.targetUsers;
            oneRow.title = eachData.title;
            oneRow.userId = eachData.userId;
            oneRow.isReviewRead = eachData.isReviewRead;
            oneRow.description = [];


            if(alignment === "total"){
                eachData.description.forEach((eachDescription : any) => {

                    oneRow.description.push(eachDescription);

                })
            }

            if(alignment === "incomplete"){
                eachData.description.forEach((eachDescription : any) => {

                    if(eachDescription.status !== 3){
                        oneRow.description.push(eachDescription);
                    }

                })
            }

            if(alignment === "unread"){

                eachData.description.forEach((eachDescription : any) => {

                    if(eachDescription.status === 1){
                        oneRow.description.push(eachDescription);
                    }

                })

            }

            data.push(oneRow);

        });




        const newRows: any = [];

        data.forEach((each: any, index: number) => {

            const totalDescriptionNumber = each.description.length;

            each.finish = false;

            var count = 0;

            each.description.forEach((eachDescription: any, indexIndex: number) => {

                if (eachDescription.status === 3) {
                    count++;
                }

            })

            if (totalDescriptionNumber === count) {
                each.finish = true;
            }

        })

        // data.sort(function (a : any, b : any){
        //     if(a.finish === true && b.finish === false){
        //         return 1;
        //     }

        //     if(a.finish === b.finish){
        //         return 0;
        //     }

        //     if(a.finish === false && b.finish === true){
        //         return -1;
        //     }
        // })



        data.forEach((each: any, index: number) => {

            const date = new Date(each.createdAt);
            const newDate = `${date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)}/${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()} ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;

            each.description.forEach((eachDescription: any, indexIndex: number) => {

                // const hierarchy: any = [];

                const oneRow: any = {};
                oneRow.sortNumber = each.id * 100000000000 + indexIndex;
                oneRow.sqlId = each.id;
                oneRow.descriptionId = eachDescription.id;
                oneRow.socketUsers = each.targetUsers;
                oneRow.isReviewRead = each.isReviewRead;




                if (each.kinds === "single") {
                    // hierarchy.push(`${index}_${indexIndex}`);
                    // hierarchy.push(index + 1);
                    oneRow.id = `single_${each.id}_${indexIndex}`;
                    // oneRow.hierarchy = hierarchy;
                    oneRow.title = each.title;

                    

                    if(each.secret === 1){
                        oneRow.title = "🔑🔒 " + oneRow.title;
                        
                    }

                    oneRow.description = eachDescription.description;
                    oneRow.status = eachDescription.status;
                    oneRow.changedUser = eachDescription.changedUser;
                    oneRow.review = each.reviewCount;

                    if (oneRow.changedUser) {
                        oneRow.changedUser = oneRow.changedUser.split(" ")[0];
                    }

                    if (eachDescription.changedUser && eachDescription.changedTime) {
                        const newDate = new Date(eachDescription.changedTime);
                        oneRow.changedUser = oneRow.changedUser + ` (${newDate.getMonth() + 1 < 10 ? "0" + (newDate.getMonth() + 1) : newDate.getMonth() + 1}/${newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate()} ${newDate.getHours() < 10 ? "0" + newDate.getHours() : newDate.getHours()}:${newDate.getMinutes() < 10 ? "0" + newDate.getMinutes() : newDate.getMinutes()})`
                    }
                    oneRow.changedTime = eachDescription.changedTime;
                    oneRow.priority = each.priority;
                    if (each.due) {
                        oneRow.due = new Date(each.due);
                    } else {
                        oneRow.due = each.due;
                    }
                    oneRow.link = each.link;
                    oneRow.date = newDate;
                    oneRow.targetUsers = each.targetUsers;
                    oneRow.type = "single";
                    oneRow.just = "p"
                    oneRow.author = each.name;
                }

                if (each.kinds === "multiple") {
                    if (indexIndex === 0) {
                        const forMultipleOneRow: any = {};
                        // const newHierarchy = [index + 1];
                        forMultipleOneRow.id = `groupLeader_${each.id}_999`;
                        forMultipleOneRow.sqlId = each.id;
                        forMultipleOneRow.descriptionId = 999;
                        // forMultipleOneRow.hierarchy = newHierarchy;
                        forMultipleOneRow.title = each.title;

                        if(each.secret){
                            forMultipleOneRow.title = "🔑🔒 " + forMultipleOneRow.title;
                        }

                        forMultipleOneRow.targetUsers = each.targetUsers;
                        forMultipleOneRow.status = 5;
                        forMultipleOneRow.date = newDate;
                        forMultipleOneRow.targetUsers = each.targetUsers;
                        forMultipleOneRow.priority = each.priority;
                        forMultipleOneRow.link = each.link;
                        forMultipleOneRow.type = "groupLeader";
                        forMultipleOneRow.groupChevron = "groupLeader";
                        forMultipleOneRow.just = "p";
                        forMultipleOneRow.author = each.name;
                        forMultipleOneRow.finish = each.finish;
                        forMultipleOneRow.review = each.reviewCount;
                        forMultipleOneRow.socketUsers = each.targetUsers;
                        forMultipleOneRow.isReviewRead = each.isReviewRead;
                        if (each.due) {
                            forMultipleOneRow.due = new Date(each.due);
                        } else {
                            forMultipleOneRow.due = each.due;
                        }
                        newRows.push(forMultipleOneRow);
                    }

                    // hierarchy.push(index + 1);
                    // hierarchy.push((index + 1) + "_" + (indexIndex + 1));
                    oneRow.id = `groupElement_${each.id}_${indexIndex}`;
                    // oneRow.hierarchy = hierarchy;
                    oneRow.title = "";
                    oneRow.description = eachDescription.description;
                    oneRow.status = eachDescription.status;
                    oneRow.changedUser = eachDescription.changedUser;

                    if (oneRow.changedUser) {
                        oneRow.changedUser = oneRow.changedUser.split(" ")[0];
                    }

                    if (eachDescription.changedUser && eachDescription.changedTime) {
                        const newDate = new Date(eachDescription.changedTime);
                        oneRow.changedUser = oneRow.changedUser + ` (${newDate.getMonth() + 1 < 10 ? "0" + (newDate.getMonth() + 1) : newDate.getMonth() + 1}/${newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate()} ${newDate.getHours() < 10 ? "0" + newDate.getHours() : newDate.getHours()}:${newDate.getMinutes() < 10 ? "0" + newDate.getMinutes() : newDate.getMinutes()})`
                    }

                    oneRow.changedTime = eachDescription.changedTime;
                    oneRow.just = `${each.id}`;
                    oneRow.type = "groupElement";

                }

                newRows.push(oneRow);

                if (each.kinds === "multiple" && indexIndex === each.description.length - 1) {
                    const forMultipleOneRow: any = {};
                    const newHierarchy = [index + 1, "end"];
                    forMultipleOneRow.id = `${index}_999`;
                    forMultipleOneRow.hierarchy = newHierarchy;
                    forMultipleOneRow.type = "groupNull";
                    forMultipleOneRow.just = `${each.id}`;

                    newRows.push(forMultipleOneRow);
                }

            })


            if(scrollPosition){
                
                apiRef.current.scroll(scrollPosition);
            }

        })

        setLoading(false);
        setRows([...newRows]);

    }

    const getTodos = async (scrollPosition : any = 0 ) => {

        fetch("https://peetsunbae.com/dashboard/report/todos", {
            method: "GET",
            credentials: "include"
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    

                    const data = result.data;

                    if(!data || data.length === 0){
                        setLoading(false);
                        return;
                    }

                    setOriginalData([...data]);
                    makeTable([...data], scrollPosition);

                })
        }).catch((e: any) => {
            
        })
    }

    const handleChange = (e: any) => {
        setKinds(e.target.value);
    }

    const handleChange2 = (e: any) => {
        setPriority(e.target.value);
    }

    const handleChange3 = (e: any, idx: number) => {
        const newDescription = description;
        newDescription[idx] = e.target.value;
        setDescription([...newDescription]);
    }

    const handlePlus = () => {
        
        var newToDoNumber: number = toDoNumber;
        newToDoNumber++;
        setToDoNumber(newToDoNumber);
    }

    const handleMinus = (idx: number) => {

        

        var newToDoNumber: number = toDoNumber;
        newToDoNumber--;

        setToDoNumber(newToDoNumber);

        const newDescription = description;
        newDescription.splice(idx, 1);

        setDescription([...newDescription]);
    }

    const onchange = (e: any, value: any) => {

        
        setSelectedUser(value);
    }

    const handleSubmit2 = async () => {

        if (!addElement) {
            
            return;
        }

        if (!selectedRow) {
            
            return;
        }

        
        

        if (selectedRow.type !== "groupElement") {
            
            return;
        }

        const data = {
            selectedRow, addElement
        }



        fetch("https://peetsunbae.com/dashboard/report/todos", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify(data)
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    
                    if (result.message === "success") {
                        
                        
                        if(selectedTargetUsers){
                            
                            selectedTargetUsers.forEach((eachUser : any) => {
                                
                                
                                props.socket.emit("newTodo", eachUser.id);
    
                            })

                        }


                        setUpdate(Math.random());
                        handleClose2();
                        notify3();
                        setSelectedRow(null);
                        props.undoTodos();


                    }
                })
        })


    }

    const handleSubmit = async () => {

        
        
        
        

        var token = "";
        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        const data = {
            selectedUser,
            kinds,
            title,
            description
        }


        fetch("https://peetsunbae.com/dashboard/report/todos", {
            method: "post",
            headers: {
                "Content-Type": "application/json", "Authorization": token
            },
            credentials: "include",
            body: JSON.stringify({
                data
            })
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    
                    if (result.message === "success") {
                        setUpdate(Math.random());
                        notify1();
                        props.undoTodos();

                        selectedUser.forEach((eachUser : any) => {

                            
                            props.socket.emit("newTodo", eachUser.id);

                        })

                    }
                })
        })

    }

    const handleClose = () => {

        setOpen(false);
        setSelectedUser([]);
        setKinds("single");
        setTitle("");
        setDescription(new Array(50).fill(undefined));
        setToDoNumber(1);
    }

    useEffect(() => {

        if (addElement) {
            setDisabled2(false);
        } else {
            setDisabled2(true);
        }

    }, [addElement]);

    const handleClose2 = () => {
        setAddElement("");
        setOpen2(false);
    }

    const handleDelete = () => {

        if (!selectedRow) {
            return;
        }

        

        var typeString = "";

        if (selectedRow.type === "single") {
            typeString = "투두 내역"
        }

        if (selectedRow.type === "groupLeader") {
            typeString = "그룹 투두 전체 내역"
        }

        if (selectedRow.type === "groupElement") {
            typeString = "하나의 항목"
        }


        if (window.confirm(`선택된 ${typeString}을 완전히 삭제할까요?`)) {

        } else {
            return;
        }

        fetch("https://peetsunbae.com/dashboard/report/todos", {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify(selectedRow)
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    if (result.message === "success") {

                        if(selectedTargetUsers){
                            
                            selectedTargetUsers.forEach((eachUser : any) => {
                                
                                
                                props.socket.emit("newTodo", eachUser.id);
    
                            })

                        }


                        notify2();
                        setUpdate(Math.random());
                        props.undoTodos();

                        


                    }
                })
        })


    }



    // const getTreeDataPath: DataGridProProps["getTreeDataPath"] = (row) => row.hierarchy;

    const nullComparator: any = (v1: any, v2: any) => {
        
    }

    const handleEditCommit = React.useCallback(
        async (newRow: GridRowModel, old: any) => {
            var field = "";


            

            for (const [key, value] of Object.entries(newRow)) {
                if (value !== old[key]) {
                    field = key;
                }
            }

            const value = newRow[field] ? newRow[field] : null;
            const type = newRow.type;
            const sqlId = newRow.sqlId;
            const descriptionId = newRow.descriptionId;


            
            
            

            if (!field) {
                
                return newRow;
            }

            if (!sqlId) {
                
                return newRow;
            }

            const data = {
                value,
                type,
                sqlId,
                descriptionId,
                field
            }

            

            fetch("https://peetsunbae.com/dashboard/report/todos", {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify(data)
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        if (result.message === "success") {
                            setUpdate(Math.random());

                            if(field === "status"){
                                props.undoTodos();
                            }

                        }
                    })
            })

            return newRow;


        }, []
    );

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        
        alert(error.message);
    }, []);

    const handleUp = () => {

        if (!selectedRow) {
            return;
        }

        

        if (window.confirm(`선택된 TODO를 최상단으로 올릴까요?`)) {

        } else {
            return;
        }


        fetch("https://peetsunbae.com/dashboard/report/todosup", {
            method : "post",
            headers : {
                "Content-Type" : "application/json"
            },
            credentials : "include",
            body : JSON.stringify(selectedRow)
        }).then((response : any) => {
            response.json()
            .then((result : any) => {
                
                if(result.message === "success"){
                    setUpdate(Math.random());
                    notify4();
                }
            })
        })

    }

    const handleSecret = () => {

        if (!selectedRow) {
            return;
        }

        

        if (window.confirm(`선택된 TODO를 최상단으로 올릴까요?`)) {

        } else {
            return;
        }


        fetch("https://peetsunbae.com/dashboard/report/todossecret", {
            method : "post",
            headers : {
                "Content-Type" : "application/json"
            },
            credentials : "include",
            body : JSON.stringify(selectedRow)
        }).then((response : any) => {
            response.json()
            .then((result : any) => {
                
                if(result.message === "success"){
                    setUpdate(Math.random());
                    notify5();
                }
            })
        })


    }



    return (
        <div className={styles.main}>
            <div className={styles.title}>
                <img src="img/off/check.svg" alt="chart" />
                <span style={{ marginRight: "24px" }}>
                    ToDo
                </span>
                <CssVarsProvider>
                    <Button
                        variant="solid"
                        color="primary"
                        startDecorator={<Add />}
                        onClick={() => setOpen(true)}
                        size="md"
                    >
                        새로운 ToDo
                    </Button>
                </CssVarsProvider>

                <Modal open={open2} onClose={() => { handleClose2(); }}>
                    <ModalDialog
                        aria-labelledby="basic-modal-dialog-title"
                        aria-describedby="basic-modal-dialog-description"
                        sx={{ maxWidth: 600, maxHeight: 800, overflow: "auto", paddingBottom: "32px" }}
                    >
                        <div className={styles.formTitle}>
                            항목 추가하기
                        </div>
                        <div style={{ height: "12px" }}>

                        </div>
                        <Stack spacing={2}>
                            <div>
                                <Input value={addElement} onChange={(e) => { setAddElement(e.target.value); }} sx={{ width: 400 }} size="lg" required />
                            </div>
                            <Button onClick={handleSubmit2} disabled={disabled2} size="lg" type="submit" style={{ marginTop: "24px", fontFamily: "Apple_B" }}>제출하기</Button>
                        </Stack>
                    </ModalDialog>
                </Modal>

                <Modal open={open} onClose={() => { handleClose(); }}>
                    <ModalDialog
                        aria-labelledby="basic-modal-dialog-title"
                        aria-describedby="basic-modal-dialog-description"
                        sx={{ maxWidth: 600, maxHeight: 800, overflow: "auto", paddingBottom: "32px" }}
                    >
                        <div className={styles.formTitle}>
                            새로운 TODO 등록하기
                        </div>
                        <div style={{ height: "12px" }}>

                        </div>
                        {/* <Typography id="basic-modal-dialog-description" textColor="text.tertiary">
                                Fill in the information of the project.
                            </Typography> */}
                        <form
                            onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                                event.preventDefault();
                                handleClose();
                            }}
                        >
                            <Stack spacing={2}>
                                <div>
                                    <div className={styles.questionTitle}>
                                        책임자*
                                    </div>
                                    <Autocomplete
                                        multiple={true}
                                        onChange={onchange}
                                        disablePortal
                                        id="combo-box-demo"
                                        options={users}
                                        sx={{ width: "100%" }}
                                        renderInput={(params) => <TextField autoFocus sx={{ width: 400 }}  {...params} fullWidth />}
                                        fullWidth
                                    />
                                </div>
                                <FormControl>
                                    <div className={styles.questionTitle}>
                                        종류선택
                                    </div>
                                    <RadioGroup
                                        orientation="horizontal"
                                        defaultValue="female"
                                        name="controlled-radio-buttons-group"
                                        value={kinds}
                                        onChange={handleChange}
                                        sx={{ my: 1 }}
                                    >
                                        <Radio value="single" label="한개짜리" />
                                        <Radio value="multiple" label="여러항목" />
                                    </RadioGroup>
                                </FormControl>
                                <FormControl>
                                    <div className={styles.questionTitle}>
                                        제목*
                                    </div>
                                    <Input value={title} onChange={(e) => { setTitle(e.target.value); }} sx={{ width: 400 }} size="lg" required />
                                </FormControl>
                                {/* {
                                    kinds === "multiple" &&
                                    <FormControl>
                                        <div className={styles.questionTitle}>
                                            제목*
                                        </div>
                                        <Input sx={{ width: 400 }} size="lg" required />
                                    </FormControl>
                                } */}
                                {
                                    new Array(toDoNumber).fill(undefined).map((value, idx) => {
                                        return (
                                            <FormControl>
                                                <div key={idx} className={`${styles.questionTitle} ${styles.ToDoDescription}`}>
                                                    <div>
                                                        해야할 일{kinds === "multiple" ? ` ${idx + 1}` : ""}*
                                                    </div>
                                                    <div>
                                                        {
                                                            (kinds === "multiple" && (idx + 1) > 1)
                                                            &&
                                                            <img onClick={() => { handleMinus(idx); }} className={styles.circleMinus} src="/img/circle-minus-regular.svg" style={{ width: "20px", cursor: "pointer" }} />
                                                        }
                                                    </div>
                                                </div>
                                                <Textarea value={description[idx]} onChange={(e) => { handleChange3(e, idx); }} sx={{ width: 400 }} size="lg" minRows={1} required />
                                            </FormControl>
                                        );
                                    })
                                }
                                {
                                    kinds === "multiple" &&
                                    <div className={styles.plusDiv}>
                                        <img src="/img/circle-plus-regular.svg" className={styles.circlePlus} onClick={handlePlus} />
                                    </div>
                                }


                                <Button onClick={handleSubmit} disabled={disabled} size="lg" type="submit" style={{ marginTop: "48px", fontFamily: "Apple_B" }}>제출하기</Button>
                            </Stack>
                        </form>
                    </ModalDialog>
                </Modal>
            </div>

            <div style={{ display: "flex", alignItems: "center", width: "1500px", marginBottom: "24px", justifyContent: "flex-end", marginTop: "12px" }}>
                <div>

                </div>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px", width: "1500px", alignItems : "center" }}>
                <div style={{ paddingLeft: "12px" }}>

                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleAlignmentChange}
                        aria-label="Platform"
                        sx={{ marginRight: "36px" }}
                    >
                        <ToggleButton value="incomplete">미완료</ToggleButton>
                        <ToggleButton value="unread">미확인</ToggleButton>
                        <ToggleButton value="total">전체</ToggleButton>
                    </ToggleButtonGroup>

                    <TextField sx={{ fontSize: "24px", marginRight : "50px" }} label="책임자 검색" variant="standard" value={searchName} onChange={handleSearchNameChange} InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AccountCircle />
                            </InputAdornment>
                        ),
                    }} />

                    <TextField sx={{ fontSize: "24px" }} label="제목, 내용 검색" variant="standard" value={searchText} onChange={handleSearchTextChange} InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                               <CheckCircle />
                            </InputAdornment>
                        ),
                    }} />

                </div>
                <div>
                    <Button variant="soft"  style={{ marginRight: "12px" }} disabled={upDisabled} onClick={() => { handleUp(); }}>
                        최상단으로 올리기
                    </Button>
                    <Button variant="soft"  style={{ marginRight: "12px" }} disabled={upDisabled} onClick={() => { handleSecret(); }}>
                        운영진에게만 보이기
                    </Button>
                    <Button variant="soft" color="primary" style={{ marginRight: "12px" }} disabled={addDisabled} onClick={() => { setOpen2(true); }}>
                        항목 추가하기
                    </Button>
                    <Button variant="soft" color="danger" disabled={deleteDisabled} onClick={handleDelete}>
                        삭제하기
                    </Button>
                </div>
            </div>
            <div className={classes.root} style={{ height: 650, width: '1500px', backgroundColor: "white" }}>
                <DataGridPremium
                    loading={loading}
                    density="compact"
                    rows={rows}
                    columns={columns}
                    // treeData
                    // getTreeDataPath={getTreeDataPath}
                    isCellEditable={(params) => {
                        if (params.field === "status" && params.row.type === "groupLeader") {
                            return false;
                        } else if (params.field === "description" && params.row.type === "groupLeader") {
                            return false;
                        } else if (params.field === "due") {
                            if (params.row.type !== "groupElement" && params.row.type !== "groupNull") {
                                return true;
                            } else {
                                return false;
                            }
                        } else if (params.field === "link") {
                            if (params.row.type !== "groupElement" && params.row.type !== "groupNull") {
                                return true;
                            } else {
                                return false;
                            }
                        } else if (params.field === "priority") {
                            if (params.row.type !== "groupElement" && params.row.type !== "groupNull") {
                                return true;
                            } else {
                                return false;
                            }
                        } else if (params.field === "title") {
                            if (params.row.type !== "groupElement" && params.row.type !== "groupNull") {
                                return true;
                            } else {
                                return false;
                            }
                        }
                        else {
                            return true;
                        }
                    }}
                    apiRef={apiRef}
                    onRowSelectionModelChange={(newSelectionModel) => {
                        const data: any = apiRef.current.getSelectedRows();
                        if (!data) {
                            return;
                        }
                        
                        
                        data.forEach((value: any, index: number) => {

                            const newSelectedInfo = {
                                sqlId: value.sqlId,
                                descriptionId: value.descriptionId,
                                type: value.type
                            }
                            
                            setSelectedRow(newSelectedInfo);
                            setSelectedTitle(value.title);
                            setSelectedTargetUsers(value.socketUsers);

                        })
                    }}
                    getRowClassName={(params) => {
                        if (params.row.type === "groupLeader") {
                            return ""
                        }
                        else {
                            return ""
                        }
                    }}
                    getCellClassName={(params : any) => {
                        if (params.row.type === "groupLeader" && (params.field === "groupChevron" || params.field === "title" || params.field === "review" || params.field === "priority" || params.field === "link")) {
                            if (params.field === "priority") {
                                if (params.value === 3) {
                                    return "todos_priority3"
                                } else {
                                    return "clickable"
                                }
                            } else {
                                return "clickable"
                            }

                        } else if (params.row.type === "groupElement" && (params.field === "description" || params.field === "status")) {
                            if (params.field === "status") {
                                if (params.value === 1) {
                                    return "todos_status1"
                                } else if (params.value === 2) {
                                    return "todos_status2"
                                } else if (params.value === 3) {
                                    return "todos_status3"
                                } else if (params.value === 4) {
                                    return "todos_status4"
                                } else {
                                    return "todos_status5"
                                }
                            } else {
                                return "clickable"
                            }
                        } else if (params.row.type === "single" && (params.field === "description" || params.field === "status" || params.field === "title" || params.field === "review" || params.field === "priority" || params.field === "link")) {
                            if (params.field === "status") {
                                if (params.value === 1) {
                                    return "todos_status1"
                                } else if (params.value === 2) {
                                    return "todos_status2"
                                } else if (params.value === 3) {
                                    return "todos_status3"
                                } else if (params.value === 4) {
                                    return "todos_status4"
                                } else {
                                    return "todos_status5"
                                }
                            } else if (params.field === "priority") {
                                if (params.value === 3) {
                                    return "todos_priority3"
                                } else {
                                    return "clickable"
                                }
                            } else {
                                return "clickable"
                            }
                        } else if (params.row.type === "groupLeader" && params.field === "status") {
                            if (params.row.finish) {
                                return "todos_finish";
                            } else {
                                return "todos_unfinish";
                            }
                        } else {
                            return "";
                        }
                    }}
                    unstable_cellSelection
                    unstable_cellSelectionModel={cellSelectionModel}
                    unstable_onCellSelectionModelChange={(cellSelectionModel) => {
                        

                        if (!cellSelectionModel) {
                            return;
                        }

                        var key_1;
                        var key_2;

                        for (const [key, value] of Object.entries(cellSelectionModel)) {
                            
                            key_1 = key;
                            for (const [key2, value2] of Object.entries(value)) {
                                
                                key_2 = key2;
                            }
                        }

                        if (!key_1 || !key_2) {
                            return;
                        }

                        const type = key_1.split("_")[0];
                        const id = key_1.split("_")[1];
                        const column = key_2;

                        // 
                        // 
                        // 

                        if (column === "groupChevron" && type === "groupLeader") {
                            handleFilterModel(`${id}`);
                        }

                    }}
                    filterModel={filterModel}
                    processRowUpdate={handleEditCommit}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                />
            </div>

            {
                selectedRow &&
                <SwipeableDrawer
                    anchor="right"
                    open={open3}
                    onClose={() => { toggleDrawer(false) }}
                    onOpen={() => { toggleDrawer(true) }}
                >
                    <div
                        style={{ width: "550px", height : "100%"}}
                        role="presentation"
                        className={styles.todoChat}
                    // onClick={(e) => { toggleDrawer(false) }}
                    // onKeyDown={(e) => { toggleDrawer(false) }}
                    >
                        <ChatForTodos handleUpdate2={handleUpdate2} handleUpdate={handleUpdate} openControl={open3} user={props.user} studentName={selectedTitle} chatRoomId={(selectedRow && selectedRow.sqlId) ? selectedRow.sqlId : 0} unreadMessage={() => { }} />
                    </div>
                </SwipeableDrawer>


            }




        </div>
    );
}

export default Check;