import { Chip, CircularProgress, Divider, TextareaAutosize, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState, useRef, useCallback } from "react";
import styles from "../../componentsStyle/check.module.css";
import Picker from "emoji-picker-react";

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import { ReactComponent as ShapeLogoSvg } from '../../../svg/onlyshape.svg';

import SendIcon from '@mui/icons-material/Send';
import { Socket } from "socket.io-client";
import {each, throttle} from "lodash";
import { IonAvatar } from "@ionic/react";
import ImageModal from "../use/imagemodal";


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'white',
    outline: "none",
    borderRadius: "8px",
    boxShadow: "0 3px 25px -5px rgba(0,0,0,.35)"
};

// var savingContainer : any;

var newIndex = 0;
var isTop = false;

var previousNode : any;

const ChatForTodos: React.FC<any> = (props) => {

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [data, setData] = useState<any>();
    const [message, setMessage] = useState("");

    const [update, setUpdate] = useState(0);

    const [isFocus, setIsFoucs] = useState(false);

    const [rows, setRows] = useState(1);

    const [showPicker, setShowPicker] = useState(false);

    const [fileName, setFileName] = useState<any>("");
    const [file, setFile] = useState<any>();

    const [link, setLink] = useState("");

    const [imgLoading, setImgLoading] = useState(false);
    const [imgSrc, setImgSrc] = useState("");
    const [imgId, setImgId] = useState(0);

    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);

    const [index, setIndex] = useState(0);

    const [chatRoomId, setChatRoomId] = useState();

    const chatRef = useRef<any>();
    const chatLastRef = useRef<any>();
    const voidRef = useRef<any>();

    const firstRef = useRef<any>(null);

    const [loading, setLoading] = useState(false);

    const [imageSrc, setImageSrc] = useState("");

    const [onDrag, setOnDrag] = useState(true);

    const twentythNode = useCallback((node : any) => {
        if (node !== null){
            // 
            // 
            // 
            // chatRef.current.scrollTop = node.offsetTop - 20;
        }
    }, [])

    const scrollTopCallback = useCallback((node : any) => {

        

        if (node !== null && !isTop){

            
            
            
            
            

            chatRef.current.scrollTop = chatRef.current.scrollHeight;
            
            previousNode = node;
        }

        if(isTop){
            isTop = false;
        }


    }, []);

    const onImageLoad = () => {
        
        if(chatRef && chatRef.current){
            
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }

    const handleOpen = () => setOpen(true);
    const handleOpen2 = () => setOpen2(true);
    const handleClose = () => setOpen(false);
    const handleClose2 = () => {setOpen2(false); setLink("");}


    // useEffect(() => {

    //     if (firstRef && firstRef.current) {
    //         let observer: any;

    //         setTimeout(() => {
    //             if (firstRef && firstRef.current) {
    //                 observer = new IntersectionObserver(onIntersect, { threshold: 0.5 });
    //                 observer.observe(firstRef.current);
    //             }
    //         }, 600)



    //         return () => observer && observer.disconnect();

    //     }
    // }, [firstRef]);


    // const onIntersect = async ([entry] : any, observer : any) => {

    //     // 
    //     // savingContainer = firstRef.current;

    //     
    //     

    //     if(entry.isIntersecting){
    //         newIndex++;
    //         isTop = true;
    //         setIndex(newIndex);
    //     }
    // }

    // useEffect(() => {
    //     
    //     if (chatRef && chatRef.current) {

    //         const throttledFn = throttle(handleScroll, 10);

    //         chatRef.current.addEventListener("scroll", throttledFn);

    //         return () => {
    //             if(chatRef && chatRef.current)
    //             chatRef.current.removeEventListener("scroll", throttledFn);
    //         }
    //     }
    // }, [])

    // const handleScroll = () => {
        
    //     if(!chatRef){
    //         return;
    //     }

    //     if(!chatRef.current){
    //         return;
    //     }


    //     
    //     

    //     if(chatRef.current.scrollTop < 800){
    //         
    //         newIndex++;
    //         setIndex(newIndex);
    //     }
    // }



    // useEffect(() => {
    //     if(props.user.value === "student" || props.user.value === "parent"){
    //         

    //         fetch("https://peetsunbae.com/dashboard/envelope/studentChatRoom", {
    //             method : "GET",
    //             credentials : "include"
    //         }).then((response : any) => {
    //             response.json()
    //             .then((result : any) => {
    //                 
    //                 
    //                 if(result.message === "already" || result.message === "created"){
    //                     setChatRoomId(result.roomNumber);
    //                 }
    //             })
    //         })
    //     }
    // }, [props.user])



    useEffect(() => {

        if(!props.openControl){
            return;
        }

        getMessage();

    }, [props.chatRoomId, chatRoomId, props.user, update, props.messageUpdate, props.openControl]);

    useEffect(() => {
        // getMessageForIndexChange();
    }, [index]);






    const getMessage = () => {
        


        if( (props.user && props.user.value === "teacher" || props.user.value === "staff") && !props.chatRoomId){
            
            return;
        }

        if( (props.user && props.user.value === "student" || props.user.value === "parent")){
            
            return;
        }


        fetch(`https://peetsunbae.com/dashboard/envelope/messagesfortodos?index=${index}&chatRoomId=${(props.user.value === "teacher" || props.user.value === "staff") ? props.chatRoomId : chatRoomId}`, {
            method : "GET",
            credentials : "include",
        }).then((response : any) => {
            response.json()
            .then((result : any) => {
                
                const data = result.data;
                if (result.message === "success" && result.data.length > 0) {
                    const newData = [];
                    const newDate = new Date(result.data[0].createdAt);
                    var newDateDay = "";
                    switch (newDate.getDay()) {
                        case 0:
                            newDateDay = "일";
                            break;
                        case 1:
                            newDateDay = "월";
                            break;
                        case 2:
                            newDateDay = "화";
                            break;
                        case 3:
                            newDateDay = "수";
                            break;
                        case 4:
                            newDateDay = "목";
                            break;
                        case 5:
                            newDateDay = "금";
                            break;
                        case 6:
                            newDateDay = "토";
                            break;
                    }

                    const newDateString = `${newDate.getFullYear()}년 ${newDate.getMonth()+1}월 ${newDate.getDate()}일 ${newDateDay}요일`;

                    const newDateRow = {
                        kind : "date", date : newDateString, id : Math.random()
                    }

                    newData.push(newDateRow);

                    var previousDate = newDateString;

                    result.data.forEach((eachData : any) => {

                        const newDate2 = new Date(eachData.createdAt);
                        var newDateDay2 = "";
                        switch (newDate2.getDay()) {
                            case 0:
                                newDateDay2 = "일";
                                break;
                            case 1:
                                newDateDay2 = "월";
                                break;
                            case 2:
                                newDateDay2 = "화";
                                break;
                            case 3:
                                newDateDay2 = "수";
                                break;
                            case 4:
                                newDateDay2 = "목";
                                break;
                            case 5:
                                newDateDay2 = "금";
                                break;
                            case 6:
                                newDateDay2 = "토";
                                break;
                        }
    
                        const newDateString2 = `${newDate2.getFullYear()}년 ${newDate2.getMonth()+1}월 ${newDate2.getDate()}일 ${newDateDay2}요일`;
    
                        const newDateRow2 = {
                            kind : "date", date : newDateString2, id : Math.random()
                        }
                        
                        if(previousDate !== newDateString2){
                            newData.push(newDateRow2);
                            previousDate = newDateString2;
                        }

                        newData.push(eachData);
    
                    })

                    setData(newData);
                    props.handleUpdate2();

                    // props.unreadMessage();

                    // if(props.user.value === "student" || props.user.value === "parent"){


                    //     setTimeout(() => {
                    //         
                    //         props.socket.emit("studentReadMessage", chatRoomId);
                    //     }, 300);

                    // }
                }else{

                    

                    setData(result.data);
                    // props.unreadMessage();
                    setLoading(false);
                    

                    // if (props.user.value === "student" || props.user.value === "parent") {
                    //     setTimeout(() => {
                    //         
                    //         //읽었다는 1 없애기 위해서 선생님들꺼 재로딩
                    //         props.socket.emit("newStudentMessage", chatRoomId);
                    //     }, 300);
                    // }

                }
            })
        });

        // if(props.user.value === "teacher" || props.user.value === "staff"){
        //     props.socket.emit("newTeacherMessage", props.chatRoomId);
        // }


    }



    const getMessageForIndexChange = () => {


        
        setLoading(true);

        if( (props.user && props.user.value === "teacher" || props.user.value === "staff") && !props.chatRoomId){
            
            return;
        }

        if( (props.user && props.user.value === "student" || props.user.value === "parent") && !chatRoomId){
            
            return;
        }


        fetch(`https://peetsunbae.com/dashboard/envelope/messages?index=${index}&chatRoomId=${(props.user.value === "teacher" || props.user.value === "staff") ? props.chatRoomId : chatRoomId}`, {
            method : "GET",
            credentials : "include",
        }).then((response : any) => {
            response.json()
            .then((result : any) => {
                
                const data = result.data;
                if (result.message === "success" && result.data.length > 0) {
                    const newData = [];
                    const newDate = new Date(result.data[0].createdAt);
                    var newDateDay = "";
                    switch (newDate.getDay()) {
                        case 0:
                            newDateDay = "일";
                            break;
                        case 1:
                            newDateDay = "월";
                            break;
                        case 2:
                            newDateDay = "화";
                            break;
                        case 3:
                            newDateDay = "수";
                            break;
                        case 4:
                            newDateDay = "목";
                            break;
                        case 5:
                            newDateDay = "금";
                            break;
                        case 6:
                            newDateDay = "토";
                            break;
                    }

                    const newDateString = `${newDate.getFullYear()}년 ${newDate.getMonth()+1}월 ${newDate.getDate()}일 ${newDateDay}요일`;

                    const newDateRow = {
                        kind : "date", date : newDateString, id : Math.random()
                    }

                    newData.push(newDateRow);

                    var previousDate = newDateString;

                    result.data.forEach((eachData : any) => {

                        const newDate2 = new Date(eachData.createdAt);
                        var newDateDay2 = "";
                        switch (newDate2.getDay()) {
                            case 0:
                                newDateDay2 = "일";
                                break;
                            case 1:
                                newDateDay2 = "월";
                                break;
                            case 2:
                                newDateDay2 = "화";
                                break;
                            case 3:
                                newDateDay2 = "수";
                                break;
                            case 4:
                                newDateDay2 = "목";
                                break;
                            case 5:
                                newDateDay2 = "금";
                                break;
                            case 6:
                                newDateDay2 = "토";
                                break;
                        }
    
                        const newDateString2 = `${newDate2.getFullYear()}년 ${newDate2.getMonth()+1}월 ${newDate2.getDate()}일 ${newDateDay2}요일`;
    
                        const newDateRow2 = {
                            kind : "date", date : newDateString2, id : Math.random()
                        }
                        
                        if(previousDate !== newDateString2){
                            newData.push(newDateRow2);
                            previousDate = newDateString2;
                        }

                        newData.push(eachData);
    
                    })
                    setLoading(false);
                    setData(newData);
                }else{
                    setData(result.data);
                    setLoading(false);
                }
            })
        });

    }



    const handleFocus = (e: any) => {
        
        
        setIsFoucs(true);
    }

    const handleBlur = (e: any) => {
        
        setIsFoucs(false);
    }

    const handleChange = (e: any) => {
        setMessage(e.target.value);

        // const height = e.target.scrollHeight;
        // const rowHeight = 22;
        // const trows = Math.ceil(height/rowHeight) - 1;

        // 

        // if(trows&&rows){
        //     setRows(trows);
        // }
    }

    const handleEmojiClick = (e: any, emojiObject: any) => {
        e.stopPropagation();
        setMessage((prevMessage: any) => prevMessage + emojiObject.emoji);
        setShowPicker(false);
    }

    const handleEmojiOpen = (e: any) => {
        e.stopPropagation();
        setShowPicker(!showPicker);
    }

    const mainClick = (e: any) => {
        setShowPicker(false);
        
    }

    const handleKeydown = (e: any) => {

        const keyCode = e.which || e.keyCode;

        if (keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            
            handleSubmit("e", "text");
        }

    }

    const fileOnChange = (e: any) => {
        
        if (e.target && e.target.files.length > 0) {
            
            setFile(e.target.files[0]);
            setFileName(e.target.files[0].name);

            setImgLoading(true);

            var formData = new FormData();
            formData.append("chat_img", e.target.files[0]);

            setOpen(true);

            fetch("https://peetsunbae.com/dashboard/envelope/img", {
                method: "post",
                credentials: "include",
                body: formData
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        
                        if (result.message === "success") {
                            setImgSrc(result.src);
                            setImgId(result.imgId);
                        }
                        setImgLoading(false);
                    })
            })

        }
    }

    const handleImgDelete = (e: any) => {

        setOpen(false);

        fetch("https://peetsunbae.com/dashboard/envelope/img", {
            method: "delete",
            headers: { "content-type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
                imgId: imgId
            })
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    
                })
        })

    }


    const handleLinkChange = (e : any) => {
        setLink(e.target.value);
    }

    const handleSubmit = (e : any, kind : string) => {

        var sendMessage = "";

        if(!props.user){
            alert("잠시 후 시도하세요");
            return;
        }

        if((props.user.value === "teacher" || props.user.value === "staff") && !props.chatRoomId){
            alert("채팅룸을 선택하고 전송하세요");
            return;
        }

        if( (props.user.value === "student" || props.user.value === "parent")){
            alert("권한 부족으로 전송 실패");
            return;
        }

        if(kind === "text"){
            var direction = "teacher";

            const newRow = {
                id : Math.random(),
                chatRoomId,
                direction,
                fromUserId : props.user.id,
                isRead : 0,
                kind : "text",
                message,
                name : props.user.name, 
            }

            const newData = [...data];
            newData.push(newRow);
            setData([...newData]);
        }



        switch (kind) {
            case "text" :
                sendMessage = message;
                setMessage("");
                break;
            case "img" :
                sendMessage = imgSrc;
                setImgSrc("");
                setOpen(false);
                break;
            case "link" :
                sendMessage = link;
                setLink("");
                setOpen2(false);
                break;
            default :
                
                return;
        }

        if(!sendMessage){
            
            return;
        }

        fetch("https://peetsunbae.com/dashboard/envelope/messagesfortodos", {
            method: "post",
            headers: { "content-type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
                kind : kind,
                message : sendMessage,
                chatRoomId : (props.user.value === "teacher" || props.user.value === "staff") ? props.chatRoomId : chatRoomId
            })
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    
                    if(result.message === "success"){
                        // if(props.user.value === "teacher" || props.user.value === "staff"){
                        //     props.socket.emit("newTeacherMessage", props.chatRoomId);
                        // }
                        // if(props.user.value === "student" || props.user.value === "parent"){
                        //     props.socket.emit("newStudentMessage", chatRoomId);
                        // }
                        setUpdate(Math.random());
                        props.handleUpdate();
                        // props.unreadMessage();
                    }
                })
        })
    }

    const handleOpenModal = (src : string) => {

        if(!src){
            return;
        }

        
        setImageSrc(src);
        setOpen3(true);
    }

    const handlePaste = (e : React.ClipboardEvent<HTMLDivElement>) => {

        if(e.clipboardData.files.length === 0){
            
            return;
        }

        if(e.clipboardData.files.length !== 1){
            alert("한 번에 한 장의 사진만 전송 가능합니다");
            return;
        }

        if(!e.clipboardData.files[0].type.includes("image")){
            alert("이미지 파일만 전송가능합니다");
            return;
        }

        

        const files = e.clipboardData.files;


        setFile(files[0]);
        setFileName(files[0].name);

        setImgLoading(true);

        var formData = new FormData();
        formData.append("chat_img", files[0]);

        setOpen(true);

        fetch("https://peetsunbae.com/dashboard/envelope/img", {
            method: "post",
            credentials: "include",
            body: formData
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    
                    if (result.message === "success") {
                        setImgSrc(result.src);
                        setImgId(result.imgId);
                    }
                    setImgLoading(false);
                })
        })
    }

    const handleDragOver = (e : React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    }

    const handleDragEnter = (e : React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        
    }

    const handleDragLeave = (e : React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        
    }

    const handleDrop = (e :React.DragEvent<HTMLDivElement>) => {
        
        e.preventDefault();
        
        

        if(e.dataTransfer.files.length === 0){
            
            return;
        }

        if(e.dataTransfer.files.length !== 1){
            alert("한 번에 한 장의 사진만 전송 가능합니다");
            return;
        }

        if(!e.dataTransfer.files[0].type.includes("image")){
            alert("이미지 파일만 전송가능합니다");
            return;
        }

        const files = e.dataTransfer.files;

        setFile(files[0]);
        setFileName(files[0].name);

        setImgLoading(true);

        var formData = new FormData();
        formData.append("chat_img", files[0]);

        setOpen(true);

        fetch("https://peetsunbae.com/dashboard/envelope/img", {
            method: "post",
            credentials: "include",
            body: formData
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    
                    if (result.message === "success") {
                        setImgSrc(result.src);
                        setImgId(result.imgId);
                    }
                    setImgLoading(false);
                })
        })
    }

    function convertLinks(text : string) {
        var urlRegex = /(https?:\/\/\S+)/g;
        return text.replace(urlRegex, function(url : string) {
            // 마지막 문자가 알파벳이나 숫자가 아닌 경우, URL에서 제외합니다.
            var lastIndex = url.length - 1;
            var punctuation = url[lastIndex];
            if (!(/[a-zA-Z0-9]/.test(punctuation))) {
                url = url.substring(0, lastIndex);
            }
            return '<a target="_blank" href="' + url + '">' + url + '</a>';
        });
    }


    return (
        <div className={styles.chatMain} onClick={mainClick} 
        onPaste={(e) => {handlePaste(e)}}
        onDragOver={(e) => {handleDragOver(e)}}
        onDragEnter={(e) => {handleDragEnter(e)}}
        onDragLeave={(e) => {handleDragLeave(e)}}
        onDrop={(e) => {handleDrop(e)}}
        >
            <div className={styles.chatHeader}>
                <div className={styles.profileAnchor}>
                    <div className={`${styles.profileContainer} ${styles.ofStudent}`}>
                        <img src="img/user-solid.svg" alt="user" className={styles.avatar} />
                    </div>
                    <span className={`${styles.userName} ${styles.chatHeaderName}`}>
                        {props.studentName && props.studentName}
                    </span>
                </div>
            </div>
            <div className={styles.chatBody} ref={chatRef}>
                <div className={styles.firstRef}>
                    {
                        loading &&
                        <div className={styles.loadingDiv}>
                            <CircularProgress />
                        </div>
                    }
                </div>
                {
                    data &&
                    data.map((eachData: any, indexNumber : number) => {

                        var timestring = "";

                        if(eachData.createdAt){
                            const newDate = new Date(eachData.createdAt);
                            const dateString = `${newDate.getFullYear()}년 ${newDate.getMonth() + 1}월 ${newDate.getDate()}일`;
                            timestring = `${newDate.getHours() < 10 ? "0" + newDate.getHours() : newDate.getHours()}:${newDate.getMinutes() < 10 ? "0" + newDate.getMinutes() : newDate.getMinutes()}`
                        }

                        if (eachData.kind !== "date") {
                            return (
                                <div key={eachData.id} className={styles.eachMessage} ref={(data.length - 1 === indexNumber && data.length !== 0) ? scrollTopCallback : (40 === indexNumber) ? twentythNode : (indexNumber === 5)  ? firstRef : voidRef}>
                                    {
                                        eachData.direction === "student" &&
                                        <>
                                            <div className={styles.profileAnchor}>
                                                <div className={styles.profileContainer}>
                                                    <ShapeLogoSvg className={styles.shapeLogo} />
                                                </div>
                                                <span className={styles.userName}>
                                                    수능선배
                                                </span>
                                            </div>
                                            <div className={styles.msg_container}>
                                                <div className={styles.msg_item}>
                                                    {
                                                        eachData.kind === "text" &&
                                                        <span className={styles.msg_text_box}>
                                                            {eachData.message}
                                                        </span>
                                                    }
                                                    {
                                                        eachData.kind === "img" &&
                                                        <img style={{cursor : "pointer"}} onClick={() => {handleOpenModal(eachData.message);}} onLoad={onImageLoad} src={`https://peetsunbae.com/${eachData.message.split("/public/")[1]}`} className={styles.msg_img}>
                                                        </img>
                                                    }
                                                    {
                                                        eachData.kind === "link" &&
                                                        <a href={eachData.message} target="_blank" className={styles.link}>
                                                            {eachData.message}
                                                        </a>
                                                    }
                                                </div>
                                                <div className={styles.msg_extra}>
                                                    <div className={styles.msg_extra_action}>
                                                        {/* {
                                                            ((props.user.value === "teacher" || props.user.value === "staff") && eachData.isRead === 0) &&
                                                            <div className={styles.unReadNumber}>
                                                            1
                                                            </div>
                                                        } */}

                                                        <div className={styles.timeString}>
                                                            {
                                                                timestring
                                                                    ?
                                                                    timestring
                                                                    :
                                                                    <CircularProgress size="10px" />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </>
                                    }
                                    {
                                        eachData.direction === "teacher" &&
                                        <>
                                            <div className={styles.profileAnchor}>
                                                {
                                                    !eachData.avatar ?
                                                    <div className={`${styles.profileContainer} ${styles.ofStudent}`}>
                                                        <img src="img/user-solid.svg" alt="user" className={styles.avatar} />
                                                    </div>
                                                    :
                                                    ""
                                                }
                                                {
                                                    eachData.avatar ?
                                                    <IonAvatar className={styles.profileContainer}>
                                                        <img alt="" src={`/img/avatar/Avatar-${eachData.avatar}.png`} className={styles.ionAvatarImg} />
                                                    </IonAvatar>
                                                    :
                                                    ""
                                                }
                                                <span className={styles.userName}>
                                                    {eachData.name}
                                                </span>
                                            </div>
                                            <div className={styles.msg_container}>
                                                <div className={`${styles.msg_item} ${styles.ofStudent}`}>
                                                    <span className={styles.msg_text_box}>
                                                    {
                                                        eachData.kind === "text" &&
                                                        <span className={styles.msg_text_box}>
                                                            <div dangerouslySetInnerHTML={{__html : convertLinks(eachData.message)}} />
                                                        </span>
                                                    }
                                                    {
                                                        eachData.kind === "img" &&
                                                        <img style={{cursor : "pointer"}} onLoad={onImageLoad} onClick={() => {handleOpenModal(eachData.message);}}  src={`https://peetsunbae.com/${eachData.message.split("/public/")[1]}`} className={styles.msg_img}>
                                                        </img>
                                                    }
                                                    {
                                                        eachData.kind === "link" &&
                                                        <a href={eachData.message} target="_blank">
                                                            {eachData.message}
                                                        </a>
                                                    }
                                                    </span>
                                                </div>
                                                <div className={styles.msg_extra}>
                                                    <div className={styles.msg_extra_action}>

                                                        <Tooltip title={eachData.readUserName} open={tooltipOpen} onClose={() => { setTooltipOpen(false) }} onOpen={() => { setTooltipOpen(true) }}>
                                                            <div className={styles.unReadNumber} style={{ cursor: "pointer" }}>
                                                                읽은 사람 : {eachData.readUser ? eachData.readUser.length : 0}
                                                            </div>
                                                        </Tooltip>

                                                        
                                                        <div className={styles.timeString}>
                                                            {
                                                                timestring
                                                                    ?
                                                                    timestring
                                                                    :
                                                                    <CircularProgress size="10px" />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            )
                        }else{
                            return (
                                <div key={eachData.id} ref={indexNumber === 40 ? twentythNode : voidRef} className={`${styles.divider} ${(indexNumber === 0 && loading) ? styles.dividerNotShown : ""} `}>
                                    <Divider light={true}>
                                        <Chip label={eachData.date} />
                                    </Divider>
                                </div>
                            )
                        }
                    })
                }

            </div>

            <div className={styles.chatFooter}>
                <div className={`${styles.inputDiv} ${isFocus ? styles.focus : ""}`}>
                    <div className={styles.messageDiv}>
                        {/* <textarea value={message} onChange={handleChange} rows={rows} className={styles.textarea} onFocus={handleFocus} onBlur={handleBlur} /> */}
                        <TextareaAutosize
                            autoFocus
                            placeholder="메시지를 입력하세요 (Shift + Enter로 줄바꿈, Ctrl + V로 이미지 넣기, Drag Drop으로 이미지 넣기 가능합니다.)"
                            className={styles.textarea}
                            value={message}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            onKeyDown={handleKeydown}
                            onPaste={(e) => {}}
                        />
                    </div>
                    <div className={styles.messageBtns}>
                        <div className={styles.emoticons}>
                            <div className={styles.smile}>
                                <img onClick={handleEmojiOpen} src="img/face-smile-light.svg" className={styles.smileFace} />
                            </div>
                            <div className={styles.clip}>
                                <form encType="multipart/formdata">
                                    <label htmlFor="file">
                                        <img src="img/paperclip-light.svg" className={styles.clipImg} />
                                    </label>
                                    <input onChange={(e: any) => { fileOnChange(e) }} type="file" name="file" id="file" accept="image/*" hidden />
                                </form>
                            </div>
                            <div className={styles.link}>
                                <img src="img/link-simple-light.svg" className={styles.linkImg} onClick={handleOpen2} />
                            </div>
                        </div>
                        <div onClick={(e : any) => {handleSubmit(e, "text")}} className={`${styles.sendBtn} ${message ? styles.active : ""}`}>
                            <SendIcon
                            color={
                                message ? 
                                "primary" :
                                "inherit"
                            }
                            />
                        </div>
                    </div>
                    {
                        showPicker &&
                        <div className={styles.emojiBox} onClick={(e: any) => { e.stopPropagation(); }}>
                            <Picker
                                onEmojiClick={handleEmojiClick}
                                pickerStyle={{ width: "100%" }}
                            />
                        </div>
                    }

                </div>
            </div>


            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className={styles.modalTitle}>
                        사진 보내기
                    </div>
                    <div className={styles.modalBody}>
                        <div className={styles.modalBodyImg}>
                            {
                                imgLoading &&
                                <div className={styles.imgLoadingDiv}>
                                    <CircularProgress />
                                </div>
                            }
                            {
                                (imgSrc && !imgLoading) &&
                                <div>
                                    <img className={styles.messageImg} alt="notificationImg" src={`https://peetsunbae.com/${imgSrc.split("/public/")[1]}`}></img>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={styles.modalFooter}>
                        <div onClick={handleImgDelete} className={styles.cancelBtn}>
                            취소
                        </div>
                        <div onClick={(e : any) => {handleSubmit(e, "img")}} className={styles.uploadBtn}>
                            업로드
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={open2}
                onClose={handleClose2}
            >
                <Box sx={style}>
                    <div className={styles.modalTitle}>
                        링크 보내기
                    </div>
                    <div className={styles.modalBody}>
                        <TextField value={link} onChange={handleLinkChange} placeholder="링크를 적으세요" fullWidth />
                    </div>
                    <div className={styles.modalFooter}>
                        <div onClick={handleClose2} className={styles.cancelBtn}>
                            취소
                        </div>
                        <div onClick={(e : any) => {handleSubmit(e, "link")}} className={styles.uploadBtn}>
                            보내기
                        </div>
                    </div>
                </Box>
            </Modal>

            <ImageModal
            open={open3}
            handleClose={() => {setOpen3(false)}}
            src={imageSrc}
            />

        </div>
    )
}

export default ChatForTodos;