import React, { useEffect, useState, useRef } from "react";
import InnerHeader from "../etc/innerheader";
import styles from "./mobileprofile.module.css";
import useEmblaCarousel from "embla-carousel-react";
import BottomButton from "../etc/bottomButton";
import { IonLoading } from "@ionic/react";
import { useHistory } from "react-router-dom";

import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import { getFetch, postFetch } from "../../fetch/fetch";
import { LinearProgress } from "@mui/material";

const MobileProfilePicture: React.FC<any> = (props) => {
    const [emblaRef, emblaApi] = useEmblaCarousel({
        align: "center"
    });
    const [targetIndex, setTargetIndex] = useState(1);
    const headerRef = useRef<any>();
    const [height, setHeight] = useState(0);

    const [loading, setLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {

        
        
        

        if (!headerRef || !headerRef.current) {
            
            return;
        }

        const calcBodyHeight = () => {

            if (!headerRef || !headerRef.current) {
                
                return;
            }

            

            const totalHeight = window.innerHeight;
            const safeAreaTop = +getComputedStyle(document.documentElement).getPropertyValue("--sat").split("p")[0];
            const headerHeight = headerRef.current.offsetHeight;
            const bodyHeight = totalHeight - headerHeight - safeAreaTop;

            

            setHeight(bodyHeight);
        }

        calcBodyHeight();

        window.addEventListener("resize", () => calcBodyHeight);

        return () => window.removeEventListener("resize", () => calcBodyHeight);

    }, [headerRef]);

    useEffect(() => {
        if (emblaApi) {
            
            
 
            emblaApi.on("select", () => {

                setTargetIndex(emblaApi.selectedScrollSnap() + 1);
            })
        }
    }, [emblaApi]);

    useEffect(() => {

        
    }, [emblaRef]);


    const handleClick = (index: number) => {
        setTargetIndex(index);
    }

    const handleLoad = (e: any) => {
        setLoading(false);
    }

    const notHandleLoad = () => {

    }

    useEffect(() => {
        getAvatar();
    }, [emblaApi]);

    const getAvatar = async () => {

        if (!emblaApi) {
            return;
        }

        try {
            const result: any = await getFetch("/dashboard/profile/avatar");
            if (result.message === "success") {
                
                emblaApi.scrollTo(result.index - 1);
            }
        } catch (e) {
            
        }
    }

    const submit = async () => {

        try {
            const result: any = await postFetch("/dashboard/profile/avatar", { targetIndex });
            if (result.message === "success") {
                
                history.push({
                    pathname: "/mobiledashboard/success",
                    state: {
                        home: true,
                        description: "프로필 설정 완료",
                        title: "프로필 설정"
                    }
                })
            }
        } catch (e) {
            
        }

    }

    return (
        <div>
            <InnerHeader title="프로필 설정" headerRef={headerRef} />
            {
                loading
                &&
                <LinearProgress />
            }


            <div>
                <div className={styles.pictureBody}>
                    <div className={styles.pictureTitle}>
                        어떤 프로필 사진을 사용하실래요?
                    </div>
                </div>

                <div className={styles.embla} ref={emblaRef}>
                    <div className={styles.embla_container}>
                        {
                            new Array(36).fill(undefined).map((each: undefined, index: number) => {
                                return (
                                    <div className={styles.embla_slide} key={index}>
                                        <img src={`/img/avatar/Avatar-${index + 1}.png`} onLoad={index === 35 ? handleLoad : notHandleLoad} onError={index === 35 ? handleLoad : notHandleLoad} className={`${styles.avatar} ${targetIndex === index + 1 ? styles.active : ""}`} />
                                    </div>
                                );
                            })
                        }

                    </div>
                </div>

                <div className={styles.numberDiv}>
                    {targetIndex} / 36
                </div>
            </div>




            <BottomButton submit={submit} text="저장하기" />

            {/* <IonLoading
                cssClass='my-custom-class'
                isOpen={loading}
                onDidDismiss={() => setLoading(false)}
                message={'잠시만 기다려주세요'}
            /> */}

        </div>
    )
}

export default MobileProfilePicture;