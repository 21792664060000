import React, { useEffect, useState } from "react";
import { DataGridPremium, GridColDef, GridColumnGroupingModel, GridFilterModel, GridRenderCellParams, GridRowModel, GridRowProps } from "@mui/x-data-grid-premium";
import { makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
import { createStyles } from '@mui/styles';

import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import koLocale from 'date-fns/locale/ko'
import TextField from '@mui/material/TextField';
import { DatePicker } from "@mui/x-date-pickers";
import { Button } from "@mui/joy";
import { da } from "date-fns/locale";
import { set } from "lodash";




const defaultTheme = createTheme();

const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

        return {
            root: {
                '& .super-app-theme--처리완료': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .super-app-theme--확인': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .super-app-theme--미확인': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .clickable': {
                    cursor: "pointer",
                    '&:hover': {
                        color: "blue"
                    }
                },
                '& .status3': {

                },
                '& .complete': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                },
                '& .incomplete': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                },
                '& .status4': {
                    backgroudColor: "red"
                },
                '& .status5': {
                    backgroudColor: "red"
                },
                '& .canceled': {
                    color: "red"
                },
                '& .notSend1': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                },
                '& .notReply1': {
                    color: "red"
                }
            },
        };
    },
    { defaultTheme },
);

interface GridCellExpandProps {
    value: string;
    width: number;
}


const useStyles = makeStyles(() =>
    createStyles({
        root: {
            alignItems: 'center',
            lineHeight: '24px',
            width: '100%',
            height: '100%',
            position: 'relative',
            display: 'flex',
            '& .cellValue': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },
    }),
);


const columns: GridColDef[] = [
    { field: "name", headerName: "이름", width: 200 },
    { field: "purestudytime", headerName: "순공부시간", width: 300 },
];

const SecondPureStudyTime: React.FC<any> = (props) => {

    const [dateValue, setDateValue] = useState(new Date(Date.now() - 86400000));
    const classes = useStyles2();
    const [rows, setRows] = useState<GridRowProps[]>([]);
    const [name, setName] = useState<string>("");
    const [location, setLocation] = useState<string>("gangnam");
    const [loading, setLoading] = useState<boolean>(false);



    useEffect(() => {

        if (dateValue) {
            submit(null);
        }

    }, [dateValue]);

    const handleNameChange = (event: any) => {
        setName(event.target.value);
    }

    const submit = (e: any) => {

        setLoading(true);

        setRows([]);

        if (!dateValue) {
            alert("날짜를 선택해주세요.");
            return;
        }

        fetch("https://peetsunbae.com/fingerprint/purestudytime?dateTime=" + dateValue.getTime() + "&kinds=" + location, {
            method: "GET",
            credentials: "include",
        }).then((res) => {
            res.json()
                .then((result: any) => {

                    if (result.message === "success") {

                        const totalAccessInformation = result.data;

                        const userId: number[] = [];
                        const userNameArray: string[] = [];

                        totalAccessInformation.forEach((item: any) => {
                            if (!userId.includes(item.userId) && item.value === "student") {
                                userId.push(item.userId);
                                userNameArray.push(item.name);
                            }
                        })

                        const totalData: { userId: number, usersAccessInformation: [], userName : string }[] = [];

                        userId.forEach((item: any, index : number) => {
                            totalData.push({ userId: item, userName : userNameArray[index], usersAccessInformation: [] });
                        }
                        )

                        totalAccessInformation.forEach((item: any) => {
                            totalData.forEach((item2: any) => {
                                if (item.userId === item2.userId) {
                                    item2.usersAccessInformation.push(item);
                                }
                            })
                        }
                        )

                        

                        totalData.forEach((item: any) => {
                            item.pureStudyTime = 0;
                            var previousAccessInformation : any;
                            item.usersAccessInformation.forEach((item2: any) => {
                                if(previousAccessInformation){
                                    if(previousAccessInformation.direction !== item2.direction && previousAccessInformation.direction === "outside"){
                                        item.pureStudyTime += item2.time - previousAccessInformation.time;
                                    }
                                }
                                previousAccessInformation = item2;
                            })
                            
                        })

                        const newRows : any = [];

                        totalData.forEach((item: any) => {
                            

                            const oneRow : any = {};
                            oneRow.id = item.userId;
                            oneRow.name = item.userName;
                            //item.purestudytime은 millisecond단위인데 이를 ~시간 ~분 꼴로 바꿔줘야함
                            const hour = Math.floor(item.pureStudyTime / 3600000);
                            const minute = Math.floor((item.pureStudyTime - hour * 3600000) / 60000);
                            oneRow.purestudytime = hour + "시간 " + minute + "분";

                            newRows.push(oneRow);
                        })

                        setRows([...newRows]);
                        setLoading(false);

                    }

                })
        })


    }

    return (
        <div>
            <div>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "1650px"
                }}>
                    <div style={{display : "flex", alignItems : "center"}}>
                        <LocalizationProvider locale={koLocale} dateAdapter={AdapterDateFns}>
                            {/* <StaticDatePicker
                            //shouldDisableDate={disableWeekends}
                            displayStaticWrapperAs="desktop"
                            openTo="day"
                            value={dateValue}
                            disablePast
                            //minDate={minDate}
                            onChange={(newValue: any) => {
                                
                            }}
                            renderInput={(params: any) => <TextField {...params} />}
                        /> */}
                            <DatePicker
                                disableFuture
                                value={dateValue}
                                onChange={(newValue: any) => {
                                    setDateValue(newValue);
                                }}
                                renderInput={(params: any) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        {/* <Button
                            variant="soft"
                            size="lg"
                            sx={{
                                marginLeft: "24px",
                            }}
                            onClick={submit}
                        >조회</Button> */}
                    </div>
                    <div>
                        {/* <TextField 
                        value={name}
                        onChange={handleNameChange}
                        variant="standard" placeholder="이름을 입력하세요" /> */}
                    </div>
                </div>
            </div>
            <div className={classes.root} style={{ height: 650, width: '1650px', backgroundColor: "white", marginTop: "12px" }}>
                <DataGridPremium
                    rows={rows}
                    columns={columns}
                    density="compact"
                    loading={loading}
                />
            </div>
        </div>
    )
}

export default SecondPureStudyTime;