import { IonApp } from "@ionic/react";
import React, { useEffect, useState, useRef } from "react";
import styles from "./mobilewordtest.module.css";
import { useHistory } from "react-router-dom";
import { ReactComponent as ArrowLeftLight } from "../../../src/svg/arrow-left-light.svg";
import { IonItem, IonList, IonSelect, IonSelectOption } from '@ionic/react';
import { Capacitor } from "@capacitor/core";
import Switch from '@mui/joy/Switch';
import Typography from '@mui/joy/Typography';
import { CssVarsProvider} from "@mui/joy";
import { Preferences } from "@capacitor/preferences";

import {ReactComponent as EyeSvg} from "../../../src/svg/eye-light.svg";
import {ReactComponent as EyeSlashSvg} from "../../../src/svg/eye-slash-light.svg";
import { LinearProgress } from "@mui/material";


const MobileWordtest: React.FC<any> = (props) => {
    const headerRef = useRef<any>();
    const [platform, setPlatform] = useState("");
    const history = useHistory();
    const [day, setDay] = useState(1);
    const mainBodyRef = useRef<any>();
    const [bodyHeight, setBodyHeight] = useState(0);
    const [checked, setChecked] = useState(false);
    const [invisible, setInvisible] = useState("total");
    const [display, setDisplay] = useState(new Array(40).fill(false));
    const [loading, setLoading] = useState(false);
    const [lastReport, setLastReport] = useState<any>();
    const [totalReport, setTotalReport] = useState<any>();

    useEffect(() => {

        


        const handleBackButton = () => {
            
            history.goBack();
        }

        //backbutton 눌리면 handleBackButton 실행
        document.addEventListener("backbutton", handleBackButton);

        return () => {
            document.removeEventListener("backbutton", handleBackButton);
        }

    }, []);


    const caculateBodyHeight = () => {
        setTimeout(() => {

            if (!headerRef || !headerRef.current) {
                return;
            }


            const value = +getComputedStyle(document.documentElement).getPropertyValue("--sat").split("p")[0];
            const totalHeight = window.innerHeight;
            const safeareaTop = value;
            const headerHeight = headerRef.current.offsetHeight;

            const lastHeight = totalHeight - safeareaTop - headerHeight

            
            
            setBodyHeight(lastHeight);

        }, 50);
    }


    useEffect(() => {

        caculateBodyHeight();

        window.addEventListener("resize", () => caculateBodyHeight());

        return window.removeEventListener("resize", () => caculateBodyHeight());

    }, [headerRef]);

    const handleClick = (e: any) => {
        history.push("/mobiledashboard");
    }

    const handleDayChange = (value: number) => {
        setDay(value);
    }

    //플랫폼 정보 확인
    useEffect(() => {
        const platform = Capacitor.getPlatform();

        if (platform) {
            setPlatform(platform);
        }


    }, []);

    const getWords = async () => {



        if(!props.user){
            return;
        }

        const newArray = new Array(40).fill(false);

        setDisplay([...newArray]);

        setChecked(false);
        setInvisible("total");
        setLoading(true);

        var studentId = props.user.id;

        

        var token : any = "";

        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        if (Capacitor.isNativePlatform()) {
            const { value } = await Preferences.get({ key: "token" });
            token = value;
        }


        fetch("https://peetsunbae.com/dashboard/words/getday?day=" + day + "&studentId=" + studentId, {
            method: "GET",
            credentials: "include",
            headers : {
                "Authorization" : token
            }
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    
                    

                    if (result.data && result.data.length > 0) {

                        // result.data.sort(function (a: any, b: any) {
                        //     if (a.result === "correct" && b.result === "incorrect") {
                        //         return 1;
                        //     } else if (a.result === "incorrect" && b.result === "correct") {
                        //         return -1;
                        //     } else {
                        //         return 1;
                        //     }
                        // })

                        setLastReport(result.data);
                        setTotalReport(result.data);

                        var totalCorrectCount = 0;
                        var totalIncorrectCount = 0;

                        result.data.forEach((each: any) => {
                            if (each.result === "correct") {
                                totalCorrectCount++;
                            } else if (each.result === "incorrect") {
                                totalIncorrectCount++;
                            }
                        });

                        // setCorrectNumber(totalCorrectCount);
                        // setIncorrectNumber(totalIncorrectCount);


                    }

                    setLoading(false);
                })
        })

    }


    useEffect(() => {

        getWords();

    }, [day, props.user]);


    useEffect(() => {

        if(!totalReport){
            return;
        }

        if (checked) {
            const newLastReport = totalReport;

            const filteredLastReport = newLastReport.filter((each: any) => {
                if (each.result === "incorrect") {
                    return true;
                }
            })

            setLastReport([...filteredLastReport]);

            const newArray = new Array(40).fill(false);
            setDisplay([...newArray]);

        }else{
            const newLastReport = totalReport;

            setLastReport([...newLastReport]);

            const newArray = new Array(40).fill(false);
            setDisplay([...newArray]);

        }


    }, [checked]);

    const handleVisible = (e : any, index : number) => {

        if(invisible === "total"){
            return;
        }


        const newDisplay = display;
        const currentBoolean = display[index];
        const newBoolean = !currentBoolean;

        newDisplay[index] = newBoolean;

        setDisplay([...newDisplay]);

    }


    return (
        <IonApp>
            <div style={{ paddingTop: "env(safe-area-inset-top)" }}>
                <div className={`${styles.header} ${platform === "ios" ? styles.ios : ""}`} ref={headerRef}>
                    <div className={styles.backBtnDiv}
                        onClick={(e: any) => { handleClick(e) }}
                    >
                        <ArrowLeftLight
                            fill="#2a2a2a"
                            className={styles.arrowLeft}
                        />
                    </div>

                    <div className={styles.titleText}>
                        <IonSelect value={day} onIonChange={(e) => handleDayChange(e.detail.value)}>
                            {
                                new Array(50).fill(undefined).map((each: undefined, index: number) => {
                                    return (
                                        <IonSelectOption value={index + 1}>
                                            Day {index + 1}
                                        </IonSelectOption>
                                    );
                                })
                            }
                        </IonSelect>
                        {/* <div className={styles.titleTextString}>
                            전체
                        </div>
                        <CaretDown fill="#9e9e9e" className={styles.caret} /> */}
                    </div>

                    <div className={`${styles.submit}`}>

                    </div>
                </div>
                {
                    loading
                    &&
                    <LinearProgress />
                }
                

                <div className={styles.body}>
                    <div className={styles.mainBody} ref={mainBodyRef} style={{ height: `${bodyHeight}px`, overflow: "scroll" }}>
                        <div className={styles.incorrectDiv}>
                            <div className={styles.incorrectText}>
                                틀린 단어만
                            </div>
                            <div className={styles.incorrectSwitch}>
                                <CssVarsProvider>
                                    <Switch
                                        sx={{"& .JoySwitch-track.Joy-checked" : {backgroundColor : "#304ffe"}}}
                                        size="sm"
                                        checked={checked}
                                        onChange={(event) => setChecked(event.target.checked)}
                                    />
                                </CssVarsProvider>
                            </div>
                        </div>
                        <div className={styles.invisibleDiv}>
                            <div className={`${styles.invisible} ${styles.first} ${invisible === "total" ? styles.active : ""}`} onClick={(e) => {setInvisible("total");}}>
                                전체
                            </div>
                            <div className={`${styles.invisible} ${invisible === "word" ? styles.active : ""}`} onClick={(e) => {setInvisible("word");}} >
                                단어만
                            </div>
                        </div>

                        <div className={styles.wordsWrapper}>
                            {
                                lastReport 
                                &&
                                lastReport.map((eachWord : any, index : number) => {
                                    return (
                                        <div className={styles.eachWord}>
                                            <div className={styles.topHeader}>
                                                <div className={styles.word}>
                                                    {eachWord.word}
                                                </div>
                                                <div className={styles.visibleSvgDiv} onClick={(e) => {handleVisible(e, index);}}>
                                                    {
                                                        (invisible === "word" && !display[index])
                                                        &&
                                                        <EyeSlashSvg className={styles.eyeSvg} />
                                                    }
                                                    {
                                                        (invisible === "word" && display[index])
                                                        &&
                                                        <EyeSvg className={styles.eyeSvg} />
                                                    }
                                                </div>
                                            </div>
                                            <div className={styles.bottomLine}>
                                                <div className={styles.meaning}>
                                                    {
                                                        invisible === "total"
                                                        &&
                                                        eachWord.meaning
                                                    }
                                                    {
                                                        (invisible === "word" && display[index])
                                                        &&
                                                        eachWord.meaning
                                                    }
                                                </div>
                                                <div className={styles.isCorrect}>
                                                    {
                                                        eachWord.result === "incorrect"
                                                        &&
                                                        <div>
                                                            ❌
                                                        </div>
                                                    }
                                                    {
                                                        eachWord.result === "correct"
                                                        &&
                                                        <div>
                                                            😃
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>

            </div>
        </IonApp>
    );
}

export default MobileWordtest;