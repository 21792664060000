import React, { useEffect, useState, useRef } from "react";
import styles from "./mobileedit.module.css";
import { ReactComponent as Check } from "../../../src/svg/check-light.svg";
import { ReactComponent as Xmark } from "../../../src/svg/xmark-light.svg";
import { ReactComponent as ArrowUp } from "../../../src/svg/arrow-up-light.svg";
import { ReactComponent as Trash } from "../../../src/svg/trash-can-regular.svg";
import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import { IonButton, useIonActionSheet } from '@ionic/react';
import { IonLoading } from "@ionic/react";
import { returnDayString } from "../etc/functions";
import { Skeleton } from "@mui/material";

const MobileEditStatus: React.FC<any> = (props) => {

    const [checkResult, setCheckResult] = useState<any>();
    const [present] = useIonActionSheet();
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const topRef = useRef<any>(null);
    const [descriptionHeight, setDescriptionHeight] = useState(0);

    useEffect(() => {

        

        

        if (!topRef || !topRef.current || !props.statusDivHeight) {
            return;
        }

        const caculateBodyHeight = () => {

            

            if (!topRef || !topRef.current || !props.statusDivHeight) {
                return;
            }

            const totalHeight = props.statusDivHeight;
            const topHeight = topRef.current.offsetHeight;

            const descriptionHeight = totalHeight - topHeight - (2.469 * 16);

            
            

            setDescriptionHeight(descriptionHeight);
        }

        caculateBodyHeight();

        window.addEventListener("resize", () => caculateBodyHeight());

        return window.removeEventListener("resize", () => caculateBodyHeight());


    }, [topRef, props.statusDivHeight]);



    useEffect(() => {
        start();
    }, []);


    async function start() {
        setLoading(true);
        var token: any = "";
        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        //네이티브 라면 토큰 가져오는 기능
        if (Capacitor.isNativePlatform()) {
            
            const { value } = await Preferences.get({ key: "token" });
            token = value;
        }

        fetch("https://peetsunbae.com/dashboard/edit/check", {
            method: "GET",
            headers: { "Authorization": token },
            credentials: "include",
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    

                    const message = result.message;

                    if (!message) {
                        return;
                    }

                    message.sort(function (a: any, b: any) {
                        const afterTime = new Date(a.DateInserted).getTime();
                        const beforeTime = new Date(b.DateInserted).getTime();

                        if (afterTime < beforeTime) {
                            return 1;
                        }
                        if (afterTime > beforeTime) {
                            return -1;
                        }
                    })

                    setCheckResult(message);
                    setLoading(false);
                })
        })
    }

    const confirmDelete = (id: number) => {
        present({
            header: "삭제 안내",
            subHeader: "삭제를 진행할까요?",
            buttons: [{
                text: "삭제",
                role: "destructive"
            },
            {
                role: "cancel",
                text: "취소",
            }
            ],
            onDidDismiss: ({ detail }) => {
                
                switch (detail.role) {
                    case "destructive":
                        cancel(id);
                        break;
                    case "cancel":
                        break;
                }
            },
        })
    }


    const cancel = async (id: number) => {

        setLoading2(true);

        var token: any = "";
        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        //네이티브 라면 토큰 가져오는 기능
        if (Capacitor.isNativePlatform()) {
            
            const { value } = await Preferences.get({ key: "token" });
            token = value;
        }

        fetch("https://peetsunbae.com/dashboard/edit/delete", {
            method: "DELETE",
            headers: { "Content-Type": "application/json", "Authorization": token },
            credentials: "include",
            body: JSON.stringify({
                id: id
            })
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    
                    if (result.message === "success") {
                        setCheckResult(
                            checkResult.filter((each: any) => each.id != id)
                        )
                    }
                    setLoading2(false);
                })
        })
    }


    return (
        <div className={styles.statusBody}>
            <div className={styles.statusTop} ref={topRef}>
                <div className={styles.statusTitle}>
                    이번달 사유 제출 현황
                </div>
                <div className={styles.statusNumber}>
                    # 이번달 제출 횟수 - {checkResult && checkResult.length}회
                </div>
            </div>

            {
                loading
                &&
                <div style={{ width: "90.6%", margin: "0 auto" }}>
                    <div style={{ height: "20px" }}>
                    </div>
                    <Skeleton variant="rectangular" width="100%" height={140} />
                    <div style={{ height: "20px" }}>
                    </div>
                    <Skeleton variant="rectangular" width="100%" height={140} />
                    <div style={{ height: "20px" }}>
                    </div>
                    <Skeleton variant="rectangular" width="100%" height={140} />
                    <div style={{ height: "20px" }}>
                    </div>
                </div>
            }

            {
                !loading
                &&
                <div className={styles.description} style={{ height: `${descriptionHeight}px`, overflow: "scroll" }}>
                    <div className={styles.descriptionBody}>

                        {
                            checkResult &&
                            checkResult.map((eachCheckResult: any, index: number) => {

                                const parentPermit = eachCheckResult.parentpermit;

                                

                                const dateInserted = new Date(eachCheckResult.DateInserted);
                                var dateInsertedDay = returnDayString(dateInserted.getDay());

                                const dateInsertedString = `${dateInserted.getMonth() + 1}월 ${dateInserted.getDate()}일`;

                                const targetDate = new Date(eachCheckResult.targetDate);

                                var canBeDeleted = false;

                                if (targetDate.getTime() > new Date().getTime()) {
                                    canBeDeleted = true;
                                }

                                var targetDateDay = returnDayString(targetDate.getDay());

                                const targetDateString = `${targetDate.getMonth() + 1}월 ${targetDate.getDate()}일 ${targetDateDay}요일`;

                                var kindString = "";

                                switch (eachCheckResult.type) {
                                    case "among":
                                        kindString = "외출";
                                        break;
                                    case "long":
                                        kindString = "지각";
                                        break;
                                    case "early":
                                        kindString = "조퇴";
                                        break;
                                    case "absent":
                                        kindString = "결석";
                                        break;
                                }

                                var startAmPm = "오전";
                                var startHours = eachCheckResult.startHours;
                                var startMinutes = eachCheckResult.startMinutes < 10 ? "0" + eachCheckResult.startMinutes : eachCheckResult.startMinutes;

                                if (startHours > 11) {
                                    if (startHours > 12) {
                                        startHours = startHours - 12;
                                    }
                                    startAmPm = "오후";
                                }

                                var endAmPm = "오전";
                                var endHours = eachCheckResult.endHours;
                                var endMinutes = eachCheckResult.endMinutes < 10 ? "0" + eachCheckResult.endMinutes : eachCheckResult.endMinutes;

                                if (endHours > 11) {
                                    endAmPm = "오후";
                                    if (endHours > 12) {
                                        endHours = endHours - 12;
                                    }
                                }




                                return (
                                    <div key={eachCheckResult.id}>
                                        <div className={`${styles.eachDescription} ${checkResult.length - 1 === index ? styles.last : ""}`}>
                                            <div className={styles.eachDescriptionDate}>
                                                신청일 : {dateInsertedString}
                                            </div>
                                            <div className={styles.eachDescriptionBox}>
                                                <div className={styles.eachDescriptionText}>
                                                    {eachCheckResult.reason}
                                                </div>
                                                <div className={styles.eachDescriptionTargetDate}>
                                                    사유 날짜 : {targetDateString}
                                                </div>
                                                <div className={styles.eachDescriptionKindDiv}>
                                                    <div className={styles.eachDescriptionKindText}>
                                                        [{kindString}]
                                                        {
                                                            kindString === "지각" &&
                                                            `${startAmPm} ${startHours}시 ${startMinutes}분 등원`
                                                        }
                                                        {
                                                            kindString === "조퇴" &&
                                                            `${endAmPm} ${endHours}시 ${endMinutes}분 하원`
                                                        }
                                                        {
                                                            kindString === "외출" &&
                                                            `${startAmPm} ${startHours}시 ${startMinutes}분 - ${endAmPm} ${endHours}시 ${endMinutes}분`
                                                        }
                                                    </div>
                                                    <div className={`${styles.eachDescriptionKindSuccess} ${parentPermit === 0 ? styles.waiting : parentPermit === 1 ? styles.success : parentPermit === 2 ? styles.decline : ""}`}>
                                                        {
                                                            parentPermit === 1 &&
                                                            <Check fill="#fff" className={styles.check}></Check>
                                                        }
                                                        {
                                                            parentPermit === 2 &&
                                                            <Xmark fill="#fff" className={styles.xmark}></Xmark>
                                                        }
                                                        {
                                                            parentPermit === 0 &&
                                                            <ArrowUp fill="#fff" className={styles.arrowUp}></ArrowUp>
                                                        }
                                                        &nbsp;
                                                        <span>
                                                            {
                                                                parentPermit === 1 &&
                                                                "승인"
                                                            }
                                                            {
                                                                parentPermit === 2 &&
                                                                "비승인"
                                                            }
                                                            {
                                                                parentPermit === 0 &&
                                                                "대기중"
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className={`${styles.trashCanDiv} ${canBeDeleted ? "" : styles.none}`} onClick={(e: any) => { confirmDelete(eachCheckResult.id); }}>
                                                    <Trash fill="#251e1c" className={`${styles.trash}`} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                );

                            })
                        }




                    </div>
                </div>
            }



            {/* <IonLoading
                cssClass='my-custom-class'
                isOpen={loading}
                onDidDismiss={() => setLoading(false)}
                message={'잠시만 기다려주세요'}
            /> */}

            <IonLoading
                cssClass='my-custom-class'
                isOpen={loading2}
                onDidDismiss={() => setLoading2(false)}
                message={'잠시만 기다려주세요'}
            />
        </div>
    )
}

export default MobileEditStatus;