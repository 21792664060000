import React, { useEffect, useState, useRef } from "react";
import { IonApp } from "@ionic/react";
import InnerHeader from "../etc/innerheader";
import MobileOfflineQuestionRegister from "./mobileofflinequestionregister";
import MobileOfflineQuestionStatusTeacher from "./mobileofflinequestionstatusteacher";
import MobileOfflineQuestionStatusStudent from "./mobileofflinequestionstatusstudent";
import styles from "./mobileofflinequestionregister.module.css";
import { LinearProgress } from "@mui/material";


const MobileOfflineQuestion: React.FC<any> = (props) => {

    const [currentMenu, setCurrentMenu] = useState("submit");
    const [underBarPosition, setUnderBarPosition] = useState(6);

    const topDivRef = useRef<any>(null);
    const [statusDivHeight, setStatusDivHeight] = useState(0);
    const [loading, setLoading] = useState(false);

    const turnOnLoading = () => {
        
        setLoading(true);
    }

    const turnOffLoading = () => {
        
        setLoading(false);
    }


    useEffect(() => {

        if (!topDivRef || !topDivRef.current) {
            return;
        }

        const calculateStatusDivHeight = () => {

            setTimeout(() => {

                if (!topDivRef || !topDivRef.current) {
                    return;
                }

                const totalHeight = window.innerHeight;
                const topDivHeight = topDivRef.current.offsetHeight;

                const statusDivHeight = totalHeight - topDivHeight;
                
                
                
                

                setStatusDivHeight(statusDivHeight);

            }, 20);

        }

        calculateStatusDivHeight();

        window.addEventListener("resize", () => calculateStatusDivHeight());

        return () => window.removeEventListener("resize", () => calculateStatusDivHeight());

    }, [topDivRef]);



    const moveUnderBar = async (index: number) => {

        switch (index) {
            case 1:
                setUnderBarPosition(6);
                break;
            case 2:
                setUnderBarPosition(54);
                break;
        }

    }

    return (
        <div>
            <div ref={topDivRef}>
                <InnerHeader title={"질의응답 신청"} home={true} />
                {
                    loading
                    &&
                    <LinearProgress />
                }


                <div className={styles.underBorder}>

                </div>

                <div className={styles.menuBar}>
                    <div className={`${styles.menuBarSection} ${currentMenu === "submit" ? styles.active : ""}`} onClick={() => { moveUnderBar(1); setCurrentMenu("submit"); }}>
                        질의응답 신청
                    </div>
                    <div className={`${styles.menuBarStatus} ${currentMenu === "status" ? styles.active : ""}`} onClick={() => { moveUnderBar(2); setCurrentMenu("status"); }}>
                        신청 현황
                    </div>
                    <div className={styles.menuUnderBar} style={{ left: `${underBarPosition}%` }}>

                    </div>
                </div>
            </div>

            {
                !loading
                &&
                <div>
                    {
                        currentMenu === "submit" &&
                        <MobileOfflineQuestionRegister user={props.user} socket={props.socket} statusDivHeight={statusDivHeight} turnOnLoading={turnOnLoading} turnOffLoading={turnOffLoading} loading={loading} />
                    }
                    {
                        (currentMenu === "status" && (props.user.value === "teacher" || props.user.value === "staff")) &&
                        <MobileOfflineQuestionStatusTeacher user={props.user} socket={props.socket} statusDivHeight={statusDivHeight} turnOnLoading={turnOnLoading} turnOffLoading={turnOffLoading} />
                    }
                    {
                        (currentMenu === "status" && (props.user.value === "student" || props.user.value === "parent")) &&
                        <MobileOfflineQuestionStatusStudent user={props.user} socket={props.socket} statusDivHeight={statusDivHeight} turnOnLoading={turnOnLoading} turnOffLoading={turnOffLoading} />
                    }
                </div>

            }
        </div>
    );
}

export default MobileOfflineQuestion;