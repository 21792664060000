import React, { useEffect, useState, useRef } from "react";
import styles from "../../componentsStyle/makewordtest.module.css";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/joy/Button';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import { Chip, CssVarsProvider, Divider } from '@mui/joy';
import { ChevronLeft, ChevronRight, Cloud, KeyboardArrowRight, TurnedIn, WbSunny } from '@mui/icons-material';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Typography from '@mui/joy/Typography';


import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';

import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Person from '@mui/icons-material/Person';
import People from '@mui/icons-material/People';
import Apartment from '@mui/icons-material/Apartment';

import Input from '@mui/joy/Input';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EachdayTestForChart from "./eachdaytestforchart";



const MakeWordTest: React.FC<any> = (props: any) => {

    const [justify, setJustify] = React.useState('flex-start');
    const [bookName, setBookName] = useState("");
    const [open, setOpen] = React.useState<boolean>(false);
    const inputRef = React.useRef<HTMLInputElement | null>(null);

    const [dayCount, setDayCount] = useState(7);
    const [radioValue, setRadioValue] = useState("");
    const [update, setUpdate] = useState(0);
    const [update2, setUpdate2] = useState(0);

    const autocompleteRef = useRef<any>([]);

    const [disabled, setDisabled] = useState(true);

    const [data, setData] = useState<any>([]);

    const [today, setToday] = useState(new Date());

    const [values, setValues] = useState<any>([[], [],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]]);

    const [index, setIndex] = useState(1);

    const [testData, setTestData] = useState<any>([]);
    const [testBook, setTestBook] = useState("");
    const [createdDate, setCreatedDate] = useState("");

    const [open2, setOpen2] = useState(false);
    const [selectedWords, setSelectedWords] = useState<any>([]);
    const [dayTestData, setDayTestData] = useState<any>([]);
    const [selectedTitle, setSelectedTitle] = useState("");

    const notify1 = () => toast.success("저장 성공!", {
        position : "bottom-right",
        autoClose : 3000,
        hideProgressBar : true,
        theme : "colored"
    });

    useEffect(() => {

        setValues([[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);

    }, [props.selectedUser]);

    useEffect(() => {

        if(!props.selectedUser){
            
            return;
        }

        if(!index){
            
            return;
        }

        fetch(`https://peetsunbae.com/dashboard/words/getwordtest?studentId=${props.selectedUser.id}&index=${index}`, {
            method : "get",
            credentials : "include"
        }).then((response : any) => {
            response.json()
            .then((result : any) => {
                
                if(result.message === "success"){

                    const kind = result.data1.kind;

                    switch (kind) {
                        case 1:
                            setTestBook("워드마스터 수능 2000 (2018년 버전)");
                            break;
                        case 2:
                            setTestBook("워드마스터 수능 2000 (2022년 버전)");
                            break;
                        case 3:
                            setTestBook("워드마스터 하이퍼 2000 (2021년 버전)");
                            break;
                        case 5:
                            setTestBook("워드마스터 EBS 파이널 1200(2020년 버전)");
                            break;
                    }

                    const created = new Date(result.data1.createdAt);
                    const createdString = `${created.getMonth() + 1}/${created.getDate()}`;

                    setCreatedDate(createdString);
                    
                    const data1 = result.data1.data;
                    const rows : any = [];

                    const data2 = result.data2;
                    const data2rows : any = [];

                    data2.forEach((each : any) => {
                        each.dayInfo.forEach((eacheach : any) => {
                            const oneRow : any = {};
                            oneRow.day = eacheach.dayNumber;
                            if(eacheach.isTotal){
                                oneRow.type = "total"
                            }else{
                                oneRow.type = "incorrect"
                            }

                            oneRow.words = [];


                            each.report.forEach((eachWord : any) => {

                                if(eachWord.day === oneRow.day){
                                    oneRow.words.push(eachWord);
                                }
                            })

                            data2rows.push(oneRow);
                        })
                    })

                    data2rows.forEach((each : any) => {

                        var totalCount = 0;
                        var count = 0;

                        each.words.forEach((eachWord : any) => {
                            totalCount++;
                            if(eachWord.result === "correct"){
                                count++;
                            }
                        })

                        each.totalCount = totalCount;
                        each.count = count;

                    })

                    


                    data1.forEach((each : any) => {

                        const newRow : any = {};
                        newRow.month = each.month + 1;
                        newRow.date = each.date;
                        
                        const newDate = new Date(each.year, each.month, each.date);

                        var day = ""

                        switch (newDate.getDay()) {
                            case 0:
                                day = "일"
                                break;
                            case 1:
                                day = "월"
                                break;
                            case 2:
                                day = "화"
                                break;
                            case 3:
                                day = "수"
                                break;
                            case 4:
                                day = "목"
                                break;
                            case 5:
                                day = "금"
                                break;
                            case 6:
                                day = "토"
                                break;
                        }

                        newRow.day = day;
                        newRow.data = [];

                        each.data.forEach((eachData : any) => {

                            const partNewRow : any = {};
                            partNewRow.title = `Day${eachData.day}`;
                            if(eachData.type === "incorrect"){
                                partNewRow.title += " 오답";
                            }

                            partNewRow.completed = false;

                            data2rows.forEach((eachData2 : any) => {

                                if(eachData.day === eachData2.day && eachData.type === eachData2.type){
                                    partNewRow.completed = true;
                                    partNewRow.wordsData = eachData2;
                                }

                            })

                            newRow.data.push(partNewRow);

                        });

                        
                        rows.push(newRow);
                    })

                    
                    setTestData([...rows]);

                }else{
                    setTestData([]);
                    setTestBook("");
                    setCreatedDate("");
                }
            })
        })

    }, [props.selectedUser, index, update2]);

    useEffect(() => {

        if(bookName){

            if(bookName === "영단어 시험 제외 학생" || !props.selectedUser){
                setDisabled(true);
            }else{
                setDisabled(false);
            }

        }

    }, [bookName, props.selectedUser]);

    useEffect(() => {

        if (!props.selectedUser) {
            
            return;
        }

        fetch(`https://peetsunbae.com/dashboard/words/userdefaultforspecific?studentId=${props.selectedUser.id}`, {
            method: "GET",
            credentials: "include"
        }).then((response: any) => {
            response.json()
                .then((result: any) => {

                    

                    if (result.message === "success") {

                        var kind = result.kind;


                        switch (kind) {
                            case 1:
                                setBookName("워드마스터 수능 2000 (2018년 버전)");
                                setRadioValue("워드마스터 수능 2000 (2018년 버전)");
                                break;
                            case 2:
                                setBookName("워드마스터 수능 2000 (2022년 버전)");
                                setRadioValue("워드마스터 수능 2000 (2022년 버전)");
                                break;
                            case 3:
                                setBookName("워드마스터 하이퍼 2000 (2021년 버전)");
                                setRadioValue("워드마스터 하이퍼 2000 (2021년 버전)");
                                break;
                            case 4:
                                setBookName("영단어 시험 제외 학생");
                                setRadioValue("영단어 시험 제외");
                                break;
                            case 5:
                                setBookName("워드마스터 EBS 파이널 1200(2020년 버전)");
                                setRadioValue("워드마스터 EBS 파이널 1200(2020년 버전)");
                                break;
                        }
                    }


                })
        })

    }, [props.selectedUser, update]);

    useEffect(() => {

        if(!bookName){
            
            return;
        }

        var kind = 0;

        switch (bookName) {
            case "워드마스터 수능 2000 (2018년 버전)" :
                kind = 1;
                break;
            case "워드마스터 수능 2000 (2022년 버전)" :
                kind = 2;
                break;
            case "워드마스터 하이퍼 2000 (2021년 버전)" :
                kind = 3;
                break;
            case "영단어 시험 제외 학생" :
                kind = 4;
                break;
            case "워드마스터 EBS 파이널 1200(2020년 버전)" :
                kind = 5;
                break;
        }

        

        fetch(`https://peetsunbae.com/dashboard/words/howmanydays?kind=${kind}`, {
            method : "get",
            credentials : "include"
        }).then((response : any) => {
            response.json()
            .then((result : any) => {
                
                
                if(result.message === "success"){

                    const newData : any = [];
                    
                    result.data.forEach((eachData : any, index : number) => {

                        const oneRowOdd : any = {};
                        const oneRowEven : any = {};

                        oneRowOdd.id = ((index + 1) * 2) - 1;
                        oneRowEven.id = ((index + 1) * 2);

                        oneRowOdd.key = ((index + 1) * 2) - 1;
                        oneRowEven.key = ((index + 1) * 2);

                        oneRowOdd.day = eachData.day;
                        oneRowEven.day = eachData.day;

                        oneRowOdd.type = "total";
                        oneRowEven.type = "incorrect";

                        oneRowOdd.label = `day${eachData.day < 10 ? "0" + eachData.day : eachData.day}`;
                        oneRowEven.label = `day${eachData.day < 10 ? "0" + eachData.day : eachData.day} 오답`;

                        oneRowOdd.title = `Day${eachData.day} 전체`;
                        oneRowEven.title = `Day${eachData.day} 오답`;

                        newData.push(oneRowOdd);
                        newData.push(oneRowEven);
                    })

                    setData([...newData]);

                }

            })
        })
        
    }, [bookName]);

    const goDefaultRadio = () => {

        if(!bookName){
            return;
        }

        if(bookName !== "영단어 시험 제외 학생"){
            const newBookName = bookName;

            setRadioValue(newBookName);
        }

        if(bookName === "영단어 시험 제외 학생"){

            setRadioValue("영단어 시험 제외");

        }

    }


    const onchange = (e: any, value: any, index : number) => {

        const newValues = values;
        newValues[index] = value;

        setValues([...newValues]);

        

        setTimeout(() => {

            autocompleteRef.current[index].focus();

        }, 10);


    }

    const save = () => {

        if(!props.selectedUser || !props.selectedUser.id){
            alert("학생을 선택해주세요");
            return;
        }

        

        var kind = 0;

        switch (radioValue) {
            case "워드마스터 수능 2000 (2018년 버전)" :
                kind = 1;
                break;
            case "워드마스터 수능 2000 (2022년 버전)" :
                kind = 2;
                break;
            case "워드마스터 하이퍼 2000 (2021년 버전)" :
                kind = 3;
                break;
            case "영단어 시험 제외" :
                kind = 4;
                break;
            case "워드마스터 EBS 파이널 1200(2020년 버전)" :
                kind = 5;
                break;
        }

        if(!kind){
            alert("책을 선택해주세요");
            return;
        }

        

        fetch("https://peetsunbae.com/dashboard/words/studentnewbook", {
            method : "post",
            credentials : "include",
            headers : {
                "Content-Type" : "application/json"
            },
            body : JSON.stringify({
                kind,
                studentId : props.selectedUser.id
            })
        }).then((response : any) => {
            response.json()
            .then((result : any) => {
                if(result.message === "success"){
                    notify1();
                    setUpdate(Math.random());
                    setOpen(false);
                }
            })
        })

    }

    const submit = () => {

        if(!props.selectedUser){
            alert("학생을 선택 후에 저장해주세요");
            return;
        }

        if(!bookName){
            
            return;
        }

        var kind = 0;

        switch (bookName) {
            case "워드마스터 수능 2000 (2018년 버전)" :
                kind = 1;
                break;
            case "워드마스터 수능 2000 (2022년 버전)" :
                kind = 2;
                break;
            case "워드마스터 하이퍼 2000 (2021년 버전)" :
                kind = 3;
                break;
            case "영단어 시험 제외 학생" :
                kind = 4;
                break;
            case "워드마스터 EBS 파이널 1200(2020년 버전)" :
                kind = 5;
                break;
        }

        if(!kind){
            alert("책을 먼저 선택해주세요");
        }

        if(kind === 4){
            alert("영단어 미응시자입니다.");
        }

        

        

        const data = [];

        for (var i = 0; i < dayCount; i++){

            const newDate = new Date(today.getTime());
            newDate.setDate(newDate.getDate() + i);

            const year = newDate.getFullYear();
            const month = newDate.getMonth();
            const date = newDate.getDate();

            const oneRow : any = {};

            oneRow.year = year;
            oneRow.month = month;
            oneRow.date = date;
            oneRow.data = values[i];

            data.push(oneRow);
        }

        var chatString = "[영단어 시험 과제 안내]\n\n"
        var string = "";

        data.forEach((each : any) => {

            const date = new Date(each.year, each.month, each.date);
            
            var day = ""

            switch (date.getDay()) {
                case 0:
                    day = "일"
                    break;
                case 1:
                    day = "월"
                    break;
                case 2:
                    day = "화"
                    break;
                case 3:
                    day = "수"
                    break;
                case 4:
                    day = "목"
                    break;
                case 5:
                    day = "금"
                    break;
                case 6:
                    day = "토"
                    break;
            }

            var oneRowString = `${day}(${each.month + 1}/${each.date}) : `

            each.data.forEach((eachData : any, index : number) => {
                oneRowString += `${eachData.title}`;

                if(index !== each.data.length - 1){
                    oneRowString += `, `
                }

            })

            oneRowString += "\n"

            string += oneRowString;
        })

        chatString += string;
        

        sendMessage(string, chatString);

        

        fetch("https://peetsunbae.com/dashboard/words/makewordtest", {
            method : "post",
            credentials : "include",
            headers : {
                "Content-Type" : "application/json"
            },
            body : JSON.stringify({
                kind,
                data,
                userId : props.selectedUser.id
            })
        }).then((response : any) => {
            response.json()
            .then((result : any) => {
                
                if(result.message === "success"){
                    notify1();
                    setValues([[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
                    setUpdate2(Math.random());
                }
            })
        })

    }

    const sendMessage = (string : string, chatString : string) => {

        if(!string || !chatString){
            
            return;
        }

        if(!props.selectedUser){
            
            return;
        }

        fetch("https://peetsunbae.com/dashboard/words/sendMessage", {
            method : "post",
            headers : {
                "content-type" : "application/json"
            },
            credentials : "include",
            body : JSON.stringify({
                string, chatString, studentId : props.selectedUser.id
            })
        }).then((response : any) => {
            response.json()
            .then((result : any) => {
                
                if(result.message === "success"){

                    if(!result.roomNumber){
                        
                        return;
                    }

                    const chatRoomId = result.roomNumber;

                    props.socket.emit("newTeacherMessage", chatRoomId);
                }
            })
        })

    }

    const handleChipClick = (data : any) => {

        if(!data){
            return;
        }

        setOpen2(true);

        
        setDayTestData([...data.wordsData.words]);
        setSelectedTitle(data.title);

    }

    const handleIndexChange = (kind : string) => {

        if(kind === "plus"){
            const newIndex = index + 1;
            setIndex(newIndex);
        }

        if(kind === "minus"){
            if(index <= 1){
                return;
            }

            const newIndex = index - 1;
            setIndex(newIndex);
        }

    }
 

    return (
        <div>
            <div style={{ width: "1150px", marginBottom: "45px" }}>
                <CssVarsProvider>
                    <Divider>

                    </Divider>
                </CssVarsProvider>
            </div>

            <div className={styles.titleBox}>
                <div className={`${styles.titleText}`}>
                    영단어 시험 관리 <span className={`${!props.selectedUser ? styles.red : ""}`}>({props.selectedUser ? props.selectedUser.label + " 학생" : "학생을 먼저 선택해주세요"})</span>
                </div>
                {
                    props.selectedUser &&
                    <div className={styles.titleText2}>
                        현재 공부중인 영단어 책 - {bookName} &nbsp; <CssVarsProvider><Button variant="soft" size="sm" color="neutral" onClick={() => { setOpen(true); }}>책 변경하기</Button></CssVarsProvider>
                    </div>
                }
            </div>


            <div className={styles.main}>
                <div className={styles.subMain}>

                    <div className={styles.title}>
                        {new Date().getMonth() + 1}월 {new Date().getDate()}일 영단어 시험 출제 &nbsp;&nbsp;&nbsp;
                        <Input
                            endDecorator="일 분량"
                            type="number"
                            value={dayCount}
                            onChange={(e) => {setDayCount(+e.target.value);}}
                            sx={{
                                "--Input-minHeight": "32px"
                              }}
                            slotProps={{
                                input: {
                                    ref: inputRef,
                                    min: 7,
                                    max: 50,
                                    step: 1,
                                },
                            }}
                        />
                    </div>

                    <div className={styles.eachBox}>
                        {
                            new Array(dayCount).fill(0).map((each : any, index : number) => {
                                const date = new Date();
                                date.setDate(date.getDate() + index);

                                var day = ""

                                switch (date.getDay()) {
                                    case 0:
                                        day = "일"
                                        break;
                                    case 1:
                                        day = "월"
                                        break;
                                    case 2:
                                        day = "화"
                                        break;
                                    case 3:
                                        day = "수"
                                        break;
                                    case 4:
                                        day = "목"
                                        break;
                                    case 5:
                                        day = "금"
                                        break;
                                    case 6:
                                        day = "토"
                                        break;
                                }

                                return (
                                    <div className={styles.oneBox} key={date.getTime()}>
                                        <div className={styles.questionTitle}>
                                            {day} <span className={styles.date}>({date.getMonth() + 1}/{date.getDate()})</span>
                                        </div>
                                        <Autocomplete
                                            multiple={true}
                                            onChange={(e, value) => {onchange(e, value, index)}}
                                            value={values[index]}
                                            disablePortal
                                            id="combo-box-demo"
                                            options={data}
                                            sx={{ width: "100%" }}
                                            renderInput={(params) => <TextField inputRef={(el) => { autocompleteRef.current[index] = el; }} sx={{ width: 480, backgroundColor: "white"}}   {...params} />}
                                            fullWidth
                                        />
                                    </div>
                                );

                            })
                        }
                    </div>



                    <div className={styles.printDiv} style={{ marginBottom: "4px", display: "flex", justifyContent: "flex-end", width: "528px", marginTop: "24px" }}>
                        <CssVarsProvider>
                            <Button onClick={submit} size='lg' endDecorator={<KeyboardArrowRight />} color="primary" disabled={disabled}>
                                출제하기
                            </Button>
                        </CssVarsProvider>
                    </div>

                </div>


                <div className={styles.subMain}>
                    <div className={styles.titleWrapper}>
                        <div className={styles.title5}>
                            최근 출제 내역{createdDate && ` (${createdDate})`}
                        </div>
                        {
                            props.selectedUser 
                            &&
                            <div>
                                <CssVarsProvider>
                                        <Button onClick={(e) => {handleIndexChange("plus")}} size="sm" aria-label="Like" variant="outlined" color="neutral" sx={{marginRight : "8px", width : "32px"}}>
                                            <ChevronLeft />
                                        </Button>
                                        <Button onClick={(e) => {handleIndexChange("minus")}} size="sm" aria-label="Like" variant="outlined" color="neutral" sx={{width : "32px"}}>
                                            <ChevronRight />
                                        </Button>
                                </CssVarsProvider>
                            </div>
                        }
                    </div>

                    {
                        testBook
                        &&
                        <div className={styles.title6}>
                            {testBook}
                        </div>
                    }

                    <div className={styles.eachBox}>
                        {/* <div className={styles.oneBox}>
                            <div className={styles.questionTitle}>
                                월
                            </div>
                            <div>
                                <CssVarsProvider>
                                    <Chip variant="soft" startDecorator={<WbSunny />} sx={{ marginRight: "8px" }}>
                                        Day1 (30/40)
                                    </Chip>
                                    <Chip variant="soft" startDecorator={<Cloud />} color="danger">
                                        Day 2
                                    </Chip>
                                </CssVarsProvider>
                            </div>
                        </div> */}
                        {
                                testData &&
                                testData.map((each : any, index : number) => {

                                    return (
                                        <div key={Math.random()} className={styles.oneBox}>
                                            <div className={styles.questionTitle}>
                                                {each.day} <div className={styles.date}>({each.month}/{each.date})</div>
                                            </div>
                                            <div>
                                                <CssVarsProvider>
                                                    {
                                                        each.data.map((eachData: any, indexIndex: number) => {
                                                            return (
                                                                <div style={{display : "inline-block", paddingTop : "3px", paddingBottom : "3px"}}>
                                                                    <Chip disabled={eachData.completed ? false : true} onClick={(e: any) => { handleChipClick(eachData); }} key={Math.random()} variant="soft" startDecorator={eachData.completed ? <WbSunny /> : <Cloud />} color={eachData.completed ? "primary" : "danger"} sx={{ marginRight: indexIndex === each.data.length - 1 ? "0px" : "8px" }}>
                                                                        {eachData.title} {eachData.completed && `(${eachData.wordsData.count}/${eachData.wordsData.totalCount})`}
                                                                    </Chip>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </CssVarsProvider>
                                            </div>
                                        </div>
                                    );
                                    

                                })
                            }
                    </div>
                </div>
            </div>


            <div style={{ width: "1150px", marginTop: "45px" }}>
                <CssVarsProvider>
                    <Divider>

                    </Divider>
                </CssVarsProvider>
            </div>

            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={open}
                onClose={() => {setOpen(false); goDefaultRadio(); }}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 800,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose
                        variant="outlined"
                        sx={{
                            top: 'calc(-1/4 * var(--IconButton-size))',
                            right: 'calc(-1/4 * var(--IconButton-size))',
                            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                            borderRadius: '50%',
                            bgcolor: 'background.body',
                        }}
                    />
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        fontFamily="Apple_SB"
                        mb={2}
                    >
                        영단어 책 변경하기
                    </Typography>

                    <div className={styles.modalRadio}>
                        <RadioGroup aria-label="Your plan" name="people" value={radioValue} onChange={(e : any) => { setRadioValue(e.target.value) }}>
                            <List
                                sx={{
                                    minWidth: 450,
                                    '--List-gap': '0.5rem',
                                    '--ListItem-paddingY': '1rem',
                                    '--ListItem-radius': '8px',
                                    '--ListItemDecorator-size': '32px',
                                }}
                            >
                                {['워드마스터 수능 2000 (2018년 버전)', '워드마스터 수능 2000 (2022년 버전)', '워드마스터 하이퍼 2000 (2021년 버전)', '워드마스터 EBS 파이널 1200(2020년 버전)', '영단어 시험 제외'].map((item, index) => (
                                    <ListItem
                                        variant="outlined"
                                        key={item}
                                        sx={{ boxShadow: 'sm', bgcolor: 'background.body' }}
                                    >
                                        <ListItemDecorator>
                                            {[<TurnedIn />, <TurnedIn />, <TurnedIn />, <TurnedIn />][index]}
                                        </ListItemDecorator>
                                        <Radio
                                            overlay
                                            value={item}
                                            label={item}
                                            sx={{ flexGrow: 1, flexDirection: 'row-reverse' }}
                                            slotProps={{
                                                action: ({ checked }) => ({
                                                    sx: (theme) => ({
                                                        ...(checked && {
                                                            inset: -1,
                                                            border: '2px solid',
                                                            borderColor: theme.vars.palette.primary[500],
                                                        }),
                                                    }),
                                                }),
                                            }}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </RadioGroup>
                    </div>

                    <div style={{display : "flex", justifyContent : "flex-end", marginTop : "24px"}}>
                        <Button size="lg" variant="soft" onClick={save}>
                            저장하기
                        </Button>
                    </div>

                </Sheet>
            </Modal>

            <Modal
                open={open2}
                onClose={() => { setOpen2(false); }}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 1200,
                        maxHeight : 800,
                        overflowY : "auto",
                        overflowX : "hidden",
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <div>
                        <EachdayTestForChart selectedTitle={selectedTitle} open={open2} data={dayTestData} />  
                    </div>
                </Sheet>
            </Modal>



        </div>
    );
}

export default MakeWordTest;