import { CircularProgress, FormControl, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import styles from "../../componentsStyle/profileStyles.module.css";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import { display } from '@mui/system';
import Button from '@mui/material/Button';
import { Subject } from '@mui/icons-material';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import koLocale from 'date-fns/locale/ko'
import { DesktopDatePicker } from '@mui/x-date-pickers-pro';


const ProfileSecond3: React.FC<any> = (props: any) => {
    const [loading, setLoading] = useState(false);

    const [currentMenu, setCurrentMenu] = useState("student");

    const [studentTelephone, setStudentTelephone] = useState("");
    const [parentTelephone, setParentTelephone] = useState("");
    const [regularSchedule, setRegularSchedule] = useState("");

    const [howToKnow, setHowToKnow] = useState([false, false, false, false, false, false, false]);
    const [coachingSubject, setCoachingSubject] = useState([false, false, false, false, false]);

    const [majorReason, setMajorReason] = useState<any>(10);
    const [isCoaching, setIsCoaching] = useState<any>(10);

    const [value, setValue] = React.useState<any>(
        new Date()
    );

    const handleChange = (newValue: any) => {
        setValue(newValue);
    };


    const majorReasonChange = (e: any) => {
        setMajorReason(e.target.value);
    }

    const isCoachingChange = (e : any) => {
        setIsCoaching(e.target.value);
    }

    const howToKnowChange = (e: any, number: number) => {
        
        
        const newHowToKnow = howToKnow
        newHowToKnow[number] = e.target.checked;
        setHowToKnow([...newHowToKnow]);
    }

    const coachingSubjectChange = (e : any, number : number) => {
        
        const newCoachingSubject = coachingSubject;
        newCoachingSubject[number] = e.target.checked;
        setCoachingSubject([...newCoachingSubject]);
    }



    useEffect(() => {
        const start = async () => {
            var token = "";
            if (window.electron) {
                token = await window.electron.sendMessageApi.getToken();
            }


            var isSelectedUser = "no";
            var selectedUser = props.selectedUser;

            


            if(selectedUser){
                isSelectedUser = "yes"
            }



            fetch(`https://peetsunbae.com/dashboard/profile/secondthree?isSelectedUser=${isSelectedUser}&selectedUser=${selectedUser ? selectedUser.id : ""}`, {
                method: "GET",
                headers: { "Authorization": token },
                credentials: "include",
            }).then((response: any) => {
                response.json()
                    .then(async (result: any) => {
                        if (result.data) {
                            const data = result.data.message;
                            

                            // setKoreanGrade(data.koreanGrade);
                            // setMathGrade(data.mathGrade);
                            // setEnglishGrade(data.englishGrade);
                            // setScienceFirst(data.scienceFirst);
                            // setScienceSecond(data.scienceSecond);
                            // setMeetingWant(data.meetingWant);

                            setStudentTelephone(data.studentTelephone);
                            setParentTelephone(data.parentTelephone);
                            setRegularSchedule(data.regularSchedule);
                            setHowToKnow(data.howToKnow);
                            setMajorReason(data.majorReason);
                            setValue(data.value);
                            setIsCoaching(data.isCoaching);
                            setCoachingSubject(data.coachingSubject);

                        }
                    })
            })
        }

        start();
    }, []);

    const change = (e: any, kind: string) => {

        if(props.selectedUser){
            return;
        }

        switch (kind) {
            case "studentTelephone":
                setStudentTelephone(e.target.value);
                break;
            case "parentTelephone":
                setParentTelephone(e.target.value);
                break;
            case "regularSchedule":
                setRegularSchedule(e.target.value);
                break;
        }
    }



    const submit = async (e: any) => {

        if (props.selectedUser) {
            props.changeProfilePage("third");
            return;
        } else {
            setLoading(true);

            const message = {
                studentTelephone, parentTelephone, regularSchedule, howToKnow, majorReason, value, isCoaching, coachingSubject
            }

            

            fetch("https://peetsunbae.com/dashboard/profile/secondthree", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                credentials: "include",
                body: JSON.stringify({
                    message
                })
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        setLoading(false);
                        
                        if (result.message === "success") {
                            props.changeProfilePage("third");
                        }
                    })
            })

        }
    }


    return (
        <div className={styles.body}>
            <div className={styles.header}>
                프로필 설정
            </div>
            <div className={styles.currentMenu}>
                <div className={`${styles.circle} ${currentMenu === "information" ? styles.active : ""}`}>
                    학습정보
                </div>
                <img src="img/chevron-right-profile.svg" />
                <div className={`${styles.circle} ${currentMenu === "student" ? styles.active : ""}`}>
                    학생정보
                </div>
                <img src="img/chevron-right-profile.svg" />
                <div className={`${styles.circle}`}>
                    확인사항
                </div>
            </div>
            <div className={styles.description}>
            </div>

            <div className={styles.profileBoard}>
                <div className={styles.profileBoardDescription}>
                    <div className={styles.descriptionText}>
                        *학생카드의 모든 항목은 담임 멘토와의 상담 시 참고될 항목들입니다.<br>
                        </br>
                        빠짐 없이 작성해주시면 감사하겠습니다.
                    </div>

                    <div className={styles.beforeUniversity}>
                        <div className={styles.beforeUniversityTitle}>
                            학생 정보
                        </div>

                        <div className={styles.fullWidthText}>
                            <div className={styles.subtitle}>
                                학생 연락처
                            </div>
                            <div className={styles.textField}>
                                <TextField value={studentTelephone} onChange={e => { change(e, "studentTelephone") }} fullWidth id="outlined-basic" placeholder="ex) 010-0000-0000" variant="outlined" />
                            </div>
                        </div>
                        <div className={styles.fullWidthText}>
                            <div className={styles.subtitle}>
                                학부모 연락처
                            </div>
                            <div className={styles.textField}>
                                <TextField value={parentTelephone} onChange={e => { change(e, "parentTelephone") }} fullWidth id="outlined-basic" placeholder="ex) 010-0000-0000" variant="outlined" />
                            </div>
                        </div>
                        {/* <div className={styles.fullWidthText}>
                            <div className={styles.subtitle}>
                                개인 ㅇㅇ정기 일정 (첫 등록일 및 매월 개강일에 정기일정 등록가능, 학부모 확인 후 적용, 벌점 미적용)<br></br>
                                (ex) 매주 월-수 9:00-16:00 학교수업 / 매주 금 19:00-20:00 운동
                            </div>
                            <div className={styles.textField}>
                                <TextField multiline rows={4} value={regularSchedule} onChange={e => { change(e, "regularSchedule") }} fullWidth id="outlined-basic" placeholder="" variant="outlined" />
                            </div>
                        </div> */}

                        <div className={styles.fullWidthText}>
                            <div className={styles.subtitle}>
                                수능선배를 알게 된 경로 (복수선택 가능)
                            </div>
                            <div className={styles.textField}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={howToKnow[0]} onChange={(e: any) => { howToKnowChange(e, 0); }} />} label={<span className={styles.justFont}>네이버 검색</span>} />
                                    <FormControlLabel control={<Checkbox checked={howToKnow[1]} onChange={(e: any) => { howToKnowChange(e, 1); }} />} label={<span className={styles.justFont}>유튜브</span>} />
                                    <FormControlLabel control={<Checkbox checked={howToKnow[2]} onChange={(e: any) => { howToKnowChange(e, 2); }} />} label={<span className={styles.justFont}>다음 카페/네이버 카페</span>} />
                                    <FormControlLabel control={<Checkbox checked={howToKnow[3]} onChange={(e: any) => { howToKnowChange(e, 3); }} />} label={<span className={styles.justFont}>SNS (인스타그램, 블로그 등)</span>} />
                                    <FormControlLabel control={<Checkbox checked={howToKnow[4]} onChange={(e: any) => { howToKnowChange(e, 4); }} />} label={<span className={styles.justFont}>지인 소개</span>} />
                                    <FormControlLabel control={<Checkbox checked={howToKnow[5]} onChange={(e: any) => { howToKnowChange(e, 5); }} />} label={<span className={styles.justFont}>타 학원을 통해</span>} />
                                    <FormControlLabel control={<Checkbox checked={howToKnow[6]} onChange={(e: any) => { howToKnowChange(e, 6); }} />} label={<span className={styles.justFont}>기타</span>} />
                                </FormGroup>
                            </div>
                        </div>

                        <div className={styles.fullWidthText}>
                            <div className={styles.subtitle}>
                                수능선배를 선택하게 된 가장 큰 이유는 무엇인가요?<br></br>
                            </div>
                            <div className={styles.textField}>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={majorReason}
                                        onChange={majorReasonChange}
                                    >
                                        <FormControlLabel value={0} control={<Radio />} label={<span className={styles.justFont}>1:1 담임 멘토링</span>} />
                                        <FormControlLabel value={1} control={<Radio />} label={<span className={styles.justFont}>생활관리</span>} />
                                        <FormControlLabel value={2} control={<Radio />} label={<span className={styles.justFont}>질의응답</span>} />
                                        <FormControlLabel value={3} control={<Radio />} label={<span className={styles.justFont}>같이 공부하는 수험생들과의 경쟁</span>} />
                                        <FormControlLabel value={3} control={<Radio />} label={<span className={styles.justFont}>쾌적한 시설</span>} />
                                        <FormControlLabel value={3} control={<Radio />} label={<span className={styles.justFont}>일대일 과외 시스템</span>} />
                                        <FormControlLabel value={3} control={<Radio />} label={<span className={styles.justFont}>기타</span>} />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>

                        <div className={styles.heightBox}>

                        </div>

                        <div className={styles.beforeUniversityTitle}>
                            등록 신청
                        </div>

                        <div className={styles.fullWidthText}>
                            <div className={styles.subtitle}>
                                수능선배 등원 시작일
                            </div>
                            <div className={styles.textField}>
                                <LocalizationProvider locale={koLocale} dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        value={value}
                                        onChange={handleChange}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>


                        <div className={styles.fullWidthText}>
                            <div className={styles.subtitle}>
                                과외수업<br></br>
                            </div>
                            <div className={styles.textField}>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={isCoaching}
                                        onChange={isCoachingChange}
                                    >
                                        <FormControlLabel value={0} control={<Radio />} label={<span className={styles.justFont}>신청</span>} />
                                        <FormControlLabel value={1} control={<Radio />} label={<span className={styles.justFont}>미신청</span>} />
                                        <FormControlLabel value={2} control={<Radio />} label={<span className={styles.justFont}>상담 후 결정</span>} />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>


                        <div className={styles.fullWidthText}>
                            <div className={styles.subtitle}>
                                (과외 수업 신청자만 선택) 과외 신청 과목
                            </div>
                            <div className={styles.textField}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={coachingSubject[0]} onChange={(e: any) => { coachingSubjectChange(e, 0); }} />} label={<span className={styles.justFont}>국어</span>} />
                                    <FormControlLabel control={<Checkbox checked={coachingSubject[1]} onChange={(e: any) => { coachingSubjectChange(e, 1); }} />} label={<span className={styles.justFont}>영어</span>} />
                                    <FormControlLabel control={<Checkbox checked={coachingSubject[2]} onChange={(e: any) => { coachingSubjectChange(e, 2); }} />} label={<span className={styles.justFont}>수학</span>} />
                                    <FormControlLabel control={<Checkbox checked={coachingSubject[3]} onChange={(e: any) => { coachingSubjectChange(e, 3); }} />} label={<span className={styles.justFont}>사탐</span>} />
                                    <FormControlLabel control={<Checkbox checked={coachingSubject[4]} onChange={(e: any) => { coachingSubjectChange(e, 4); }} />} label={<span className={styles.justFont}>과탐</span>} />
                                </FormGroup>
                            </div>
                        </div>


                    </div>


                    <div className={styles.beforeUniversity}>
                        <div onClick={submit} className={styles.nextBtn}>
                            {loading ? <CircularProgress style={{ color: "white" }} /> : <div>
                                {
                                    props.selectedUser ?
                                    "다음"
                                    :
                                    "저장 후 다음"
                                }
                                </div>}
                        </div>
                    </div>
                </div>


            </div>


        </div>
    )
}

export default ProfileSecond3;