import React, { useEffect, useState } from 'react';
import { GridRenderCellParams, DataGridPro, GridRowsProp, GridColDef, GridToolbar, useGridApiRef, GridFilterModel, DataGridPremium, GridRowModel } from '@mui/x-data-grid-premium';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { createStyles, makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
import { getFetch, postFetch } from '../../../../fetch/fetch';
import { Button } from '@mui/joy';



interface GridCellExpandProps {
    value: string;
    width: number;
}

function isOverflown(element: Element): boolean {
    return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
    );
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            alignItems: 'center',
            lineHeight: '24px',
            width: '100%',
            height: '100%',
            position: 'relative',
            display: 'flex',
            '& .cellValue': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },
    }),
);

const defaultTheme = createTheme();
const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

        return {
            root: {
                '& .super-app-theme--처리완료': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .super-app-theme--확인': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .super-app-theme--미확인': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .completed': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                },
                '& .notCompleted': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                },
                '& .notApplied': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                },
                '& .notPermit': {
                    color: "rgb(205, 205, 198)",
                },
            },
        };
    },
    { defaultTheme },
);

const GridCellExpand = React.memo(function GridCellExpand(
    props: GridCellExpandProps,
) {
    const { width, value } = props;
    const wrapper = React.useRef<HTMLDivElement | null>(null);
    const cellDiv = React.useRef(null);
    const cellValue = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const classes = useStyles();
    const [showFullCell, setShowFullCell] = React.useState(false);
    const [showPopper, setShowPopper] = React.useState(false);


    const handleMouseEnter = () => {
        const isCurrentlyOverflown = isOverflown(cellValue.current!);
        setShowPopper(isCurrentlyOverflown);
        setAnchorEl(cellDiv.current);
        setShowFullCell(true);
    };

    const handleMouseLeave = () => {
        setShowFullCell(false);
    };

    React.useEffect(() => {
        if (!showFullCell) {
            return undefined;
        }

        function handleKeyDown(nativeEvent: KeyboardEvent) {
            // IE11, Edge (prior to using Bink?) use 'Esc'
            if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
                setShowFullCell(false);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [setShowFullCell, showFullCell]);

    return (
        <div
            ref={wrapper}
            className={classes.root}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div
                ref={cellDiv}
                style={{
                    height: 1,
                    width,
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                }}
            />
            <div ref={cellValue} className="cellValue">
                {value}
            </div>
            {showPopper && (
                <Popper
                    open={showFullCell && anchorEl !== null}
                    anchorEl={anchorEl}
                    style={{ width, marginLeft: -17 }}
                >
                    <Paper
                        elevation={1}
                        style={{ minHeight: wrapper.current!.offsetHeight - 3 }}
                    >
                        <Typography variant="body2" style={{ padding: 8 }}>
                            {value}
                        </Typography>
                    </Paper>
                </Popper>
            )}
        </div>
    );
});

function renderCellExpand(params: GridRenderCellParams<any>) {
    return (
        <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    );
}

const MyCheckList: React.FC<any> = (props) => {

    const [rows, setRows] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const columns: GridColDef[] = [
        { field: "date", headerName: "날짜", width: 100 },
        { field: "time", headerName: "시간", width: 100 },
        { field: "status", headerName: "완료여부", width: 100 },
        { field: "completeTime", headerName: "완료시간", width: 150 },
        { field: "studentMessage", headerName: "전달사항", width: 150, renderCell: renderCellExpand },
        { field : "cancel", headerName : "취소하기", width : 150, renderCell : (params) => {

            const deleteEnrolledOfflineQuestion = async (enrolledId: number, targetDateTime: Date) => {

                try {
        
                    const targetDate = new Date(targetDateTime);
        
                    //오늘이 아니면 삭제 불가능
                    if (today.getFullYear() !== targetDate.getFullYear() || today.getMonth() !== targetDate.getMonth() || today.getDate() !== targetDate.getDate()) {
                        
                        return;
                    }
        
                    setLoading(true);
        
                    const result : any = await postFetch("/dashboard/checklist/deleteenrollchecklist", { enrolledId });
        
                    setLoading(false);
        
                    if(result.message === "success"){
                        start();
                    }
        
        
                } catch (e) {
                    
                }
        
        
            }

            
            


            var isToday = false;

            const targetDate = new Date(params.row.targetDate);
            const enrolledId = params.row.enrolledId;
            const today = new Date();

            if(targetDate.getFullYear() === today.getFullYear() && targetDate.getMonth() === today.getMonth() && targetDate.getDate() === today.getDate()){
                isToday = true;
            }

            var isCompleted = false;

            if(params.row.status === "완료"){
                isCompleted = true;
            }

            if(isToday && !isCompleted){

                return (
                    <div>
                        <Button size="sm" color="neutral" onClick={async () => {
                            deleteEnrolledOfflineQuestion(enrolledId, targetDate);
                        } }>취소하기</Button>
                    </div>
                )

            }else{

                return (
                    <div>
    
                    </div>
                )

            }

            

        } }
    ];
    const classes = useStyles2();



    useEffect(() => {
        start();
    }, []);

    const start = async () => {

        setLoading(true);
        const data = await getData();
        makeRows(data);
        setLoading(false);

    }


    const getData = async () => {


        try{

            const result : any = await getFetch("/dashboard/checklist/mychecklist");

            

            if(result.message === "success"){
                return result.data;
            }

        }catch(e){
            
        }

    }

    const makeRows = (data: any) => {

        if(!data){
            return;
        }

        const newRows : any = [];

        data.forEach((each : any) => {

            const oneRow : any = {};

            if(!each.enrolledId){
                return;
            }

            oneRow.id = each.id;

            oneRow.targetDate = each.targetDate;
            oneRow.enrolledId = each.enrolledId;

            if(each.completeTime){
                const completeTime = new Date(each.completeTime);
                const hours = completeTime.getHours();
                const minutes = completeTime.getMinutes();
                oneRow.completeTime = `${hours}시 ${minutes}분`;
            }

            const targetDate = new Date(each.targetDate);
            const targetDateString = `${targetDate.getMonth() + 1}월 ${targetDate.getDate()}일`;

            oneRow.date = targetDateString;

            const startDate = new Date(+each.enrolledId);
            const startHours = startDate.getHours();

            var time = `${startHours}시~`;

            const intervalTime = each.intervalTime;

            startDate.setMinutes(startDate.getMinutes() + intervalTime);
            const endHours = startDate.getHours();
            time += `${endHours}시`;

            oneRow.time = time;

            if (each.status === "notCompleted") {
                oneRow.status = "미완료";
                oneRow.statusNumber = 2;
            }

            if (each.status === "completed") {
                oneRow.status = "완료";
                oneRow.statusNumber = 3;
            }

            oneRow.studentMessage = each.studentMessage;

            newRows.push(oneRow);

        })

        setRows([...newRows]);

    }


    return (
        <div>
            <div style={{ height: 650, width: '1500px' }}>
                <div style={{ display: "flex", height: "100%" }}>
                    <div style={{ flexGrow: 1 }} className={classes.root}>
                        <DataGridPremium
                            rows={rows}
                            columns={columns}
                            loading={loading}
                            density="compact"
                            getCellClassName={(params) => {

                                if (params.value === "완료") {
                                    return "completed";
                                }

                                if (params.value === "미완료") {
                                    return "notCompleted";
                                }

                                if (params.value === "미신청") {
                                    return "notApplied";
                                }

                                return ""
                            }}
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MyCheckList;