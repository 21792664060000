import React, { useEffect, useState } from 'react'
import styles from '../../../componentsStyle/offline.module.css'
import { Autocomplete, Backdrop, Button, CircularProgress, Divider, ToggleButton, ToggleButtonGroup } from "@mui/material";


// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import StaticDatePicker from '@mui/lab/StaticDatePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';


import koLocale from 'date-fns/locale/ko'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

// import DatePicker from '@mui/lab/DatePicker';

import TextField from '@mui/material/TextField';

// import TimePicker from '@mui/lab/TimePicker';

import { AnySrvRecord, AnyTxtRecord } from 'dns';
import Alert from '@mui/material/Alert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';



import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { is } from 'date-fns/locale';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { set } from 'lodash';
import { getFetch } from '../../../../fetch/fetch';



const SubmitCheckList: React.FC<any> = (props) => {
    const [loading, setLoading] = useState(false);

    const [isNull, setIsNull] = useState(false);

    const [currentId, setCurrentId] = useState(0);

    const [selectedUploadTeacher, setSelectedUploadTeacher] = useState<any>();
    const [alignment, setAlignment] = React.useState('total');
    const [selectedMenu, setSelectedMenu] = useState("enroll");
    const [openUpload, setOpenUpload] = useState(false);
    const [teacherName, setTeacherName] = useState("");
    const [uploadTeacherDescription, setUploadTeacherDescription] = useState("");
    const [uploadFile, setUploadFile] = useState();
    const [uploadFileName, setUploadFileName] = useState();
    const [uploadLoading, setUploadLoading] = useState(false);
    const [uploadBool, setUploadBool] = useState(false);
    const [uploadSubject, setUploadSubject] = useState("");
    const [date, setDate] = useState<any>(new Date());
    const [uploadTelephoneNumber, setUploadTelephoneNumber] = useState();
    const [startTime, setStartTime] = useState<any>(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 14));
    const [endTime, setEndTime] = useState<any>(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 14));


    const [where, setWhere] = useState("gangnam");
    const [teacher, setTeacher] = useState("");
    const [location, setLocation] = useState("");
    const [dateValue, setDateValue] = useState(new Date());
    const [availableDate, setAvailableDate] = useState<any>();
    const [calendarLoading, setCalendarLoading] = useState(false);
    const [enrolled, setEnrolled] = useState<any>();
    const [enrollStatus, setEnrollStatus] = useState<any>("");
    const [enrollLoading, setEnrollLoading] = useState(false);
    const [afterEnroll, setAfterEnroll] = useState(false);

    const [updateModalOpen, setUpdateModalOpen] = useState(false);

    const [updateBool, setUpdateBool] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);

    const [descriptionLoading, setDescriptionLoading] = useState(false);

    const [questionValue, setQuestionValue] = useState(["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]);

    const [fileNames, setFileNames] = useState([[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
    const [files, setFiles] = useState([[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);

    const [targetQuestionId, setTargetQuestionId] = useState(0);
    const [isErrorArray, setIsErrorArray] = useState(new Array(200).fill(false));

    const [update, setUpdate] = useState(0);
    const [showLoading, setShowLoading] = useState(false);

    const [description, setDescription] = useState("");
    const [enrolledId, setEnrolledId] = useState("");

    const handleCloseSubject = () => {
        setOpenSubject(false);
    }

    const handleUpdateModalOpen = () => {
        setUpdateModalOpen(true);
    }

    const handleUpdateModalClose = () => {
        setUpdateModalOpen(false);
    }

    const [data, setData] = useState<any>([]);

    const [random, setRandom] = useState(1);

    const [teachersList, setTeachersList] = useState<any>([]);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: any, targetId: number) => {
        
        setTargetQuestionId(targetId);
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const [openSubject, setOpenSubject] = useState(false);
    const [subjectData, setSubjectData] = useState<any>([]);
    const [subjectUpdate, setSubjectUpdate] = useState(0);


    const styleCharge = {
        borderRadius: "6px",
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        bgcolor: 'background.paper',
    };

    const styleCharge2 = {
        borderRadius: "6px",
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        bgcolor: 'background.paper',
    };

    const styleUpdateModal = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }

    useEffect(() => {
        getMessage()
    }, []);

    const getMessage = async () => {

        try{

            

            setShowLoading(true);

            const result : any = await getFetch("/dashboard/checklist/studentwrite");

            setShowLoading(false);

            

            if(result.message === "success"){
                
                
                setDescription(result.description);
            }


        }catch(e){
            
            
        }

        
    }

    const voidFunction = () => {
    }

    const enrollQuestion = (e: any) => {

    }

    const descriptionSubmit = async () => {

        if (!enrolledId) {
            alert("시간을 선택하신 후 작성해주세요");
            return;
        }

        if (!description) {
            alert("내용을 입력해주세요");
            return;
        }

        setShowLoading(true);

        // const formData = new FormData;
        var message = { enrolledId: enrolledId, description: description };
        // formData.append("message", JSON.stringify(message));

        // images.forEach((eachImage: any) => {
        //     formData.append('question_picture', eachImage);
        // });

        var token: any = "";

        if (window.electron) {
            token = window.electron.sendMessageApi.getToken();
        }

        fetch("https://peetsunbae.com/dashboard/checklist/studentwrite", {
            method: "POST",
            headers: {
                "Authorization": token,
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify(message),
        }).then((response) => {
            response.json()
                .then((result) => {
                    if (result.message === "success") {
                        setShowLoading(false);
                        alert("성공");
                    } else {
                        setShowLoading(false);
                        alert("전송 실패");
                    }
                })
        }).catch((error) => {
            
        })

    }


    //data 설정하는 기능
    useEffect(() => {

        if (!props.user) {
            return;
        }

        start();

    }, [update, props.user]);

    const start = async () => {

        const data = await getData();
        makeFirstData(data);

    }

    const getData = async () => {

        try {

            if (update === 0) {
                setShowLoading(true);
            }

            var token: any = "";

            const targetDate = new Date();
            const time = targetDate.getTime();

            const response = await fetch("https://peetsunbae.com/dashboard/checklist/enrollchecklist?time=" + time, {
                method: "GET",
                headers: { "Authorization": token },
                credentials: "include"
            });

            const result = await response.json();

            if (result.message === "success") {
                setShowLoading(false);

                return result.data;
            }


        } catch (e) {
            
        }

    }

    const makeFirstData = async (occupiedData: any) => {

        const interval = 60;
        const due = 50;
        const startTime = 480;
        const endTime = 1399;
        const max = 24;

        const currentDate = new Date();
        const currentTime = currentDate.getHours() * 60 + currentDate.getMinutes();

        const data = [{
            teacherName: "검사 조교",
            dateString: `오늘(${currentDate.getMonth() + 1}월 ${currentDate.getDate()}일)`,
            message: "하원 전 검사 필수",
            subMessage: "(시간별 최대 신청자수 제한)",
            availableTimes: []
        }];

        var isBeforeStartTimeDue = false;

        if (startTime + due >= currentTime) {
            isBeforeStartTimeDue = true;
        }

        const currentHour = currentDate.getHours();
        const currentMinues = currentDate.getMinutes();

        var currentStartTime = currentHour * 60;

        if (currentMinues > due) {
            currentStartTime += interval;
        }

        if (isBeforeStartTimeDue) {
            currentStartTime = startTime;
        }

        const availableTimes: any = [];

        while (currentStartTime < endTime) {

            const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), Math.floor(currentStartTime / 60), currentStartTime % 60);
            const id = newDate.getTime();

            availableTimes.push(
                {
                    id,
                    time: currentStartTime,
                    interval,
                    max,
                    number: 0,
                    isMax: false,
                    isMine: false
                }
            );
            currentStartTime += interval;
        }

        availableTimes.forEach((one: any) => {

            if(!occupiedData){
                return;
            }

            occupiedData.forEach((occupied: any) => {
                if (!occupied.enrolledId) {
                    return;
                }

                if (one.id === +occupied.enrolledId) {
                    one.number++;

                    if (props.user.id === occupied.userId) {
                        one.isMine = true;
                        one.status = occupied.status;
                        setEnrolledId(occupied.enrolledId);
                    }
                }


            });

            if (one.number >= max) {
                one.isMax = true;
            }
        });

        

        data[0].availableTimes = availableTimes;

        setData(data);

    }

    const enrollCheckList = async (id: number, status: any, interval: number, max: number) => {

        setShowLoading(true);

        if (!id || !interval || !max) {
            
        }

        

        
        

        var token: any = "";

        if (window.electron) {
            token = window.electron.sendMessageApi.getToken();
        }


        try {

            const response = await fetch("https://peetsunbae.com/dashboard/checklist/enrollchecklist", {
                method: "POST",
                headers: { "Authorization": token, "Content-Type": "application/json" },
                credentials: "include",
                body: JSON.stringify({
                    id, interval, max
                })
            });

            const result = await response.json();

            const message = result.message;

            if (message === "success") {
                toast.success("등록 성공");
            }

            if (message === "DELETED") {
                toast.success("삭제 성공");
            }

            if (message === "MAX_COUNT") {
                toast.error("최대 인원 초과했습니다");
            }

            if (message === "ALREADY") {
                toast.error("이미 등록되어 있습니다");
            }

            setUpdate(Math.random());


        } catch (e) {
            
        }

        setShowLoading(false);

    }



    return (

        <div className={styles.offlineBoard}>

            {
                data.map((each: any, dataIndex: number) => {

                    var isMine = false;
                    var enrolledId = "";
                    var isCompleted = false;

                    each.availableTimes.forEach((one: any) => {
                        if (one.isMine) {
                            isMine = true;
                            enrolledId = one.id;
                            if(one.status === "completed"){
                                isCompleted = true;
                            }
                        }
                    });



                    return (
                        <div key={each.id}>
                            <div className={`${styles.teacherDiv} ${dataIndex === 0 ? styles.teacherFirst : ""}`}>
                                <div className={styles.teacherImg}>
                                    <img
                                        className={styles.teacherImage} src={`https://peetsunbae.com/img/questionteacherimage/default.webp`} alt="사진 업로드 예정" />
                                </div>
                                <div className={styles.teacherDescription}>
                                    <div className={styles.teacherSubject}>
                                        {each.dateString}
                                    </div>
                                    <div className={styles.teacherName} style={{ color: "black" }}>
                                        {each.message}
                                    </div>
                                    <div className={styles.teacherName}>
                                        {each.teacherName}
                                    </div>
                                    <div className={styles.location}>
                                        {each.subMessage}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.selectDiv}>
                                <LocalizationProvider locale={koLocale} dateAdapter={AdapterDateFns}>
                                    <StaticDatePicker
                                        loading={calendarLoading}
                                        shouldDisableDate={(date: any) => {
                                            const targetDate = new Date(each.targetDate);
                                            if (targetDate.getFullYear() === date.getFullYear() && targetDate.getMonth() === date.getMonth() && targetDate.getDate() === date.getDate()) {
                                                return false
                                            } else {
                                                return true;
                                            }
                                        }}
                                        displayStaticWrapperAs="desktop"
                                        openTo="day"
                                        value={new Date(each.targetDate)}
                                        disablePast
                                        onChange={(newValue: any) => {
                                            return;
                                        }}
                                        renderInput={(params: any) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>

                                <div className={styles.timeSelect}>
                                    <div className={styles.timeSelectTitle}>
                                        <div>시간선택</div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        paddingTop: "18px",
                                        flexWrap : "wrap"
                                    }}>
                                        {
                                            props.user && each.availableTimes.map((one: any, index: number) => {

                                                var status: any;
                                                var count = 0;

                                                if (one.isMax) {
                                                    status = "max";
                                                }

                                                if (one.isMine) {
                                                    status = "mine";
                                                }

                                                return (
                                                    <div key={"b" + index} onClick={() => {

                                                        if (status === "max") {
                                                            return;
                                                        }

                                                        if(isCompleted){
                                                            return;
                                                        }

                                                        enrollCheckList(one.id, status, one.interval, one.max)
                                                    }
                                                    } className={`${styles.timeDiv} ${status === "max" ? styles.occupied : ""} ${status === "mine" ? styles.mine : ""}`}
                                                        style={{
                                                            width: "90px"
                                                        }}
                                                    >
                                                        {Math.floor(one.time / 60)}시~{Math.floor((one.time + one.interval) / 60)}시
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                </div>
                                <div className={styles.questionDescription}>
                                    <div className={styles.questionDescriptionTitle}>
                                        요청사항 전송
                                    </div>
                                    <div className={styles.questionDiscriptionDiv}>
                                        <div className={styles.questionDescriptionTextFieldDiv}>
                                            <form encType="multipart/formdata" id="form2">
                                                <TextField
                                                    fullWidth
                                                    variant='outlined'
                                                    placeholder='내용을 적어주세요.(ENTER로 줄바꿈)'
                                                    rows={6}
                                                    multiline={true}
                                                    value={description}
                                                    onChange={(e) => {
                                                        setDescription(e.target.value);
                                                    }}
                                                />
                                                <div className={styles.questionDescriptionSubmitDiv} style={{
                                                    paddingTop: "12px"
                                                }}>
                                                    <Button onClick={(e) => { if (!isMine) { alert("계획표 검사 시간 선택을 먼저 하신 후 \'내용을 전송\'해 주세요"); return; }; descriptionSubmit(); }} variant='contained'>
                                                        {
                                                            descriptionLoading &&
                                                            <CircularProgress color='inherit' size={28} />
                                                        }
                                                        {
                                                            !descriptionLoading &&
                                                            <span className={styles.justText}>
                                                                보내기
                                                            </span>
                                                        }

                                                    </Button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Divider sx={{ marginTop: "80px", marginBottom: "80px" }} />
                        </div>
                    )
                })
            }



            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </div>
    )
}

export default SubmitCheckList;