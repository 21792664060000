import { CircularProgress, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useState, useEffect } from 'react';
import styles from "../../componentsStyle/offlinestatus.module.css";
import TextField from '@mui/material/TextField';


// import DateRangePicker, { DateRange } from '@mui/lab/DateRangePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';


import Box from '@mui/material/Box';
import koLocale from 'date-fns/locale/ko'
import { Button, CssVarsProvider } from '@mui/joy';

import { DataGridPremium, GridColDef, GridFilterModel, useGridApiRef, GridToolbar, useKeepGroupedColumnsHidden } from "@mui/x-data-grid-premium";

import { toast } from 'react-toastify';

import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Add from '@mui/icons-material/Add';
import Typography from '@mui/joy/Typography';
import Autocomplete from '@mui/material/Autocomplete';


import 'react-toastify/dist/ReactToastify.css';
import totalLocationData from '../../../data/locationdata';
import { fil } from 'date-fns/locale';



const columns: GridColDef[] = [
    { field: 'teacher', headerName: "선생님", width: 180, filterable: true },
    { field: 'location', headerName: "호점", width: 80 },
    { field: 'date', headerName: '날짜', width: 100 },
    { field: 'when', headerName: "시간", width: 100 },
    { field: 'name', headerName: '신청자', width: 150 },
    { field: 'studentLocation', headerName: "학생 소속", width: 100 },
    { field: 'place', headerName: "위치", width: 150, filterable: false },
    { field: 'subject', headerName: "과목", width: 150 },
];

const columns2: GridColDef[] = [
    { field: 'teacher', headerName: "선생님", width: 150 },
    { field: 'date', headerName: '날짜', width: 150 },
    { field: 'total', headerName: "총 갯수", width: 150 },
    { field: 'number', headerName: '신청 수', width: 150 },
    { field: 'ratio', headerName: "신청 비율", width: 150 },
    { field: 'subject', headerName: "과목", width: 150 },
];


const OfflineStatus: React.FC<any> = (props) => {

    const [alignment, setAlignment] = useState("total");

    const [day, setDay] = useState<any[]>([]);
    const [date, setDate] = useState<number[]>([]);
    const [info, setInfo] = useState<any>();
    const [number, setNumber] = useState(0);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState("student");
    const [update, setUpdate] = useState(0);

    const [selectedId, setSelectedId] = useState(0);
    const [selectedName, setSelectedName] = useState("");

    const [rows, setRows] = useState<any>();
    const [rows2, setRows2] = useState<any>();

    const [value, setValue] = React.useState<DateRange<Date>>([new Date(), new Date()]);
    const [submitBool, setSubmitBool] = useState(false);
    const apiRef = useGridApiRef();
    const apiRef2 = useGridApiRef();

    const [users, setUsers] = useState<any>();
    const [selectedUser, setSelectedUser] = useState<any>();

    const [disabled1, setDisabled1] = useState(true);
    const [disabled2, setDisabled2] = useState(true);
    const [disabled3, setDisabled3] = useState(true);

    const [nameText, setNameText] = useState("");

    const [open, setOpen] = useState(false);

    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [
            {
                id: 2,
                field: 'location',
                operator: 'isAnyOf',
                value: []
            },
            {
                id: 3,
                field: 'teacher',
                operator: 'contains',
                value: ""
            }
        ],
    });



    useEffect(() => {

    }, [props.user]);



    const handleAlignmentChange = (e: any, newAlignment: any) => {

        if (!newAlignment) {
            return;
        }

        

        setAlignment(newAlignment);

        

        const newFilterModel = filterModel;

        const filterValue: string[] = [];

        if (newAlignment === "total") {

        } else {

            totalLocationData.forEach((eachLocation) => {

                if (!eachLocation.questionLocation) {
                    return;
                }

                if (eachLocation.english === newAlignment) {
                    eachLocation.questionLocation.forEach((eachQuestionLocation) => {
                        filterValue.push(eachQuestionLocation.korean);
                    })
                }

            });

        }

        newFilterModel.items.forEach((each) => {
            if (each.id === 2) {
                each.value = filterValue;
            }
        })

        // if(newAlignment === "total"){
        //     newFilterModel.items.forEach((each) => {
        //         if(each.id === 2){
        //             each.value = ["강남점", "대치점", "대치3층", "대치공통", "대치6층", "대치2,3층", "대치2,6층", "대치3,6층"]
        //         }
        //     })
        // }

        // if(newAlignment === "gangnam"){
        //     newFilterModel.items.forEach((each) => {
        //         if(each.id === 2){
        //             each.value = ["강남점"]
        //         }
        //     })
        // }

        // if(newAlignment === "daechi"){
        //     newFilterModel.items.forEach((each) => {
        //         if(each.id === 2){
        //             each.value = ["대치점", "대치공통", "대치2,3층", "대치2,6층"]
        //         }
        //     })
        // }

        // if(newAlignment === "daechi2"){
        //     newFilterModel.items.forEach((each) => {
        //         if(each.id === 2){
        //             each.value = ["대치3층", "대치공통", "대치3,6층", "대치2,3층"]
        //         }
        //     })
        // }

        // if(newAlignment === "daechi3"){
        //     newFilterModel.items.forEach((each) => {
        //         if(each.id === 2){
        //             each.value = ["대치6층", "대치공통", "대치3,6층", "대치2,6층"]
        //         }
        //     })
        // }

        setFilterModel({ ...newFilterModel });

    }

    useEffect(() => {

        setLoading(true);
        
        async function start() {

            var token = "";

            if (window.electron) {
                token = await window.electron.sendMessageApi.getToken();
            }

            fetch("https://peetsunbae.com/dashboard/restaurant/users", {
                method: "GET",
                headers: { "Authorization": token },
                credentials: "include",
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        
                        const rows: any = [];

                        if (result.message !== "success") {
                            return;
                        }

                        result.data.forEach((each: any, index: number) => {
                            var data: any = {};
                            data.id = each.id;
                            data.label = each.name;
                            data.phoneNumber = each.phoneNumber;
                            data.amount = each.amount;
                            data.value = each.value;
                            data.key = index;
                            rows.push(data);
                        })
                        setUsers([...rows]);
                        setLoading(false);

                    })
            })
        }

        start();

    }, [])

    useEffect(() => {

        if (selectedUser) {
            setDisabled3(false);
        } else {
            setDisabled3(true);
        }

    }, [selectedUser]);

    useEffect(() => {


        if (selectedId) {
            setDisabled1(false);
            setDisabled2(false);
        } else {
            setDisabled1(true);
            setDisabled2(true);
        }

    }, [selectedId]);

    useEffect(() => {
        if (props.user) {
            setUser(props.user.value);
        }

        setLoading(true);
        
        async function start() {
            var token = "";
            if (window.electron) {
                token = await window.electron.sendMessageApi.getToken();
            }

            fetch("https://peetsunbae.com/dashboard/question/offline/check", {
                method: "GET",
                headers: { "Authorization": token },
                credentials: "include",
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        
                        setInfo(result.data);
                        setLoading(false);
                    })
            })
        }

        if (props.user && props.user.value === "student") {
            start();

            const date = new Date();
            const dateInfo = [];
            date.setDate(date.getDate() - 1);
            for (var i = 0; i < 7; i++) {
                dateInfo.push(date.setDate(date.getDate() + 1));
            }

            
            const currentDay: any = [];
            const currentDate: any = [];

            dateInfo.forEach((each: any) => {
                currentDate.push(new Date(each).getDate());
                switch (new Date(each).getDay()) {
                    case 0: currentDay.push("일"); break;
                    case 1: currentDay.push("월"); break;
                    case 2: currentDay.push("화"); break;
                    case 3: currentDay.push("수"); break;
                    case 4: currentDay.push("목"); break;
                    case 5: currentDay.push("금"); break;
                    case 6: currentDay.push("토"); break;
                }
            })

            
            
            setDay(currentDay);
            setDate(currentDate);
        } else {
            setLoading(false);
        }

    }, []);

    useEffect(() => {

        if (!props.user) {
            return;
        }


        if (props.user.value === "teacher" || props.user.value === "staff") {
            submit("e");
        }


    }, [props.user, update]);

    const onchange = async (e: any, value: any) => {

        if (!users) {
            return;
        }

        // 

        // if(selectedUser){
        //     if(!window.confirm("내용을 저장하지 않은 채 학생을 바꾸면 적은 내용이 사라집니다. 내용을 저장하셨거나 저장할 필요가 없으십니까?")){
        //         return;
        //     }else{

        //     }
        // }

        

        
        setSelectedUser(value);
    }

    const notify2 = () => toast.success("삭제 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "dark"
    });

    const notify3 = () => toast.success("신청자 변경 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "dark"
    });

    const submit = async (e: any) => {
        

        var token = "";
        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        fetch("https://peetsunbae.com/dashboard/question/offline/total", {
            method: "POST",
            headers: { "Authorization": token, "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
                message: value
            })
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    
                    var data: any = [];

                    result.data.forEach((each: any, index: number) => {
                        var oneRow: any = {};
                        oneRow.id = index + 1;
                        oneRow.offlineQuestionId = each.offlineQuestionId;

                        var koreanLocation = "";

                        

                        totalLocationData.forEach((eachLocation) => {

                            if (!eachLocation.questionLocation) {
                                return;
                            }

                            eachLocation.questionLocation.forEach((eachQuestionLocation) => {
                                if (eachQuestionLocation.english === each.location) {
                                    koreanLocation = eachQuestionLocation.korean;
                                }

                            })

                        });

                        // oneRow.location = each.location === "gangnam" ? "강남점" : each.location === "daechi" ? "대치점" : each.location === "daechi2" ? "대치3층" : each.location === "daechiTotal" ? "대치공통" : each.location === "daechi3" ? "대치6층"
                        //  :  each.location === "daechi_12" ? "대치2,3층" : each.location === "daechi_13" ? "대치2,6층" : each.location === "daechi_23" ? "대치3,6층" : "";
                        oneRow.location = koreanLocation;
                        oneRow.studentLocation = each.studentLocation;
                        oneRow.englishLocation = each.location;
                        oneRow.sqlId = each.id;
                        oneRow.name = each.name;
                        oneRow.date = `${new Date(each.targetDate).getMonth() + 1}월 ${new Date(each.targetDate).getDate()}일`;
                        oneRow.when = each.ampm + " " + each.hours + " : " + each.minutes;
                        oneRow.teacher = each.teacherName;
                        if (each.subject === "chemistry") {
                            each.subject = "화학"
                        }
                        if (each.subject === "biology") {
                            each.subject = "생물"
                        }
                        if (each.subject === "organic") {
                            each.subject = "유기"
                        }
                        if (each.subject === "physics") {
                            each.subject = "물리"
                        }

                        oneRow.subject = each.subject;
                        oneRow.place = each.uploadTeacherDescription;
                        // oneRow.time = `${new Date(+each.time).getMonth() + 1}월 ${new Date(+each.time).getDate()}일 ${new Date(+each.time).getHours()}시`;
                        data.push(oneRow);
                    })
                    


                    const sortedData: any = [];

                    result.sortedData.forEach((each: any, index: number) => {

                        data.forEach((eachData: any) => {

                            if (each.id === eachData.offlineQuestionId) {
                                sortedData.push(eachData);
                            }

                        })

                    })

                    


                    setRows([...sortedData]);










                    var data2: any = [];
                    result.data2.forEach((each: any, index: number) => {
                        var oneRow: any = {};
                        oneRow.id = index + 1;
                        oneRow.teacher = each.teacherName;
                        oneRow.date = `${new Date(each.targetDate).getMonth() + 1}월 ${new Date(each.targetDate).getDate()}일`;
                        oneRow.total = each.total;
                        oneRow.number = each.enrolled;
                        oneRow.ratio = Math.floor((+each.enrolled / +each.total) * 100) + "%";
                        if (each.subject === "chemistry") {
                            each.subject = "화학"
                        }
                        if (each.subject === "biology") {
                            each.subject = "생물"
                        }
                        if (each.subject === "organic") {
                            each.subject = "유기"
                        }
                        if (each.subject === "physics") {
                            each.subject = "물리"
                        }

                        oneRow.subject = each.subject;
                        // oneRow.time = `${new Date(+each.time).getMonth() + 1}월 ${new Date(+each.time).getDate()}일 ${new Date(+each.time).getHours()}시`;
                        data2.push(oneRow);
                    })
                    
                    setRows2([...data2]);


                })
        })
    }

    const filterChange = (e: any) => {

    }

    const filterChange2 = (e: any) => {

    }

    const handleDelete = (e: any) => {

        if (!selectedId) {
            
            return;
        }

        if (window.confirm(`선택된 질의응답 예약을 삭제할까요?`)) {

        } else {
            return;
        }

        fetch("https://peetsunbae.com/dashboard/question/offline/total", {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                selectedId
            })
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    
                    if (result.message === "success") {
                        setUpdate(Math.random());
                        notify2();

                        setSelectedName("");
                        setSelectedId(0);

                    }

                })
        })



    }

    const handleOpen = (e: any) => {

        if (!selectedId || !selectedName) {
            
            return;
        }

        setOpen(true);

    }

    const handleChange = (e: any) => {

        if (!selectedId) {
            
            return;
        }

        const data = {
            selectedId, selectedUser
        }

        fetch("https://peetsunbae.com/dashboard/question/offline/total", {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                data
            })
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    
                    if (result.message === "success") {
                        setUpdate(Math.random());
                        notify3();

                        setSelectedName("");
                        setSelectedId(0);
                        setSelectedUser(null);
                    }

                })
        })

    }

    const nameChange = (e: any) => {
        setNameText(e.target.value);
        const newFilterModel = filterModel;
        newFilterModel.items.forEach((each) => {
            if (each.id === 3) {
                each.value = e.target.value;
            }
        })
        setFilterModel({ ...newFilterModel });
    }





    return (
        <div className={styles.checkBoard}>
            <div className={styles.title}>
                <img src="img/calendar.svg" alt="calendar"></img><span className={styles.titleText}>질의응답 신청현황</span>
            </div>
            {loading &&
                <div className={styles.loading}>
                    <CircularProgress />
                </div>
            }
            {(user === "student" && !loading) &&
                <>
                    <div className={styles.dayLine}>
                        <div className={styles.dayFirst1}>

                        </div>
                        {day && day.map((eachDay, index) => {
                            return (
                                <div className={styles.dayDiv} key={index}>
                                    {eachDay}
                                </div>
                            );
                        })}
                    </div>
                    <div className={styles.dateLine}>
                        <div className={styles.dayFirst2}>
                            <div>현</div>
                            <div>황</div>
                        </div>
                        {day && day.map((eachDay, index) => {
                            var day: any;

                            switch (eachDay) {
                                case "일": day = 0; break;
                                case "월": day = 1; break;
                                case "화": day = 2; break;
                                case "수": day = 3; break;
                                case "목": day = 4; break;
                                case "금": day = 5; break;
                                case "토": day = 6; break;
                            }


                            return (
                                <div key={number + index} className={styles.dateDiv}>
                                    {
                                        (typeof (day) === "number" && info && info[day].length > 0) ?
                                            <div className={styles.dateDivDiv}>
                                                {
                                                    info[day].map((eachEnroll: any, index2: number) => {
                                                        return (
                                                            <div className={styles.eachEnroll} key={index2}>
                                                                <div>
                                                                    {eachEnroll.subject === "chemistry" && "[화학]"}
                                                                    {eachEnroll.subject === "organic" && "[유기]"}
                                                                    {eachEnroll.subject === "physics" && "[물리]"}
                                                                    {eachEnroll.subject === "biology" && "[생물]"}
                                                                </div>
                                                                <div style={{ fontWeight: 600 }}>
                                                                    {eachEnroll.ampm} {eachEnroll.hours}:{eachEnroll.minutes}
                                                                </div>
                                                                <div>
                                                                    {eachEnroll.uploadTeacherDescription}
                                                                </div>
                                                                <div>
                                                                    {eachEnroll.teacherName} 튜터
                                                                </div>

                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                            :
                                            ""
                                    }

                                </div>
                            )
                        })}
                    </div>
                </>
            }
            {
                ((user === "staff") || (user === "teacher")) &&
                <div className={styles.teacherDiv}>
                    <div className={styles.datePicker}>
                        <LocalizationProvider locale={koLocale} dateAdapter={AdapterDateFns}>
                            <DateRangePicker
                                startText="시작일"
                                endText="마지막일"
                                value={value}
                                onChange={(newValue: any) => {
                                    setValue(newValue);
                                    if (newValue[0] && newValue[1]) {
                                        setSubmitBool(true);
                                    }
                                }}
                                renderInput={(startProps: any, endProps: any) => (
                                    <React.Fragment>
                                        <TextField {...startProps} />
                                        <Box sx={{ mx: 2 }}> to </Box>
                                        <TextField {...endProps} />
                                    </React.Fragment>
                                )}

                            />
                        </LocalizationProvider>
                    </div>
                    {submitBool ?
                        <div onClick={submit} className={styles.totalCheckBtn}>
                            조회하기
                        </div>
                        :
                        <div className={styles.disableTotalCheckBtn}>
                            조회하기
                        </div>
                    }


                    <div className={styles.dataGrid}>
                        {rows &&
                            <div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
                                    <div>
                                        <div>
                                            <ToggleButtonGroup
                                                color="primary"
                                                value={alignment}
                                                exclusive
                                                onChange={handleAlignmentChange}
                                                aria-label="Platform"
                                            >
                                                {/* <ToggleButton value="total">전체</ToggleButton>
                                            <ToggleButton value="gangnam">강남점</ToggleButton>
                                            <ToggleButton value="daechi">대치2층</ToggleButton>
                                            <ToggleButton value="daechi2">대치3층</ToggleButton>
                                            <ToggleButton value="daechi3">대치6층</ToggleButton> */}
                                                {
                                                    props.user && totalLocationData.filter(locationData => locationData.academy.includes(props.user.academy)).map((eachLocation, index) => {
                                                        return (
                                                            <ToggleButton value={eachLocation.english} key={index}>{eachLocation.korean}</ToggleButton>
                                                        );
                                                    })
                                                }
                                            </ToggleButtonGroup>
                                        </div>
                                        <div style={{
                                            paddingTop : "18px"
                                        }}>
                                            <TextField value={nameText} onChange={nameChange} id="standard-basic" placeholder="선생님을 검색하세요" variant="standard" />
                                        </div>

                                    </div>
                                    <div>
                                        <CssVarsProvider>
                                            <Button color="neutral" variant='soft' disabled={disabled2} onClick={handleOpen} style={{ marginRight: "16px" }}>
                                                신청자 변경하기
                                            </Button>
                                            <Button color='danger' variant='soft' disabled={disabled1} onClick={handleDelete}>
                                                삭제하기
                                            </Button>
                                        </CssVarsProvider>
                                    </div>
                                </div>
                                <div style={{ height: 500, width: '100%' }}>
                                    <div style={{ display: "flex", height: "100%" }}>
                                        <div style={{ flexGrow: 1, backgroundColor: "white", width: "100%" }}>
                                            <DataGridPremium rows={rows} columns={columns} apiRef={apiRef}
                                                density='compact'
                                                onStateChange={filterChange}
                                                onRowClick={(e) => {
                                                    
                                                    setSelectedId(e.row.sqlId);
                                                    setSelectedName(e.row.name);
                                                }}
                                                filterModel={filterModel}
                                                onFilterModelChange={(model) => { setFilterModel(model); }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    <div className={styles.dataGrid}>
                        {rows2 &&
                            <div style={{ height: 500, width: '100%' }}>
                                <div style={{ display: "flex", height: "100%" }}>
                                    <div style={{ flexGrow: 1 }}>
                                        <DataGridPremium rows={rows2} columns={columns2} components={{ Toolbar: GridToolbar }} apiRef={apiRef2}
                                            onStateChange={filterChange2}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }


            <Modal open={open} onClose={() => setOpen(false)}>
                <ModalDialog
                    aria-labelledby="basic-modal-dialog-title"
                    aria-describedby="basic-modal-dialog-description"
                    sx={{ maxWidth: 500 }}
                >
                    <Typography id="basic-modal-dialog-title" component="h2">
                        신청자 변경하기
                    </Typography>
                    <Typography id="basic-modal-dialog-description" textColor="text.tertiary">
                        변경을 원하는 신청자를 선택해주세요.
                    </Typography>
                    <Typography id="basic-modal-dialog-description" textColor="text.tertiary" sx={{ marginBottom: "12px" }}>
                        (현재 신청자 : {selectedName})
                    </Typography>
                    <form
                        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                            event.preventDefault();
                            setOpen(false);
                        }}
                    >
                        <Stack spacing={3}>
                            <FormControl>
                                <Autocomplete
                                    onChange={onchange}
                                    value={selectedUser}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={users}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => <TextField {...params} placeholder="학생을 선택하세요" />}
                                />
                            </FormControl>
                            <Button type="submit" onClick={handleChange} disabled={disabled3}>변경하기</Button>
                        </Stack>
                    </form>
                </ModalDialog>
            </Modal>





        </div>
    )
}

export default OfflineStatus;