import { useEffect, useState } from "react";
import styles from "../styles/bookingstyle.module.css";
import { Button, Checkbox, FormControl, Radio, Tab, TabList, Tabs } from "@mui/joy";
import { RadioGroup } from "@mui/joy";
import { addZeroIfOne } from "../../etc/etcfunctions";
import { getFetch, postFetch } from "../../../../../fetch/fetch";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Backdrop, CircularProgress } from "@mui/material";

const ManageBookingTime: React.FC<any> = (props) => {


    const [selectedLocation, setSelectedLocation] = useState<string>("gangnam");
    const [selectedKind, setSelectedKind] = useState<any>("0");
    const [loading, setLoading] = useState<boolean>(false);
    const [smallLoading, setSmallLoading] = useState<boolean>(false);
    const [serverData, setServerData] = useState<any>([]);
    const [update, setUpdate] = useState<number>(0);

    const [selectedTime, setSelectedTime] = useState<any>({
        weekdays : [],
        saturday : [],
        sunday : [],
    });

    const notify1 = () => toast.success("저장 성공!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "colored"
    });

    const notify2 = () => toast.error("전송 실패!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "colored"
    });

    useEffect(() => {

        setSelectedTime({
            weekdays : [],
            saturday : [],
            sunday : [],
        });

    }, [selectedLocation, selectedKind]);

    useEffect(() => {
            
            getData();
    
    }, []);

    useEffect(() => {

        
        

        const data = serverData.find((d : any) => d.location === selectedLocation && `${d.kind}` === selectedKind) ? serverData.find((d : any) => d.location === selectedLocation && `${d.kind}` === selectedKind).time : { weekdays : [], saturday : [], sunday : [] };

        
        

        setSelectedTime(data);

    }, [serverData, selectedLocation, selectedKind]);

    const timeList = new Array(24).fill(0).map((_, i) => {

        const startTime = 600;

        const thisTime = startTime + i * 30;

        const hours = Math.floor(thisTime / 60);
        const minutes = thisTime % 60;

        return {
            time : thisTime,
            string : `${hours}:${addZeroIfOne(minutes)}`
        }
        
    });

    const getData = async () => {

        try{

            

            const result : any = await getFetch("/dashboard/report/managebookingtime");

            if(result.message === "success"){

                if(!result.data){
                    return;
                }

                setServerData([...result.data]);

            }

            

        }catch(e){
            
        }

    }

    const submit = async () => {

        try{

            setLoading(true);
            const data = {
                location : selectedLocation,
                kind : selectedKind,
                time : selectedTime
            }

            

            const result : any = await postFetch("/dashboard/report/managebookingtime", data);

            

            if(result.message === "success"){
                
                notify1();
                getData();
            }

            setLoading(false);


        }catch(e){
            
            notify2();

            setLoading(false);
        }

    }

    return (
        <div>
            <div className={styles.radioTitle}>
               - 어느 지점을 관리하고 싶으신가요?
            </div>
            <div>
                <FormControl>
                    <RadioGroup
                        orientation="horizontal"
                        value={selectedLocation}
                        onChange={(e) => {
                            setSelectedLocation(e.target.value);
                        }}
                    >
                        <Radio size="lg" value="gangnam" label={<span className={styles.radiofont}>강남점</span>} />
                        <Radio size="lg" value="daechi" label={<span className={styles.radiofont}>대치점</span>} />
                        <Radio size="lg" value="bundang" label={<span className={styles.radiofont}>분당점</span>} />
                        <Radio size="lg" value="songpa" label={<span className={styles.radiofont}>송파점</span>} />
                    </RadioGroup>
                </FormControl>
            </div>
            {/* <div className={styles.radioTitle}>
                정기적/일회성 어떤 예약을 관리하고 싶으신가요?
            </div>
            <div>
                <FormControl>
                    <RadioGroup
                        orientation="horizontal"
                        value={selectedType}
                        onChange={(e) => {
                            setSelectedType(e.target.value);
                        }}
                    >
                        <Radio size="lg" value="regular" label={<span className={styles.radiofont}>정기적인 상담 시간 관리</span>} />
                        <Radio size="lg" value="onetime" label={<span className={styles.radiofont}>일회성 상담 시간 관리</span>} />
                    </RadioGroup>
                </FormControl>
            </div> */}
            <div className={styles.radioTitle}>
               - 어떤 종류의 상담을 관리하고 싶으신가요?
            </div>
            <div>
                <FormControl>
                    <RadioGroup
                        orientation="horizontal"
                        value={selectedKind}
                        onChange={(e) => {
                            
                            setSelectedKind(e.target.value);
                        }}
                    >
                        <Radio size="lg" value={"0"} label={<span className={styles.radiofont}>방문상담</span>} />
                        <Radio size="lg" value={"1"} label={<span className={styles.radiofont}>전화상담</span>} />
                    </RadioGroup>
                </FormControl>
            </div>
            <div className={styles.radioTitle}>
               - 상담이 가능한 시간을 선택해주세요
            </div>
            <div style={{
                display: "flex",
            }}>
                {
                    [{korean : "평일", english : "weekdays"}, {korean : "토요일", english : "saturday"}, {korean : "일요일", english : "sunday"}].map((day, i) => {
                        return (
                            <div key={i} style={{
                                marginRight: "40px"
                            }}>
                                <div className={styles.radioBox}>
                                    <div className={styles.boxTitle} style={{
                                        marginBottom: "18px",
                                        fontFamily : "Apple_SB",
                                        fontSize : "18px",
                                    }}>
                                        {day.korean}
                                    </div>
                                    <div style={{
                                        display : "grid",
                                        gridTemplateColumns : "repeat(4, 1fr)",
                                        gap : "13px",
                                    }}>
                                        {
                                            timeList.map((time, j) => {
                                                return (
                                                    <div key={j} className={styles.timeBox}>
                                                        <Checkbox
                                                        label={<span className={styles.checkfont}>{time.string}</span>}
                                                        color="neutral"
                                                        checked={selectedTime[day.english].includes(time.time)}
                                                        onChange={(e) => {
                                                            const newSelectedTime = selectedTime;
                                                            if(e.target.checked){
                                                                newSelectedTime[day.english].push(time.time);
                                                            }else{
                                                                newSelectedTime[day.english] = newSelectedTime[day.english].filter((t : any) => t !== time.time);
                                                            }
                                                            setSelectedTime({...newSelectedTime});
                                                        }}
                                                        />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div style={{
                width : "1205px",
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "40px"
            }}>
                <Button
                size="lg"
                color="primary"
                variant="solid"
                onClick={submit}
                >
                    저장하기
                </Button>
            </div>



            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default ManageBookingTime;