import React, { useEffect, useState } from "react";
import { postFetch } from "../../../fetch/fetch";

import TextField from '@mui/material/TextField';

import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';


import Box from '@mui/material/Box';
import styles from "../../componentsStyle/restaurant.module.css";
import koLocale from 'date-fns/locale/ko'
import { DataGridPro, GridRowsProp, GridColDef, GridToolbar, useGridApiRef, GridFilterModel, DataGridPremium } from '@mui/x-data-grid-premium';
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import totalLocationData from "../../../data/locationdata";
import CustomInput from "./etc/custominput";


const columns: GridColDef[] = [
    { field: 'location', headerName: '위치', width: 100, filterable: true },
    { field: 'name', headerName: '이름', width: 100, filterable: true },
    { field: 'date', headerName: '응시 날짜', width: 150, filterable: false },
    { field: 'range', headerName: "응시 범위", width: 150, filterable: false },
    { field: 'kind', headerName: "응시 종류", width: 250, filterable: false },
    { field: 'book', headerName: "단어책", width: 250, filterable: false },
];


const TotalRecordTest: React.FC<any> = (props) => {

    const [value, setValue] = React.useState<DateRange<Date>>([new Date(), new Date()]);
    const [submitBool, setSubmitBool] = useState(false);
    const [rows, setRows] = useState<any>();
    const [sum, setSum] = useState(0);
    const [name, setName] = useState("");
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [
            { id: 2, field: 'name', operator: 'contains', value: "" },
            { id: 3, field: 'location', operator: 'contains', value: "" },
        ],
    });
    const [alignment, setAlignment] = useState("total");

    const nameChange = (e: any) => {
        setName(e.target.value);

        const newFilterModel = filterModel;

        newFilterModel.items.forEach((each) => {
            if (each.id === 2) {
                each.value = e.target.value
            }
        })

        setFilterModel({ ...newFilterModel });
    }

    useEffect(() => {

        submit();

    }, []);

    const submit = async () => {
        try {

            const result: any = await postFetch("/dashboard/words/gettotaltestrecord", { message: value });
            
            const newRows: any = [];
            if (result.message === "success") {
                result.data.forEach((each: any, index: number) => {
                    const row: any = {};
                    row.id = each.id;
                    row.location = each.location;
                    row.name = each.name;
                    const createdDate = new Date(each.createdAt);
                    const createdString = `${createdDate.getMonth() + 1}월 ${createdDate.getDate()}일 ${createdDate.getHours()}시 ${createdDate.getMinutes()}분`;
                    row.date = createdString;
                    row.range = `Day ${each.day}`;
                    row.kind = each.isTotal ? "전체 단어" : "틀린 단어";

                    const kind = each.kind;

                    switch (kind) {
                        case 1:
                            row.book = "워드마스터 수능 2000 (2018 버전)";
                            break;
                        case 2:
                            row.book = "워드마스터 수능 2000 (2022 버전)";
                            break;
                        case 3:
                            row.book = "워드마스터 하이퍼 2000 (2021 버전)";
                            break;
                        case 5:
                            row.book = "워드마스터 EBS 파이널 1200(2020년 버전)";
                            break;
                    }


                    newRows.push(row);
                })

                setRows([...newRows]);
            }

        } catch (e) {
            
        }
    }

    const handleAlignmentChange = (e : any, value : string) => {
        setAlignment(value);

        const newFilterModel = filterModel;

        // if(value === "total"){
        //     newFilterModel.items.forEach((each : any) => {
        //         if(each.id === 3){
        //             each.value = "";
        //         }
        //     })
        // }

        // if(value === "daechi"){
        //     newFilterModel.items.forEach((each : any) => {
        //         if(each.id === 3){
        //             each.value = "대치점";
        //         }
        //     })
        // }

        // if(value === "gangnam"){
        //     newFilterModel.items.forEach((each : any) => {
        //         if(each.id === 3){
        //             each.value = "강남점";
        //         }
        //     })
        // }

        // if(value === "daechi2"){
        //     newFilterModel.items.forEach((each : any) => {
        //         if(each.id === 3){
        //             each.value = "대치3층";
        //         }
        //     })
        // }

        totalLocationData.forEach((each) => {
            if(each.english === value){
                newFilterModel.items.forEach((each2 : any) => {
                    if(each2.id === 3){
                        each2.value = each.filterValue_contains;
                    }
                })
            }
        })

        setFilterModel({...newFilterModel});
    }


    return (
        <div>
            <div className={styles.datePicker}>
                <LocalizationProvider locale={koLocale} dateAdapter={AdapterDateFns}>
                    <DateRangePicker
                        startText="시작일"
                        endText="마지막일"
                        value={value}
                        onChange={(newValue: any) => {
                            setValue(newValue);
                            if (newValue[0] && newValue[1]) {
                                setSubmitBool(true);
                            }
                        }}
                        renderInput={(startProps: any, endProps: any) => (
                            <React.Fragment>
                                <TextField {...startProps} />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <TextField {...endProps} />
                            </React.Fragment>
                        )}

                    />
                </LocalizationProvider>
            </div>
            {submitBool ?
                <div onClick={submit} className={styles.totalCheckBtn}>
                    조회하기
                </div>
                :
                <div className={styles.disableTotalCheckBtn}>
                    조회하기
                </div>
            }



            <div className={styles.dataGrid}>
                {
                    rows &&
                    <div style={{ display: "flex", justifyContent: "space-between", width: "1200px", marginBottom: "18px" }}>
                        <div>
                            <ToggleButtonGroup
                                color="primary"
                                value={alignment}
                                exclusive
                                onChange={handleAlignmentChange}
                            >
                                {/* <ToggleButton value="total">전체</ToggleButton>
                                <ToggleButton value="gangnam">강남점</ToggleButton>
                                <ToggleButton value="daechi">대치점</ToggleButton>
                                <ToggleButton value="daechi2">대치3층</ToggleButton> */}
                                {
                                    props.user && totalLocationData.filter(locationData => locationData.academy.includes(props.user.academy)).map((each, index) => {
                                        return <ToggleButton value={each.english} key={index}>{each.korean}</ToggleButton>
                                    })
                                }
                            </ToggleButtonGroup>
                        </div>
                        <div>
                            <CustomInput value={name} onChange={nameChange} placeholder="이름을 검색하세요" />
                        </div>
                    </div>
                }
                {rows &&
                    <div style={{ height: 500, width: 1200 }}>
                        <div style={{ display: "flex", height: "100%" }}>
                            <div style={{ flexGrow: 1 }}>
                                <DataGridPremium rows={rows} columns={columns}
                                    filterModel={filterModel}
                                    onFilterModelChange={(model) => setFilterModel(model)}
                                    density="compact"
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>


        </div>
    );

}

export default TotalRecordTest;