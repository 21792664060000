import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import styles from "./dial.module.css";
import { throttle } from "lodash";

var firstPositionValue : any;
var gapValue : any;
var windowSize : any;
var eachPosition : any;


const Dial: React.FC<any> = forwardRef((props, ref) => {
    
    useImperativeHandle(ref, () => ({
        function1 (number : number) {

            goToScroll(number)
        },
        function2 () {
            opacityControl();
        }
    }));


    const goToScroll = (number : number) => {

        
        
        if(props.timeSelectRef1.current.length > 95){

            


            const firstCurrent = props.timeSelectRef1.current[1];
            if(!firstCurrent){
                return;
            }
            const firstY = firstCurrent.getBoundingClientRect().y;
            const firstHeight = firstCurrent.offsetHeight;
            
            

            const secondCurrent = props.timeSelectRef1.current[2];
            // if(!secondCurrent){
            //     return;
            // }
            const secondY = secondCurrent.getBoundingClientRect().y;
            const secondHeight = secondCurrent.offsetHeight;

            const timeSelectBoxCurrent = props.timeSelectBoxRef.current;
            if(!timeSelectBoxCurrent){
                return;
            }
            const timeSelectY = timeSelectBoxCurrent.getBoundingClientRect().y;
            const timeSelectHeight = timeSelectBoxCurrent.clientHeight;
            windowSize = timeSelectHeight;

            const interval = secondY - firstY;
            gapValue = interval;
            
            
            
            const firstPosition = firstY + firstHeight / 2 - timeSelectY - timeSelectHeight/2;
            firstPositionValue = firstPosition;
            

            const zeroPosition = firstPosition - interval;
            
            eachPosition = [];

            var currentPosition = zeroPosition;
            

            for(var i = 0; i < 96; i++){
                eachPosition.push(currentPosition);
                currentPosition = currentPosition + interval;
            }

            

            var nineposition = firstPosition + 35 * interval;

            if(props.kindMenu === "early"){
                nineposition = nineposition + 44 * interval;
            }

            if(number !== 0){
                nineposition = firstPosition + (number - 1) * interval;
            }

            

            

            props.timeSelectBoxRef.current.scrollTo({top : nineposition, behavior : "instant"});
        }
    }


    const opacityControl = () => {

        
        if(props.timeSelectBoxRef && props.timeSelectBoxRef.current){
            
            

            var i = 0;

            const handleScroll = (e : any) => {
                // 
                const currentScrollPostion = props.timeSelectBoxRef.current.scrollTop;
                if(firstPositionValue && gapValue && windowSize && eachPosition){

                    eachPosition.forEach((eachValue : any, index : number) => {
                        const targetValue = eachValue - currentScrollPostion;
                        const positiveTargetValue = Math.abs(targetValue);
                        const threshold = windowSize / 2;
                        
                        if(positiveTargetValue < threshold){
                            const number : any = 1 - (positiveTargetValue / threshold);
                            props.timeSelectRef1.current[index].style.opacity = number;
                        }
                        if(i !== index && positiveTargetValue < gapValue / 2){
                            i = index;
                            
                            props.handleSelectedTimeIndex(i);
                        }
                    })
                }
            }

            const throttledFn = throttle(handleScroll, 50);
            
            const instance = props.timeSelectBoxRef.current;

            instance.addEventListener("scroll", throttledFn);

            return () => {
                instance.removeEventListener("scroll", throttledFn);
            };

        }


    }





    return (
        <div className={styles.timeSelectWrapper}>

            <div>

            </div>

            <div className={styles.timeSelectBox} ref={props.timeSelectBoxRef}>

                {
                    new Array(96).fill(undefined).map((each: any, index: number) => {
                        var totalMinutes = 15 * index;

                        var ampm = "am";
                        var hours = 0;
                        var minutes: any = 0;

                        if (totalMinutes >= 720) {
                            ampm = "pm"
                            totalMinutes = totalMinutes - 720;
                        }

                        hours = Math.floor(totalMinutes / 60);

                        if (hours === 0) {
                            hours = 12;
                        }

                        minutes = totalMinutes % 60;
                        if (minutes < 10) {
                            minutes = "0" + minutes;
                        }

                        return (
                            <div key={ampm + hours + minutes} className={styles.timeSelectElement} ref=
                                {
                                    // index === 1 ? firstRef : index === 2 ? secondRef : index === 36 ? nineRef : voidRef
                                    (el) => { props.timeSelectRef1.current[index] = el }
                                }
                            >
                                {ampm === "am" ? "오전" : "오후"}&nbsp;{hours}:{minutes}
                            </div>
                        );


                    })
                }



            </div>

            <div className={styles.text}>
                {props.kindMenu === "long" ? "등원" : "하원"}
            </div>

            <div>

            </div>

            <div className={styles.layer}>
            </div>

        </div>
    );
})

export default Dial;