import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowLeft } from '../../../src/svg/mobiletotal/arrow-left-light.svg';
import styles from "./attendancemobile.module.css";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import StyledDataGrid from "./styleddatagrid/tablestyles";
import { GridColDef } from '@mui/x-data-grid-pro';
import { useLocation } from "react-router-dom";
import InnerHeader from "../etc/innerheader";
import { Capacitor } from "@capacitor/core";
import { IonLoading } from "@ionic/react";
import { Preferences } from "@capacitor/preferences";
import { LinearProgress } from "@mui/material";


const dayList = ["일", "월", "화", "수", "목", "금", "토"]

const deadline = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0).getTime();

const style = {
    outline: "none",
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    height: "65%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1.5,
    p: 0,
    overflow: "hidden"
};

const style2 = {
    outline: "none",
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    opacity: 1,
    transition: "all 500ms ease-in"
};

const style3 = {
    outline: "none",
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: "auto"
};

const columns: GridColDef[] = [
    { field: "date", headerName: "부여날짜", width: 70 },
    { field: "score", headerName: "벌점", width: 70 },
    { field: "description", headerName: "사유", width: 200 }
]

const AttendanceMobileReal: React.FC<any> = (props) => {

    const [loading, setLoading] = useState(false);

    const [dateList, setDateList] = useState<any>();
    const [data, setData] = useState<any>();
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);

    const [totalScore, setTotalScore] = useState(0);
    const headerRef = useRef<HTMLDivElement>(null);
    const topDivRef = useRef<HTMLDivElement>(null);



    const [targetArray, setTargetArray] = useState<any>();
    const [targetMonth, setTargetMonth] = useState(3);
    const [targetDate, setTargetDate] = useState(1);
    const [targetDay, setTargetDay] = useState("월");
    const [rows, setRows] = useState([]);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);
    const handleOpen3 = () => setOpen3(true);
    const handleClose3 = () => setOpen3(false);

    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const [eachWeekDivHeight, setEachWeekDivHeight] = useState(0);

    const calculateEachWeekHeight = () => {

        setTimeout(() => {

            if (!topDivRef || !topDivRef.current) {
                return;
            }

            const totalHeight = window.innerHeight;
            const value1 = +getComputedStyle(document.documentElement).getPropertyValue("--sat").split("p")[0];
            const value2 = +getComputedStyle(document.documentElement).getPropertyValue("--sab").split("p")[0];
            const topDivHeight = topDivRef.current.offsetHeight;

            const bodyHeight = totalHeight - value1 - value2 - topDivHeight;
            const eachWeekHeight = bodyHeight / 6;
            setEachWeekDivHeight(eachWeekHeight);

        }, 15);


    }

    useEffect(() => {

        calculateEachWeekHeight();

        window.addEventListener("resize", () => calculateEachWeekHeight());

        return window.removeEventListener("resize", () => calculateEachWeekHeight());


    }, [topDivRef]);

    async function start(id: number) {
        setLoading(true);
        var token: any = "";
        //-----// 만약 electron 이라면 저장되어 있는 토큰 가져오는 기능----------

        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        if (Capacitor.isNativePlatform()) {
            const { value } = await Preferences.get({ key: "token" });
            token = value;
        }

        //------------------------------------------------------------------

        fetch(`https://peetsunbae.com/fingerprint/totalMonthStampRealForMobile`, {
            method: "GET",
            credentials: "include",
            headers: { 'Authorization': token }
        }).then((response) => {
            response.json()
                .then((result) => {
                    

                    if (result.message !== "success") {
                        setLoading(false);
                        return;
                    }

                    const newArray: any = [];
                    for (var i = 0; i < 31; i++) {
                        newArray.push([]);
                    }

                    result.data.forEach((each: any) => {
                        const date = new Date(+each.time);
                        const targetDate = date.getDate();

                        if(each.koreanKinds === "대치점"){
                            each.koreanKinds = "대치2층";
                        }

                        each.hours = date.getHours();
                        each.minutes = date.getMinutes();
                        each.hoursString = each.hours < 10 ? "0" + each.hours : each.hours;
                        each.minutesString = each.minutes < 10 ? "0" + each.minutes : each.minutes;
                        each.countedTime = each.hours * 60 + each.minutes;
                        newArray[targetDate - 1].push(each);
                    });

                    const newRows: any = [];
                    result.demerit.forEach((each: any) => {
                        const oneRow: any = {};
                        const date = new Date(each.createdAt);
                        const month = date.getMonth() + 1;
                        const dateNumber = date.getDate();

                        oneRow.id = each.id;
                        oneRow.score = each.score;
                        oneRow.description = each.description;
                        oneRow.date = `${month}/${dateNumber}`

                        newRows.push(oneRow);
                    })

                    var newTotalScore = 0;

                    if (result.demeritTotal.length > 0) {
                        newTotalScore = result.demeritTotal[0].totalDemerit;
                    }

                    
                    setTotalScore(newTotalScore)
                    setData(newArray);
                    setRows(newRows);
                    setLoading(false);
                })
        }).catch(error => {
            
            setLoading(false);
        })
    }



    useEffect(() => {

        start(2);

    }, [])
    //-----------------------------------------------------------------------

    const showDetail = (e: any, month: number, day: number, dayNumber: number) => {
        if (month != new Date().getMonth() + 1) {
            return;
        }

        setTargetDate(day);
        setTargetMonth(month);
        setTargetArray(data[day - 1]);

        switch (dayNumber) {
            case 0:
                setTargetDay("일");
                break;
            case 1:
                setTargetDay("월");
                break;
            case 2:
                setTargetDay("화");
                break;
            case 3:
                setTargetDay("수");
                break;
            case 4:
                setTargetDay("목");
                break;
            case 5:
                setTargetDay("금");
                break;
            case 6:
                setTargetDay("토");
                break;
        }


        handleOpen();
    }



    useEffect(() => {
        const date = new Date();
        const monthFirstDate = new Date(date.getFullYear(), date.getMonth(), 1);
        const monthLastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        const monthFirstDay = monthFirstDate.getDay();
        

        const count = -(monthFirstDay) + 1;
        

        const calendarFirstDate = new Date(date.getFullYear(), date.getMonth(), count);

        const array = [1, 2, 3, 4, 5, 6];

        const data: any = [];
        array.forEach((each: any) => {
            const weekData: any = [];
            for (var i = 0; i < 7; i++) {
                const row: any = {};

                row.month = calendarFirstDate.getMonth() + 1;
                row.day = calendarFirstDate.getDate();

                weekData.push(row);

                calendarFirstDate.setDate(calendarFirstDate.getDate() + 1);
            }
            data.push(weekData);
        })

        

        setDateList(data);

    }, [])



    return (
        <div className={styles.main}>

            <div className={styles.topDiv} ref={topDivRef}>
                <InnerHeader title="출석기록" ref={headerRef} submitBtn={"벌점 보기"} handleClickSubmitBtn={handleOpen3} />
                {
                    loading
                    &&
                    <LinearProgress />
                }

                <div className={styles.dayList}>
                    {
                        dayList.map((each: string, index: number) => {
                            return (
                                <div key={each} className={`${styles.dayTitle} ${index === 0 ? styles.active : ""}`}>
                                    {each}
                                </div>
                            )
                        })
                    }
                </div>
            </div>


            {
                !loading
                &&
                <div className={styles.dateList}>
                    {
                        dateList && dateList.map((eachWeek: any, dayNumber: number) => {
                            return (
                                <div key={Math.random()} className={styles.eachWeekDiv} style={{ height: `${eachWeekDivHeight}px` }}>
                                    {
                                        eachWeek.map((eachDay: any, index: number) => {
                                            return (
                                                <div onClick={(e) => { showDetail(e, eachDay.month, eachDay.day, index) }} key={eachDay.day} className={`${styles.eachDay} ${index % 7 === 0 ? styles.sunday : ""} ${eachDay.month !== new Date().getMonth() + 1 ? styles.deactive : ""}`} >
                                                    {eachDay.day}
                                                    {
                                                        data && data.map((eachData: any, indexNumber: number) => {
                                                            if (eachDay.month === new Date().getMonth() + 1) {
                                                                if (indexNumber + 1 === eachDay.day) {
                                                                    var totalTime = 0;

                                                                    if (eachData && eachData.length > 0) {
                                                                        totalTime = eachData[eachData.length - 1].countedTime - eachData[0].countedTime;
                                                                    }

                                                                    return (
                                                                        <div key={eachData.id}>
                                                                            {
                                                                                eachData.length > 0 &&
                                                                                <div className={`${styles.time} ${styles.first}`}>
                                                                                    {eachData[0].hoursString}:{eachData[0].minutesString}
                                                                                </div>

                                                                            }
                                                                            {
                                                                                (eachData.length > 1 && +eachData[0].time < deadline) &&
                                                                                <div className={styles.time}>
                                                                                    {eachData[eachData.length - 1].hoursString}:{eachData[eachData.length - 1].minutesString}
                                                                                </div>
                                                                            }
                                                                            {
                                                                                (eachData.length > 1 && +eachData[0].time < deadline && totalTime > 0) &&
                                                                                <div className={`${styles.time} ${styles.last}`}>
                                                                                    {Math.floor(totalTime / 60)}h{totalTime % 60}m
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    );
                                                                }
                                                            }
                                                        })
                                                    }
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            );
                        })
                    }
                </div>
            }



            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className={styles.modalMain}>
                        <div className={styles.modalDay}>
                            {targetDay}요일
                        </div>
                        <div className={styles.modalDate}>
                            {targetMonth}월 {targetDate}일
                        </div>
                        <div className={styles.modalBorder}>
                        </div>
                        <div className={styles.records}>
                            {
                                (targetArray && targetArray.length > 0) &&
                                targetArray.map((eachData: any, index: number) => {
                                    return (
                                        <div key={index} className={styles.eachMove}>
                                            <div className={`${styles.colorBar} ${eachData.direction === "inside" ? styles.inside : ""}`}>

                                            </div>
                                            <div className={styles.modalTimeDiv}>
                                                <div className={styles.modalTime}>
                                                    {
                                                        (targetArray && targetArray.length > 0) &&
                                                        `${eachData.hoursString}:${eachData.minutesString}`
                                                    }
                                                </div>
                                                <div className={`${styles.timeDescription} ${eachData.direction === "inside" ? styles.none : ""}`}>
                                                    입실 {(index > 0 && eachData.direction === "outside") ? `(외출 ${data[targetDate - 1][index].countedTime - data[targetDate - 1][index - 1].countedTime}분 경과)` : ""}
                                                    <span style={{color : "black", fontWeight : 700}}>{eachData.other ? ` - ${eachData.koreanKinds}` : ""}</span>
                                                </div>
                                                <div className={`${styles.timeDescription} ${eachData.direction === "outside" ? styles.none : ""}`}>
                                                    퇴실
                                                    <span style={{color : "black", fontWeight : 700}}>{eachData.other ? ` - ${eachData.koreanKinds}` : ""}</span>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                            <div className={styles.voidHeight}>

                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>


            <Modal
                open={open2}
                onClose={handleClose2}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style2}>
                    <div>
                        <div className={styles.secondModalDescription}>
                            각 일자별 자세한 출입기록을 보시려면 해당 날짜 칸을 클릭해주세요!
                        </div>
                        <div className={styles.btnDiv}>
                            <div>

                            </div>
                            <div>
                                <Button onClick={handleClose2} variant="contained" sx={{ backgroundColor: "#1b49af", width: "30px", fontSize: "12px", height: "26px", borderRadius: "14px" }}>
                                    <span className={styles.btnText}>
                                        확인
                                    </span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={open3}
                onClose={handleClose3}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style3}>
                    <div style={{ height: "350px", width: "100%", paddingBottom: "24px" }}>
                        <StyledDataGrid hideFooter={true} columns={columns} rows={rows} />
                        <div style={{ fontFamily: "Apple_SB", textAlign: "end", marginTop: "8px" }}>
                            이번달 누적 벌점 : {totalScore}점
                        </div>
                    </div>
                </Box>
            </Modal>

            {/* <IonLoading
                cssClass='my-custom-class'
                isOpen={loading}
                onDidDismiss={() => setLoading(false)}
                message={'잠시만 기다려주세요'}
            /> */}

        </div>
    );
}

export default AttendanceMobileReal;