import React, { useEffect, useState, useRef } from 'react';
import { Backdrop, CircularProgress, Fab, LinearProgress } from '@mui/material';
import Modalcomp from '../components/modalcomp';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GangnamSeatSvg from '../../etc/gangnamseatsvg';
import DaechiSeatSvg from '../../etc/daechiseatsvg';
import LocationSelect from '../components/locationselect';
import Daechi2SeatSvg from '../../etc/daechi2seatsvg';
import Daechi3SeatSvg from '../../etc/daechi3seatsvg';
import { renderSeatSvg } from '../../../../data/locationFuntion';
import totalLocationData from '../../../../data/locationdata';

var eventListenrs: any[] = [];

const PatrolManager: React.FC<any> = (props: any) => {


    const [where, setWhere] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [open2, setOpen2] = useState<boolean>(false);

    const [selectedName, setSelectedName] = useState<string>("");
    const [selectedId, setSelectedId] = useState<number>(0);
    const [selectedSeat, setSelectedSeat] = useState<number>(0);

    const [isScanning, setIsScanning] = useState<boolean>(false);

    const [locationCount, setLocationCount] = useState<number>(0);

    useEffect(() => {

        if(!props.user || !props.user.academy){
            return;
        }

        const academy = props.user.academy;

        const filteredLocationData = totalLocationData.filter((locationData) => {

            if(locationData.english === "total"){
                return false;
            }

            return locationData.academy.includes(academy);
        })

        setLocationCount(filteredLocationData.length);

        const firstLocationData = filteredLocationData[0];

        setWhere(firstLocationData.english);

    }, [props.user]);


    const notify1 = () => toast.success("전송 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored"
    });

    const notify2 = () => toast.success("하원 처리 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored"
    });

    const handleLoading = (status: boolean) => {
        setLoading(status);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleClose2 = () => {
        setOpen2(false);
    }

    useEffect(() => {

        if (!props.socket) {
            return;
        }

        
        

        props.socket.emit("joinAttendanceCheckRoom", where);

        props.socket.on("attendanceCheckUpdate", (fingerprintId: number, location: string, direction: string) => {
            
            
            
            getInfo(where, false);
        });

        return () => {
            props.socket.emit("leaveAttendanceCheckRoom", where);
            props.socket.off("attendanceCheckUpdate");
        }

    }, [props.socket, where]);


    useEffect(() => {

        if (!where) {
            return;
        }

        getInfo(where);

        return () => {

        }
    }, [where])


    const getInfo = async (location: string, isFirst = true) => {

        if (!location) {
            return;
        }

        try {
            if (isFirst) {
                setLoading(true);
            }

            const response = await fetch(`https://peetsunbae.com/dashboard/avatar/seatlist1592?location=${location}`, {
                method: "GET"
            });

            const result = await response.json();

            

            if (result.message === "success") {
                setLoading(false);
                const data = result.data;
                drawSeat(data, location);
            }

        } catch (e) {
            
            
        }

    }


    const drawSeat = (data: any[], location : string) => {

        

        const handleClick = (userId: number, name: string, seat: number, element : any) => () => {

            

            if (!userId || !name || !seat) {
                
                return;
            }

            
            

            setSelectedId(userId);
            setSelectedName(name);
            setSelectedSeat(seat);

            setOpen(true);
        };

        const handleExitClick = (userId: number, name: string, seat: number) => async () => {

            const locationData = totalLocationData.find((locationData) => locationData.english === location);

            if(!locationData?.isFree){
                return;
            }


            if (!userId || !name || !seat) {
                
                return;
            }

            setSelectedId(userId);
            setSelectedName(name);
            setSelectedSeat(seat);
            
            if(!window.confirm(`${seat}번 자리 ${name}님을 하원처리 하시겠습니까?`)){
                return;
            }

            setLoading(true);

            const response = await fetch("https://peetsunbae.com/dashboard/report/selectseat/exit", {
                method : "POST",
                headers : {
                    "Content-Type" : "application/json"
                },
                credentials : "include",
                body : JSON.stringify({
                    userId : userId,
                    isTeacher : true
                })
            })

            const result = await response.json();

            setLoading(false);

            if(result.message === "success"){
                notify2();
                getInfo(location, false);
            }

        };

        data.forEach((eachData: any) => {

            eachData.isNowInClass = false;

            if (eachData.accessControlData.length === 0) {
                return;
            }

            if (eachData.accessControlData[0].direction === "outside") {
                eachData.isNowInClass = true;
            }

        })

        for (var i = 1; i < 301; i++) {
            for (var j = 0; j < 3; j++) {

                var targetId = i * 10 + j;

                const element = document.getElementById(targetId.toString());
                //

                if (!element) {
                    continue;
                }

                //element 밑에 있는 태그 path를 가져온다.
                const path = element.getElementsByTagName("path")[0];

                //path의 fill을 red로 바꾼다.


                //element밑에 있는 text를 가져온다.
                const text = element.getElementsByTagName("text")[0];
                //text의 text-anchor를 middle로 바꾼다.
                text.setAttribute("text-anchor", "middle");


                //element밑에 있는 tspan을 가져온다.
                const tspan = element.getElementsByTagName("tspan")[0];

                var name = "";

                //tspan의 글씨 크기를 바꾼다.
                tspan.setAttribute("font-size", "16px");

                if (targetId % 10 === 1) {

                    data.forEach((eachData: any) => {
                        if (!eachData.seat) {
                            return;
                        }

                        //i값이랑 eachData.seat이랑 같으면 eachData.name을 name에 추가한다.
                        if (i === +eachData.seat) {
                            name += eachData.name;
                        }
                    })
                    //tspan의 text에 name을 넣는다.
                    tspan.textContent = name;
                }

                //data.isNowInClass가 true면 tspan의 background color를 blue로 false면 red로 바꾼다.
                data.forEach((eachData: any) => {
                    if (!eachData.seat) {
                        return;
                    }

                    if (i === +eachData.seat) {
                        if (eachData.isNowInClass) {
                            //tspan.setAttribute("fill", "#0B6BCB");
                            tspan.setAttribute("fill", "blue");
                            const listner = element.addEventListener("click", handleClick(eachData.userId, eachData.name, +eachData.seat, element));
                            eventListenrs.push(listner);
                        } else {
                            tspan.setAttribute("fill", "#C41C1C");
                            const listner = element.addEventListener("click", handleExitClick(eachData.userId, eachData.name, +eachData.seat));
                            eventListenrs.push(listner);
                        }
                    }
                })



                //tsapn의 attribute중 x를 값을 바꾼다.
                //27.5가 정중앙
                tspan.setAttribute("x", "45.5");
                //tspan.setAttribute("y", "18.7");
                tspan.setAttribute("y", "19.2");

                if(location === "molip"){
                    if(i >= 86 && i <= 92){
                        tspan.setAttribute("x", "60");
                    }

                    if(i >= 99 && i <= 102){
                        tspan.setAttribute("x", "70");
                    }

                    if(i >= 93 && i <= 98){
                        tspan.setAttribute("x", "52");
                    }

                    if(i>=103 && i <=105){
                        tspan.setAttribute("x", "52");
                    }  

                    if(i === 300){
                        tspan.setAttribute("x", "102");
                    }
                }


                if (targetId % 10 === 2) {

                    tspan.textContent = "";
                    const path = element.getElementsByTagName("path")[0];

                    //path의 transform 속성을 가져온다.
                    const transform = path.getAttribute("transform");

                    //transform의 형식은 translate(x, y)이다.
                    //x값을 가져온다.
                    const x = transform?.split(" ")[0].split("(")[1];
                    //y값을 가져온다.
                    const y = transform?.split(" ")[1].split(")")[0];

                    const foreignObject = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
                    foreignObject.setAttribute('x', x?.toString() || "");
                    foreignObject.setAttribute('y', y?.toString() || "");
                    foreignObject.setAttribute('width', '91.1');
                    foreignObject.setAttribute('height', '25.7');

                    // input 태그 생성
                    const input = document.createElement('input');
                    input.type = 'text';
                    input.style.width = '100%';
                    input.style.height = '100%';
                    input.style.border = 'none';
                    input.style.backgroundColor = 'transparent';
                    input.style.fontFamily = '맑은 고딕';
                    input.style.fontSize = '16px';
                    //input.style.color = '#C41C1C';
                    input.style.textAlign = 'center';
                    input.value = "";

                    // input.addEventListener("change", (e) => {
                    //     handleInputChange(e, i);
                    // });

                    data.forEach((eachData: any) => {
                        if (!eachData.seat) {
                            return;
                        }

                        if (i === +eachData.seat) {
                            input.value = eachData.seatMemo;
                        }

                    })


                    // input.addEventListener("change", createChangeHandler(i));
                    // foreignObject.appendChild(input);
                    // element.appendChild(foreignObject);


                }

            }
        }

    }

    const handleLocationChange = (location : string) => {
        setWhere(location);
    }



    return (
        <div>
            <Backdrop open={loading}>
                <CircularProgress color='primary' />
            </Backdrop>
            <div>
                <div style={{
                    width : `${locationCount * 85}px`
                }}>
                    <LocationSelect handleLocationChange={handleLocationChange} user={props.user} />
                </div>
            </div>
            <div style={{
            }}>
                <div style={{
                    width: "60%"
                }}>
                    {/* {
                        where === 'gangnam' ?
                            <GangnamSeatSvg />
                            :
                            where === 'daechi' ?
                                <DaechiSeatSvg />
                                :
                                where === 'daechi2' ?
                                <Daechi2SeatSvg />
                                :
                                where === "daechi3" ?
                                <Daechi3SeatSvg />
                                :
                                ""
                    } */}
                    {
                        (props.user && props.user.academy) &&
                        renderSeatSvg(where, props.user)
                    }
                </div>
            </div>


            <Modalcomp open={open} handleClose={handleClose} userId={selectedId} name={selectedName} seat={selectedSeat} handleLoading={handleLoading} loading={loading} notify1={notify1} />


        </div>
    );
}

export default PatrolManager;

