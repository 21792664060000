import { Button, Fab, IconButton, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Backdrop, CircularProgress } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos, ArrowUpward } from '@mui/icons-material';
import { set } from 'lodash';
import { dir } from 'console';

const CatchBioStarError = (props: any) => {

    const [menu, setMenu] = useState(1);
    const [totalUsers, setTotalUsers] = useState<any>();
    const [logs, setLogs] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [targetDate, setTargetDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date().getHours() + 1, 0, 0));
    const [previousTargetDate, setPreviousTargetDate] = useState<any>();

    useEffect(() => {

        if (targetDate) {
            const previousDate = new Date(targetDate);
            previousDate.setHours(targetDate.getHours() - 1);
            setPreviousTargetDate(previousDate);
        }

    }, [targetDate]);

    const getAllUsers = async () => {

        setLoading(true);

        const response = await fetch("https://peetsunbae.com/dashboard/report/notGangnamBiostarUsers", {
            method: "GET",
            credentials: "include"
        });

        const data = await response.json();

        const users: any = [];

        data.data.forEach((eachData: any) => {
            users.push(eachData.User);
        })

        

        //이름 순으로 정렬
        const sortedUsers = users.sort((a: any, b: any) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        })

        setTotalUsers([...sortedUsers]);

        setLoading(false);

    }

    const getBiostarLog = async (year : number, month : number, day : number, hours : number, minutes : number) => {
            
            setLoading2(true);
    
            const response = await fetch("https://peetsunbae.com/dashboard/report/getBiostarFaceLog", {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    year : year,
                    month : month,
                    day : day,
                    hours : hours,
                    minutes : minutes
                })
            });
    
            const data = await response.json();
            const logs = data.data;
            
            
            setLogs([...logs]);
    
            setLoading2(false);
    
    }

    useEffect(() => {
        getAllUsers();
    }, []);

    useEffect(() => {
        getBiostarLog(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate(), targetDate.getHours(), targetDate.getMinutes());
    }, [targetDate]);

    const onClick = (direction : string) => {
        if(direction === "left"){

            const changedDate = new Date(targetDate);
            changedDate.setHours(targetDate.getHours() - 1);
            setTargetDate(changedDate);

        }

        if(direction === "right"){

            const changedDate = new Date(targetDate);
            changedDate.setHours(targetDate.getHours() + 1);
            setTargetDate(changedDate);

        }
    }


    return (
        <div>
            <div>
                <ToggleButtonGroup value={menu} exclusive onChange={(event, newMenu) => setMenu(newMenu)} color='primary'>
                    <ToggleButton value={1}>출입기록</ToggleButton>
                    <ToggleButton value={2}>모든 회원</ToggleButton>
                </ToggleButtonGroup>
            </div>

            <div style={{marginTop : "24px"}}>
                {
                    previousTargetDate &&
                    `${previousTargetDate.getFullYear()}/${previousTargetDate.getMonth() + 1}/${previousTargetDate.getDate()} ${previousTargetDate.getHours()}:${previousTargetDate.getMinutes()} - ${targetDate.getFullYear()}/${targetDate.getMonth() + 1}/${targetDate.getDate()} ${targetDate.getHours()}:${targetDate.getMinutes()}`
                }
            </div>

            <div style={{marginTop : "12px"}}>
                <IconButton
                onClick={() => onClick("left")}
                >
                    <ArrowBackIos />
                </IconButton>
                <IconButton
                onClick={() => onClick("right")}
                >
                    <ArrowForwardIos />
                </IconButton>
            </div>
            

            {
                (loading || loading2) &&
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "48px"
                }}>
                    <CircularProgress size={60} />
                </div>
            }

            {/* <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading || loading2}
            >
                <CircularProgress color="inherit" />
            </Backdrop> */}

            {
                menu === 1 &&
                <div>
                    {
                        (logs && totalUsers) && logs.map((eachLog: any, index: number) => {

                            var imageSrc = "";
                            const date = new Date(eachLog.datetime);

                            const dateString = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

                            if(eachLog && eachLog.image){
                                const base64string = eachLog.image;
                                imageSrc = `data:image/jpeg;base64,${base64string}`;
                            }

                            const targetUser = totalUsers.find((eachUser: any) => eachUser.user_id === eachLog.user_id.user_id);
                            var targetUserImageSrc = "";
                            //targetUser의 사진을 가져온다
                            if (targetUser && targetUser.credentials && targetUser.credentials.visualFaces && targetUser.credentials.visualFaces.length > 0) {
                                const base64string = targetUser.credentials.visualFaces[0].template_ex_normalized_image;
                                targetUserImageSrc = `data:image/jpeg;base64,${base64string}`;
                            }

                            return (
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "16px"
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        marginRight: "24px",
                                        fontWeight : 600
                                    }}>
                                        <div>{eachLog.user_id.user_id}</div>
                                        <div>{eachLog.user_id.name}</div>
                                        <div>{dateString}</div>
                                    </div>
                                    <div>
                                        {
                                            (imageSrc) &&
                                            <img src={imageSrc} alt="" style={{width : "200px"}} />
                                        }
                                    </div>
                                    <div style={{width : "60px"}}>
                                    </div>
                                    <div>
                                        {
                                            (targetUserImageSrc) &&
                                            <img src={targetUserImageSrc} alt="" style={{width : "200px"}} />
                                        }
                                    </div>
                                    
                                </div>
                            )

                        })
                    }
                </div>
            }

            {
                menu === 2 &&
                <div style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(8, 1fr)",
                    rowGap: "16px",
                    marginTop: "32px"
                }}>
                    {
                        totalUsers && totalUsers.map((eachUser: any, index: number) => {

                            var imageSrc = "";

                            if (eachUser.credentials && eachUser.credentials.visualFaces && eachUser.credentials.visualFaces.length > 0) {
                                const base64string = eachUser.credentials.visualFaces[0].template_ex_normalized_image;
                                imageSrc = `data:image/jpeg;base64,${base64string}`;

                                if (index < 3) {
                                    
                                }
                            }

                            return (
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}>
                                    <div>{eachUser.user_id}</div>
                                    <div>{eachUser.name}</div>
                                    <div>
                                        {
                                            (imageSrc) &&
                                            <img src={imageSrc} alt="" />
                                        }
                                    </div>
                                </div>
                            )

                        })
                    }
                </div>
            }

            <Fab
                aria-label="scroll back to top"
                size="large"
                color="primary"
                style={{
                    position: "fixed",
                    bottom: "32px",
                    right: "32px"
                }}
                onClick={() => props.scrollTop()}
            >
                <ArrowUpward />
            </Fab>


        </div>
    )

}

export default CatchBioStarError;