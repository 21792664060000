import { getFetch, postFetch } from "../fetch/fetch";

import { Market } from "@ionic-native/market";
import { InAppBrowser } from '@ionic-native/in-app-browser';
import {Capacitor} from "@capacitor/core";
import {App, AppInfo} from "@capacitor/app";

interface data {
    androidUrl: string;
    iosUrl: string;
    isForcedUpdate : boolean;
}


export const getAvatarSrc = async () => {

    
    
    try{
        const result : any = await getFetch("/dashboard/profile/avatar");
        if(result.message === "success"){
            const index = result.index;
            if(index !== 0){
                return `/img/avatar/Avatar-${index}.png`;
            }else{
                return ;
            }
        }

    }catch(e : any){
        
    }

}



const letsGetServerVersion : (arg0 : AppInfo) => Promise<data> = async (appInfo : AppInfo) => {

    return new Promise(async (resolve, reject) => {
      try {
        const response : any = await postFetch("/realVersion", appInfo);

        

        if(response.message !== "success"){
          
          return;
        }

        const result = response.data as data;
        
        

        const data = {
            androidUrl : result.androidUrl,
            iosUrl : result.iosUrl,
            isForcedUpdate : result.isForcedUpdate
        }

        

        resolve(data);

      } catch (e) {
        
        
        reject(e);
      }
    })
  }

  const letsGetInfo : () => Promise<AppInfo> = async () => {

    return new Promise(async (resolve, reject) => {
      try{
        const appInfo = await App.getInfo();
        
        
        
        
        resolve(appInfo);
      }catch(e){
        
        reject("");
      }
    })
  }

  const goUpdate = (presentAlert : any, androidUrl : string, iosUrl : string) => {
    
    
    presentAlert({
      header : "필수 업데이트",
      subHeader : "업데이트 후에 앱 사용 가능",
      buttons : [
        {
          text : "업데이트",
          role : "confirm",
          handler : () => {
            goNativeStore(androidUrl, iosUrl);
          }
        }
      ],
      backdropDismiss : false
    })

  }

  const goNativeStore = (androidUrl : string, iosUrl : string) => {

    if(Capacitor.getPlatform() === "ios"){
      
      InAppBrowser.create(iosUrl);
    }

    if(Capacitor.getPlatform() === "android"){
      
      // InAppBrowser.create(androidUrl);
      Market.open(androidUrl);
    }

  }

  const compareVersion = async (presentAlert : any, handleOpen : (boolean : boolean) => void, setUrl : (url : string) => void) => {
    try{
      
      const appInfo = await letsGetInfo();
      const data = await letsGetServerVersion(appInfo);


      if(data.isForcedUpdate){
        

        if(Capacitor.getPlatform() === "android"){
          setUrl(data.androidUrl);
        }else if(Capacitor.getPlatform() === "ios"){
          setUrl(data.iosUrl);
        }else{
          setUrl("");
          return;
        }

        handleOpen(true);
        // goUpdate(presentAlert, data.androidUrl, data.iosUrl);
      }


    }catch(e){
      
    }
  }


export const checkMobileMainUpdate = (presentAlert : any, handleOpen : (boolean : boolean) => void, setUrl : (url : string) => void) => {
    compareVersion(presentAlert, handleOpen, setUrl);
}
