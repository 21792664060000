import React, {useEffect, useState, useRef} from "react";
import { Capacitor } from "@capacitor/core";
import styles from "./mobileopinion.module.css";
import { Helmet } from "react-helmet";
import {ReactComponent as ArrowLeftLight} from "../../../src/svg/arrow-left-light.svg";
import { useHistory } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";
import MobileMyOpinion from "./mobilemyopinion";
import MobileOpinionWrite from "./mobileopinionwrite";

const list = [
    {
        id : 1,
        title : "건의사항 작성",
        value : ["teacher", "staff", "student", "parent"]
    },
    {
        id : 2,
        title : "나의 건의사항",
        value : ["teacher", "staff", "student", "parent"]
    },
    {
        id : 3,
        title : "전체 건의사항",
        value : ["teacher", "staff", "student", "parent"]
    }
]

const MobileOpinionMain: React.FC<any> = (props) => {

    const [platform, setPlatform] = useState("");
    const history = useHistory();
    const listRef = useRef<any>(null);
    const eachRef = useRef<any>([]);
    const [index, setIndex] = useState(0);

    //smoothscroll
    useEffect(() => {
        smoothscroll.polyfill();
    }, [])

    //플랫폼 정보 확인
    useEffect(() => {
        const platform = Capacitor.getPlatform();

        if (platform) {
            setPlatform(platform);
        }

        
        

    }, []);

    const mobileChange = (eachIndex: number) => {
        setIndex(eachIndex);
        const targetScroll = eachRef.current[eachIndex].getBoundingClientRect().x;
        listRef.current.scrollTo({ left: targetScroll + listRef.current.scrollLeft - 80, behavior: "smooth" });
    }


    return (
            <div className={`${styles.main} ${platform === "ios" ? styles.ios : ""}`}>
                <Helmet>
                    <meta name="viewport" content="initial-scale=1, maximum-scale=1, user-scalable=no, width=device-width, viewport-fit=cover" />
                </Helmet>

                <MobileMyOpinion />

                {/* <MobileOpinionWrite /> */}



            </div>
    )
}

export default MobileOpinionMain;