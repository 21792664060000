import React, { useState, useEffect } from "react";
import {
    DataGridPremium,
    GridColDef,
    GridRowsProp,
    GridRowSelectionModel,
    useGridApiRef,
    GridGroupNode,
    GRID_ROOT_GROUP_ID,
    GridCellSelectionModel,
    GridFilterModel,
    GridLogicOperator,
    GridRowModel,
    GridRowClassNameParams,
} from '@mui/x-data-grid-premium';
import { renderCellExpand } from "../infos/suddenData";
import { set } from "lodash";
import { makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';


const defaultTheme = createTheme();

const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);


        return {
            root: {
                '& .suspicious_in': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    color: "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .suspicious': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .once': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .once_in': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    color: "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    }
                },
                '& .never': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .never_in': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    color: "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .clickable': {
                    cursor: "pointer",
                    '&:hover': {
                        color: "blue"
                    }
                },
                '& .out': {
                    color: "red"
                },
                '& .in': {
                    color: "blue"
                },
                '& .phone_memo': {
                    color: "black"
                },
                '& .status2': {
                    backgroudColor: "red"
                },
                '& .status1': {
                    backgroudColor: "red"
                },
                '& .status4': {
                    backgroudColor: "red"
                },
                '& .status5': {
                    backgroudColor: "red"
                }
            },
        };
    },
    { defaultTheme },
);

const UserAccesscontrol: React.FC<any> = (props) => {

    const classes = useStyles2();
    const [rows, setRows] = useState<GridRowsProp[]>([])
    const [columns, setColumns] = useState<GridColDef[]>([
        { field: "status", headerName: "방향", width: 80 },
        { field: "time", headerName: "시간", width: 120, renderCell : renderCellExpand },
        { field : "location", headerName : "장소", width : 120}
    ]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {

        if(!props.open){
            setRows([]);
            return;
        }

        start();


    }, [props.open]);

    const start = async () => {

        if(!props.open){
            setRows([]);
            return;
        }

        if(!props.targetDate){
            setRows([]);
            return;
        }

        if(!props.studentId){
            setRows([]);
            return;
        }

        setLoading(true);

        const accessData = await getAccessData();

        if(accessData.length === 0){
            setLoading(false);
            setRows([]);
            return;
        }else{
            makeRows(accessData);
        }

        setLoading(false);
    
    
    }

    const getAccessData = async () => {

        try{

            const response = await fetch(`https://peetsunbae.com/dashboard/report/demerit/accesscontrol?studentId=${props.studentId}&targetDateTime=${props.targetDate.getTime()}`, {
                method : "GET",
                credentials : "include"
            });

            const result = await response.json();

            if(result.message === "success"){
                return result.data;
            }else{
                return [];
            }

        }catch(e){
            
        }

    }

    const makeRows = (accessData : any[]) => {

        const newRows: GridRowsProp[] = [];



        accessData.forEach((eachAccess : any) => {

            const oneRow : any = {};

            oneRow.id = eachAccess.id;

            if(eachAccess.direction === "inside"){
                oneRow.status = "out";
            }

            if(eachAccess.direction === "outside"){
                oneRow.status = "in";
            }

            if(eachAccess.time){
                
                const date = new Date(+eachAccess.time);
                //HH:MM:SS
                const dateString = `${date.getHours() < 10 ? "0" + date.getHours() : date.getHours() }:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}:${date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()}`;
                oneRow.time = dateString;

            }

            if(eachAccess.other){
                oneRow.location = eachAccess.koreanKinds;

                if(oneRow.location === "대치점"){
                    oneRow.location = "대치2층";
                }
            }

            newRows.push(oneRow);

        })

        setRows([...newRows]);

    };


    return (
        <div className={classes.root}>
            <DataGridPremium
                loading={loading}
                sx={{
                    height : "300px"
                }}
                rows={rows}
                columns={columns}
                hideFooter={true}
                density="compact"
                getCellClassName={(params) => {

                    if(params.field === "status"){

                        if(params.value === "in"){
                            return "in";
                        }

                        if(params.value === "out"){
                            return "out";
                        }

                    }

                    return "";

                }}
            />
        </div>

    );

}

export default UserAccesscontrol;