import React, { useEffect, useState, useRef } from "react";
import styles from "./mobilewifipermit.module.css";
import { ReactComponent as Check } from "../../../src/svg/check-light.svg";
import { ReactComponent as Xmark } from "../../../src/svg/xmark-light.svg";
import { ReactComponent as ArrowUp } from "../../../src/svg/arrow-up-light.svg";
import { ReactComponent as Trash } from "../../../src/svg/trash-can-regular.svg";
import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import { IonButton, useIonActionSheet } from '@ionic/react';
import { IonLoading } from "@ionic/react";
import { returnDayString } from "../etc/functions";
import { Skeleton } from "@mui/material";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const style = {
    outline: "none",
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    height: "65%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1.5,
    p: 0,
    overflow: "hidden"
};

const MobileEditStatus: React.FC<any> = (props) => {

    const [checkResult, setCheckResult] = useState<any>();
    const [present] = useIonActionSheet();
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const topRef = useRef<any>(null);
    const [descriptionHeight, setDescriptionHeight] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [targetArray, setTargetArray] = useState<any>();
    const [userName, setUserName] = useState("");
    const [serverId, setServerId] = useState("");

    const handleClose = () => setOpen(false);

    useEffect(() => {

        

        

        if (!topRef || !topRef.current || !props.statusDivHeight) {
            return;
        }

        const caculateBodyHeight = () => {

            

            if (!topRef || !topRef.current || !props.statusDivHeight) {
                return;
            }

            const totalHeight = props.statusDivHeight;
            const topHeight = topRef.current.offsetHeight;

            const descriptionHeight = totalHeight - topHeight - (2.469 * 16);

            
            

            setDescriptionHeight(descriptionHeight);
        }

        caculateBodyHeight();

        window.addEventListener("resize", () => caculateBodyHeight());

        return window.removeEventListener("resize", () => caculateBodyHeight());


    }, [topRef, props.statusDivHeight]);



    useEffect(() => {
        start();
    }, []);


    async function start() {
        setLoading(true);
        var token: any = "";
        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        //네이티브 라면 토큰 가져오는 기능
        if (Capacitor.isNativePlatform()) {
            
            const { value } = await Preferences.get({ key: "token" });
            token = value;
        }

        fetch("https://peetsunbae.com/dashboard/wifipermit/status", {
            method: "GET",
            headers: { "Authorization": token },
            credentials: "include",
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    

                    const message = result.message;

                    if (!message) {
                        return;
                    }

                    message.sort(function (a: any, b: any) {
                        const afterTime = new Date(a.createdAt).getTime();
                        const beforeTime = new Date(b.createdAt).getTime();

                        if (afterTime < beforeTime) {
                            return 1;
                        }
                        if (afterTime > beforeTime) {
                            return -1;
                        }
                    })

                    setCheckResult(message);
                    setLoading(false);
                    setServerId(result.serverId);
                    setUserName(result.userName);
                    setTargetArray(result.deviceList);
                })
        })
    }

    const confirmDelete = (id: number) => {
        present({
            header: "삭제 안내",
            subHeader: "삭제를 진행할까요?",
            buttons: [{
                text: "삭제",
                role: "destructive"
            },
            {
                role: "cancel",
                text: "취소",
            }
            ],
            onDidDismiss: ({ detail }) => {
                
                switch (detail.role) {
                    case "destructive":
                        cancel(id);
                        break;
                    case "cancel":
                        break;
                }
            },
        })
    }


    const cancel = async (id: number) => {

        setLoading2(true);

        var token: any = "";
        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        //네이티브 라면 토큰 가져오는 기능
        if (Capacitor.isNativePlatform()) {
            
            const { value } = await Preferences.get({ key: "token" });
            token = value;
        }

        fetch("https://peetsunbae.com/dashboard/edit/delete", {
            method: "DELETE",
            headers: { "Content-Type": "application/json", "Authorization": token },
            credentials: "include",
            body: JSON.stringify({
                id: id
            })
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    
                    if (result.message === "success") {
                        setCheckResult(
                            checkResult.filter((each: any) => each.id != id)
                        )
                    }
                    setLoading2(false);
                })
        })
    }


    return (
        <div className={styles.statusBody}>
            <div className={styles.statusTop} ref={topRef}>
                <div className={styles.statusTitle}>
                    방화벽 해제 신청 현황
                </div>
                <div className={styles.statusNumber} style={{ fontFamily: "Apple_B", color: "#1b49af" }} onClick={() => {setOpen(true);}}>
                    나의 기기
                </div>
            </div>

            {
                loading
                &&
                <div style={{ width: "90.6%", margin: "0 auto" }}>
                    <div style={{ height: "20px" }}>
                    </div>
                    <Skeleton variant="rectangular" width="100%" height={140} />
                    <div style={{ height: "20px" }}>
                    </div>
                    <Skeleton variant="rectangular" width="100%" height={140} />
                    <div style={{ height: "20px" }}>
                    </div>
                    <Skeleton variant="rectangular" width="100%" height={140} />
                    <div style={{ height: "20px" }}>
                    </div>
                </div>
            }

            {
                !loading
                &&
                <div className={styles.description} style={{ height: `${descriptionHeight}px`, overflow: "scroll" }}>
                    <div className={styles.descriptionBody}>

                        {
                            checkResult &&
                            checkResult.map((eachCheckResult: any, index: number) => {

                                const parentPermit = eachCheckResult.permitstatus;

                                

                                const dateInserted = new Date(eachCheckResult.createdAt);
                                var dateInsertedDay = returnDayString(dateInserted.getDay());

                                const dateInsertedString = `${dateInserted.getMonth() + 1}월 ${dateInserted.getDate()}일`;

                                const targetDate = new Date(eachCheckResult.startDate);

                                var canBeDeleted = false;

                                if (targetDate.getTime() > new Date().getTime()) {
                                    canBeDeleted = true;
                                }

                                var targetDateDay = returnDayString(targetDate.getDay());

                                const targetDateString = `${targetDate.getMonth() + 1}월 ${targetDate.getDate()}일 ${targetDateDay}요일`;

                                const startDate = new Date(eachCheckResult.startDate);
                                const endDate = new Date(eachCheckResult.endDate);

                                var startAmPm = "오전";
                                var startHours = startDate.getHours();
                                const prevStartMinutes = startDate.getMinutes();
                                var startMinutes = prevStartMinutes < 10 ? "0" + prevStartMinutes : prevStartMinutes;

                                if (startHours > 11) {
                                    if (startHours > 12) {
                                        startHours = startHours - 12;
                                    }
                                    startAmPm = "오후";
                                }

                                var endAmPm = "오전";
                                var endHours = endDate.getHours();
                                const prevEndMinutes = endDate.getMinutes();
                                var endMinutes = prevEndMinutes < 10 ? "0" + prevEndMinutes : prevEndMinutes;

                                if (endHours > 11) {
                                    endAmPm = "오후";
                                    if (endHours > 12) {
                                        endHours = endHours - 12;
                                    }
                                }




                                return (
                                    <div key={eachCheckResult.id}>
                                        <div className={`${styles.eachDescription} ${checkResult.length - 1 === index ? styles.last : ""}`}>
                                            <div className={styles.eachDescriptionDate}>
                                                신청일 : {dateInsertedString}
                                            </div>
                                            <div className={styles.eachDescriptionBox}>
                                                <div className={styles.eachDescriptionText}>
                                                    {eachCheckResult.reason}
                                                </div>
                                                <div className={styles.eachDescriptionTargetDate}>
                                                    해제 날짜 : {targetDateString}
                                                </div>
                                                <div className={styles.eachDescriptionKindDiv}>
                                                    <div className={styles.eachDescriptionKindText}>
                                                        {startAmPm} {startHours}시 {startMinutes}분 - {endAmPm} {endHours}시 {endMinutes}분
                                                    </div>
                                                    <div className={`${styles.eachDescriptionKindSuccess} ${parentPermit === 0 ? styles.waiting : parentPermit === 1 ? styles.success : (parentPermit === 2 || parentPermit === 4) ? styles.decline : ""}`}>
                                                        {
                                                            (parentPermit === 1 || parentPermit === 3) &&
                                                            <Check fill="#fff" className={styles.check}></Check>
                                                        }
                                                        {
                                                            (parentPermit === 2 || parentPermit === 4) &&
                                                            <Xmark fill="#fff" className={styles.xmark}></Xmark>
                                                        }
                                                        {
                                                            parentPermit === 0 &&
                                                            <ArrowUp fill="#fff" className={styles.arrowUp}></ArrowUp>
                                                        }
                                                        &nbsp;
                                                        <span>
                                                            {
                                                                (parentPermit === 1 || parentPermit === 3) &&
                                                                "승인"
                                                            }
                                                            {
                                                                parentPermit === 2 &&
                                                                "비승인"
                                                            }
                                                            {
                                                                parentPermit === 0 &&
                                                                "대기중"
                                                            }
                                                            {
                                                                parentPermit === 4 &&
                                                                "직원취소"
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                                {/* <div className={`${styles.trashCanDiv} ${canBeDeleted ? "" : styles.none}`} onClick={(e: any) => { confirmDelete(eachCheckResult.id); }}>
                                                    <Trash fill="#251e1c" className={`${styles.trash}`} />
                                                </div> */}
                                            </div>
                                        </div>

                                    </div>
                                );

                            })
                        }




                    </div>
                </div>
            }


            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className={styles.modalMain}>
                        <div className={styles.modalDay}>
                            서버 id : {serverId}
                        </div>
                        <div className={styles.modalDate}>
                            {userName}님의 기기
                        </div>
                        <div className={styles.modalBorder}>
                        </div>
                        <div className={styles.records}>
                            {
                                (targetArray && targetArray.length > 0) &&
                                targetArray.map((eachData: any, index: number) => {
                                    return (
                                        <div key={index} className={styles.eachMove}>
                                            <div className={`${styles.colorBar}`}>

                                            </div>
                                            <div className={styles.modalTimeDiv}>
                                                <div className={styles.modalTime}>
                                                    {
                                                       eachData.name
                                                    }
                                                </div>
                                                <div className={`${styles.timeDescription}}`}>
                                                    {
                                                        eachData.macaddr
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                            {
                                (targetArray && targetArray.length === 0) &&
                                <div className={styles.modalDate} style={{fontWeight : 400}}>
                                    등록된 기기가 없습니다. 🥹<br></br>(방화벽 기기 등록 필요)
                                </div>
                            }
                            <div className={styles.voidHeight}>

                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>



            {/* <IonLoading
                cssClass='my-custom-class'
                isOpen={loading}
                onDidDismiss={() => setLoading(false)}
                message={'잠시만 기다려주세요'}
            /> */}

            <IonLoading
                cssClass='my-custom-class'
                isOpen={loading2}
                onDidDismiss={() => setLoading2(false)}
                message={'잠시만 기다려주세요'}
            />
        </div>
    )
}

export default MobileEditStatus;