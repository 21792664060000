import React, { useEffect, useState, useRef } from "react";
import InnerHeader from "../etc/innerheader";
import styles from "./mobiletimetable.module.css";

import Box from '@mui/joy/Box';
import FormLabel from '@mui/joy/FormLabel';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Sheet from '@mui/joy/Sheet';
import { CssVarsProvider } from "@mui/joy";
import useInterval from "../etc/useInterval";
import { koreanLocationToEnglish, returnLocationClassInfo } from "../../data/locationFuntion";



const MobileTimeTable: React.FC<any> = (props : any) => {

    const [hour1, setHour1] = useState(0);
    const [hour2, setHour2] = useState(0);
    const [minute1, setMinute1] = useState(0);
    const [minute2, setMinute2] = useState(0);

    const [clickedIndex, setClickedIndex] = useState(0);

    const [bodyHeight, setBodyHeight] = useState(0);
    const headerRef = useRef<any>();
    const clockRef = useRef<any>();

    const [currentClass, setCurrentClass] = useState(5);

    // const timeTableData : any = [
    //     {startHours : 8, startMinutes : 0, endHours : 8, endMinutes : 45},
    //     {startHours : 9, startMinutes : 0, endHours : 10, endMinutes : 15},
    //     {startHours : 10, startMinutes : 30, endHours : 11, endMinutes : 45},
    //     {startHours : 11, startMinutes : 45, endHours : 13, endMinutes : 0},
    //     {startHours : 13, startMinutes : 0, endHours : 14, endMinutes : 15},
    //     {startHours : 14, startMinutes : 30, endHours : 15, endMinutes : 45},
    //     {startHours : 16, startMinutes : 0, endHours : 17, endMinutes : 15},
    //     {startHours : 17, startMinutes : 15, endHours : 18, endMinutes : 30},
    //     {startHours : 18, startMinutes : 30, endHours : 19, endMinutes : 30},
    //     {startHours : 19, startMinutes : 45, endHours : 20, endMinutes : 45},
    //     {startHours : 21, startMinutes : 0, endHours : 22, endMinutes : 0},
    // ]

    useEffect(() => {

        if(!props.user){
            return;
        }

        if(!props.user.location){
            return;
        }


        const location = koreanLocationToEnglish(props.user.location);


        if(!location){
            return;
        }

        const classInfo = returnLocationClassInfo(location);

        const timeTableData : any = [];


        classInfo.forEach((each, index) => {
            
            const oneRowTimeTable : any = {};
            oneRowTimeTable.startHours = each.start.hours;
            oneRowTimeTable.startMinutes = each.start.minutes;
            oneRowTimeTable.endHours = each.end.hours;
            oneRowTimeTable.endMinutes = each.end.minutes;

            timeTableData.push(oneRowTimeTable);

        })

        

        timeTableData.forEach((each : any) => {
            each.startTime = each.startHours * 60 + each.startMinutes;
            each.endTime = each.endHours * 60 + each.endMinutes;
        });

        

        var newClass = 0;

        const currentDate = new Date();
        const currentTime = currentDate.getHours() * 60 + currentDate.getMinutes();
        

        timeTableData.forEach((each : any, index : number) => {
            if(each.startTime <= currentTime && each.endTime >= currentTime){
                newClass = index + 1;
            }
        })

        
        
        setCurrentClass(newClass);

    }, [props.user]);

    const caculateBodyHeight = () => {
        setTimeout(() => {

            if (!headerRef || !headerRef.current) {
                return;
            }

            if(!clockRef || !clockRef.current){
                return;
            }


            const value = +getComputedStyle(document.documentElement).getPropertyValue("--sat").split("p")[0];
            const totalHeight = window.innerHeight;
            const safeareaTop = value;
            const safeareaBottom = +getComputedStyle(document.documentElement).getPropertyValue("--sab").split("p")[0];
            const headerHeight = headerRef.current.offsetHeight;
            const clockHeight = clockRef.current.offsetHeight;
    
            const lastHeight = totalHeight - safeareaTop - headerHeight - safeareaBottom - clockHeight;

            
            
            setBodyHeight(lastHeight);

        }, 50);
    }


    useEffect(() => {

        caculateBodyHeight();

        window.addEventListener("resize", () => caculateBodyHeight());

        return window.removeEventListener("resize", () => caculateBodyHeight());

    }, [headerRef, clockRef]);

    const displayTime = () => {

        const date = new Date();

        const currentHours = date.getHours();
        const currentMinutes = date.getMinutes();

        if(currentHours < 10){
            setHour1(0);
            setHour2(currentHours);
        }

        if(currentHours >= 10 && currentHours < 20){
            setHour1(1);
            setHour2(currentHours - 10);
        }

        if(currentHours >= 20){
            setHour1(2);
            setHour2(currentHours - 20);
        }

        if(currentMinutes < 10){
            setMinute1(0);
            setMinute2(currentMinutes);
        }

        if(currentMinutes >= 10 && currentMinutes < 20){
            setMinute1(1);
            setMinute2(currentMinutes - 10);
        }

        if(currentMinutes >= 20 && currentMinutes < 30){
            setMinute1(2);
            setMinute2(currentMinutes - 20);
        }

        if(currentMinutes >= 30 && currentMinutes < 40){
            setMinute1(3);
            setMinute2(currentMinutes - 30);
        }

        if(currentMinutes >= 40 && currentMinutes < 50){
            setMinute1(4);
            setMinute2(currentMinutes - 40);
        }

        if(currentMinutes >= 50 && currentMinutes < 60){
            setMinute1(5);
            setMinute2(currentMinutes - 50);
        }
        

    }

    useInterval(() => {
        displayTime();
    }, 5000);

    useEffect(() => {
        displayTime();
    }, []);

    const handleClick = (index : number) => {

        setClickedIndex(index);

    }


    return (
        <div>
            <InnerHeader title="시간표 안내" headerRef={headerRef} />
            <div className={styles.clockWrapper} ref={clockRef}>
                <div className={styles.clock}>
                    <div className={`${styles.hourClock} ${styles.real}`}>
                        <div className={`${styles.eachNumberWrapperBox} ${styles.before}`}>
                            <div className={styles.upperWrapper}>

                            </div>
                            <div className={styles.bottomWrapper}>

                            </div>

                            <div className={styles.leftClip}>

                            </div>
                            <div className={styles.rightClip}>
                                
                            </div>
                            <div className={styles.clockNumber}>
                                {hour1}
                            </div>
                        </div>
                        <div className={styles.eachNumberWrapperBox}>
                            <div className={styles.upperWrapper}>

                            </div>
                            <div className={styles.bottomWrapper}>

                            </div>

                            <div className={styles.leftClip}>

                            </div>
                            <div className={styles.rightClip}>
                                
                            </div>
                            <div className={styles.clockNumber}>
                                {hour2}
                            </div>
                        </div>
                    </div>

                    <div className={styles.hourClock}>
                    <div className={`${styles.eachNumberWrapperBox} ${styles.before}`}>
                            <div className={styles.upperWrapper}>

                            </div>
                            <div className={styles.bottomWrapper}>

                            </div>

                            <div className={styles.leftClip}>

                            </div>
                            <div className={styles.rightClip}>
                                
                            </div>
                            <div className={styles.clockNumber}>
                                {minute1}
                            </div>
                        </div>
                        <div className={styles.eachNumberWrapperBox}>
                            <div className={styles.upperWrapper}>

                            </div>
                            <div className={styles.bottomWrapper}>

                            </div>

                            <div className={styles.leftClip}>

                            </div>
                            <div className={styles.rightClip}>
                                
                            </div>
                            <div className={styles.clockNumber}>
                                {minute2}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className={styles.schedulerWrapper} style={{height : `${bodyHeight}px`, overflow : "auto", paddingBottom : "30px"}}>
                {/* <div className={`${styles.eachSchedule} ${styles.first} ${clickedIndex === 1 ? styles.active : ""} ${currentClass === 1 ? styles.current : ""}`} onClick={() => {handleClick(1)}}>
                    <div className={styles.whichClass}>
                        0교시
                    </div>
                    <div className={styles.whichClass}>
                        <div className={styles.startTime}>
                            08:00
                        </div>
                        <div className={styles.middleTime}>
                            ~
                        </div>
                        <div className={styles.endTime}>
                            08:45
                        </div>
                    </div>
                </div>
                <div className={`${styles.eachSchedule} ${clickedIndex === 2 ? styles.active : ""} ${currentClass === 2 ? styles.current : ""}`} onClick={() => {handleClick(2)}}>
                    <div className={styles.whichClass}>
                        1교시
                    </div>
                    <div className={styles.whichClass}>
                        <div className={styles.startTime}>
                            09:00
                        </div>
                        <div className={styles.middleTime}>
                            ~
                        </div>
                        <div className={styles.endTime}>
                            10:15
                        </div>
                    </div>
                </div>
                <div className={`${styles.eachSchedule} ${clickedIndex === 3 ? styles.active : ""} ${currentClass === 3 ? styles.current : ""}`} onClick={() => {handleClick(3)}}>
                    <div className={styles.whichClass}>
                        2교시
                    </div>
                    <div className={styles.whichClass}>
                        <div className={styles.startTime}>
                            10:30
                        </div>
                        <div className={styles.middleTime}>
                            ~
                        </div>
                        <div className={styles.endTime}>
                            11:45
                        </div>
                    </div>
                </div>
                <div className={`${styles.eachSchedule} ${clickedIndex === 4 ? styles.active : ""} ${currentClass === 4 ? styles.current : ""}`} onClick={() => {handleClick(4)}}>
                    <div className={styles.whichClass}>
                        점심시간
                    </div>
                    <div className={styles.whichClass}>
                        <div className={styles.startTime}>
                            11:45
                        </div>
                        <div className={styles.middleTime}>
                            ~
                        </div>
                        <div className={styles.endTime}>
                            13:00
                        </div>
                    </div>
                </div>
                <div className={`${styles.eachSchedule} ${clickedIndex === 5 ? styles.active : ""} ${currentClass === 5 ? styles.current : ""}`} onClick={() => {handleClick(5)}}>
                    <div className={styles.whichClass}>
                        3교시
                    </div>
                    <div className={styles.whichClass}>
                        <div className={styles.startTime}>
                            13:00
                        </div>
                        <div className={styles.middleTime}>
                            ~
                        </div>
                        <div className={styles.endTime}>
                            14:15
                        </div>
                    </div>
                </div>
                <div className={`${styles.eachSchedule} ${clickedIndex === 6 ? styles.active : ""} ${currentClass === 6 ? styles.current : ""}`} onClick={() => {handleClick(6)}}>
                    <div className={styles.whichClass}>
                        4교시
                    </div>
                    <div className={styles.whichClass}>
                        <div className={styles.startTime}>
                            14:30
                        </div>
                        <div className={styles.middleTime}>
                            ~
                        </div>
                        <div className={styles.endTime}>
                            15:45
                        </div>
                    </div>
                </div>
                <div className={`${styles.eachSchedule} ${clickedIndex === 7 ? styles.active : ""} ${currentClass === 7 ? styles.current : ""}`} onClick={() => {handleClick(7)}}>
                    <div className={styles.whichClass}>
                        5교시
                    </div>
                    <div className={styles.whichClass}>
                        <div className={styles.startTime}>
                            16:00
                        </div>
                        <div className={styles.middleTime}>
                            ~
                        </div>
                        <div className={styles.endTime}>
                            17:15
                        </div>
                    </div>
                </div>
                <div className={`${styles.eachSchedule} ${clickedIndex === 8 ? styles.active : ""} ${currentClass === 8 ? styles.current : ""}`} onClick={() => {handleClick(8)}}>
                    <div className={styles.whichClass}>
                        저녁시간
                    </div>
                    <div className={styles.whichClass}>
                        <div className={styles.startTime}>
                            17:15
                        </div>
                        <div className={styles.middleTime}>
                            ~
                        </div>
                        <div className={styles.endTime}>
                            18:30
                        </div>
                    </div>
                </div>
                <div className={`${styles.eachSchedule} ${clickedIndex === 9 ? styles.active : ""} ${currentClass === 9 ? styles.current : ""}`} onClick={() => {handleClick(9)}}>
                    <div className={styles.whichClass}>
                        6교시
                    </div>
                    <div className={styles.whichClass}>
                        <div className={styles.startTime}>
                            18:30
                        </div>
                        <div className={styles.middleTime}>
                            ~
                        </div>
                        <div className={styles.endTime}>
                            19:30
                        </div>
                    </div>
                </div>
                <div className={`${styles.eachSchedule} ${clickedIndex === 10 ? styles.active : ""} ${currentClass === 10 ? styles.current : ""}`} onClick={() => {handleClick(10)}}>
                    <div className={styles.whichClass}>
                        7교시
                    </div>
                    <div className={styles.whichClass}>
                        <div className={styles.startTime}>
                            19:45
                        </div>
                        <div className={styles.middleTime}>
                            ~
                        </div>
                        <div className={styles.endTime}>
                            20:45
                        </div>
                    </div>
                </div>
                <div className={`${styles.eachSchedule} ${clickedIndex === 11 ? styles.active : ""} ${currentClass === 11 ? styles.current : ""}`} onClick={() => {handleClick(11)}}>
                    <div className={styles.whichClass}>
                        8교시
                    </div>
                    <div className={styles.whichClass}>
                        <div className={styles.startTime}>
                            21:00
                        </div>
                        <div className={styles.middleTime}>
                            ~
                        </div>
                        <div className={styles.endTime}>
                            22:00
                        </div>
                    </div>
                </div> */}
                {
                    
                    (props.user && props.user.location && koreanLocationToEnglish(props.user.location)) &&
                    returnLocationClassInfo(koreanLocationToEnglish(props.user.location)!).sort((a : any,b : any) => {
                        const AstartTimeCalc = (a.start.hours * 60 + a.start.minutes);
                        const BstartTimeCalc = (b.start.hours * 60 + b.start.minutes);

                        return AstartTimeCalc - BstartTimeCalc;

                    }).map((each : any, index : number) => {

                        var i = 0;

                        const thisArray = returnLocationClassInfo(koreanLocationToEnglish(props.user.location)!).sort((a : any,b : any) => {
                            const AstartTimeCalc = (a.start.hours * 60 + a.start.minutes);
                            const BstartTimeCalc = (b.start.hours * 60 + b.start.minutes);

                            return AstartTimeCalc - BstartTimeCalc;
                        });

                        //thisArray에서 classNumber가 lunch이거나 dinner인 열을 제거시킨다.
                        const thisArrayFiltered = thisArray.filter((each : any) => {
                            return each.classNumber !== "lunch" && each.classNumber !== "dinner";
                        });

                        //현재 each가 thisArrayFiltered에서 몇번째인지 찾는다.
                        thisArrayFiltered.forEach((each2 : any, index : number) => {
                            if(each2.classNumber === each.classNumber){
                                i = index;
                            }
                        })

                        if(thisArray[0].classNumber === "first"){
                            i++;
                        }

                        const plusZeroToString = (number : number) => {

                            var string = number.toString();

                            if(number < 10){
                                string = `0${string}`;
                            }

                            return string;

                        }

                        return (
                            <div key={index} className={`${styles.eachSchedule} ${clickedIndex === index + 1 ? styles.active : ""} ${currentClass === index + 1 ? styles.current : ""}`} onClick={() => {handleClick(index + 1)}}>
                                <div className={styles.whichClass}>
                                    {each.classNumber === "lunch" ? "점심시간" : ""}
                                    {each.classNumber === "dinner" ? "저녁시간" : ""}
                                    {(each.classNumber !== "lunch" && each.classNumber !== "dinner") ? `${i}교시` : ""}
                                </div>
                                <div className={styles.whichClass}>
                                    <div className={styles.startTime}>
                                        {plusZeroToString(each.start.hours)}:{plusZeroToString(each.start.minutes)}
                                    </div>
                                    <div className={styles.middleTime}>
                                        ~
                                    </div>
                                    <div className={styles.endTime}>
                                        {plusZeroToString(each.end.hours)}:{plusZeroToString(each.end.minutes)}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default MobileTimeTable;