import React, { useState, useEffect } from 'react';
import styles from "../../money.module.css";
import { Button, IconButton, Option, Select } from '@mui/joy';
import { DataGridPremium, GridToolbar } from '@mui/x-data-grid-premium';
import useStyles2 from '../../etc/useStyles';
import { Backdrop, Box, CircularProgress, Modal, Switch, TextField } from '@mui/material';
import ModalHeaderForMoney from '../../etc/modalheader';
import { ControlPoint, DeleteOutline } from '@mui/icons-material';
import ModalBottomForMoney from '../../etc/modalbottom';
import { getFetch, postFetch } from '../../../../../../fetch/fetch';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { id } from 'date-fns/locale';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
};

const OptionTable: React.FC<any> = (props) => {

    const classes = useStyles2();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [rows, setRows] = useState<any>([]);
    const [columns, setColumns] = useState([
        { field: 'optionName', headerName: '옵션 이름', width: 90 },
        { field: 'isMandantory', headerName: '필수 선택 여부', width: 130 },
        { field: 'maxNumber', headerName: '선택 가능한 최대 항목 수', width: 180 },
        { field: 'number', headerName: '옵션 항목 수', width: 130 },
        { field: 'description', headerName: '옵션 항목 내용', width: 200 },
    ]);
    const [modalType, setModalType] = useState("");

    const [optionName, setOptionName] = useState("");
    const [optionItems, setOptionItems] = useState([""]);
    const [isMandantory, setIsMandantory] = useState(false);
    const [maxNumber, setMaxNumber] = useState(1);
    const [disabled, setDisabled] = useState(true);
    const [editDisabled, setEditDisabled] = useState(true);
    const [tableLoading, setTableLoading] = useState(false);
    const [update, setUpdate] = useState(0);
    const [selectedRow, setSelectedRow] = useState<any>(null);

    const notify1 = () => toast.success("전송 성공!", {
        position : "bottom-right",
        autoClose : 3000,
        hideProgressBar : true,
        theme : "light"
    });

    const notify2 = () => toast.error("실패했습니다.", {
        position : "bottom-right",
        autoClose : 3000,
        hideProgressBar : true,
        theme : "light"
    });

    useEffect(() => {

        var newDisabled = true;

        if(optionName && optionItems.length > 0){
        
            var isEmpty = false;

            optionItems.forEach((item) => {

                if(!item){
                    isEmpty = true;
                }

            });

            if(!isEmpty){
                newDisabled = false;
            }
        
        }

        setDisabled(newDisabled);

    }, [optionName, optionItems, open]);

    useEffect(() => {

        if(selectedRow){
            setEditDisabled(false);
        }else{
            setEditDisabled(true);
        }

    }, [selectedRow]);

    useEffect(() => {

        getOptions();

    }, [update]);

    const handleClose = () => {
        setOpen(false);
        setModalType("");
        setOptionName("");
        setOptionItems([""]);
        setIsMandantory(false);
        setEditDisabled(true);
        setMaxNumber(1);
    }

    const handleOpen = (type: string) => {

        if(type === "edit"){

            if(!selectedRow){
                return;
            }

            

            const value = selectedRow.row.value;

            

            setOptionName(value.optionName);
            setOptionItems(value.optionItems);
            setIsMandantory(value.isMandantory);
            setMaxNumber(value.maxNumber);

        }

        setModalType(type);
        setOpen(true);

    }

    const handleCreateOption = () => {
        const newOptionItems = optionItems;
        newOptionItems.push("");
        setOptionItems([...newOptionItems]);
    }

    const handleDeleteOption = (index: number) => {
        const newOptionItems = optionItems;

        if (newOptionItems.length === 1) {
            return;
        }

        newOptionItems.splice(index, 1);
        setOptionItems([...newOptionItems]);
    }

    const getOptions = async () => {

        try{

            setTableLoading(true);

            const result: any = await getFetch("/dashboard/payments/option/get");

            setTableLoading(false);

            

            if(result.message === "success"){
                
                const newRows : any = [];

                const data = result.data;

                data.forEach((each : any) => {

                    const oneRow : any = {};
                    oneRow.id = each.id;
                    oneRow.optionName = each.optionName;
                    oneRow.isMandantory = each.isMandantory ? "필수선택" : "-";
                    oneRow.maxNumber = `최대 ${each.maxNumber}개`;
                    oneRow.number = each.optionItems.length + "개";

                    var description = "";

                    each.optionItems.forEach((item : any, index : any) => {
                        description += item;

                        var isLast = index === each.optionItems.length - 1;

                        if(!isLast){
                            description += " | "
                        }
                    });

                    oneRow.description = description;

                    const value = {
                        id : each.id,
                        optionName : each.optionName,
                        optionItems : each.optionItems,
                        isMandantory : each.isMandantory,
                        maxNumber : each.maxNumber
                    }

                    oneRow.value = value;

                    newRows.push(oneRow);
                })

                setRows([...newRows]);

            }

        }catch(e){
            
        }

    }

    const submitNewOption = async () => {

        try {

            const data = {
                optionName: optionName,
                optionItems: optionItems,
                isMandantory: isMandantory,
                maxNumber: maxNumber
            }

            handleClose();

            if (!optionName || optionItems.length === 0) {
                
                return;
            }

            

            setLoading(true);

            const result: any = await postFetch("/dashboard/payments/option/create", data);

            setLoading(false);

            setUpdate(Math.random());

            

            if (result.message === "success") {
                notify1();
            } else {
                notify2();
            }

        } catch (e) {
            
        }


    }

    const submitEditOption = async () => {

        try{

            if(!selectedRow){
                return;
            }

            const id = selectedRow.id;

            const data = {
                optionName: optionName,
                optionItems: optionItems,
                isMandantory: isMandantory,
                maxNumber: maxNumber,
                id
            }

            handleClose();

            if (!optionName || optionItems.length === 0) {
                
                return;
            }

            

            setLoading(true);

            const result: any = await postFetch("/dashboard/payments/option/update", data);

            setLoading(false);

            setUpdate(Math.random());

            

            if (result.message === "success") {
                notify1();
            } else {
                notify2();
            }

        }catch(e){
            
        }

    }

    const submitDeleteOption = async () => {

        try{

            if(!selectedRow){
                return;
            }

            const id = selectedRow.id;

            if(!id){
                return;
            }

            setLoading(true);

            const result : any = await postFetch("/dashboard/payments/option/delete", {id});

            setLoading(false);

            

            if(result.message === "success"){
                setUpdate(Math.random());
                notify1();
            }else{
                notify2();
            }

        }catch(e){
            
        }

    }

    return (
        <div>
            <div className={styles.main}>
                <div className={styles.btnLine}>
                    <div>
                        
                    </div>
                    <div className={styles.btns}>
                        <Button variant='soft' color='neutral' size='sm' sx={{ marginRight: "1rem" }} onClick={() => { handleOpen("create") }}>옵션 생성</Button>
                        <Button variant='soft' color='neutral' size='sm' sx={{ marginRight: "1rem" }}
                            disabled={editDisabled} onClick={() => { handleOpen("edit") }}
                        >수정하기</Button>
                        <Button variant='soft' color='danger' size='sm'
                            disabled={editDisabled} onClick={submitDeleteOption}
                        >삭제하기</Button>
                    </div>
                </div>
                <div className={classes.root} style={{ height: 500, width: '100%', backgroundColor: "white", marginTop: "24px" }}>
                    <DataGridPremium loading={tableLoading} rows={rows} columns={columns}
                        components={{ Toolbar: GridToolbar }}
                        density='compact'
                        hideFooter
                        onRowClick={(row) => {
                            setSelectedRow(row);
                        }}
                    />
                </div>
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <ModalHeaderForMoney title={modalType === "create" ? "옵션 생성" : modalType === "edit" ? "옵션 수정" : ""} />
                    <div className={styles.modalBody}>
                        <div>
                            <div className={styles.title}>
                                옵션 이름
                            </div>
                            <TextField variant="outlined" fullWidth
                                value={optionName} onChange={(e) => { setOptionName(e.target.value) }}
                                size='small'
                            />
                        </div>
                        <div>
                            <div className={styles.title}>
                                옵션 항목
                            </div>
                            <div>
                                <div>
                                    {
                                        optionItems.map((item, index) => {
                                            return (
                                                <div key={index} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                                    <TextField variant="outlined" fullWidth
                                                        value={item} onChange={(e) => {
                                                            let temp = optionItems;
                                                            temp[index] = e.target.value;
                                                            setOptionItems([...temp]);
                                                        }}
                                                        size='small'
                                                    />
                                                    <IconButton variant='soft' color="neutral" sx={{ marginLeft: "1.2rem" }}
                                                        onClick={() => { handleDeleteOption(index) }}
                                                        disabled={optionItems.length === 1 ? true : false}
                                                    >
                                                        <DeleteOutline />
                                                    </IconButton>
                                                </div>
                                            )
                                        }
                                        )
                                    }
                                </div>
                                <div>
                                    <Button
                                        onClick={handleCreateOption}
                                        variant='soft' color='neutral' size='lg' sx={{ backgroundColor: "#fff", paddingLeft: "0.2rem", paddingRight: "0.2rem" }} startDecorator={<ControlPoint />}>
                                        항목 추가하기
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={styles.title}>
                                필수 선택 여부
                            </div>
                            <div className={styles.horizontalRow}>
                                <div className={styles.subMessage}>
                                    이 옵션은 필수로 선택해야 합니다.
                                </div>
                                <div>
                                    <Switch
                                    checked={isMandantory}
                                    onChange={(e) => { setIsMandantory(e.target.checked) }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={styles.title}>
                                선택 가능한 최대 항목 수
                            </div>
                            <div className={styles.horizontalRow}>
                                <div>

                                </div>
                                <div>
                                    <Select
                                    sx={{width : "120px"}}
                                    value={maxNumber} onChange={(e, newValue : any) => { setMaxNumber(newValue) }}>
                                        <Option value={1}>1개</Option>
                                        <Option value={2}>2개</Option>
                                        <Option value={3}>3개</Option>
                                        <Option value={4}>4개</Option>
                                        <Option value={5}>5개</Option>
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className={styles.justHeight}>

                        </div>
                    </div>
                    <ModalBottomForMoney disabled={disabled} buttonName={modalType === "create" ? "생성하기" : modalType === "edit" ? "수정하기" : ""} handleClick={modalType === "create" ? submitNewOption : modalType === "edit" ? submitEditOption : () => {}} />
                </Box>
            </Modal>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>


        </div>
    )

}

export default OptionTable;