import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router";
import { ReactComponent as ArrowLeftLight } from "../../../src/svg/arrow-left-light.svg";
import { ReactComponent as TrashCan } from "../../../src/svg/trash-can-regular.svg";
import styles from "./mobilemyopinion.module.css";
import { useHistory } from "react-router-dom";
import { Capacitor } from "@capacitor/core";
import { useIonAlert, IonLoading, IonApp } from "@ionic/react";
import { IonButton, useIonActionSheet } from '@ionic/react';
import { Preferences } from "@capacitor/preferences";
import Avatar from '@mui/material/Avatar';
import FaceIcon from '@mui/icons-material/Face';
import SmartToyIcon from '@mui/icons-material/SmartToy';

const MobileMyOpinionDetail: React.FC<any> = (props) => {

    const location: any = useLocation();
    const history = useHistory();

    const [userName, setUserName] = useState("");

    const [platform, setPlatform] = useState<any>();

    const headerRef = useRef<any>(null);
    const [bodyHeight, setBodyHeight] = useState<number>(0);

    const [loading, setLoading] = useState<any>(false);
    const [data, setData] = useState<any>();

    const [present] = useIonActionSheet();

    useEffect(() => {

        


        const handleBackButton = () => {
            
            history.goBack();
        }

        //backbutton 눌리면 handleBackButton 실행
        document.addEventListener("backbutton", handleBackButton);

        return () => {
            document.removeEventListener("backbutton", handleBackButton);
        }

    }, []);

    useEffect(() => {

        if (!headerRef) {
            return;
        }

        const value = +getComputedStyle(document.documentElement).getPropertyValue("--sat").split("p")[0];

        const caculateBodyHeight = () => {

            if(!headerRef || !headerRef.current){
                return;
            }

            const totalHeight = window.innerHeight;
            const safeareaTop = value;
            const headerHeight = headerRef.current.offsetHeight;

            const lastHeight = totalHeight - safeareaTop - headerHeight
            setBodyHeight(lastHeight);
        }

        caculateBodyHeight();

        window.addEventListener("resize", () => caculateBodyHeight());

        return window.removeEventListener("resize", () => caculateBodyHeight());

    }, [headerRef]);

    //플랫폼 정보 확인
    useEffect(() => {
        const platform = Capacitor.getPlatform();

        if (platform) {
            setPlatform(platform);
        }

    }, []);

    useEffect(() => {
        
        getInfo();
    }, [])

    const getInfo = async () => {

        setLoading(true);

        var token: any = "";
        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        if (Capacitor.isNativePlatform()) {
            const { value } = await Preferences.get({ key: "token" });
            token = value;
        }

        fetch(`https://peetsunbae.com/dashboard/search/opiniondetail?id=${location.state.id}`, {
            method: "GET",
            headers: { "Authorization": token, "Content-Type": "application/json" },
            credentials: "include",
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    
                    if (result.message === "success") {
                        
                        const data = result.data;

                        data.forEach((oneRow: any, index: number) => {
                            oneRow.over = Math.floor((new Date().getTime() - new Date(oneRow.createdAt).getTime()) / 86400000) + "일";

                            switch (oneRow.kindValue) {
                                case "live":
                                    oneRow.kind = "생활";
                                    break;
                                case "change":
                                    oneRow.kind = "자리변경";
                                    break;
                                case "consult":
                                    oneRow.kind = "담임상담";
                                    break;
                                case "question":
                                    oneRow.kind = "질의응답";
                                    break;
                                case "coaching":
                                    oneRow.kind = "과외";
                                    break;
                                case "ceo":
                                    oneRow.kind = "대표에게";
                                    break;
                                case "etc":
                                    oneRow.kind = "기타";
                                    break;
                            }

                            if (index === 0) {
                                setUserName(oneRow.name);
                            }

                        })

                        setData(data[0]);
                        setLoading(false);

                    }
                })
        })


    }

    const deleteOpinion = async () => {

        if (!location.state.id) {
            return;
        }

        setLoading(true);

        var token: any = "";
        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        if (Capacitor.isNativePlatform()) {
            const { value } = await Preferences.get({ key: "token" });
            token = value;
        }

        fetch("https://peetsunbae.com/dashboard/search/myopinion", {
            method: "DELETE",
            headers: { "Authorization": token, "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
                id: [location.state.id]
            })
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    setLoading(false);
                    if (result.message === "success") {
                        history.goBack();
                    }
                })
        })
    }

    const letsDelete = () => {
        present({
            header: "삭제 안내",
            subHeader: "삭제를 진행할까요?",
            buttons: [{
                text: "삭제",
                role: "destructive"
            },
            {
                role: "cancel",
                text: "취소",
            }
            ],
            onDidDismiss: ({ detail }) => {
                
                switch (detail.role) {
                    case "destructive":
                        deleteOpinion();
                        break;
                    case "cancel":
                        break;
                }
            },
        })
    }

    return (
        <IonApp>
            <div style={{ paddingTop: "env(safe-area-inset-top)" }}>
                <div className={styles.headerDiv}>
                    <div className={`${styles.header} ${platform === "ios" ? styles.ios : ""}`} ref={headerRef}>
                        <div className={styles.backBtnDiv}
                            onClick={() => { history.goBack(); }}
                        >
                            <ArrowLeftLight
                                className={styles.arrowLeft}
                            />
                        </div>

                        <div className={styles.titleText}>
                            나의 의견
                        </div>


                        <div className={`${styles.submit}`} onClick={letsDelete}>
                            <TrashCan className={styles.pentosqure} />
                        </div>
                    </div>
                </div>

                <div className={styles.bodyDiv}>

                    <div className={styles.body} style={{ height: `${bodyHeight}px`, overflow: "scroll" }}>
                        <div className={styles.kindTitle}>
                            {
                                data &&
                                data.kind
                            }
                        </div>
                        <div className={styles.overString}>
                            경과시간
                            {
                                " "
                            }
                            {
                                data &&
                                data.over
                            }
                            {
                                "  · "
                            }
                            {
                                data &&
                                data.status
                            }
                        </div>
                        <div className={styles.avatarDiv2}>
                            <Avatar variant="rounded" sx={{ borderRadius: "0.7rem", bgcolor: "#3d50b0", width: "3rem", height: "3rem" }}>
                                <span className={styles.name}>
                                    {
                                        userName &&
                                        userName.slice(userName.length - 2)
                                    }
                                </span>
                            </Avatar>
                            <div className={styles.userNameDiv}>
                                {userName}
                            </div>
                        </div>
                        <div className={styles.descriptionWrapper}>
                            {
                                data &&
                                data.description
                            }
                        </div>

                        {
                            (data && data.firstReply)
                            &&
                            <div>
                                <div className={`${styles.avatarDiv2}`}>
                                    <Avatar variant="rounded" sx={{ borderRadius: "0.7rem", bgcolor: "#768497", width: "3rem", height: "3rem" }}>
                                        <SmartToyIcon />
                                    </Avatar>
                                    <div className={styles.userNameDiv}>
                                        관리자
                                    </div>
                                </div>
                                <div className={styles.descriptionWrapper}>
                                    {
                                        data &&
                                        data.firstReply
                                    }
                                </div>
                            </div>
                        }

                        {
                            (data && data.secondReply)
                            &&
                            <div>
                                <div className={`${styles.avatarDiv2}`}>
                                    <Avatar variant="rounded" sx={{ borderRadius: "0.7rem", bgcolor: "#768497", width: "3rem", height: "3rem" }}>
                                        <SmartToyIcon />
                                    </Avatar>
                                    <div className={styles.userNameDiv}>
                                        관리자
                                    </div>
                                </div>
                                <div className={styles.descriptionWrapper}>
                                    {
                                        data &&
                                        data.secondReply
                                    }
                                </div>
                            </div>
                        }



                    </div>
                </div>


                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={loading}
                    onDidDismiss={() => setLoading(false)}
                    message={'잠시만 기다려주세요'}
                />
            </div>
        </IonApp>
    )
}

export default MobileMyOpinionDetail;