import React, {useEffect, useState} from 'react';
import AccessData from '../infos/accessData';
import RegularData from '../infos/regularData';
import SuddenData from '../infos/suddenData';
import AttendanceCheckData from '../infos/attendancecheckdata';
import PhoneData from '../infos/phoneData';
import DemeritMemoData from '../infos/demeritmemodata';

const StudentAttendanceInfo = (props: any) => {

    const [loading, setLoading] = useState(false);
    const [accessControlData, setAccessControlData] = useState<any>([]);
    const [regularScheduleData, setRegularScheduleData] = useState<any>();
    const [suddenNoticeData, setSuddenNoticeData] = useState<any>([]);
    const [attendanceCheckData, setAttendanceCheckData] = useState<any>([]);

    const [phoneLogData, setPhoneLogData] = useState<any>([]);

    useEffect(() => {

        if(!props.selectedUserId) return;

        if(!props.targetDate) return;

        if(!props.classNumber) return;

        getStudentAttendanceInfo();


    }, [props.selectedUserId, props.targetDate, props.classNumber]);




    const getStudentAttendanceInfo = async () => {

        if(!props.selectedUserId) return;

        if(!props.targetDate) return;

        if(!props.classNumber) return;

        try{

            setLoading(true);

            const targetDateTime = new Date(props.targetDate).getTime();

            const response = await fetch(`https://peetsunbae.com/dashboard/report/attendance/student?userId=${props.selectedUserId}&targetDateTime=${targetDateTime}&classNumber=${props.classNumber}`, {
                method : "GET",
                credentials : "include"
            })

            const result = await response.json();

            

            if(result.message === "success"){

                

                setAccessControlData(result.accessControlData);
                setRegularScheduleData(result.regularScheduleData);
                setSuddenNoticeData(result.suddenNoticeData);
                setAttendanceCheckData(result.attendanceCheckData);

                const callLogData = result.callLogData;
                const smsManagerData = result.smsManagerData;
                const mmsManagerData = result.mmsManagerData ? result.mmsManagerData : [];

                callLogData.forEach((callLog: any) => {
                    callLog.timeNumber = +callLog.dateTime;
                })

                smsManagerData.forEach((smsManager: any) => {
                    smsManager.timeNumber = +smsManager.timeValue;
                })

                mmsManagerData.forEach((mmsManager: any) => {
                    mmsManager.timeNumber = +mmsManager.timeValue;
                    if(mmsManager.type){
                        mmsManager.type = +mmsManager.type;
                    }
                })

                const newPhoneLogData = [...callLogData, ...smsManagerData, ...mmsManagerData];

                newPhoneLogData.sort((a: any, b: any) => {
                    return b.timeNumber - a.timeNumber;
                })

                setPhoneLogData([...newPhoneLogData]);

                setLoading(false);

            }else{
                setAccessControlData([]);
                setRegularScheduleData(null);
                setSuddenNoticeData([]);
                setAttendanceCheckData([]);
                setPhoneLogData([]);
            }

            //해당 학생의 출입기록, 정기일정, 사유제출, 오늘의 출석 현황을 가져온다.

        }catch(e){
            
        }

    }
    


    return (
        <div>
            <div style={{
                marginLeft : "24px",
                display : "grid",
                gridTemplateColumns : "1fr 1fr",
                gridGap : "24px"
            }}>
                <div>
                    <div>
                        <AccessData loading={loading} accessControlData={accessControlData} />
                    </div>
                    <div>
                        <RegularData loading={loading} regularScheduleData={regularScheduleData} targetDate={props.targetDate} />
                    </div>
                    <div>
                        <SuddenData loading={loading} suddenNoticeData={suddenNoticeData} />
                    </div>
                </div>
                <div>
                    <div>
                        <AttendanceCheckData location={props.location} phoneLogData={phoneLogData} loading={loading} accessControlData={accessControlData} attendanceCheckData={attendanceCheckData} targetDate={props.targetDate} classNumber={props.classNumber} selectedUserId={props.selectedUserId} />
                    </div>
                    <div>
                        <PhoneData loading={loading} phoneLogData={phoneLogData} studentPhoneNumber={props.studentPhoneNumber} parentPhoneNumber={props.parentPhoneNumber} />
                    </div>
                    <div>
                        <DemeritMemoData loading={loading} targetDate={props.targetDate} classNumber={props.classNumber} selectedUserId={props.selectedUserId} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StudentAttendanceInfo;

