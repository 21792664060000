import React, { useEffect, useRef, useState } from 'react';
import { withStyles } from '@mui/styles';
// import styles from '../componentsStyle/homeStyles'
import { Avatar, Modal, Skeleton, Box } from '@mui/material';
import styles from "../componentsStyle/home.module.css";
import useNotifications from './use/usenotification';
import { notificationResult, notification } from './use/usenotification';
import { RouteComponentProps } from 'react-router';

import { Socket } from 'socket.io-client';

import "../componentsStyle/home.css"
import { findRenderedComponentWithType } from 'react-dom/test-utils';

import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";



type currentSideBarMenuList = "home" | "notification" | "alarm" | "edit" | "book" | "question" | "restaurant" | "envelope" | "search" | "chart" | "attendance" | "출석 관리 보고";


interface socketProps extends RouteComponentProps {
    // classes : any;
    socket: Socket;
    newNotification: number;
    activateMenuList: (curret: currentSideBarMenuList) => void;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: 3,
    p: 4,
    paddingLeft: 6,
    paddingRight: 6,
    maxHeight: "600px",
    overflow: "auto"
};


const Home: React.FC<socketProps> = (props) => {
    // const classes = props.classes;

    const [fingerprintId, setFingerprintId] = useState<any>();

    const [monthTotalStudent, setMonthTotalStuent] = useState<any>();
    const [weekTotalStudent, setWeekTotalStudent] = useState<any>();

    const [myMonthRankingState, setMyMonthRankingState] = useState<any>();
    const [myWeekRankingState, setMyWeekRankingState] = useState<any>();

    const [myMonthTotalStudyTime, setMyMonthTotalStudyTime] = useState<any>();
    const [myWeekTotalStudyTime, setMyWeekTotalStudyTime] = useState<any>();

    const [orderedMonthInfo, setOrderedMonthInfo] = useState<any>();
    const [orderedWeekInfo, setOrderedWeekInfo] = useState<any>();

    const [open, setOpen] = useState(false);

    const [modalKind, setModalKind] = useState<any>();

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }


    const getStudyTime = async () => {

        var token: any = "";

        //네이티브 라면 토큰 가져오는 기능
        if (Capacitor.isNativePlatform()) {
            const { value } = await Preferences.get({ key: "token" });
            
            token = value;
        }

        

        fetch("https://peetsunbae.com/dashboard/home/studytime", {
            method: "GET",
            credentials: "include",
            headers: { "Authorization": token }
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    
                    if (result.message === "success" && result.fingerprintId) {
                        setFingerprintId(result.fingerprintId);
                        const fingerprintId = result.fingerprintId;

                        var myMonthStudyTime = 0;
                        var myWeekStudyTime = 0;

                        const monthInfo = result.monthInfo;
                        const weekInfo = result.weekInfo;

                        monthInfo.forEach((each: any) => {
                            if (each.fingerprintId === fingerprintId) {
                                myMonthStudyTime = each.totalStudyTime;
                            }
                        })

                        setMyMonthTotalStudyTime(myMonthStudyTime);

                        weekInfo.forEach((each: any) => {
                            if (each.fingerprintId === fingerprintId) {
                                myWeekStudyTime = each.totalStudyTime;
                            }
                        })

                        setMyWeekTotalStudyTime(myWeekStudyTime);

                        
                        

                        var myMonthRanking = monthInfo.length;

                        setMonthTotalStuent(monthInfo.length);

                        var myWeekRanking = weekInfo.length;

                        setWeekTotalStudent(weekInfo.length);

                        monthInfo.forEach((each: any) => {
                            if (each.totalStudyTime < myMonthStudyTime) {
                                myMonthRanking--;
                            }
                        })

                        weekInfo.forEach((each: any) => {
                            if (each.totalStudyTime < myWeekStudyTime) {
                                myWeekRanking--;
                            }
                        })

                        monthInfo.sort(function (a: any, b: any) {
                            if (a.totalStudyTime < b.totalStudyTime) {
                                return 1;
                            } else if (a.totalStudyTime > b.totalStudyTime) {
                                return -1;
                            } else {
                                return 0;
                            }
                        })

                        weekInfo.sort(function (a: any, b: any) {
                            if (a.totalStudyTime < b.totalStudyTime) {
                                return 1;
                            } else if (a.totalStudyTime > b.totalStudyTime) {
                                return -1;
                            } else {
                                return 0;
                            }
                        })


                        setOrderedMonthInfo(monthInfo);
                        setOrderedWeekInfo(weekInfo);


                        setMyMonthRankingState(myMonthRanking);
                        setMyWeekRankingState(myWeekRanking);

                    }
                })
        })

    }

    useEffect(() => {
        props.activateMenuList("home");

        getStudyTime();

    }, [])


    //바로 업데이트 필요한 기능들 use로 모아 놓는 곳-------------------------------------

    //-----//공지사항 업데이트 하는 기능------------------------------------------------
    const { notificationResults, loading } = useNotifications(props.newNotification);
    //-----//-----------------------------------------------------------------------

    //-------------------------------------------------------------------------------

    const notificationDetail = (e: any) => {
        const id = e.target.dataset.id;
        
        props.history.push({
            pathname: "/dashboard/notificationdetail",
            state: { id: id, history: props.history }
        })
    }






    return (
        <>
            <div className={`${styles.main}`}>
                <div className={styles.attendanceBoard}>
                    <div className={styles.attendanceBoardWeek}>
                        <div className={styles.attendanceBoardTitle}>
                            <div className={styles.attendanceBoardTitle_1}>
                                이번달 공부 시간
                            </div>
                            <div onClick={(e: any) => { setModalKind("month"); setOpen(true); }} className={styles.attendanceBoardTitle_2}>
                                #자세히
                            </div>
                        </div>
                        <div className={styles.attendanceBoardDescription}>
                            <div className={styles.attendanceBoardDescription_1}>
                                <div className={styles.attendanceBoardDescription_1_1}>
                                    총 공부시간
                                </div>
                                <div className={styles.attendanceBoardDescription_1_2}>
                                    <span className={styles.attendanceBoardDescriptionHour}>
                                        {myMonthTotalStudyTime && Math.floor(myMonthTotalStudyTime / 3600000)}
                                    </span>
                                    <span>시간</span>
                                    <span className={styles.attendanceBoardDescriptionMinute}>
                                        {myMonthTotalStudyTime && Math.floor(myMonthTotalStudyTime / 60000) % 60}
                                    </span>
                                    <span>분</span>
                                </div>
                            </div>

                            {/* <div className={styles.attendanceBoardDescription_1}>
                            <div className={styles.attendanceBoardDescription_1_1}>
                                지각/결석
                            </div>
                            <div className={styles.attendanceBoardDescription_1_2}>
                                <span className={styles.attendanceBoardDescriptionHour}>3 / 1</span>
                                <span>회</span>
                            </div>
                        </div> */}

                            <div className={styles.attendanceBoardDescription_1}>
                                <div className={styles.attendanceBoardDescription_1_1}>
                                    공부 시간 등수
                                </div>
                                <div className={styles.attendanceBoardDescription_1_2}>
                                    <span className={styles.attendanceBoardDescriptionHour}>{myMonthRankingState} / {monthTotalStudent}</span>
                                    <span>등</span>
                                </div>
                            </div>

                        </div>
                    </div>





                    <div className={styles.attendanceBoardToday}>
                        <div className={styles.attendanceBoardTitle}>
                            <div className={styles.attendanceBoardTitle_1}>
                                이번주 공부 시간
                            </div>
                            <div onClick={(e: any) => { setModalKind("week"); setOpen(true); }} className={styles.attendanceBoardTitle_2}>
                                #자세히
                            </div>
                        </div>
                        <div className={styles.attendanceBoardDescription}>
                            <div className={styles.attendanceBoardDescription_1}>
                                <div className={styles.attendanceBoardDescription_1_1}>
                                    총 공부시간
                                </div>
                                <div className={styles.attendanceBoardDescription_1_2}>
                                    <span className={styles.attendanceBoardDescriptionHour}>
                                        {myWeekTotalStudyTime && Math.floor(myWeekTotalStudyTime / 3600000)}
                                    </span>
                                    <span>시간</span>
                                    <span className={styles.attendanceBoardDescriptionMinute}>
                                        {myWeekTotalStudyTime && Math.floor(myWeekTotalStudyTime / 60000) % 60}
                                    </span>
                                    <span>분</span>
                                </div>
                            </div>

                            {/* <div className={styles.attendanceBoardDescription_1}>
                            <div className={styles.attendanceBoardDescription_1_1}>
                                지각/결석
                            </div>
                            <div className={styles.attendanceBoardDescription_1_2}>
                                <span className={styles.attendanceBoardDescriptionHour}>0 / 0</span>
                                <span>회</span>
                            </div>
                        </div> */}

                            <div className={styles.attendanceBoardDescription_1}>
                                <div className={styles.attendanceBoardDescription_1_1}>
                                    공부 시간 등수
                                </div>
                                <div className={styles.attendanceBoardDescription_1_2}>
                                    <span className={styles.attendanceBoardDescriptionHour}>{myWeekRankingState} / {weekTotalStudent}</span>
                                    <span>등</span>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className={styles.notificationDiv}>
                    <div className={styles.notificationTitle}>최근 공지사항</div>

                    <div className={styles.notifications}>

                        {
                            notificationResults &&
                            notificationResults.map((each: any, index: number) => {
                                if (each.images.length > 0) {
                                    return (
                                        <div onClick={notificationDetail} data-id={each.id} key={each.title + Math.random()} className={`${styles.notification} ${(index === 2 || index === 3) ? styles.tabletnone : ""}`}>
                                            <div data-id={each.id} className={styles.notification_imageDiv} style={{ width: "277px", height: "140px", backgroundSize: "cover", backgroundImage: `url("https://peetsunbae.com/${each.images[0].split("/public/")[1]}")` }}>
                                            </div>
                                            <div data-id={each.id} className={styles.notification_description}>
                                                <div data-id={each.id} className={styles.notification_description1}>
                                                    <div data-id={each.id} className={styles.notification_description1_1}>
                                                        {each.title}
                                                    </div>
                                                    <div data-id={each.id} className={styles.notification_description1_2}>
                                                        <Avatar>{each.author[0]}</Avatar>
                                                    </div>
                                                </div>
                                                <div data-id={each.id} className={styles.notification_description2}>

                                                    <div data-id={each.id} className={styles.notification_description2_2}>
                                                        {!each.isRead ? <div className={styles.new}>NEW</div> : ""}
                                                    </div>
                                                    <div data-id={each.id} className={styles.notification_description2_1}>
                                                        {each.createdAt.year + "." + each.createdAt.month + "." + each.createdAt.date}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                                else {
                                    return (
                                        <div onClick={notificationDetail} data-id={each.id} key={each.title + Math.random()} className={`${styles.notification} ${(index === 2 || index === 3) ? styles.tabletnone : ""}`}>

                                            <div data-id={each.id} className={styles.notification_description_text}>
                                                <div data-id={each.id} className={styles.notification_description1}>
                                                    <div data-id={each.id} className={styles.notification_description1_1}>
                                                        {each.title}
                                                    </div>
                                                    <div data-id={each.id} className={styles.notification_description1_2}>
                                                        <Avatar><span className={styles.avatarText}>{each.author[0]}</span></Avatar>
                                                    </div>
                                                </div>
                                                <div data-id={each.id} className={styles.eachDescription}>
                                                    {each.description}
                                                </div>
                                                <div data-id={each.id} className={styles.notification_description2}>
                                                    <div data-id={each.id} className={styles.notification_description2_2}>
                                                        {!each.isRead ? <div className={styles.new}>NEW</div> : ""}
                                                    </div>
                                                    <div data-id={each.id} className={styles.notification_description2_1}>{each.createdAt.year + "." + each.createdAt.month + "." + each.createdAt.date}</div>

                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            }
                            )
                        }

                    </div>

                </div>
            </div>


            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    {
                        modalKind === "month" &&
                        <div className={styles.modalPaper}>
                            <div className={styles.modalTitle}>
                                {new Date().getMonth() + 1}월 공부 시간
                            </div>
                            <div className={styles.tableDiv}>
                                <div className={styles.tableTop}>
                                    <div className={styles.col1}>
                                        순위
                                    </div>
                                    <div className={styles.col2}>
                                        시간
                                    </div>
                                </div>
                                {
                                    orderedMonthInfo &&
                                    orderedMonthInfo.map((each: any, index: number) => {

                                        var mine = false;

                                        if (+fingerprintId === each.fingerprintId) {
                                            mine = true;
                                        }

                                        return (
                                            <div className={`${styles.tableRow} ${mine ? styles.mine : ""}`}>
                                                <div className={styles.col1}>
                                                    {index + 1} 등
                                                </div>
                                                <div className={styles.col2}>
                                                    {Math.floor(each.totalStudyTime / 3600000)}시간 {Math.floor(each.totalStudyTime / 60000) % 60}분
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    }
                    {
                        modalKind === "week" &&
                        <div className={styles.modalPaper}>
                            <div className={styles.modalTitle}>
                                이번주 공부 시간
                            </div>
                            <div className={styles.tableDiv}>
                                <div className={styles.tableTop}>
                                    <div className={styles.col1}>
                                        순위
                                    </div>
                                    <div className={styles.col2}>
                                        시간
                                    </div>
                                </div>
                                {
                                    orderedWeekInfo &&
                                    orderedWeekInfo.map((each: any, index: number) => {

                                        var mine = false;

                                        if (+fingerprintId === each.fingerprintId) {
                                            mine = true;
                                        }

                                        return (
                                            <div className={`${styles.tableRow} ${mine ? styles.mine : ""}`}>
                                                <div className={styles.col1}>
                                                    {index + 1} 등
                                                </div>
                                                <div className={styles.col2}>
                                                    {Math.floor(each.totalStudyTime / 3600000)}시간 {Math.floor(each.totalStudyTime / 60000) % 60}분
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    }
                </Box>
            </Modal>

        </>
    )
}

export default Home;