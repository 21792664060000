import {useEffect, useState} from "react";
import styles from "./styles/bookingstyle.module.css";
import BookedList from "./components/bookedlist";
import ManageBookingTime from "./components/managebookingtime";
import { addZeroIfOne } from "../etc/etcfunctions";

const Booking : React.FC<any> = (props) => {

    const [searchMenu, setSearchMenu] = useState("list");

    useEffect(() => {
        props.activateMenuList("booking");
    }, []);

    

    return (
        <div className="main22">
            <div className="title" style={{
                display: "flex", alignItems: "center",
            }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "6px",
                        marginRight: "10px"
                    }}
                >
                    <img src="img/off/booking.svg" alt="playlist" style={{ width: "18px" }} />
                </div>
                <div>상담 예약 관리</div>
            </div>
            
            <div className={styles.searchMenu}>
                <div onClick={(e) => { setSearchMenu("list") }} className={`${styles.searchMenuDiv} ${searchMenu === "list" ? styles.active : ""}`}>
                    예약리스트
                </div>
                <div onClick={(e) => { setSearchMenu("manage") }} className={`${styles.searchMenuDiv} ${searchMenu === "manage" ? styles.active : ""}`}>
                    상담 가능시간 관리
                </div>
                <div onClick={(e) => { setSearchMenu("block") }} className={`${styles.searchMenuDiv} ${searchMenu === "block" ? styles.active : ""}`}>
                    상담 시간 닫기
                </div>
            </div>

            {
                searchMenu === "list" ?
                    <BookedList user={props.user} />
                    :
                    ""
            }
            {
                searchMenu === "manage" ?
                    <div>
                        <ManageBookingTime />
                    </div>
                    :
                    ""
            }
        </div>
    )
}

export default Booking;