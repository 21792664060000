import React, { useEffect, useState, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import styles from "./mobilewifipermit.module.css";
import { IonDatetime } from '@ionic/react';
import Dial from "../etc/dial";
import Dial2 from "../etc/wifidial";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { IonLoading } from "@ionic/react";
import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import { set } from "lodash";


const MobileEditSubmit: React.FC<any> = (props) => {

    const history = useHistory();

    const [bodyHeight, setBodyHeight] = useState(0);

    const dayTextRef = useRef<any>(null);
    const [dayHeight, setDayHeight] = useState(0);
    const [calendarInfo, setCalendarInfo] = useState<any>();


    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [currentDate, setCurrentDate] = useState(new Date().getDate());

    const [calendarHeight, setCalendarHeight] = useState(56.25);
    const [calendarOpen, setCaldendarOpen] = useState(true);

    const [kindHeight, setKindHeight] = useState(3);
    const [kindOpen, setKindOpen] = useState(false);
    const [kindMenu, setKindMenu] = useState("among");
    const [kindSelectorLeft, setKindSelectorLeft] = useState(1.5);
    const [targetKindString, setTargetKindString] = useState("방화벽 해제");

    const [targetYear, setTargetYear] = useState(0);
    const [targetMonth, setTargetMonth] = useState(0)
    const [targetDate, setTargetDate] = useState(0);

    const timeSelectBoxRef = useRef<any>(null);
    const timeSelectRef1 = useRef<any>([]);
    const dialRef1 = useRef<any>(null);
    const [selectedTimeIndex1, setSelectedTimeIndex1] = useState(36);
    const [selectedTimeString1, setSelectedTimeString1] = useState("");

    const timeSelectBoxRef2 = useRef<any>(null);
    const timeSelectRef2 = useRef<any>([]);
    const dialRef2 = useRef<any>(null);
    const [selectedTimeIndex2, setSelectedTimeIndex2] = useState(80);
    const [selectedTimeString2, setSelectedTimeString2] = useState("");

    const dialRef3 = useRef<any>(null);
    const timeSelectBoxRef3 = useRef<any>(null);
    const timeSelectRef3 = useRef<any>([]);
    const timeSelectBoxRef4 = useRef<any>(null);
    const timeSelectRef4 = useRef<any>([]);
    const [selectedTimeIndex3, setSelectedTimeIndex3] = useState(52);
    const [selectedTimeString3, setSelectedTimeString3] = useState("");
    const [selectedTimeIndex4, setSelectedTimeIndex4] = useState(56);
    const [selectedTimeString4, setSelectedTimeString4] = useState("");

    const [textValue, setTextValue] = useState("");

    const [loading, setLoading] = useState(false);



    const [dialHeight, setDialHeight] = useState(0);
    const [isSafeArea, setIsSafeArea] = useState(false);

    useEffect(() => {

        const date = new Date();
        const hours = date.getHours();
        const minutes = date.getMinutes();

        

        const totalMinutes = hours * 60 + minutes;
        const index = Math.floor(totalMinutes / 15);

        setSelectedTimeIndex3(index);
        setSelectedTimeIndex4(index + 1);

        const indexTotalMinutes = 15 * index;
        const indexTotalMinutes2 = 15 * (index + 1);

        const indexHours = Math.floor(indexTotalMinutes / 60);
        const indexMinutes = indexTotalMinutes % 60;

        const indexHours2 = Math.floor(indexTotalMinutes2 / 60);
        const indexMinutes2 = indexTotalMinutes2 % 60;

        const minuteString = indexMinutes < 10 ? "0" + indexMinutes : indexMinutes;
        const minuteString2 = indexMinutes2 < 10 ? "0" + indexMinutes2 : indexMinutes2;

        setSelectedTimeString3(`${indexHours}:${minuteString}`);
        setSelectedTimeString4(`${indexHours2}:${minuteString2}`);
        

    }, [targetYear, targetMonth, targetDate]);


    useEffect(() => {


        if (!props.menuHeaderRef || !props.menuHeaderRef.current) {
            return;
        }

        const value = +getComputedStyle(document.documentElement).getPropertyValue("--sat").split("p")[0];

        const valueBottom = +getComputedStyle(document.documentElement).getPropertyValue("--sab").split("p")[0];

        if (valueBottom > 0) {
            setIsSafeArea(true);
        }

        const caculateBodyHeight = () => {

            if (!props.menuHeaderRef || !props.menuHeaderRef.current) {
                return;
            }

            const totalHeight = window.innerHeight;
            const safeareaTop = value;
            const headerHeight = props.menuHeaderRef.current.offsetHeight;

            
            

            const lastHeight = totalHeight - safeareaTop - headerHeight;
            setBodyHeight(lastHeight);
        }

        caculateBodyHeight();

        window.addEventListener("resize", () => caculateBodyHeight());

        return window.removeEventListener("resize", () => caculateBodyHeight());



    }, [props.menuHeaderRef]);



    const callbackref = useCallback((node: any) => {
        // if(node){
        //     
        //     dialRef1.current.function1();
        //     dialRef1.current.function2();
        // }
        

        if (!node) {
            return;
        }

        
        
        if (kindMenu === "long") {


            
            dialRef1.current.function1(selectedTimeIndex1);
            dialRef1.current.function2();
        }
        if (kindMenu === "early") {


            dialRef2.current.function1(selectedTimeIndex2);
            dialRef2.current.function2();
        }
        if (kindMenu === "among") {
            dialRef3.current.function1(selectedTimeIndex3, selectedTimeIndex4);
            dialRef3.current.function2();
        }

    }, [kindMenu, kindOpen]);


    useEffect(() => {
        
        if (kindMenu === "long") {
            // dialRef1.current.function1(45);
            // dialRef1.current.function2();
        }
        if (kindMenu === "early") {
            // dialRef2.current.function1(46);
            // dialRef2.current.function2();
        }

    }, [kindMenu])




    //달력 가로, 세로 길이 맞추기
    useEffect(() => {
        if (dayTextRef && dayTextRef.current) {
            const width = dayTextRef.current.offsetWidth;
            const height = (width / 7).toFixed(5);
            
            
            setDayHeight(+height);
        }
    }, [dayTextRef]);

    //달력 정보 가져오기
    useEffect(() => {

        const info: any = [];
        const newDate = new Date();
        const currentYear = newDate.getFullYear();
        const currentMonth = newDate.getMonth();
        const currentDate = newDate.getDate();

        for (var i = currentMonth; i < currentMonth + 2; i++) {

            const month = i;
            //month, firstdayday, firstdaydate, lastday, lastdaydate, rows
            const firstDate = new Date(currentYear, month, 1);
            const lastDate = new Date(currentYear, month + 1, 0);

            const eachMonthInfo: any = {};
            eachMonthInfo.year = month > 11 ? currentYear + 1 : currentYear;
            eachMonthInfo.month = month > 11 ? month - 12 : month;
            eachMonthInfo.firstdayday = firstDate.getDay();
            eachMonthInfo.firstdaydate = firstDate.getDate();
            eachMonthInfo.lastdayday = lastDate.getDay();
            eachMonthInfo.lastdaydate = lastDate.getDate();
            eachMonthInfo.rows = Math.ceil((eachMonthInfo.firstdayday + eachMonthInfo.lastdaydate) / 7);

            info.push(eachMonthInfo);
        }

        
        setCalendarInfo([...info]);

    }, []);

    //뒤로 가기 할때 만약 달력 접혀있으면 다시 여는 기능
    useEffect(() => {
        if (props.hideBackBtn) {
            setCaldendarOpen(true);
            setCalendarHeight(56.25);
            setKindOpen(false);
            setKindHeight(20);
            handleKindSelect(4);
            setTextValue("");
        }

    }, [props.hideBackBtn])

    const handleCalendarOpen = (boolean: boolean) => {
        if (props.hideBackBtn) {
            return;
        }

        if (!boolean) {
            setCalendarHeight(3);
            setTimeout(() => {
                setCaldendarOpen(false);
            }, 290);
        }

        if (boolean) {
            setCalendarHeight(56.25);
            setCaldendarOpen(true);
            handleKindOpen(false);
        }

    }

    const handleKindOpen = (boolean: boolean) => {
        if (props.hideBackBtn) {
            return;
        }

        if (!boolean) {
            setKindHeight(3);
            setTimeout(() => {
                setKindOpen(false);
            }, 250)
        }

        if (boolean) {

            handleCalendarOpen(false);
            setKindHeight(20);
            setKindOpen(true);
        }

    }

    const handleTextFocus = (e: any) => {

    }

    const handleKindSelect = (index: number) => {

        const leftValue = 25 * (index - 1) + 1.5;
        setKindSelectorLeft(leftValue);

        setTimeout(() => {
            switch (index) {
                case 1:
                    setKindMenu("absent");
                    setTargetKindString("결석");
                    setDialHeight(0);
                    break;
                case 2:
                    setKindMenu("long");
                    setTargetKindString("지각");
                    setDialHeight(150);
                    break;
                case 3:
                    setKindMenu("early");
                    setTargetKindString("조퇴");
                    setDialHeight(150);
                    break;
                case 4:
                    setKindMenu("among");
                    setTargetKindString("방화벽 해제");
                    setDialHeight(150);
                    break;
            }
        }, 200)

    }

    useEffect(() => {
        
        
    }, [selectedTimeIndex1]);

    const handleSelectedTimeIndex1 = (index: number) => {
        setSelectedTimeIndex1(index);
        const totalMinutes = 15 * index;
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        const minuteString = minutes < 10 ? "0" + minutes : minutes;
        setSelectedTimeString1(`${hours}:${minuteString}`);
    }

    const handleSelectedTimeIndex2 = (index: number) => {
        setSelectedTimeIndex2(index);
        const totalMinutes = 15 * index;
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        const minuteString = minutes < 10 ? "0" + minutes : minutes;
        setSelectedTimeString2(`${hours}:${minuteString}`);
    }

    const handleSelectedTimeIndex3 = (index: number) => {
        setSelectedTimeIndex3(index);
        const totalMinutes = 15 * index;
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        const minuteString = minutes < 10 ? "0" + minutes : minutes;
        setSelectedTimeString3(`${hours}:${minuteString}`);
    }

    const handleSelectedTimeIndex4 = (index: number) => {
        setSelectedTimeIndex4(index);
        const totalMinutes = 15 * index;
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        const minuteString = minutes < 10 ? "0" + minutes : minutes;
        setSelectedTimeString4(`${hours}:${minuteString}`);
    }


    //long, absent, early, among


    //제출하기

    const submit = async (e: any) => {
        // if (description === "" || description === "구체적인 사유를 적어주세요.") {
        //     alert("사유를 적은 후에 제출 부탁드립니다 : )");
        //     return;
        // }
        

        setLoading(true);

        if (targetMonth !== 0 && !targetMonth) {
            alert("날짜를 선택해주세요");
            setLoading(false);
            return;
        }

        if (!targetYear || !targetDate) {
            alert("날짜를 선택해주세요");
            setLoading(false);
            return;
        }

        const year = targetYear;
        const month = targetMonth + 1;
        const date = targetDate;

        if (!kindMenu) {
            alert("종류를 선택해주세요");
            setLoading(false);
            return;
        }

        var exitHours;
        var exitMinutes;
        var arriveHours;
        var arriveMinutes;



        switch (kindMenu) {
            case "absent":
                exitHours = 12;
                exitMinutes = 12;
                arriveHours = 12;
                arriveMinutes = 12;
                break;
            case "long":
                const index1 = selectedTimeIndex1;
                const totalMinutes1 = 15 * index1;
                const hours1 = Math.floor(totalMinutes1 / 60);
                const minutes1 = totalMinutes1 % 60;

                exitHours = 12;
                exitMinutes = 12;
                arriveHours = hours1;
                arriveMinutes = minutes1;
                break;
            case "early":
                const index2 = selectedTimeIndex2;
                const totalMinutes2 = 15 * index2;
                const hours2 = Math.floor(totalMinutes2 / 60);
                const minutes2 = totalMinutes2 % 60;

                exitHours = hours2;
                exitMinutes = minutes2;

                arriveHours = 12;
                arriveMinutes = 12;
                break;
            case "among":

                const index3 = selectedTimeIndex3;
                const totalMinutes3 = 15 * index3;
                const hours3 = Math.floor(totalMinutes3 / 60);
                const minutes3 = totalMinutes3 % 60;

                const index4 = selectedTimeIndex4;
                const totalMinutes4 = 15 * index4;
                const hours4 = Math.floor(totalMinutes4 / 60);
                const minutes4 = totalMinutes4 % 60;


                exitHours = hours3;
                exitMinutes = minutes3;
                arriveHours = hours4;
                arriveMinutes = minutes4;

                //5시간 이상이면 안됨
                if (index4 - index3 > 20) {
                    alert("5시간 이상 신청할 수 없습니다.");
                    setLoading(false);
                    return;
                }

                break;

        }

        if (!textValue) {
            alert("해제 사유를 적은 후 제출해주세요");
            setLoading(false);
            return;
        }

        //textValue 4글자 이하이면
        if (textValue.length < 5) {
            alert("해제 사유를 5글자 이상 적어주세요");
            setLoading(false);
            return;
        }


        var token: any = "";
        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        if (Capacitor.isNativePlatform()) {
            const { value } = await Preferences.get({ key: "token" });
            token = value;
        }

        fetch("https://peetsunbae.com/dashboard/wifipermit/submit", {
            method: "POST",
            headers: { "Content-Type": "application/json", "Authorization": token },
            credentials: "include",
            body: JSON.stringify({
                type: kindMenu,
                year: year,
                month: month,
                date: date,
                exitHours: exitHours,
                exitMinutes: exitMinutes,
                arriveHours: arriveHours,
                arriveMinutes: arriveMinutes,
                description: textValue
            })
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    setLoading(false);
                    

                    if(result.message === "NOT_ADDRESS"){
                        alert("등록된 기기가 없습니다.(데스크 문의)");
                        return;
                    }



                    if (result.message === "success") {
                        
                        history.push({
                            pathname : "/mobiledashboard/success",
                            state : {
                                title : "방화벽 해제 신청",
                                description : "신청 성공\n(학부모 알림톡 승인 필요)"
                            }
                        })
                    }
                })
        })
    }





    return (
        <div style={{ height: `${bodyHeight}px`, overflow: "scroll" }}>
            <div className={styles.body2Div}>

                <div className={styles.body2}>
                    <div className={`${styles.calendarDiv} ${calendarOpen ? "" : styles.noPadding}`} style={{ maxHeight: `${calendarHeight}rem` }}>
                        <div className={`${styles.calendarWrapperDisplay} ${calendarOpen ? styles.active : ""}`}>
                            <div className={styles.calendarTitle} onClick={() => { handleCalendarOpen(false); }} >
                                언제 사용하나요?
                            </div>
                            <div className={styles.calendar}>
                                <div className={styles.dayTextDiv} ref={dayTextRef}>
                                    <div className={styles.dayText}>
                                        일
                                    </div>
                                    <div className={styles.dayText}>
                                        월
                                    </div>
                                    <div className={styles.dayText}>
                                        화
                                    </div>
                                    <div className={styles.dayText}>
                                        수
                                    </div>
                                    <div className={styles.dayText}>
                                        목
                                    </div>
                                    <div className={styles.dayText}>
                                        금
                                    </div>
                                    <div className={styles.dayText}>
                                        토
                                    </div>
                                </div>
                                <div className={styles.calendarInfoDiv} style={{ overflow: "scroll", height: `${7.5 * dayHeight}px` }}>
                                    {
                                        calendarInfo &&
                                        calendarInfo.map((eachMonth: any, index: number) => {
                                            return (
                                                <div className={styles.monthWrapper} key={index}>
                                                    <div className={styles.monthTitle}>
                                                        {eachMonth.year}년 {eachMonth.month + 1}월
                                                    </div>
                                                    <div className={styles.dayWrapper}>
                                                        {
                                                            new Array(7 * eachMonth.rows).fill(undefined).map((eachDay: any, indexNumber: number) => {



                                                                const date = indexNumber - eachMonth.firstdayday + 1;

                                                                var today = false;
                                                                if (currentYear === eachMonth.year && currentMonth === eachMonth.month && currentDate === date) {
                                                                    today = true;
                                                                }
                                                                var past = false;
                                                                if (currentYear * 100000 + (currentMonth + 1) * 1000 + currentDate > eachMonth.year * 100000 + (eachMonth.month + 1) * 1000 + date) {
                                                                    past = true;
                                                                }

                                                                if (date < 1 || date > eachMonth.lastdaydate) {
                                                                    return (
                                                                        <div key={date * 100} className={styles.dayDivWrapper} style={{ height: `${dayHeight}px` }}>
                                                                            <div className={styles.dayDiv}>
                                                                            </div>
                                                                        </div>

                                                                    )
                                                                } else {
                                                                    return (
                                                                        <div key={date} className={styles.dayDivWrapper} style={{ height: `${dayHeight}px` }} onClick={() => { props.handleDayClick(eachMonth.year, eachMonth.month, date, past); setTargetYear(eachMonth.year); setTargetMonth(eachMonth.month); setTargetDate(date); }}>
                                                                            <div className={`${styles.dayDiv} ${indexNumber % 7 === 0 ? styles.sunday : ""} ${indexNumber % 7 === 6 ? styles.saturday : ""} ${today ? styles.today : ""} ${past ? styles.past : ""} ${props.targetDate === (eachMonth.year * 100000 + ((eachMonth.month + 1) * 1000) + date) ? styles.active : ""}`}>
                                                                                {date}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                        <div className={`${styles.subCalendarTextDiv} ${calendarOpen ? "" : styles.active}`} onClick={() => { handleCalendarOpen(true); }}>
                            <div className={styles.smallTitle}>
                                날짜
                            </div>
                            <div className={styles.smallDescription}>
                                {targetYear}년 {targetMonth + 1}월 {targetDate}일
                            </div>
                        </div>

                    </div>








                    <div className={`${styles.kindWrapper} ${props.hideBackBtn ? "" : styles.active}`}>
                        <div className={`${styles.kindDiv}`} style={{ maxHeight: `${kindHeight}rem` }}>
                            {
                                !kindOpen &&
                                <div className={`${styles.kindSmallDiv} ${kindOpen ? "" : styles.active}`} onClick={() => { handleKindOpen(true); }}>
                                    <div className={styles.smallTitle}>
                                        시간
                                    </div>
                                    <div className={styles.smallDescription}>
                                        {targetKindString}
                                        {
                                            kindMenu === "long" &&
                                            <div className={styles.timeDisplay}>
                                                {selectedTimeString1}
                                            </div>
                                        }
                                        {
                                            kindMenu === "early" &&
                                            <div className={styles.timeDisplay}>
                                                {selectedTimeString2}
                                            </div>
                                        }
                                        {
                                            kindMenu === "among" &&
                                            <div className={selectedTimeString3 && styles.timeDisplay}>
                                                {selectedTimeString3}{selectedTimeString3 && "~"}{selectedTimeString4}
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            {
                                kindOpen &&
                                <div className={`${styles.kindBigDiv} ${kindMenu !== "absent" ? styles.morePadding : ""}`} ref={callbackref}>
                                    <div className={styles.kindBigTitle} onClick={() => { handleKindOpen(false); }}>
                                        얼마나 사용할까요?
                                    </div>
                                    {/* <div className={styles.kindSelectDiv}>
                                        <div className={styles.kindSelectWrapper}>
                                            <div className={`${styles.kindSelectElement} ${kindMenu === "absent" ? styles.active : ""}`} onClick={() => { handleKindSelect(1) }}>
                                                결석
                                            </div>
                                            <div className={`${styles.kindSelectElement} ${kindMenu === "long" ? styles.active : ""}`} onClick={() => { handleKindSelect(2) }}>
                                                지각
                                            </div>
                                            <div className={`${styles.kindSelectElement} ${kindMenu === "early" ? styles.active : ""}`} onClick={() => { handleKindSelect(3) }}>
                                                조퇴
                                            </div>
                                            <div className={`${styles.kindSelectElement} ${kindMenu === "among" ? styles.active : ""}`} onClick={() => { handleKindSelect(4) }}>
                                                외출
                                            </div>
                                            <div className={styles.kindSelector} style={{ left: `${kindSelectorLeft}%` }}>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className={styles.dialDiv} style={{ maxHeight: `${dialHeight}px` }}>
                                        {
                                            kindMenu === "long"
                                            &&
                                            <Dial ref={dialRef1} handleSelectedTimeIndex={handleSelectedTimeIndex1} timeSelectBoxRef={timeSelectBoxRef} timeSelectRef1={timeSelectRef1} kindMenu={kindMenu} />
                                        }
                                        {
                                            kindMenu === "early"
                                            &&
                                            <Dial ref={dialRef2} handleSelectedTimeIndex={handleSelectedTimeIndex2} timeSelectBoxRef={timeSelectBoxRef2} timeSelectRef1={timeSelectRef2} kindMenu={kindMenu} />
                                        }
                                        {
                                            kindMenu === "absent"
                                            &&
                                            <div style={{ height: "200px" }}>
                                            </div>
                                        }
                                        {
                                            kindMenu === "among"
                                            &&
                                            <Dial2 ref={dialRef3} handleSelectedTimeIndex3={handleSelectedTimeIndex3} handleSelectedTimeIndex4={handleSelectedTimeIndex4} timeSelectRef3={timeSelectRef3} timeSelectRef4={timeSelectRef4} timeSelectBoxRef3={timeSelectBoxRef3} timeSelectBoxRef4={timeSelectBoxRef4} kindMenu={kindMenu} />
                                        }

                                    </div>
                                </div>
                            }

                        </div>
                    </div>

                    <div className={`${styles.textDescriptionWrapper} ${props.hideBackBtn ? "" : styles.active}`}>
                        <TextareaAutosize
                            className={styles.textArea}
                            minRows={2}
                            placeholder="해제 사유 및 접속할 사이트명"
                            style={{ width: "100%" }}
                            onFocus={handleTextFocus}
                            value={textValue}
                            onChange={(e: any) => { setTextValue(e.target.value); }}
                        />
                    </div>

                    {
                        !props.hideBackBtn &&
                        <div style={{ height: "60px" }}>

                        </div>
                    }

                </div>

            </div>

            <div onClick={submit} className={`${styles.submitButton} ${props.hideBackBtn ? "" : styles.active} ${isSafeArea ? styles.safeArea : ""}`}>
                해제 신청하기
            </div>

            <IonLoading
                cssClass='my-custom-class'
                isOpen={loading}
                onDidDismiss={() => setLoading(false)}
                message={'잠시만 기다려주세요'}
            />
        </div>
    )
}

export default MobileEditSubmit;