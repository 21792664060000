import React, { useEffect, useState, useRef, MouseEventHandler, useCallback } from "react";
import styles from "./mobileonlinequestion.module.css";
import { useHistory } from "react-router-dom";
import { ReactComponent as ArrowLeftLight } from "../../../src/svg/arrow-left-light.svg";
import { ReactComponent as Plus } from "../../../src/svg/mobiletotal/square-plus-light.svg";
import { Capacitor } from "@capacitor/core";
import { ReactComponent as CaretDown } from "../../../src/svg/mobiletotal/caret-down-solid.svg";
import { getFetch, postFetch } from "../../fetch/fetch";
import { IonApp, IonAvatar} from "@ionic/react";
import { getAvatarSrc } from "../../etc/functions";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Zoom } from "swiper"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { IonItem, IonList, IonSelect, IonSelectOption } from '@ionic/react';

import { ReactComponent as Thumb } from "../../../src/svg/mobiletotal/thumbs-up-solid.svg";
import { ReactComponent as Message } from "../../../src/svg/mobiletotal/message-dots-light.svg";
import { ReactComponent as Trash } from "../../../src/svg/mobiletotal/trash-can-xmark-light.svg";

import AspectRatio from '@mui/joy/AspectRatio';
import { CssVarsProvider } from '@mui/joy/styles';
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/zoom/zoom.min.css'

import ImageViewer from "../etc/imageviewer/imageviewer";
import SelectModal from "../etc/selectModal/selectmodal";
import { useIonActionSheet, IonLoading } from '@ionic/react';
import {
    IonContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonLabel,
  } from '@ionic/react';
import { CircularProgress, LinearProgress } from "@mui/material";
import MobileOnlineQuestionDetail from "./mobileonlinequestiondetail";
import { Preferences } from "@capacitor/preferences";

var subjectVar = "total";
var alignmentVar = "All";

const MobileOnlineQuestion: React.FC<any> = (props) => {

    const [platform, setPlatform] = useState("");
    const history = useHistory();
    const [subject, setSubject] = useState("total");
    const [page, setPage] = useState(1);
    const [alignment, setAlignment] = useState("All");
    const [avatarSrc, setAvatarSrc] = useState();
    const [data, setData] = useState<any>();
    const blankRef = useRef<any>(null);
    const [targetIndex, setTargetIndex] = useState(0);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [imgSrc, setImgSrc] = useState("");
    const handleClose = () => { setIsModalOpen(false); setImgSrc("");}

    const [isModalOpen3, setIsModalOpen3] = useState(false);
    const handleClose3 = () => {setIsModalOpen3(false);}
    const handleOpen3 = (question : any) => { setSelectedQuestion(question); setIsModalOpen3(true);}
    const [selectedQuestion, setSelectedQuestion] = useState();

    const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
    const handleClose2= () => {setIsSelectModalOpen(false);}
    const handleOpen2 = () => {setIsSelectModalOpen(true);}

    const headerRef = useRef<any>();
    const mainBodyRef = useRef<any>();
    const [bodyHeight, setBodyHeight] = useState(0);

    const lastDiv = useRef<HTMLDivElement>(null);

    const observerRef = useRef<any>(null);
    const [loading, setLoading] = useState(false);

    const [update, setUpdate] = useState(0);
    const [present] = useIonActionSheet();

    const [ionLoading, setIonLoading] = useState(false);

    useEffect(() => {

        


        const handleBackButton = () => {

            if(isModalOpen3){
                setIsModalOpen3(false);
                return;
            }

            
            history.goBack();
        }

        //backbutton 눌리면 handleBackButton 실행
        document.addEventListener("backbutton", handleBackButton);

        return () => {
            document.removeEventListener("backbutton", handleBackButton);
        }

    }, [isModalOpen3]);



    const observerFn : IntersectionObserverCallback = (entries, observer) => {
        
        
        if(entries[0].isIntersecting){
            
            const newPage = page + 1;
            setPage(newPage);
        }
    }

    const attachObserver = () => {

        if(!lastDiv || !lastDiv.current){
            return;
        }

        const options = {
            threshold : 0.5
        }

        observerRef.current = new IntersectionObserver(observerFn, options);
        
        observerRef.current.observe(lastDiv.current);



    }

    const handleSubjectChange = (value : string) => {
        setSubject(value);
    }
    

    const caculateBodyHeight = () => {
        setTimeout(() => {

            if (!headerRef || !headerRef.current) {
                return;
            }


            const value = +getComputedStyle(document.documentElement).getPropertyValue("--sat").split("p")[0];
            const totalHeight = window.innerHeight;
            const safeareaTop = value;
            const headerHeight = headerRef.current.offsetHeight;
    
            const lastHeight = totalHeight - safeareaTop - headerHeight

            
            
            setBodyHeight(lastHeight);

        }, 50);
    }


    useEffect(() => {

        caculateBodyHeight();

        window.addEventListener("resize", () => caculateBodyHeight());

        return window.removeEventListener("resize", () => caculateBodyHeight());

    }, [headerRef]);


    //아바타 그림 소스 가져오기
    useEffect(() => {

        const getAvatar = async () => {
            const avatarSrc: any = await getAvatarSrc();
            
            
            setAvatarSrc(avatarSrc);
        }

        getAvatar();

    }, []);

    //플랫폼 정보 확인
    useEffect(() => {
        const platform = Capacitor.getPlatform();

        if (platform) {
            setPlatform(platform);
        }


    }, []);


    const getQuestion = async (isSubjectChange : boolean) => {
        try {

            

            if(observerRef && observerRef.current){
                observerRef.current.disconnect();
            }

            setIonLoading(true);
            const result: any = await getFetch(`/dashboard/question/getMobile?subject=${subject}&page=${page}&alignment=${alignment}`);
            setIonLoading(false);
            

            setData(result.message);

            if(!result.isMore){
                return;
            }

            setTimeout(() => {
                attachObserver();
            }, 250);

        } catch (e) {
            setIonLoading(false);
            
        }
    }



    useEffect(() => {
        
        var isSubjectChange = false;
        var isAlignmentChange = false;
        
        
        if(subjectVar !== subject){
            isSubjectChange = true;
        }

        if(alignmentVar !== alignment){
            isAlignmentChange = true;
        }

        if(isSubjectChange || isAlignmentChange){
            mainBodyRef.current.scrollTop = 0;
            if(isSubjectChange){
                if(page !== 1){
                    setPage(1);
                    return;
                }
            }
        }

        

        subjectVar = subject;
        alignmentVar = alignment;

        getQuestion(isSubjectChange);

    }, [subject, page, alignment, update]);


    const handleUpdate = () => {
        setUpdate(Math.random());
    }


    const handleClick = (e: any) => {
        if (props.home) {
            history.push("/mobiledashboard");
        } else {
            history.goBack();
        }
    }

    const write = (e: React.MouseEvent) => {
        history.push({
            pathname: "/mobiledashboard/onlinequestionwrite"
        })
    }

    const handleImgClick = (newImgSrc : string) => {
        setImgSrc(newImgSrc);
        setIsModalOpen(true);
    }


    //이해했어요 기능----------------------------------------------
    const understand = async (e: any, questionId: number, userId: number, isUnderStand : boolean) => {
        if (userId != props.user.id) {
            return;
        }

        if(isUnderStand){
            return;
        }

        

        if(data){
            setIonLoading(true);

            const newData = data;

            newData.forEach((eachData : any) => {
                if(eachData.id === questionId){
                    eachData.isUnderStand = true;
                }
            })

            setData([...newData]);
        }

        try{
            const body = {
                questionId: questionId,
                userId: props.user.id
            }

            const result = await postFetch(`/dashboard/question/understand`, body);
            

            setIonLoading(false);

        }catch(e){
            
            setIonLoading(false);
        }

    }
    //----------------------------------------------------------

    const handleAlignment = (e : any) => {
        if(alignmentVar === "My"){
            
            setAlignment("All");
        }else{
            
            setAlignment("My");
        }
    }






    //본인 글 삭제하는 기능------------------------------------------------------------------------
    const deleteMyQuesiton = async (questionId : number) => {

        setIonLoading(true);

        var token : any = "";

        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        if (Capacitor.isNativePlatform()) {
            const { value } = await Preferences.get({ key: "token" });
            token = value;
        }

        fetch(`https://peetsunbae.com/dashboard/question/delete?id=${questionId}`, {
            method: "DELETE",
            headers: { "Authorization": token },
            credentials: "include"
        }).then((response) => {
            response.json()
                .then((result) => {
                    
                    if (result.message === "success") {
                        handleUpdate();
                    }
                    setIonLoading(false);
                })
        }).catch((error) => {
            
            setIonLoading(false);
        })
    }
    //---------------------------------------------------------------------------------------------

    const letsDelete = (questionId : number) => {
        present({
            header: "삭제 안내",
            subHeader: "삭제를 진행할까요?",
            buttons: [{
                text: "삭제",
                role: "destructive"
            },
            {
                role: "cancel",
                text: "취소",
            }
            ],
            onDidDismiss: ({ detail }) => {
                
                switch (detail.role) {
                    case "destructive":
                        deleteMyQuesiton(questionId);
                        break;
                    case "cancel":
                        break;
                }
            },
        })
    }




    return (
        <IonApp>
            <div style={{ paddingTop: "env(safe-area-inset-top)" }}>
                <div className={`${styles.header} ${platform === "ios" ? styles.ios : ""}`} ref={headerRef}>
                    <div className={styles.backBtnDiv}
                        onClick={(e: any) => { handleClick(e) }}
                    >
                        <ArrowLeftLight
                            fill="#2a2a2a"
                            className={styles.arrowLeft}
                        />
                    </div>

                    <div className={styles.titleText}>
                        <IonSelect value={subject} onIonChange={(e) => handleSubjectChange(e.detail.value)}>
                            <IonSelectOption value="total">전체</IonSelectOption>
                            <IonSelectOption value="chemistry">국어</IonSelectOption>
                            <IonSelectOption value="organic">수학</IonSelectOption>
                            <IonSelectOption value="physics">영어</IonSelectOption>
                            <IonSelectOption value="biology">탐구</IonSelectOption>
                        </IonSelect>
                        {/* <div className={styles.titleTextString}>
                            전체
                        </div>
                        <CaretDown fill="#9e9e9e" className={styles.caret} /> */}
                    </div>

                    <div className={`${styles.submit}`}>
                        <div>

                        </div>
                        <div className={`${styles.my} ${alignment === "My" ? styles.active : ""}`} onClick={handleAlignment}>
                            MY
                        </div>
                        <div onClick={write}>
                            <Plus className={styles.plus} fill="#2a2a2a" />
                        </div>
                    </div>
                </div>
                {
                    ionLoading
                    &&
                    <LinearProgress />
                }
                <div className={styles.body}>
                    <div className={styles.mainBody} ref={mainBodyRef} style={{height : `${bodyHeight}px`, overflow : "scroll"}}>
                        {
                            data && data.map((eachQuestion: any, questionIndex: number) => {

                                const createdAt = eachQuestion.createdAt;

                                return (
                                    <div key={eachQuestion.id} className={styles.eachQuestion}>
                                        <div className={styles.eachQuestionName}  onClick={(e) => {handleOpen3(eachQuestion);}}>
                                            <IonAvatar className={styles.avatar}>
                                                <img alt="Silhouette of a person's head" src={eachQuestion.avatar ? `/img/avatar/Avatar-${eachQuestion.avatar}.png` : "https://ionicframework.com/docs/img/demos/avatar.svg"} className={styles.avatarImg} />
                                            </IonAvatar>
                                            <div className={styles.eachQuestionNameText}>
                                                <div className={styles.author}>
                                                    {eachQuestion.email.split("@")[0]}
                                                </div>
                                                <div className={styles.dateString}>
                                                    {createdAt.year}/{createdAt.month}/{createdAt.date} {createdAt.hours > 12 ? "PM" : "AM"} {createdAt.hours > 12 ? createdAt.hours - 12 : createdAt.hours}:{createdAt.minutes < 10 ? "0" + createdAt.minutes : createdAt.minutes}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.eachQuestionDescription}  onClick={(e) => {handleOpen3(eachQuestion);}}>
                                            <div className={styles.eachQuestionTitle}>
                                                {eachQuestion.title}
                                            </div>
                                            <div className={styles.eachQuestionText}>
                                                {eachQuestion.description}
                                            </div>
                                        </div>

                                        <div className={styles.imagesWrapper}>
                                            <Swiper
                                                modules={[Pagination, Zoom]}
                                                pagination
                                                zoom={{maxRatio : 3.5, minRatio : 1}}
                                            >
                                                {
                                                    eachQuestion.images.map((eachImageSrc: string, imageIndex: number) => {
                                                        return (
                                                            <SwiperSlide key={imageIndex}>
                                                                <CssVarsProvider>
                                                                    <AspectRatio ratio="1/0.64">
                                                                    <div className={styles.imageBox}
                                                                    //  onClick={(e) => {handleImgClick(`https://peetsunbae.com/${eachImageSrc.split("/public/")[1]}`);}}
                                                                    >
                                                                        <div className="swiper-zoom-container">
                                                                            <img className={styles.questionImg} src={`https://peetsunbae.com/${eachImageSrc.split("/public/")[1]}`} />
                                                                        </div>
                                                                    </div>

                                                                    </AspectRatio>
                                                                </CssVarsProvider>
                                                            </SwiperSlide>
                                                        )
                                                    })
                                                }
                                            </Swiper>
                                        </div>
                                        <div className={styles.eachQuestionFooter}>
                                            <div className={styles.eachQuestionInnerFooter}>
                                                <div className={styles.likeDiv} onClick={(e) => {understand(e, eachQuestion.id, eachQuestion.userId, eachQuestion.isUnderStand)}}>
                                                    <Thumb fill={eachQuestion.isUnderStand ? "#3d50b0" : "#d0d0d0"} className={styles.thumb} />
                                                    <span className={`${styles.understandText} ${eachQuestion.isUnderStand ? styles.active : ""}`}>
                                                        이해했어요
                                                    </span>
                                                </div>
                                                <div className={styles.replyDiv} onClick={(e) => {handleOpen3(eachQuestion);}}>
                                                    <Message fill={eachQuestion.reviews.length > 0 ? "#3d50b0" : "#9e9e9e"} className={styles.message} />
                                                    {
                                                        eachQuestion.reviews.length > 0
                                                        &&
                                                        <span className={styles.replyNumber}>
                                                            {
                                                                eachQuestion.reviews.length
                                                            }
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            eachQuestion.userId === props.user.id 
                                            &&
                                            <Trash onClick={(e) => {letsDelete(eachQuestion.id);}} className={styles.trash} />
                                        }
                                    </div>
                                );
                            })
                        }

                        <div className={styles.lastDiv} ref={lastDiv} style={{paddingTop : "0.5rem", paddingBottom : "1rem",display : "flex", justifyContent : "center"}}>
                            {
                                loading &&
                                <CircularProgress />
                            }
                        </div>

                    </div>

                </div>

                <SelectModal
                open={isSelectModalOpen}
                handleClose={handleClose2}
                selectOptions={[
                    {subject : "전체", value : "total"},
                    {subject : "국어", value : "korean"},
                    {subject : "수학", value : "math"},
                    {subject : "영어", value : "english"},
                    {subject : "탐구", value : "science"},
                ]}
                handleSubjectChange={handleSubjectChange}
                subject={subject}
                />

                <ImageViewer 
                isModalOpen={isModalOpen}
                handleClose={handleClose}
                src={imgSrc}
                />

                <MobileOnlineQuestionDetail
                isOpen={isModalOpen3}
                handleClose={handleClose3}
                eachQuestion={selectedQuestion}
                understand={understand}
                user={props.user}
                socket={props.socket}
                handleUpdate={handleUpdate}
                />


                {/* <IonLoading
                    cssClass='my-custom-class'
                    isOpen={ionLoading}
                    onDidDismiss={() => setIonLoading(false)}
                    message={'잠시만 기다려주세요'}
                /> */}
            </div>
        </IonApp>
    );
}

export default MobileOnlineQuestion;