import React, { useEffect, useState, useRef } from "react";
import styles from "../componentsStyle/diagnosis.module.css";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Alert, LinearProgress, Modal, Stack } from '@mui/material';
import ReactToPrint from 'react-to-print';


const Diagnosis: React.FC<any> = (props) => {

    const [active, setActive] = useState(false);
    const [selectedUser, setSelectedUser] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState<any>();
    const componentRef = useRef(null);
    const [value, setValue] = useState<string[]>(new Array(100).fill(""));
    const [uploadBool, setUploadBool] = useState(false);
    const [active2, setActive2] = useState(false);
    const [teacherName, setTeacherName] = useState("");

    const getData = async (studentId : number) => {

        setLoading(true);
        setTeacherName("");

        var token = "";

        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        fetch("https://peetsunbae.com/dashboard/chart/diagnosisget?studentId="+studentId, {
                method : "GET",
                headers : {
                    "Authorization" : token
                },
                credentials : "include"
            }
        ).then((response) => {
            response.json()
            .then((result) => {
                
                if(result.message === "success"){
                    const newValue = result.data.data;
                    setValue([...newValue]);
                    setTeacherName(result.data.teacherName);
                }else{
                    const newValue : any = new Array(100).fill("");
                    setValue([...newValue]);
                }

                setLoading(false);
            })
        }).catch((e) => {
            
            setLoading(false);
        })

    }

    useEffect(() => {




        if(selectedUser){
            setActive(true);
            setActive2(true);

            getData(selectedUser.id);

        }else{
            setActive(false);
            setActive2(false);
        }

    }, [selectedUser]);


    const textFieldIndex = [
        6, 7, 8, 10, 11, 12, 14, 15, 16, 19, 20, 21, 23, 24, 25, 27, 28, 29, 31, 32, 33, 36, 37, 38, 40, 41, 42, 44
    ]

    const titleFieldIndex = [
        {
            index: 0,
            text: "과목"
        },
        {
            index: 1,
            text: "진단"
        },
        {
            index: 2,
            text: "학습전략추천"
        },
        {
            index: 3,
            text: "강의 및 컨텐츠 추천"
        },
        {
            index: 4,
            text: "국어"
        },
        {
            index: 5,
            text: "문학"
        },
        {
            index: 9,
            text: "독서"
        },
        {
            index: 13,
            text: "선택"
        },
        {
            index: 17,
            text: "수학"
        },
        {
            index: 18,
            text: "수1"
        },
        {
            index: 22,
            text: "수2"
        },
        {
            index: 26,
            text: "선택"
        },
        {
            index: 30,
            text: "영어"
        },
        {
            index: 34,
            text: "탐구"
        },
        {
            index: 35,
            text: "선택"
        },
        {
            index: 39,
            text: "선택"
        },
        {
            index: 43,
            text: "추가사항"
        },
    ]

    useEffect(() => {
        props.activateMenuList("diagnosis");
    }, []);

    const marginTop = "10mm";
    const marginBottom = "10mm";
    const marginRight = "10mm";
    const marginLeft = "10mm";

    const getPageMargins = () => {
        return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
    };

    const onchange = async (e: any, value: any) => {

        if (!users) {
            return;
        }

        // 

        // if(selectedUser){
        //     if(!window.confirm("내용을 저장하지 않은 채 학생을 바꾸면 적은 내용이 사라집니다. 내용을 저장하셨거나 저장할 필요가 없으십니까?")){
        //         return;
        //     }else{

        //     }
        // }
        
        
        setSelectedUser(value);
    }

    const handler = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        event.returnValue = "";
    };

    useEffect(() => {

        window.addEventListener("beforeunload", handler)

        return () => {
            window.removeEventListener("beforeunload", handler);
        }

    }, []);


    useEffect(() => {

        setLoading(true);
        
        async function start() {

            var token = "";

            if (window.electron) {
                token = await window.electron.sendMessageApi.getToken();
            }

            fetch("https://peetsunbae.com/dashboard/chart/users", {
                method: "GET",
                headers: { "Authorization": token },
                credentials: "include",
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        
                        const rows: any = [];
                        result.data.forEach((each: any, index: number) => {
                            var data: any = {};
                            data.id = each.id;
                            data.label = each.name;
                            data.phoneNumber = each.phoneNumber;
                            data.value = each.value;
                            data.key = index;
                            if (data.value === "student") {
                                rows.push(data);
                            }
                        })
                        setUsers([...rows]);
                        setLoading(false);

                    })
            })
        }

        start();

    }, [])

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>, index: number) => {

        const newValue: string[] = value;
        newValue[index] = e.target.value;

        setValue([...newValue]);
    }

    const submit = async () => {

        

        if (!selectedUser) {
            return;
        }

        setLoading(true);

        
        

        const data = value;

        var token = "";

        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        fetch("https://peetsunbae.com/dashboard/chart/diagnosiswrite", {
            method: "POST",
            headers: { "Authorization": token, "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
                studentId : selectedUser.id,
                value
            })
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    
                    if(result.message === "success"){
                        alert("저장 성공");
                    }
                    setLoading(false);
                })
        }).catch((e) => {
            
            setLoading(false);
        })

    }

    const sendToParent = async () => {

        setLoading(true);

        if(!selectedUser){
            return;
        }

        var token = "";

        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        

        fetch("https://peetsunbae.com/dashboard/chart/diagnosissend",
            {
                method: "POST",
                headers: { "Authorization": token, "Content-Type": "application/json" },
                credentials: "include",
                body: JSON.stringify({
                    studentId: selectedUser.id,
                })
            }
        ).then((response : any) => {
            
            response.json()
            .then((result : any) => {

                
                
                if(result.message === "success"){
                    alert("전송 성공");
                }
                if(result.message === "noData"){
                    alert("이 학생에 대해 저장한 내용이 없어서 전송 불가합니다");
                }
                setLoading(false);
            })
        }).catch((e) => {
            
            alert("에러. 관리자에게 문의해주세요");
            setLoading(false);
        })

    }


    return (
        <div className={styles.main}>
            <div className={styles.title}>
                <img src="img/off/chart.svg" alt="chart" /> 학습진단 적기
            </div>
            <style>{getPageMargins()}</style>
            <div className={styles.selectStudentText}>
                학생선택
            </div>
            <div className={styles.autocompleteDiv}>
                <Autocomplete
                    onChange={onchange}
                    value={selectedUser}
                    disablePortal
                    id="combo-box-demo"
                    options={users}
                    sx={{ width: 226 }}
                    renderInput={(params) => <TextField {...params} label="이름" />}
                />
                <div style={{fontFamily : "Apple_R", marginTop : "8px"}}>
                    * [저장] 하지 않고 학생을 바꾸면 적혀있는 내용이 사라집니다.
                </div>
            </div>

            <div className={styles.manageDiv} ref={componentRef}>
                <style type="text/css" media="print">{"\
  @page {\ size: landscape;\ }\
"}</style>
                <div className={styles.manageDivTitle}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span>학습 진단({new Date().getMonth() + 1}월 {new Date().getDate()}일) {teacherName ? `  -  ${teacherName}` : ""}</span>
                    </div>
                    <div>{selectedUser && selectedUser.label}</div>
                </div>

                <div className={styles.manageDivTable}>
                    {
                        new Array(45).fill(undefined).map((each: undefined, index: number) => {

                            var isTextField = false;

                            if (textFieldIndex.includes(index)) {
                                isTextField = true;
                            }

                            var text = "";

                            titleFieldIndex.forEach((each) => {
                                if (each.index === index) {
                                    text = each.text;
                                }
                            })

                            return (
                                <div key={index} className={`${styles.cell} ${styles["cell" + index]} ${!isTextField ? styles.titleTableCell : styles.textFieldCell}`}>
                                    {text}
                                    {
                                        isTextField
                                        &&
                                        <textarea value={value[index]} onChange={(e) => { handleChange(e, index); }} className={styles.textarea} />
                                    }
                                </div>
                            )

                        })
                    }
                </div>
            </div>

            {loading &&
                    <div className={styles.linearProgress}>
                        <LinearProgress />
                    </div>
                }
                {(uploadBool) &&
                    <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert severity="info" sx={{ marginTop: 2, marginBottom: 2 }}><span>저장 성공 !</span></Alert>
                    </Stack>
                }


                <div className={styles.printDiv}>
                    <ReactToPrint
                        trigger={() => <img className={styles.print} src="img/print-regular.svg" alt="print"></img>}
                        content={() => componentRef.current}
                    />
                    {active &&
                        <div onClick={submit} className={styles.submit}>
                            저장하기
                            <img src="img/navigate_next.svg" alt="right"></img>
                        </div>
                    }
                    {!active &&
                        <div className={styles.disabledSubmit}>
                            저장하기
                            <img src="img/navigate_next.svg" alt="right"></img>
                        </div>
                    }
                </div>
                <div className={styles.printDiv} style={{ marginBottom: "4px" }}>
                    {active2 &&
                        <div onClick={sendToParent} className={styles.submit}>
                            학부모 전송
                            <img src="img/navigate_next.svg" alt="right"></img>
                        </div>
                    }
                    {!active2 &&
                        <div className={styles.disabledSubmit}>
                            학부모 전송
                            <img src="img/navigate_next.svg" alt="right"></img>
                        </div>
                    }
                </div>
                <div className={styles.printDiv} style={{ fontSize: "12px", textAlign: "right", display: "block", marginTop: "12px" }}>
                    * <strong>저장을 반드시 먼저 누르고 전송하기</strong><br></br>
                    현재 화면에 적혀있는 내용이 아니라<br></br> 마지막 저장 내용이 전송됩니다.<br></br>
                </div>
        </div>
    );
}

export default Diagnosis;