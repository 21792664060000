import { IonLoading } from "@ionic/react";
import React, { useEffect, useState, useRef } from "react";
import { getFetch } from "../../fetch/fetch";
import styles from "./mobileofflinequestionstatus.module.css";

import { ReactComponent as Check } from "../../../src/svg/check-light.svg";
import { ReactComponent as Xmark } from "../../../src/svg/xmark-light.svg";
import { ReactComponent as ArrowUp } from "../../../src/svg/arrow-up-light.svg";
import { ReactComponent as Mail } from "../../../src/svg/mobiletotal/paper-plane-light.svg";
import { returnDayString } from "../etc/functions";
import { useHistory } from "react-router-dom";
import { Skeleton } from "@mui/material";

const MobileOfflineQuestionStatusStudent: React.FC<any> = (props) => {

    const [data, setData] = useState<any>();
    const [loading, setLoading] = useState(false);
    const statusTopRef = useRef<HTMLDivElement>(null);
    const [bodyHeight, setBodyHeight] = useState(0);
    const history = useHistory();

    const calcBodyHeight = () => {
        if (!statusTopRef || !statusTopRef.current) {
            return;
        }

        if (!props.statusDivHeight) {
            return;
        }

        const totalHeight = props.statusDivHeight;
        const bodyHeight = totalHeight - statusTopRef.current.offsetHeight;

        
        
        

        setBodyHeight(bodyHeight);
    }

    useEffect(() => {

        setTimeout(() => {
            calcBodyHeight();
        }, 30);


    }, [props.statusDivHeight]);

    const getEnrolledOfflineQuestion = async () => {

        setLoading(true);


        try {
            const result: any = await getFetch("/dashboard/question/offline/mobilecheck");

            if (result.message !== "success") {
                
                setLoading(false);
                return;
            }

            if (result.data.length === 0) {
                
                setLoading(false);
                return;
            }

            result.data.sort((after: any, before: any) => {
                const afterDate = new Date(after.targetDate);
                const afterInfo = {
                    year: afterDate.getFullYear(),
                    month: afterDate.getMonth(),
                    date: afterDate.getDate()
                }
                const afterNumber = afterInfo.year * 10000 + afterInfo.month * 100 + afterInfo.date;

                const beforeDate = new Date(before.targetDate);

                const beforeInfo = {
                    year: beforeDate.getFullYear(),
                    month: beforeDate.getMonth(),
                    date: beforeDate.getDate()
                }

                const beforeNumber = beforeInfo.year * 10000 + beforeInfo.month * 100 + beforeInfo.date;

                if (afterNumber > beforeNumber) {
                    return true;
                } else if (afterNumber < beforeNumber) {
                    return false;
                } else if (afterNumber === beforeNumber) {

                    if (+after.questionTime > +before.questionTime) {
                        return true;
                    } else {
                        return false;
                    }

                }
            })

            

            setData(result.data);

        } catch (e) {
            
        }

        setTimeout(() => {
            setLoading(false);
        }, 50);
    }

    useEffect(() => {
        getEnrolledOfflineQuestion();
    }, []);

    const sendMessage = (questionId: number, teacherId: number, teacherName: string, month: number, date: number, dayString: string, enrolledId: number, enrolledQuestionTime: number) => {

        

        history.push({
            pathname: "/mobiledashboard/offlinequestionwrite",
            state: {
                questionId,
                teacherId,
                teacherName,
                month,
                date,
                dayString,
                enrolledId,
                enrolledQuestionTime
            }
        })

    }

    return (
        <div className={styles.statusBody} style={{ height: `${props.statusDivHeight}px`, overflow: "scroll" }}>

            <div className={styles.statusTop} ref={statusTopRef}>
                <div className={styles.statusTitle}>
                    질의응답 신청 현황
                </div>
                <div className={styles.statusNumber}>
                    # 신청 횟수 - {data && data.length}회
                </div>
            </div>

            {
                loading
                &&
                <div style={{ width: "90.6%", margin: "0 auto" }}>
                    <div style={{ height: "20px" }}>
                    </div>
                    <Skeleton variant="rectangular" width="100%" height={140} />
                    <div style={{ height: "20px" }}>
                    </div>
                    <Skeleton variant="rectangular" width="100%" height={140} />
                    <div style={{ height: "20px" }}>
                    </div>
                    <Skeleton variant="rectangular" width="100%" height={140} />
                    <div style={{ height: "20px" }}>
                    </div>
                </div>
            }

            {
                !loading
                &&
                <div className={styles.description}>
                    <div className={styles.descriptionBody} style={{ minHeight: `${bodyHeight}px` }}>

                        {
                            data &&
                            data.map((eachCheckResult: any, index: number) => {

                                

                                const dateInserted = new Date(eachCheckResult.createdAt);

                                const dateInsertedString = `${dateInserted.getMonth() + 1}월 ${dateInserted.getDate()}일`;

                                const targetDate = new Date(eachCheckResult.targetDate);

                                const month = targetDate.getMonth() + 1;
                                const date = targetDate.getDate();
                                var targetDateDay = returnDayString(targetDate.getDay());

                                const targetDateString = `${targetDate.getMonth() + 1}월 ${targetDate.getDate()}일 ${targetDateDay}요일`;

                                var kindString = "외출";


                                var startAmPm = "오전";

                                if (eachCheckResult.ampm === "PM") {
                                    startAmPm = "오후"
                                }

                                var startHours = eachCheckResult.hours;
                                var startMinutes = eachCheckResult.minutes;




                                return (
                                    <div key={eachCheckResult.id}>
                                        <div className={`${styles.eachDescription} ${data.length - 1 === index ? styles.last : ""}`}>
                                            <div className={styles.eachDescriptionDate}>
                                                신청 제출일 : {dateInsertedString}
                                            </div>
                                            <div className={styles.eachDescriptionBox}>
                                                <div className={styles.eachDescriptionText}>
                                                    {eachCheckResult.teacherName} 
                                                </div>
                                                <div className={styles.eachDescriptionText}>
                                                    [{eachCheckResult.uploadTeacherDescription}]
                                                </div>
                                                <div className={styles.eachDescriptionTargetDate}>
                                                    {eachCheckResult.subject}
                                                </div>
                                                <div className={styles.eachDescriptionKindDiv}>
                                                    <div className={styles.eachDescriptionKindText}>
                                                        [{targetDateString}]
                                                        &nbsp;{startAmPm} {startHours}시 {startMinutes}분
                                                    </div>
                                                    <div className={`${styles.eachDescriptionKindSuccess} ${eachCheckResult.description.length === 0 ? styles.decline : styles.success}`}>
                                                        {
                                                            (eachCheckResult.description.length > 0) &&
                                                            <Check fill="#fff" className={styles.check}></Check>
                                                        }
                                                        {
                                                            (eachCheckResult.description.length === 0) &&
                                                            <Xmark fill="#fff" className={styles.xmark}></Xmark>
                                                        }

                                                        &nbsp;
                                                        <span>
                                                            {
                                                                (eachCheckResult.description.length > 0) &&
                                                                <div>
                                                                    내용 전송
                                                                </div>
                                                            }
                                                            {
                                                                (eachCheckResult.description.length === 0) &&
                                                                "내용 미전송"
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className={styles.trashCanDiv} onClick={(e: any) => { sendMessage(eachCheckResult.questionId, eachCheckResult.teacherId, eachCheckResult.teacherName, month, date, `${targetDateDay}요일`, eachCheckResult.id, eachCheckResult.questionTime) }}>
                                                    <Mail className={styles.trash} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                );

                            })
                        }




                    </div>
                </div>
            }



            {/* <IonLoading
                cssClass='my-custom-class'
                isOpen={loading}
                onDidDismiss={() => setLoading(false)}
                message={'잠시만 기다려주세요'}
            /> */}
        </div>
    );
}

export default MobileOfflineQuestionStatusStudent;