import React, { useEffect, useState, useCallback } from "react";
import {
    DataGridPremium,
    GridColDef,
    GridRowsProp,
    GridRowSelectionModel,
    useGridApiRef,
    GridGroupNode,
    GRID_ROOT_GROUP_ID,
    GridCellSelectionModel,
    GridFilterModel,
    GridLogicOperator,
    GridRowModel,
    GridRowClassNameParams,
    GridRenderCellParams,
    useGridApiContext,
    useGridSelector,
    gridDetailPanelExpandedRowIdsSelector,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from '@mui/x-data-grid-premium';
import { makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
import { renderCellExpand } from "../../infos/suddenData";
import { IconButton } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { set } from "lodash";
import { he } from "date-fns/locale";


const defaultTheme = createTheme();

const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

        const getHoverBackgroundColor2 = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.2) : lighten(color, 0.2);


        return {
            root: {
                '& .super-app-theme--처리완료': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .once': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .once_in': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    color : "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    }
                },
                '& .never': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .never_in': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    color : "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .clickable': {
                    cursor: "pointer",
                    '&:hover': {
                        color: "blue"
                    }
                },
                '& .enter': {
                    color : "blue"
                },
                '& .exit': {
                    color : "red"
                },
                '& .access1': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                },
                '& .sudden1_1': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                },
                '& .sudden2_1': {
                    backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                },
                '& .sudden3_1': {
                    backgroundColor: getHoverBackgroundColor2(theme.palette.warning.main),
                },
                '& .sudden1_0': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    color : "red"
                },
                '& .sudden2_0': {
                    backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    color : "red"
                },
                '& .sudden3_0': {
                    backgroundColor: getHoverBackgroundColor2(theme.palette.warning.main),
                    color : "red"
                },
                '& .regular1_1': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                },
                '& .regular2_1': {
                    backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                },
                '& .regular3_1': {
                    backgroundColor: getHoverBackgroundColor2(theme.palette.success.main),
                },
                '& .regular1_0': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    color : "red"
                },
                '& .regular2_0': {
                    backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    color : "red"
                },
                '& .regular3_0': {
                    backgroundColor: getHoverBackgroundColor2(theme.palette.success.main),
                    color : "red"
                },
                '& .status1': {
                    backgroudColor: "red"
                },
                '& .status4': {
                    backgroudColor: "red"
                },
                '& .status5': {
                    backgroudColor: "red"
                },
                '& .lastRow': {
                    borderBottom : "2px solid rgba(224,224,224,1) !important"
                },
                '& .kind': {
                    borderRight : "2px solid rgba(224,224,224,1) !important",
                    borderBottom : "0px !important",
                },
                '& .kind_selected': {
                    borderRight : "1px solid rgba(224,224,224,1) !important",
                    borderBottom : "0px !important",
                    backgroundColor : "rgba(25, 118, 210, 0.08) !important"
                },
                '& .kind_lastRow': {
                    borderRight : "2px solid rgba(224,224,224,1) !important",
                    borderBottom : "2px solid rgba(224,224,224,1) !important",
                },
                '& .kind_lastRow_selected': {
                    borderRight : "2px solid rgba(224,224,224,1) !important",
                    borderBottom : "2px solid rgba(224,224,224,1) !important",
                    backgroundColor : "rgba(25, 118, 210, 0.08) !important"
                },
                '& .headerRow': {
                    borderBottom : "1.5px solid rgba(224,224,224,1) !important",
                    //fontFamily: "Apple_SB",
                }
            },
        };
    },
    { defaultTheme },
);



const DemeritChart: React.FC<any> = (props) => {

    const classes = useStyles2();
    const [rows, setRows] = useState<GridRowsProp[]>([]);
    const [columns, setColumns] = useState<GridColDef[]>([
        {
            field: "kind", headerName: "벌점사유", width: 130, editable: true, headerAlign: "center", renderCell: (params) => {


                return (
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: 500,
                            //fontFamily: "Apple_SB"
                        }}
                    >
                        {params.value}
                    </div>
                )
            },
            headerClassName : "headerRow"
        },

        { field: "access", headerName: "출입기록", width: 96, headerAlign: "center", editable: true,             headerClassName : "headerRow" },
        { field: "sudden", headerName: "사유제출", width: 96, editable: true, headerAlign: "center", renderCell: renderCellExpand,             headerClassName : "headerRow" },
        { field: "regular", headerName: "정기일정", width: 96, editable: true, headerAlign: "center", renderCell: renderCellExpand,             headerClassName : "headerRow" },
    ]);


    useEffect(() => {

        if(!props.selectedViolationListOrder){
            return;
        }

        
        

        const newRows = [...rows];

        var targetFirstIndex = 0;

        newRows.forEach((eachRow : any) => {

            if(eachRow.kind && eachRow.kind.includes(`${props.selectedViolationListOrder}.`)){
                targetFirstIndex = eachRow.firstIndex;
            }

        });

        newRows.forEach((eachRow : any) => {

            if(eachRow.firstIndex === targetFirstIndex){
                eachRow.selected = true;
            }else{
                eachRow.selected = false;
            }

        });

        setRows([...newRows]);

    }, [props.selectedViolationListOrder]);


    useEffect(() => {

        start();
 
    }, [props.targetDate, props.selectedUserId, props.drawingList]);

    const start  = async () => {

        if(!props.drawingList){
            setRows([]);
            return;
        }

        makeRows();

    }

    const makeRows = async () => {

        if(!props.drawingList){
            setRows([]);
            return;
        }

        //
        //

        const drawingList = props.drawingList;

        const newRows : any = [];

        var previousHeightIndex = 0;

        drawingList.forEach((eachDrawing : any[], index : number) => {

            var realKoreanType = "";

            const maxDrawingOrder = eachDrawing[eachDrawing.length - 1].order;
            var heightIndex = maxDrawingOrder;

            if(!maxDrawingOrder){
                return;
            }

            var isFirst = false;

            if(eachDrawing[0].time === 480 && index === 0){
                isFirst = true;
            }

            if(!isFirst){
                const oneRow: any = {};
                oneRow.id = 999 + index;
                oneRow.firstIndex = index + 1;
                newRows.push(oneRow); 
                heightIndex++;   
            }

            var accessNumber = 0;
            var suddenNumber = 0;
            var regularNumber = 0;

            for(var i = 1; i <= maxDrawingOrder; i++){

                const oneRow : any = {};
                oneRow.sudden = "";
                oneRow.regular = "";
                oneRow.firstIndex = index + 1;
                oneRow.id = +(index + "" + i);

                eachDrawing.forEach((eachDrawing : any) => {

                    if(eachDrawing.order === i){

                        if(eachDrawing.kind === "access"){

                            realKoreanType = `${index + 1}. ${eachDrawing.koreanType}`;

                            if(eachDrawing.status === "out"){
                                accessNumber++;
                            }
                            var koreanType = "";
                            switch(eachDrawing.type){
                                case "absent" :
                                    koreanType = "결석";
                                    break;
                                case "late" :
                                    koreanType = "지각";
                                    break;
                                case "early" :
                                    koreanType = "조퇴";
                                    break;
                                case "among" :
                                    koreanType = "외출";
                                    break;
                                case "lunch" :
                                    koreanType = "늦착";
                                    break;
                                case "dinner" :
                                    koreanType = "늦착";
                                    break;
                            }

                            switch(eachDrawing.type){
                                case "absent" :
                                    oneRow.access = koreanType;
                                    break;
                                case "late" :
                                    if(eachDrawing.status === "in"){
                                        oneRow.access = koreanType + " " + eachDrawing.timeString;
                                    }
                                    break;
                                case "early" :
                                    if(eachDrawing.status === "out"){
                                        oneRow.access = koreanType + " " + eachDrawing.timeString;
                                    }
                                    break;
                                case "among" :
                                    oneRow.access = koreanType + " " + eachDrawing.timeString;
                                    break;
                                case "lunch" : case "dinner" :
                                    if(eachDrawing.status === "out"){
                                        oneRow.access = "OUT" + " " + eachDrawing.timeString;
                                    }
                                    if(eachDrawing.status === "in"){
                                        oneRow.access = koreanType + " " + eachDrawing.timeString;
                                    }
                                    break;
                                case "amongOrEarly" :
                                    if(eachDrawing.status === "out"){
                                        oneRow.access = "OUT" + " " + eachDrawing.timeString;
                                    }
                                    break;
                                case "lateOrAbsent" :
                                    if(eachDrawing.status === "in"){
                                        oneRow.access = "출입기록X"
                                    }
                            }

                        }
                        if(eachDrawing.kind === "sudden"){

                            oneRow.suddenParentpermit = eachDrawing.parentpermit === 1 ? 1 : 0;

                            if(eachDrawing.status === "out"){
                                suddenNumber++;
                            }
                            var koreanType = "";

                            switch(eachDrawing.type){
                                case "absent" :
                                    koreanType = "결석";
                                    break;
                                case "long" :
                                    koreanType = "지각";
                                    break;
                                case "early" :
                                    koreanType = "조퇴";
                                    break;
                                case "among" :
                                    koreanType = "외출";
                                    break;
                            }

                            switch(eachDrawing.type){
                                case "absent" :
                                    oneRow.sudden += koreanType;
                                    break;
                                case "long" :
                                    if(eachDrawing.status === "in"){
                                        oneRow.sudden += koreanType + " " + eachDrawing.timeString;
                                    }
                                    break;
                                case "early" :
                                    if(eachDrawing.status === "out"){
                                        oneRow.sudden += koreanType + " " + eachDrawing.timeString;
                                    }
                                    break;
                                case "among" :
                                    oneRow.sudden += koreanType + " " + eachDrawing.timeString;
                                    break;
                            }

                        }
                        if(eachDrawing.kind === "regular"){
                            if(eachDrawing.status === "out"){
                                regularNumber++;
                            }

                            oneRow.regularParentpermit = eachDrawing.parentpermit === 1 ? 1 : 0;

                            var koreanType = "";

                            switch(eachDrawing.type){
                                case "absent" :
                                    koreanType = "결석";
                                    break;
                                case "late" :
                                    koreanType = "지각";
                                    break;
                                case "early" :
                                    koreanType = "조퇴";
                                    break;
                                case "among" :
                                    koreanType = "외출";
                                    break;
                            }

                            switch(eachDrawing.type){
                                case "absent" :
                                    oneRow.regular += koreanType;
                                    break;
                                case "late" :
                                    if(eachDrawing.status === "in"){
                                        oneRow.regular += koreanType + " " + eachDrawing.timeString;
                                    }
                                    break;
                                case "early" :
                                    if(eachDrawing.status === "out"){
                                        oneRow.regular += koreanType + " " + eachDrawing.timeString;
                                    }
                                    break;
                                case "among" :
                                    oneRow.regular += koreanType + " " + eachDrawing.timeString;
                                    break;
                            }
                        }

                    }

                });

                oneRow.accessNumber = accessNumber;
                oneRow.suddenNumber = suddenNumber;
                oneRow.regularNumber = regularNumber;

                eachDrawing.forEach((eachDrawing : any) => {

                    if(eachDrawing.order === i){

                        if(eachDrawing.kind === "access"){
                            if(eachDrawing.status === "in"){
                                accessNumber--;
                            }

                        }
                        if(eachDrawing.kind === "sudden"){
                            if(eachDrawing.status === "in"){
                                suddenNumber--;
                            }
                        }
                        if(eachDrawing.kind === "regular"){
                            if(eachDrawing.status === "in"){
                                regularNumber--;
                            }
                        }

                    }

                });

                newRows.push(oneRow);
                
            }

            const twoRow : any = {};
            twoRow.id = 9999 + index;
            twoRow.firstIndex = index + 1;
            heightIndex++;

            if(heightIndex % 2 != 0){
                twoRow.lastRow = true;
                twoRow.kind = "";
            }

            newRows.push(twoRow);

            //heightIndex가 짝수면 threeRow를 넣어준다.
            if(heightIndex % 2 === 0){
                const threeRow : any = {};
                threeRow.id = 99999 + index;
                threeRow.lastRow = true;
                threeRow.firstIndex = index + 1;
                newRows.push(threeRow);
                heightIndex++;
            }

            

            //heightIndex는 홀수인데 가운데 숫자를 찾는다.
            const centerIndex = Math.floor((heightIndex / 2) + previousHeightIndex);
            
            

            
            newRows[centerIndex].kind = realKoreanType;

            

            previousHeightIndex += heightIndex;

        })

        

        setRows([...newRows]);

    }





    return (
        <div className={classes.root}
        style={{
            width : "430px",
            height : "910px",
        }}
        >
            <DataGridPremium
            sx={{
                backgroundColor : "white"
            }}
            loading={props.loading}
            rows={rows}
            columns={columns}
            density="compact"
            hideFooter
            getCellClassName={(params) => {





                if(params.field === "access"){

                    if(params.row.accessNumber === 0){
                        return "access0";
                    }

                    if(params.row.accessNumber === 1){
                        return "access1";
                    }

                    if(params.row.accessNumber === 2){
                        return "access2";
                    }

                }

                if(params.field === "sudden"){
                    if(params.row.lastRow){
                        return "lastRow";
                    }

                    return `sudden${params.row.suddenNumber}_${params.row.suddenParentpermit ? 1 : 0}`
                }

                if(params.field === "regular"){
                    if(params.row.lastRow){
                        return "lastRow";
                    }

                    return `regular${params.row.regularNumber}_${params.row.regularParentpermit ? 1 : 0}`
                }

                if(params.row.lastRow){
                    if(params.field === "kind"){
                        if(params.row.selected){
                            return "kind_lastRow_selected";
                        }
                        return "kind_lastRow";
                    }
                    return "lastRow";
                }

                if(params.field === "kind"){
                    if(params.row.selected){
                        return "kind_selected";
                    }
                    return "kind";
                }


                return ""
            }}
            />
        </div>
    )
}

export default DemeritChart;