import React, { useEffect, useState } from "react";
import ClassSelect from "./classselect";
import { Button, CssVarsProvider, Modal, ModalClose, ModalDialog } from "@mui/joy";
import ExampleButtonCheckbox from "./phoneselect";
import { ChevronLeft, Publish } from "@mui/icons-material";
import { set } from "lodash";
import { Backdrop, CircularProgress } from "@mui/material";
import { sr } from "date-fns/locale";
import styles from "../styles/attendancestyles.module.css";


const needMultiplePicturesLocations = ["bundang"];




const InspectPhone: React.FC<any> = (props) => {


    const [selectedPhoneNumbers, setSelectedPhoneNumbers] = useState<boolean[]>(new Array(200).fill(false));
    const [selectedPhoneNumbersCount, setSelectedPhoneNumbersCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [imageSrc, setImageSrc] = useState("");
    const [totalNumber, setTotalNumber] = useState(75);
    const [isMultiple, setIsMultiple] = useState(false);
    const [srcArray, setSrcArray] = useState<any[]>([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [boxIndex, setBoxIndex] = useState(-1);

    useEffect(() => {

        setBoxIndex(-1);

    }, [currentIndex]);

    useEffect(() => {

        if (!props.location) {
            return;
        }

        if (needMultiplePicturesLocations.includes(props.location)) {
            setIsMultiple(true);
        }

        if(props.location === "bundang"){
            setTotalNumber(80);
        }

    }, [props.location]);


    const gridStyle1 : React.CSSProperties = {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
        gridTemplateRows: "repeat(15, 1fr)",
        gap: "16px",
        paddingTop: props.location === "songdo_free" || props.location === "songpa" ? "30px" : "125px",
        paddingBottom: "125px",
        position : "relative"
    }

    const gridStyle2 : React.CSSProperties = {
        display: "grid",
        gridTemplateRows: "repeat(10, 1fr)", // 10개의 행
        gridTemplateColumns: "repeat(11, 1fr)", // 11개의 열
        gap: "16px",
        paddingTop: props.location === "songdo_free" ? "30px" : "125px",
        paddingBottom: "125px",
        gridAutoFlow: "column", // 아이템이 열 방향으로 먼저 채워지도록 설정,
        position : "relative"
    }



    useEffect(() => {

        
        

        if (!props.location) {
            return;
        }

        if (props.location === "daechi2") {
            setTotalNumber(85);
        }

        if (props.location === "songdo_fixed") {
            setTotalNumber(60);
        }

        if (props.location === "songdo_free") {
            setTotalNumber(120);
        }

        if (props.location === "molip") {
            setTotalNumber(110);
        }

        if(props.location === "songpa"){
            setTotalNumber(100);
        }


    }, [props.location]);


    useEffect(() => {

        if (!props.open) {
            setImageSrc("");
            return;
        }


        setBoxIndex(-1);
        getSavedSelectedPhoneNumbers();
        getSavedImgSrc();

    }, [props.open]);



    const getSavedImgSrc = async () => {

        if (!props.targetDate || !props.location || !props.classNumber) {
            
            return;
        }

        const targetDateTime = new Date(props.targetDate).getTime();

        const data = {
            location: props.location,
            targetDateTime: targetDateTime,
            classNumber: props.classNumber
        }

        const response = await fetch("https://peetsunbae.com/dashboard/report/getinspectphonesimgsrc", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify(data)
        });

        const result = await response.json();

        

        if (result.message === "success") {
            setImageSrc("https://peetsunbae.com/phonesimage/" + result.src);
            if (result.srcArray && result.srcArray.length > 0) {

                result.srcArray.forEach((each: any) => {

                    const createdAt = new Date(each.createdAt);
                    const hours = createdAt.getHours();
                    const minutes = createdAt.getMinutes();
                    const seconds = createdAt.getSeconds();

                    const timeString = `${hours}:${minutes}:${seconds}`;

                    each.timeString = timeString;

                })

            }
            setSrcArray(result.srcArray);
        } else {
            setImageSrc("");
        }

    }



    const getSavedSelectedPhoneNumbers = async () => {

        if (!props.targetDate || !props.location || !props.classNumber) {
            return;
        }

        const targetDateTime = new Date(props.targetDate).getTime();


        const response = await fetch("https://peetsunbae.com/dashboard/report/getinspectphones", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                classNumber: props.classNumber,
                targetDateTime: targetDateTime,
                location: props.location
            })
        })

        const result = await response.json();

        if (result.message === "success") {
            setSelectedPhoneNumbers(result.selectedPhoneNumbers);
        }

    }

    const handlePhoneBtnClick = (index: number) => () => {
        setSelectedPhoneNumbers((prev) => {
            const newSelectedPhoneNumbers = [...prev];
            newSelectedPhoneNumbers[index] = !newSelectedPhoneNumbers[index];
            return newSelectedPhoneNumbers;
        })
    }

    useEffect(() => {

        if(!isMultiple){
            setSelectedPhoneNumbersCount(selectedPhoneNumbers.filter((item) => item === true).length);
        }else{
            setSelectedPhoneNumbersCount(selectedPhoneNumbers.filter((item, index) => {
                if(boxIndex === 1){
                    return index < 80 && item === true;
                }else if(boxIndex === 2){
                    return index >= 80 && item === true;
                }
            }).length)
        }
    }, [selectedPhoneNumbers, isMultiple, boxIndex]);


    const submitPhoneNumbers = async () => {

        if (!props.targetDate) {
            alert("날짜를 없습니다. 에러");
            return;
        }

        if (!props.location) {
            alert("호점이 없습니다. 에러");
            return;
        }

        const targetDateTime = new Date(props.targetDate).getTime();


        const data : any = {
            classNumber: props.classNumber,
            targetDateTime: targetDateTime,
            selectedPhoneNumbers: selectedPhoneNumbers,
            location: props.location,
        }

        if(isMultiple){
            data.isMultiple = true;
            data.boxIndex = boxIndex;
            data.src = srcArray[currentIndex].src;
            
            //한 사물함이 차지하는 갯수를 넣어준다.
            if(props.location === "bundang"){
                data.totalNumber = 80;
            }
        }

        if (!data.classNumber || !data.targetDateTime || !data.selectedPhoneNumbers || !data.location) {
            return;
        }

        if(isMultiple && !data.totalNumber){
            alert("totalNumber가 없습니다. 에러");
            return;
        }

        setLoading(true);

        var addUrl = "";

        if(isMultiple){
            addUrl = "multiple";

            if(boxIndex === -1){
                alert("함을 선택해주세요");
                setLoading(false);
                return;
            }
        }

        const response = await fetch("https://peetsunbae.com/dashboard/report/inspectphones" + addUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify(data)
        })

        const result = await response.json();

        if (result.message === "success") {
            setLoading(false);
            setSelectedPhoneNumbers(new Array(75).fill(false));
            props.letsUpdate();
            props.notify1();

            if(isMultiple){
                getSavedSelectedPhoneNumbers();
                return;
            }

            props.handleClose();
        }

    }


    return (
        <div>
            <Modal
                open={props.open}
                onClose={() => {
                    setSelectedPhoneNumbers(new Array(75).fill(false));
                    props.handleClose();
                }}
            >
                <ModalDialog
                    layout="fullscreen"
                    variant="soft"
                    sx={{
                        paddingBottom: "60px",
                        height: "100%",
                        overflowY: "scroll"
                    }}
                >
                    <ModalClose size="lg" color="danger" />

                    <div>
                        <div style={{
                            fontFamily: "Apple_SB", fontSize: "22px",
                            width: "1700px",
                            margin: "0 auto",
                            marginBottom: "24px",
                        }}>
                            {props.classNumberString} 핸드폰 검사하기
                        </div>
                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            width: "1800px",
                            height: props.location !== "molip" ? "900px" : "1050px",
                            margin: "0 auto",
                            gap: "16px",

                        }}>
                            <div>
                                {
                                    (imageSrc && !isMultiple) ?
                                        <img src={imageSrc} alt="phone" style={{
                                            width: "100%"
                                        }} />
                                        :
                                        ""
                                }
                                {
                                    (imageSrc && isMultiple && srcArray.length > 0) ?
                                        <div style={{
                                            position: "relative",
                                        }}>
                                            <img src={"https://peetsunbae.com/phonesimage/" + srcArray[currentIndex].src} alt="phone" style={{
                                                width: "100%"
                                            }} />
                                            <div style={{
                                                position: "absolute",
                                                bottom: "20px",
                                                right: "20px",
                                                zIndex: 100,
                                                color: "white",
                                            }}>
                                                {srcArray[currentIndex].timeString}
                                            </div>

                                            {/* 사진의 중앙 양쪽 끝에 앞 뒤로 이동하는 화살표 넣기 */}
                                            <div style={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "0px",
                                                transform: "translateY(-50%)",
                                                zIndex: 100,
                                            }}>
                                                <Button

                                                    variant="solid"
                                                    color="neutral"
                                                    size="sm"
                                                    sx={{
                                                        backgroundColor: "transparent",
                                                        display: currentIndex === srcArray.length - 1 ? "none" : "block"
                                                    }}
                                                    onClick={() => {
                                                        if (currentIndex === srcArray.length - 1) {
                                                            return;
                                                        } else {
                                                            setCurrentIndex(currentIndex + 1);
                                                        }
                                                    }}
                                                >
                                                    <ChevronLeft sx={{
                                                        fontSize: "48px"
                                                    }} />
                                                </Button>
                                            </div>
                                            <div style={{
                                                position: "absolute",
                                                top: "50%",
                                                right: "0px",
                                                transform: "translateY(-50%)",
                                                zIndex: 100,
                                            }}>
                                                <Button
                                                    variant="solid"
                                                    color="primary"
                                                    size="sm"
                                                    sx={{
                                                        backgroundColor: "transparent",
                                                        display: currentIndex === 0 ? "none" : "block"
                                                    }}
                                                    onClick={() => {
                                                        if(currentIndex === 0){
                                                            return;
                                                        }else{
                                                            setCurrentIndex(currentIndex - 1);
                                                        }
                                                    }}
                                                >
                                                    <ChevronLeft sx={{
                                                        fontSize: "48px",
                                                        transform: "rotate(180deg)"
                                                    }} />
                                                </Button>
                                            </div>
                                        </div>
                                        :
                                        ""
                                }
                            </div>
                            <div style={
                                props.location !== "molip"?
                                    gridStyle1
                                    :
                                    gridStyle2
                            }>
                                {
                                    isMultiple &&
                                    <div style={{
                                        position: "absolute",
                                        top: "20px",
                                        width: "100%",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                        }}>
                                            <div onClick={(e: any) => { setBoxIndex(1); }} className={`${styles.select} ${boxIndex === 1 ? styles.active : ""}`}>
                                                첫번째 함 사진이에요
                                            </div>
                                            <div onClick={(e: any) => { setBoxIndex(2); }} className={`${styles.select} ${boxIndex === 2 ? styles.active : ""}`}>
                                                두번째 함 사진이에요
                                            </div>
                                        </div>
                                    </div>
                                }
                               
                                <CssVarsProvider>
                                    {
                                        (props.user && props.user.academy) &&
                                        new Array(totalNumber).fill(0).map((item, index) => {

                                            var indexNumber = index;

                                            if(isMultiple && boxIndex === -1){
                                                return;
                                            }

                                            if(isMultiple && boxIndex === 2){
                                                indexNumber += 80;
                                            }

                                            return (
                                                <div key={index}>
                                                    <Button
                                                        onClick={handlePhoneBtnClick(indexNumber)}
                                                        variant={selectedPhoneNumbers[indexNumber] ? "solid" : "outlined"}
                                                        sx={{
                                                            width: "100%",
                                                            minHeight: "1px",
                                                            height: "100%"
                                                        }}
                                                        color={selectedPhoneNumbers[indexNumber] ? "primary" : "neutral"}
                                                        size="sm"
                                                    >
                                                        {
                                                            (props.location && props.location !== "songdo_free") &&
                                                            indexNumber + 1 + "번"
                                                        }
                                                        {
                                                            (props.location && props.location === "songdo_free" && index + 1 > 56) &&
                                                            indexNumber + 1 + 44 + "번"
                                                        }
                                                    </Button>
                                                </div>
                                            )
                                        })
                                    }

                                </CssVarsProvider>
                            </div>


                        </div>
                        <div style={{
                            width: "1700px",
                            display: "flex",
                            justifyContent: "flex-end",
                            margin: "0 auto",
                        }}>
                            {
                                (!isMultiple) || (isMultiple && boxIndex !== -1) ?
                                <Button variant="soft" size="lg" color="primary" startDecorator={<Publish />}
                                onClick={submitPhoneNumbers}
                                loading={loading}
                            >
                                {isMultiple &&
                                boxIndex === 1 ? "첫번째 함에는 " : boxIndex === 2 ? "두번째 함에는 " : ""
                                }
                                총 {selectedPhoneNumbersCount}개의 핸드폰이 있어요 !
                            </Button>
                            :
                            ""
                            }
                            
                        </div>
                    </div>
                </ModalDialog>
            </Modal>
        </div>
    )

}

export default InspectPhone;
