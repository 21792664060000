import React, { useEffect, useState, useRef, useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Picker from "emoji-picker-react";

import {
    // Chip, 
    CircularProgress,
    // Divider, 
    LinearProgress, TextareaAutosize, TextField
} from "@mui/material";

import Divider from '@mui/joy/Divider';
import Chip from '@mui/joy/Chip';


import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";

import styles from "./mobilechatroom.module.css";

import { useIonAlert, IonLoading, IonApp } from "@ionic/react";

import { ReactComponent as ArrowLeftLight } from "../../../src/svg/arrow-left-light.svg";
import { ReactComponent as SendTextUnactive } from "../../../src/svg/send_text_unactive.svg";
import { ReactComponent as SendText } from "../../../src/svg/paper-plane-top-solid.svg";
import { ReactComponent as Smile } from "../../../src/svg/smile.svg";
import { ReactComponent as Clip } from "../../../src/svg/clip.svg";
import { ReactComponent as Link } from "../../../src/svg/link.svg";
import { ReactComponent as ShapeLogoSvg } from '../../../src/svg/onlyshape.svg';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import { Camera, CameraResultType } from "@capacitor/camera";
import { Filesystem } from "@capacitor/filesystem";
import { IonAvatar } from "@ionic/react";


import { NativeSettings, AndroidSettings, IOSSettings } from "capacitor-native-settings";
import ImageViewer from "../etc/imageviewer/imageviewer";

import {
    IonButtons,
    IonButton,
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
} from '@ionic/react';
import { CssVarsProvider, Textarea } from "@mui/joy";
import { is, te } from "date-fns/locale";
import { set } from "lodash";



var isTop = false;
var previousNode: any;

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    bgcolor: 'white',
    outline: "none",
    borderRadius: "4px",
    boxShadow: "0 3px 25px -5px rgba(0,0,0,.35)"
};

var myAvatar : any;


const MobileChatRoomForFamily: React.FC<any> = (props) => {

    const location: any = useLocation();
    const history = useHistory();
    const [platform, setPlatform] = useState("");
    const [studentName, setStudentName] = useState("");

    const headerRef = useRef<any>(null);
    const footerRef = useRef<any>(null);
    const [bodyHeight, setBodyHeight] = useState(0);

    const [data, setData] = useState<any>();
    const [message, setMessage] = useState("");

    const [update, setUpdate] = useState(0);

    const [isFocus, setIsFocus] = useState(false);

    const [rows, setRows] = useState(1);

    const [showPicker, setShowPicker] = useState(false);

    const [fileName, setFileName] = useState<any>("");
    const [file, setFile] = useState<any>();

    const [link, setLink] = useState("");

    const [imgLoading, setImgLoading] = useState(false);
    const [imgSrc, setImgSrc] = useState("");
    const [imgId, setImgId] = useState(0);

    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = useState(false);

    const [index, setIndex] = useState(0);

    const [chatRoomId, setChatRoomId] = useState();
    const [totalUsersCount, setTotalUsersCount] = useState(0);

    const chatRef = useRef<any>();
    const chatLastRef = useRef<any>();
    const voidRef = useRef<any>();

    const firstRef = useRef<any>(null);

    const textareaRef = useRef<any>(null);

    const [loading, setLoading] = useState(false);

    const [ionModalOpen, setIonModalOpen] = useState(false);
    const [ionModalSrc, setIonModalSrc] = useState("");

    const [presentAlert] = useIonAlert();



    const handleOpen = () => setOpen(true);
    const handleOpen2 = () => setOpen2(true);
    const handleClose = () => setOpen(false);
    const handleClose2 = () => { setOpen2(false); setLink(""); }

    const [avatarNumber, setAvatarNumber] = useState(0);
    const [familyRoomTotalUsersCount, setFamilyRoomTotalUsersCount] = useState(0);

    const [justCount, setJustCount] = useState(0);

    useEffect(() => {

        

        const handleBackButton = () => {
            
            history.goBack();
        }

        //backbutton 눌리면 handleBackButton 실행
        document.addEventListener("backbutton", handleBackButton);

        return () => {
            document.removeEventListener("backbutton", handleBackButton);
        }

    }, []);

    const scrollTopCallback = useCallback((node: any) => {

        

        if (node !== null && !isTop && chatRef.current) {

            
            
            
            
            

            chatRef.current.scrollTop = chatRef.current.scrollHeight;

            // const handleScroll = (e : any) => {
            //     
            //     if(chatRef.current.scrollTop === 0){
            //         const newIndex = index + 1;
            //         setIndex(newIndex);
            //     }

            // }

            // chatRef.current.addEventListener("scroll", handleScroll);

            
            previousNode = node;

            // return () => {
            //     chatRef.current.removeEventListener("scroll", handleScroll);
            // }
        }

        if (isTop) {
            isTop = false;
        }


    }, []);

    //플랫폼 정보 확인
    useEffect(() => {
        const platform = Capacitor.getPlatform();

        if (platform) {
            setPlatform(platform);
        }

    }, []);

    //가족방은 학생 id가 chatroom이 되므로 학생 id를 가져와서 채팅룸 id로 사용
    useEffect(() => {

        const familyStart = async () => {

            const familyRoomTotalUsersCount = location.state.familyRoomTotalUsersCount;
            setFamilyRoomTotalUsersCount(familyRoomTotalUsersCount);

            

            var token: any = "";

            //네이티브 라면 토큰 가져오는 기능
            if (Capacitor.isNativePlatform()) {
                const { value } = await Preferences.get({ key: "token" });
                
                token = value;
            }

            fetch("https://peetsunbae.com/dashboard/envelope/chatroomForFamily", {
                method: "GET",
                credentials: "include",
                headers: {
                    "Authorization": token
                }
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        
                        
                        if (result.message === "success") {
                            setChatRoomId(result.roomNumber);
                            setTotalUsersCount(result.totalUsersCount);
                        }
                    })
            })

        }

        familyStart();

    }, [props.user]);





    useEffect(() => {

        if (!headerRef || !footerRef || !headerRef.current || !footerRef.current) {
            return;
        }

        const caculateBodyHeight = () => {

            if (!headerRef || !footerRef || !headerRef.current || !footerRef.current) {
                return;
            }

            const value = +getComputedStyle(document.documentElement).getPropertyValue("--sat").split("p")[0];
            const value2 = +getComputedStyle(document.documentElement).getPropertyValue("--sab").split("p")[0];

            const totalHeight = window.innerHeight;
            const safeareaTop = value;
            const headerHeight = headerRef.current.offsetHeight;
            const footerHeight = footerRef.current.offsetHeight;

            const lastHeight = totalHeight - value - headerHeight - footerHeight + value2;
            
            

            setBodyHeight(lastHeight);

            setTimeout(() => {

                if (!chatRef || !chatRef.current) {
                    return;
                }

                if (Capacitor.getPlatform() !== "android") {
                    return;
                }

                chatRef.current.scrollTop = chatRef.current.scrollHeight;

            }, 10);
        }



        caculateBodyHeight();


        window.addEventListener("resize", () => caculateBodyHeight());

        return window.removeEventListener("resize", () => caculateBodyHeight());

    }, [headerRef, footerRef, platform]);

    useEffect(() => {

        if (!chatRef || !chatRef.current) {
            return;
        }

        setTimeout(() => {

            if (!chatRef || !chatRef.current) {
                return;
            }

            

            chatRef.current.scrollTop = chatRef.current.scrollHeight;

        }, 50);


    }, [isFocus]);



    const onImageLoad = () => {
        if (chatRef && chatRef.current) {
            
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }






    useEffect(() => {
        getMessage();
    }, [chatRoomId, props.user, update, props.familyMessageUpdate]);



    const getMessage = async () => {

        setLoading(true);

        if (!props.user) {
            
            return;
        }

        if ((props.user) && !chatRoomId) {
            
            return;
        }

        var token: any = "";

        //네이티브 라면 토큰 가져오는 기능
        if (Capacitor.isNativePlatform()) {
            const { value } = await Preferences.get({ key: "token" });
            
            token = value;
        }


        fetch(`https://peetsunbae.com/dashboard/envelope/messagesforfamily?index=${index}&chatRoomId=${chatRoomId}&kind=mobile`, {
            method: "GET",
            credentials: "include",
            headers: {
                "Authorization": token
            }
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    
                    const data = result.data;
                    if (result.message !== "success") {
                        
                        return;
                    }

                    if (result.message === "success" && result.data.length > 0) {
                        const newData = [];
                        const newDate = new Date(result.data[0].createdAt);
                        var newDateDay = "";
                        switch (newDate.getDay()) {
                            case 0:
                                newDateDay = "일";
                                break;
                            case 1:
                                newDateDay = "월";
                                break;
                            case 2:
                                newDateDay = "화";
                                break;
                            case 3:
                                newDateDay = "수";
                                break;
                            case 4:
                                newDateDay = "목";
                                break;
                            case 5:
                                newDateDay = "금";
                                break;
                            case 6:
                                newDateDay = "토";
                                break;
                        }

                        const newDateString = `${newDate.getFullYear()}년 ${newDate.getMonth() + 1}월 ${newDate.getDate()}일 ${newDateDay}요일`;

                        const newDateRow = {
                            kind: "date", date: newDateString, id: Math.random()
                        }

                        newData.push(newDateRow);

                        var previousDate = newDateString;

                        result.data.forEach((eachData: any) => {

                            const newDate2 = new Date(eachData.createdAt);
                            var newDateDay2 = "";
                            switch (newDate2.getDay()) {
                                case 0:
                                    newDateDay2 = "일";
                                    break;
                                case 1:
                                    newDateDay2 = "월";
                                    break;
                                case 2:
                                    newDateDay2 = "화";
                                    break;
                                case 3:
                                    newDateDay2 = "수";
                                    break;
                                case 4:
                                    newDateDay2 = "목";
                                    break;
                                case 5:
                                    newDateDay2 = "금";
                                    break;
                                case 6:
                                    newDateDay2 = "토";
                                    break;
                            }

                            const newDateString2 = `${newDate2.getFullYear()}년 ${newDate2.getMonth() + 1}월 ${newDate2.getDate()}일 ${newDateDay2}요일`;

                            const newDateRow2 = {
                                kind: "date", date: newDateString2, id: Math.random()
                            }

                            if (previousDate !== newDateString2) {
                                newData.push(newDateRow2);
                                previousDate = newDateString2;
                            }

                            newData.push(eachData);

                        })
                        setLoading(false);
                        setData(newData);
                        props.unreadMessage();


                        setTimeout(() => {
                            
                            //읽었다는 1 없애기 위해서 선생님들꺼 재로딩
                            
                            //
                            
                            
                            if(justCount === 0 || props.familyMessageUpdate.isUpdateNeeded){
                            props.socket.emit("familyReadMessage", chatRoomId, props.user.id);
                            //isFirst = false;
                            setJustCount(1);
                            }
                        }, 300);


                    } else {
                        setData(result.data);
                        setLoading(false);
                    }
                })
        });

    }


    const handleChange = (e: any) => {
        setMessage(e.target.value);
    }

    const handleEmojiClick = (e: any, emojiObject: any) => {
        e.stopPropagation();
        setMessage((prevMessage: any) => prevMessage + emojiObject.emoji);
        setShowPicker(false);
    }

    const handleEmojiOpen = (e: any) => {
        e.stopPropagation();
        setShowPicker(!showPicker);
    }

    const mainClick = (e: any) => {
        setShowPicker(false);
        
    }


    const sendImage = async () => {

        const permissionStatus = await Camera.checkPermissions();

        

        if (permissionStatus.camera === "denied" || permissionStatus.photos === "denied") {
            

            presentAlert({
                header: "접근 권한이 필요합니다",
                subHeader: "카메라, 사진첩 접근 권한이 필요합니다.",
                buttons: [
                    {
                        text: "Cancel",
                        role: "cancel",
                    },
                    {
                        text: "OK",
                        role: "confirm",
                        handler: () => {
                            NativeSettings.open({
                                optionAndroid: AndroidSettings.ApplicationDetails,
                                optionIOS: IOSSettings.App
                            })
                        }
                    }
                ]
            })

            return;
        }

        var image;

        try {
            image = await Camera.getPhoto({
                quality: 90,
                allowEditing: false,
                resultType: CameraResultType.Uri
            })
        } catch (e) {
            
            return;
        }

        if (!image) {
            return;
        }
        setImgLoading(true);

        var sendingFile: any;

        if (Capacitor.isNativePlatform()) {

            if (!image.path) {
                
                return;
            }

            const base64data : any = await Filesystem.readFile({
                path: image.path
            });

            const byteData = atob(base64data.data);

            const byteNumbers = new Array(byteData.length);

            for (var i = 0; i < byteData.length; i++) {
                byteNumbers[i] = byteData.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            

            const blob = new Blob([byteArray], { type: "image/jpeg" });

            
            
            

            const file = new File([blob], "chatImage.jpeg", { type: blob.type });
            sendingFile = file;

        } else {

            if (!image.webPath) {
                return;
            }

            const byteArray = await fetch(image.webPath);
            const blob = await byteArray.blob();
            

            const file = new File([blob], "chatImage.jpeg", { type: blob.type });
            
            sendingFile = file;
        }

        if (!sendingFile) {
            
            return;
        }

        var formData = new FormData();
        formData.append("chat_img", sendingFile);

        var token: any = "";

        //네이티브 라면 토큰 가져오는 기능
        if (Capacitor.isNativePlatform()) {
            const { value } = await Preferences.get({ key: "token" });
            
            token = value;
        }

        fetch("https://peetsunbae.com/dashboard/envelope/img", {
            method: "post",
            credentials: "include",
            headers: { "Authorization": token },
            body: formData
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    
                    if (result.message === "success") {
                        handleSubmit("e", "img", result.src);
                    }
                    setImgLoading(false);
                })
        }).catch((error) => {
            setImgLoading(false);
            alert("업로드 실패");
            
        })

    }



    const fileOnChange = (e: any) => {
        
        if (e.target && e.target.files.length > 0) {
            
            setFile(e.target.files[0]);
            setFileName(e.target.files[0].name);

            setImgLoading(true);

            var formData = new FormData();
            formData.append("chat_img", e.target.files[0]);

            setOpen(true);

            fetch("https://peetsunbae.com/dashboard/envelope/img", {
                method: "post",
                credentials: "include",
                body: formData
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        
                        if (result.message === "success") {
                            setImgSrc(result.src);
                            setImgId(result.imgId);
                        }
                        setImgLoading(false);
                    })
            })

        }
    }

    const handleImgDelete = (e: any) => {

        setOpen(false);

        fetch("https://peetsunbae.com/dashboard/envelope/img", {
            method: "delete",
            headers: { "content-type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
                imgId: imgId
            })
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    
                })
        })

    }


    const handleLinkChange = (e: any) => {
        setLink(e.target.value);
    }

    const handleSubmit = async (e: any, kind: string, mobileImgSrc: any = "") => {


        if(textareaRef && textareaRef.current && Capacitor.getPlatform() === "ios"){
            textareaRef.current.blur();
        }

        

        var sendMessage = "";

        if (!props.user) {
            alert("잠시 후 시도하세요");
            return;
        }


        if (!chatRoomId) {
            alert("채팅룸을 선택하고 전송하세요");
            return;
        }

        if (kind === "text") {
            var direction = "teacher";

            if (props.user.value === "teacher" || props.user.value === "staff") {
                direction = "student"
            }

            const newRow = {
                id: Math.random(),
                chatRoomId,
                direction : props.user ? props.user.value : undefined,
                fromUserId: props.user.id,
                isRead: 0,
                kind: "text",
                message,
                name: props.user.name,
                readUser : [props.user.id],
                mine : true,
                avatar : myAvatar
            }

            const newData = [...data];
            newData.push(newRow);
            setData([...newData]);
        }



        switch (kind) {
            case "text":
                sendMessage = message;
                setMessage("");
                break;
            case "img":
                sendMessage = mobileImgSrc;
                setImgSrc("");
                setOpen(false);
                break;
            case "link":
                sendMessage = link;
                setLink("");
                setOpen2(false);
                break;
            default:
                
                return;
        }

        if (!sendMessage) {
            
            return;
        }

        var token: any = "";

        //네이티브 라면 토큰 가져오는 기능
        if (Capacitor.isNativePlatform()) {
            const { value } = await Preferences.get({ key: "token" });
            
            token = value;
        }

        fetch("https://peetsunbae.com/dashboard/envelope/messagesforfamily", {
            method: "post",
            headers: { "content-type": "application/json", "Authorization": token },
            credentials: "include",
            body: JSON.stringify({
                kind: kind,
                message: sendMessage,
                chatRoomId: chatRoomId
            })
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    
                    if (result.message === "success") {
                        setUpdate(Math.random());
                        props.unreadMessage();
                        props.socket.emit("newFamilyMessage", chatRoomId, props.user.id);
                    }
                })
        })
    }

    const letsShowImage = (url: string) => {
        
        setIonModalOpen(true);
        setIonModalSrc(url);
    }

    const ionModalClose = () => {
        setIonModalOpen(false);
        setIonModalSrc("");
    }

    function convertLinks(text : string) {
        var urlRegex = /(https?:\/\/\S+)/g;
        return text.replace(urlRegex, function(url : string) {
            // 마지막 문자가 알파벳이나 숫자가 아닌 경우, URL에서 제외합니다.
            var lastIndex = url.length - 1;
            var punctuation = url[lastIndex];
            if (!(/[a-zA-Z0-9]/.test(punctuation))) {
                url = url.substring(0, lastIndex);
            }
            return '<a target="_blank" href="' + url + '">' + url + '</a>';
        });
    }



    return (
        <IonApp>
            <div>
                <div className={styles.chatRoom} style={{ paddingTop: "env(safe-area-inset-top)" }} onClick={mainClick}>
                    <div className={styles.chatRoomHeader} ref={headerRef}>
                        <div>
                            <div className={`${styles.header} ${platform === "ios" ? styles.ios : ""}`} ref={props.headerRef}>
                                <div className={styles.backBtnDiv}
                                    onClick={() => { history.goBack(); }}
                                >
                                    <ArrowLeftLight
                                        className={styles.arrowLeft}
                                    />
                                </div>

                                <div className={styles.titleText}>
                                    {studentName}
                                    {/* &nbsp;<span className={styles.imageNumber}>{data && data.length}</span> */}
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className={styles.chatRoomBody} ref={chatRef} style={{ height: `${bodyHeight}px`, overflow: "scroll" }}>
                        <div className={styles.chatBody}>

                            {
                                data &&
                                data.map((eachData: any, indexNumber: number) => {

                                    if(eachData.avatar){
                                        myAvatar = eachData.avatar;
                                    }

                                    var timestring = "";

                                    if (eachData.createdAt) {
                                        const newDate = new Date(eachData.createdAt);
                                        const dateString = `${newDate.getFullYear()}년 ${newDate.getMonth() + 1}월 ${newDate.getDate()}일`;
                                        timestring = `${newDate.getHours() < 10 ? "0" + newDate.getHours() : newDate.getHours()}:${newDate.getMinutes() < 10 ? "0" + newDate.getMinutes() : newDate.getMinutes()}`
                                    }


                                    if (eachData.kind !== "date") {
                                        return (
                                            <div key={eachData.id} className={styles.eachMessage} ref={(data.length - 1 === indexNumber && data.length !== 0) ? scrollTopCallback : (indexNumber === 5) ? firstRef : voidRef}>
                                                <>
                                                    <div className={styles.profileAnchor}>
                                                        {
                                                            (!eachData.avatar)
                                                                ?
                                                                <div className={`${styles.profileContainer} ${styles.ofStudent}`}>
                                                                    <img src="img/user-solid.svg" alt="user" className={styles.avatar} />
                                                                </div>
                                                                :
                                                                ""
                                                        }
                                                        {
                                                            eachData.avatar
                                                                ?
                                                                <IonAvatar className={styles.profileContainer}>
                                                                    <img alt="" src={`/img/avatar/Avatar-${eachData.avatar}.png`} className={styles.ionAvatarImg} />
                                                                </IonAvatar>
                                                                :
                                                                ""
                                                        }

                                                        <span className={styles.userName}>
                                                            {eachData.name} {eachData.direction === "parent" ? " (학부모)" : ""}
                                                        </span>
                                                    </div>
                                                    <div className={styles.msg_container}>
                                                        <div className={`${styles.msg_item} ${eachData.mine ? "" : styles.ofStudent}`}>
                                                            <span className={styles.msg_text_box}>
                                                                {
                                                                    eachData.kind === "text" &&
                                                                    <span className={styles.msg_text_box}>
                                                                        <div dangerouslySetInnerHTML={{__html : convertLinks(eachData.message)}} />
                                                                    </span>
                                                                }
                                                                {
                                                                    eachData.kind === "img" &&
                                                                    <img onClick={(e: any) => { letsShowImage(`https://peetsunbae.com/${eachData.message.split("/public/")[1]}`); }} onLoad={onImageLoad} src={`https://peetsunbae.com/${eachData.message.split("/public/")[1]}`} className={styles.msg_img}>
                                                                    </img>
                                                                }
                                                                {
                                                                    eachData.kind === "link" &&
                                                                    <a href={eachData.message} target="_blank">
                                                                        {eachData.message}
                                                                    </a>
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className={styles.msg_extra}>
                                                            <div className={styles.msg_extra_action}>
                                                                {
                                                                    (familyRoomTotalUsersCount - eachData.readUser.length > 0) &&
                                                                    <div className={styles.unReadNumber} style={{ fontFamily: "Apple_R" }}>
                                                                        {familyRoomTotalUsersCount - eachData.readUser.length}
                                                                    </div>
                                                                }
                                                                <div className={styles.timeString}>
                                                                    {
                                                                        timestring
                                                                            ?
                                                                            timestring
                                                                            :
                                                                            <CircularProgress size="10px" />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>

                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div key={eachData.id} className={`${styles.divider} ${(indexNumber === 0 && loading) ? styles.dividerNotShown : ""} `}>
                                                <CssVarsProvider>
                                                    <Divider>
                                                        <Chip variant="soft" color="neutral">
                                                            <span style={{ fontFamily: "Apple_R", fontSize: "11px" }}>{eachData.date}</span>
                                                        </Chip>
                                                    </Divider>
                                                </CssVarsProvider>
                                            </div>
                                        )
                                    }
                                })
                            }

                            <div style={{ height: isFocus ? 0 : "env(safe-area-inset-bottom)" }}>

                            </div>

                        </div>
                    </div>

                    <div ref={footerRef} className={styles.chatRoomSubmitDiv} style={{ paddingBottom: isFocus ? 0 : "env(safe-area-inset-bottom)" }}>
                        <div className={styles.footerWrapper}>
                            <div className={styles.chatRoomSubmit}>
                                {/* <div className={styles.submitDiv}>
                                <div className={styles.textBoxBorder}>
                                    <TextareaAutosize value={message} onChange={handleChange} className={styles.input} placeholder="메시지를 입력하세요" />
                                </div>
                                <div className={styles.submitBtnDiv} onClick={(e: any) => { handleSubmit(e, "text") }}>
                                    <div className={styles.submitBtnCircle}>
                                        <SendText className={styles.sendText} />
                                    </div>
                                </div>
                            </div> */}
                                {/* <div className={styles.submitExtraBtnsDiv}>
                                <div className={`${styles.smileDiv} ${styles.submitExtraBtn}`} onClick={handleEmojiOpen}>
                                    <Smile className={styles.smile} />
                                </div>
                                <div className={`${styles.clipDiv} ${styles.submitExtraBtn}`} onClick={sendImage}>
                                    <Clip className={styles.clip} />
                                </div>
                                <div className={`${styles.linkDiv} ${styles.submitExtraBtn}`} onClick={handleOpen2}>
                                    <Link className={styles.link} />
                                </div>
                            </div> */}
                                <div className={styles.textSubmitWrapper}>
                                    <div className={styles.textSubmitDiv}>
                                        <div className={styles.textSubmitInput}>
                                            <TextareaAutosize onFocus={() => { setIsFocus(true); }} onBlur={(e) => { 
                                                e.preventDefault(); e.stopPropagation(); setIsFocus(false);  
                                                }} value={message} onChange={handleChange} className={styles.input} placeholder="메시지를 입력해주세요"
                                                ref={textareaRef}
                                                />
                                        </div>
                                        {
                                            !message
                                            &&
                                            <div className={styles.textSubmitImg} onClick={sendImage}>
                                                <Clip className={styles.clip2} />
                                            </div>
                                        }
                                        {
                                            message
                                            &&
                                            <div className={styles.sendTextCircle} onMouseDown={(e: any) => { e.preventDefault(); e.stopPropagation(); handleSubmit(e, "text"); }}>
                                                <SendText className={styles.sendText} fill="#6761ec" />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                showPicker &&
                                <div className={styles.emojiBox} onClick={(e: any) => { e.stopPropagation(); }}>
                                    <Picker
                                        onEmojiClick={handleEmojiClick}
                                        pickerStyle={{ width: "100%" }}
                                    />
                                </div>
                            }
                        </div>
                    </div>

                    <Modal
                        open={open2}
                        onClose={handleClose2}
                    >
                        <Box sx={style}>
                            <div className={styles.modalTitle}>
                                링크 보내기
                            </div>
                            <div className={styles.modalBody}>
                                <TextField value={link} onChange={handleLinkChange} placeholder="링크를 적으세요" fullWidth />
                            </div>
                            <div className={styles.modalFooter}>
                                <div onClick={handleClose2} className={styles.cancelBtn}>
                                    취소
                                </div>
                                <div onClick={(e: any) => { handleSubmit(e, "link") }} className={styles.uploadBtn}>
                                    보내기
                                </div>
                            </div>
                        </Box>
                    </Modal>

                    <IonLoading
                        cssClass='my-custom-class'
                        isOpen={imgLoading}
                        onDidDismiss={() => setImgLoading(false)}
                        message={'잠시만 기다려주세요'}
                    />

                </div>

                <ImageViewer
                    isModalOpen={ionModalOpen}
                    handleClose={ionModalClose}
                    title=""
                    src={ionModalSrc}
                />
            </div>
        </IonApp>
    );
}

export default MobileChatRoomForFamily;