import React, { useEffect, useState } from "react";
import {
    DataGridPremium,
    GridColDef,
    GridRowsProp,
    GridRowSelectionModel,
    useGridApiRef,
    GridGroupNode,
    GRID_ROOT_GROUP_ID,
    GridCellSelectionModel,
    GridFilterModel,
    GridLogicOperator,
    GridRowModel,
    GridRowClassNameParams,
    GridRenderCellParams
} from '@mui/x-data-grid-premium';
import { createTheme, darken, lighten } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { createStyles, makeStyles } from '@mui/styles';


const defaultTheme = createTheme();

const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);


        return {
            root: {
                '& .super-app-theme--처리완료': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .once': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .once_in': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    color : "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    }
                },
                '& .never': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .never_in': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    color : "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .clickable': {
                    cursor: "pointer",
                    '&:hover': {
                        color: "blue"
                    }
                },
                '& .rejectCell': {
                    color : "red"
                },
                '& .acceptCell': {
                    color : "blue"
                },
                '& .phone_memo': {
                    color : "black"
                },
                '& .status2': {
                    backgroudColor: "red"
                },
                '& .status1': {
                    backgroudColor: "red"
                },
                '& .status4': {
                    backgroudColor: "red"
                },
                '& .status5': {
                    backgroudColor: "red"
                }
            },
        };
    },
    { defaultTheme },
);

interface GridCellExpandProps {
    value: string;
    width: number;
  }


  const useStyles = makeStyles(() =>
  createStyles({
    root: {
      alignItems: 'center',
      lineHeight: '24px',
      width: '100%',
      height: '100%',
      position: 'relative',
      display: 'flex',
      '& .cellValue': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  }),
);


function isOverflown(element: Element): boolean {
    return (
      element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth
    );
  }


  const GridCellExpand = React.memo(function GridCellExpand(
    props: GridCellExpandProps,
  ) {
    const { width, value } = props;
    const wrapper = React.useRef<HTMLDivElement | null>(null);
    const cellDiv = React.useRef(null);
    const cellValue = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const classes = useStyles();
    const [showFullCell, setShowFullCell] = React.useState(false);
    const [showPopper, setShowPopper] = React.useState(false);
  
    const handleMouseEnter = () => {
      const isCurrentlyOverflown = isOverflown(cellValue.current!);
      setShowPopper(isCurrentlyOverflown);
      setAnchorEl(cellDiv.current);
      setShowFullCell(true);
    };
  
    const handleMouseLeave = () => {
      setShowFullCell(false);
    };
  
    React.useEffect(() => {
      if (!showFullCell) {
        return undefined;
      }
  
      function handleKeyDown(nativeEvent: KeyboardEvent) {
        // IE11, Edge (prior to using Bink?) use 'Esc'
        if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
          setShowFullCell(false);
        }
      }
  
      document.addEventListener('keydown', handleKeyDown);
  
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, [setShowFullCell, showFullCell]);
  
    return (
      <div
        ref={wrapper}
        className={classes.root}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          ref={cellDiv}
          style={{
            height: 1,
            width,
            display: 'block',
            position: 'absolute',
            top: 0,
          }}
        />
        <div ref={cellValue} className="cellValue">
          {value}
        </div>
        {showPopper && (
          <Popper
            open={showFullCell && anchorEl !== null}
            anchorEl={anchorEl}
            style={{ width, marginLeft: -17 }}
          >
            <Paper
              elevation={1}
              style={{ minHeight: wrapper.current!.offsetHeight - 3 }}
            >
              <Typography variant="body2" style={{ padding: 8 }}>
                {value}
              </Typography>
            </Paper>
          </Popper>
        )}
      </div>
    );
  });
  
  export function renderCellExpand(params: GridRenderCellParams<any>) {
    return (
      <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    );
  }


const SuddenData : React.FC<any> =(props) => {

    const classes = useStyles2();
    const [rows, setRows] = useState<GridRowsProp[]>([]);
    const [columns, setColumns] = useState<GridColDef[]>([
        { field : "kind", headerName : "종류", width : 80 },
        { field : "accept", headerName : "승인", width : 80 },
        { field : "start", headerName : "출발시간", width : 80 },
        { field : "end", headerName : "도착시간", width : 80 },
        { field : "description", headerName : "사유", width : 100, renderCell : renderCellExpand },
        { field : "createdAt", headerName : "제출시간", width : 100, renderCell : renderCellExpand },
    ]);

    useEffect(() => {

        if(!props.suddenNoticeData) {
            setRows([]);
            return;
        }

        makeRows();

    }, [props.suddenNoticeData]);

    const makeRows = () => {

        if(!props.suddenNoticeData) {
            setRows([]);
            return;
        }

        const newRows : any = [];

        props.suddenNoticeData.forEach((eachData : any) => {

            const oneRow : any = {};

            oneRow.id = eachData.id;

            const kind = eachData.type;

            var kindString = "";

            switch(kind) {
                case "long" :
                    kindString = "지각";
                    break;
                case "early" :
                    kindString = "조퇴";
                    break;
                case "among" :
                    kindString = "외출";
                    break;
                case "absent" :
                    kindString = "결석";
                    break;
            }

            oneRow.kind = kindString;

            if(eachData.parentpermit === 1){
                oneRow.accept = "O";
            }else{
                oneRow.accept = "X";
            }

            var arriveTime = "";
            var leaveTime = "";

            switch(kind){
                case "long" :
                    arriveTime = `${eachData.startHours < 10 ? `0${eachData.startHours}` : eachData.startHours}:${eachData.startMinutes < 10 ? `0${eachData.startMinutes}` : eachData.startMinutes}`;
                    break;
                case "early" :
                    leaveTime = `${eachData.endHours < 10 ? `0${eachData.endHours}` : eachData.endHours}:${eachData.endMinutes < 10 ? `0${eachData.endMinutes}` : eachData.endMinutes}`;
                    break;
                case "among" :
                    //arriveTime은 endHours와 endMinutes로 구성
                    //leaveTime은 startHours와 startMinutes로 구성
                    arriveTime = `${eachData.endHours < 10 ? `0${eachData.endHours}` : eachData.endHours}:${eachData.endMinutes < 10 ? `0${eachData.endMinutes}` : eachData.endMinutes}`;
                    leaveTime = `${eachData.startHours < 10 ? `0${eachData.startHours}` : eachData.startHours}:${eachData.startMinutes < 10 ? `0${eachData.startMinutes}` : eachData.startMinutes}`;
            }

            oneRow.start = leaveTime;
            oneRow.end = arriveTime;

            oneRow.description = eachData.reason;

            const createdAtDate = new Date(eachData.DateInserted);
            const createdAtDateString = `${createdAtDate.getFullYear()}-${createdAtDate.getMonth() + 1}-${createdAtDate.getDate()} ${createdAtDate.getHours() < 10 ? "0" + createdAtDate.getHours() : createdAtDate.getHours()}:${createdAtDate.getMinutes() < 10 ? "0" + createdAtDate.getMinutes() : createdAtDate.getMinutes()}:${createdAtDate.getSeconds() < 10 ? "0" + createdAtDate.getSeconds() : createdAtDate.getSeconds()}`;

            oneRow.createdAt = createdAtDateString;

            newRows.push(oneRow);

        })

        setRows([...newRows]);



    }


    return (
        <div className={classes.root}
        style={{
            width : "500px",
            height : "200px",
            marginTop : "10px",
        }}
        >
                        <div
            style={{
                display : "flex",
                justifyContent : "center",
                paddingTop : "72px",
                paddingBottom : "10px",
                fontFamily : "Apple_SB"
            }}
            >
                사유제출
            </div>
            <DataGridPremium
            sx={{
              backgroundColor : "white"
          }}
            rows={rows}
            columns={columns}
            density="compact"
            hideFooter
            loading={props.loading}
            getCellClassName={(params : any) => {

                //accept가 "O"이면 blue, "X"이면 red
                if(params.field === "accept"){
                    if(params.value === "O"){
                        return "acceptCell";
                    }else{
                        return "rejectCell";
                    }
                }

                return ""
            }}
            />
        </div>
    )

}

export default SuddenData;