import { Button, CssVarsProvider, Textarea } from "@mui/joy";
import React, { useEffect, useState } from "react";
import styles from "../dashboard/componentsStyle/regularparentpermit.module.css";


const SendVoiceText: React.FC<any> = (props) => {

    const [value, setValue] = useState<string>();

    const handleClick = () => {

        

        if(!value){
            alert("메세지를 입력한 후 방송하기를 눌려주세요");
            return;
        }

        fetch("https://peetsunbae.com/voiceToText", {
            method : "post",
            headers : {
                "content-type" : "application/json",
            },
            credentials : "include",
            body : JSON.stringify({
                value
            })
        }).then((response : any) => {
            response.json()
            .then((result : any) => {
                
                if(result.message === "success"){

                    const data = result.data;
                    const parsedData = data.split("/public/")[1]; //voice/voice.mp3

                    const audioSrc = "https://peetsunbae.com/" + parsedData;

                    const audio = new Audio(audioSrc);
                    audio.play();


                }else{
                    alert("error");
                }
            })
        })
    }


    return (
        <div className={styles.main}>
            <div className={styles.title} style={{marginBottom : "18px"}}>
                방송을 원하는 텍스트를 입력하세요
            </div>
            <div style={{marginBottom : "18px"}}>
                <Textarea value={value} onChange={(e) => {setValue(e.target.value);}} />
            </div> 
            <div>
                <CssVarsProvider>
                    <Button onClick={handleClick} variant="soft">방송하기</Button>
                </CssVarsProvider>
            </div>
        </div>
    )
}

export default SendVoiceText;