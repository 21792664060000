import React, { useEffect, useState } from "react";
import { DataGridPremium, GridColDef, GridColumnGroupingModel, GridFilterModel, GridRenderCellParams, GridRowModel } from "@mui/x-data-grid-premium";
import { makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
import { GridRowProps } from "@mui/x-data-grid";
import { TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Button, CssVarsProvider } from "@mui/joy";

import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { createStyles } from '@mui/styles';
import styles from '../../componentsStyle/upload.module.css';
import Typography from '@mui/material/Typography';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const defaultTheme = createTheme();

const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

        return {
            root: {
                '& .super-app-theme--처리완료': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .super-app-theme--확인': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .super-app-theme--미확인': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .clickable': {
                    cursor: "pointer",
                    '&:hover': {
                        color: "blue"
                    }
                },
                '& .status3': {

                },
                '& .complete': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                },
                '& .incomplete': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                },
                '& .status4': {
                    backgroudColor: "red"
                },
                '& .status5': {
                    backgroudColor: "red"
                },
                '& .canceled': {
                    color : "red"
                },
                '& .notSend1': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                },
                '& .notReply1': {
                    color : "red"
                }
            },
        };
    },
    { defaultTheme },
);

interface GridCellExpandProps {
    value: string;
    width: number;
}


const useStyles = makeStyles(() =>
    createStyles({
        root: {
            alignItems: 'center',
            lineHeight: '24px',
            width: '100%',
            height: '100%',
            position: 'relative',
            display: 'flex',
            '& .cellValue': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },
    }),
);


function isOverflown(element: Element): boolean {
    return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
    );
}


const GridCellExpand = React.memo(function GridCellExpand(
    props: GridCellExpandProps,
) {
    const { width, value } = props;
    const wrapper = React.useRef<HTMLDivElement | null>(null);
    const cellDiv = React.useRef(null);
    const cellValue = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const classes = useStyles();
    const [showFullCell, setShowFullCell] = React.useState(false);
    const [showPopper, setShowPopper] = React.useState(false);

    const handleMouseEnter = () => {
        const isCurrentlyOverflown = isOverflown(cellValue.current!);
        setShowPopper(isCurrentlyOverflown);
        setAnchorEl(cellDiv.current);
        setShowFullCell(true);
    };

    const handleMouseLeave = () => {
        setShowFullCell(false);
    };

    React.useEffect(() => {
        if (!showFullCell) {
            return undefined;
        }

        function handleKeyDown(nativeEvent: KeyboardEvent) {
            // IE11, Edge (prior to using Bink?) use 'Esc'
            if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
                setShowFullCell(false);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [setShowFullCell, showFullCell]);

    return (
        <div
            ref={wrapper}
            className={classes.root}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div
                ref={cellDiv}
                style={{
                    height: 1,
                    width,
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                }}
            />
            <div ref={cellValue} className="cellValue">
                {value}
            </div>
            {showPopper && (
                <Popper
                    open={showFullCell && anchorEl !== null}
                    anchorEl={anchorEl}
                    style={{ width, marginLeft: -17 }}
                >
                    <Paper
                        elevation={1}
                        style={{ minHeight: wrapper.current!.offsetHeight - 3 }}
                    >
                        <Typography variant="body2" style={{ padding: 8 }}>
                            {value}
                        </Typography>
                    </Paper>
                </Popper>
            )}
        </div>
    );
});

function renderCellExpand(params: GridRenderCellParams<any>) {
    return (
        <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    );
}


const columns: GridColDef[] = [
    { field: "status", headerName: "상태", width: 50 },
    { field : "type", headerName : "종류", width : 50},
    { field: 'location', headerName: '지점', width: 70 },
    { field: 'genderOrder', headerName: '재학/N수 순번', width: 70 },
    { field: 'totalOrder', headerName: '전체 순번', width: 70 },
    { field: 'name', headerName: '이름', width: 70 },
    { field: 'gender', headerName: '성별', width: 70 },
    { field: 'grade', headerName: '학년', width: 70 },
    { field: 'etc', headerName: '기타 문의', width: 120, renderCell: renderCellExpand },
    { field: 'adminEtc', headerName: '비고1', width: 120, editable: true },
    { field: 'newSendDate', headerName: '전송 시간', width: 70 },
    { field: 'newSendReply', headerName: '답변', width: 70 },
    { field: 'firstSendDate', headerName: '전송 시간', width: 70 },
    { field: 'firstSendReply', headerName: '답변', width: 70 },
    { field: 'secondSendDate', headerName: '전송 시간', width: 70 },
    { field: 'secondSendReply', headerName: '답변', width: 70 },
    { field: 'telephoneDate', headerName: '전화 날짜', width: 70, editable : true },
    { field : "gangnamWinterSend", headerName : "강남 윈터스쿨 전송"},
    { field : "daechiWinterSend", headerName : "대치 윈터스쿨 전송"},
    { field: 'rejectFirstSendDate', headerName: '전송 시간', width: 70 },
    { field: 'rejectFirstSendReply', headerName: '답변', width: 70 },
    { field: 'rejectSecondSendDate', headerName: '전송 시간', width: 70 },
    { field: 'rejectSecondSendReply', headerName: '답변', width: 70 },
    { field: 'phoneNumber', headerName: '연락처', width: 150 },
    { field: 'createdAt', headerName: '신청날짜', width: 150 },
    { field: 'adminEtc2', headerName: '비고2', width: 70, editable: true },
]

const columnGroupingModel: GridColumnGroupingModel = [
    {
        groupId : "신관 오픈",
        description : "",
        children : [{field : "newSendDate"}, {field : "newSendReply"}]
    },
    {
        groupId: "1차 카톡",
        description: "",
        children: [{ field: "firstSendDate" }, { field: "firstSendReply" }]
    },
    {
        groupId: "2차 카톡",
        description: "",
        children: [{ field: "secondSendDate" }, { field: "secondSendReply" }]
    },
    {
        groupId: "보류 1차 카톡",
        description: "",
        children: [{ field: "rejectFirstSendDate" }, { field: "rejectFirstSendReply" }]
    },
    {
        groupId: "보류 2차 카톡",
        description: "",
        children: [{ field: "rejectSecondSendDate" }, { field: "rejectSecondSendReply" }]
    }
]


const WaitingList = () => {


    const classes = useStyles2();
    const [rows, setRows] = useState<GridRowProps[]>([]);
    const [loadings, setLoadings] = useState([false, false, false, false, false, false, false, false, false, false, false, false, false, false]);
    const [disableds, setDisableds] = useState([false, false, false, false, false, false, false, false, false, false, false, false]);
    const [selectedRow, setSelectedRow] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [update, setUpdate] = useState(0);
    const [alignment1, setAlignment1] = useState("waiting");
    const [alignment2, setAlignment2] = useState("total");
    const [alignment3, setAlignment3] = useState("total");
    const [alignment4, setAlignment4] = useState("total");
    const [alignment5, setAlignment5] = useState("24");
    const [nameSearch, setNameSearch] = useState("");
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [
            {
                id: 2,
                field: "name",
                operator: "contains",
                value: ""
            },
            {
                id: 3,
                field: "status",
                operator: "contains",
                value: "대기"
            },
            {
                id: 4,
                field: "location",
                operator: "contains",
                value: ""
            },
            {
                id : 5,
                field : "name",
                operator : "contains",
                value : ""
            },
            {
                id : 7,
                field : "phoneNumber",
                operator : "contains",
                value : ""
            },
            {
                id : 6,
                field : "gender",
                operator : "contains",
                value : ""
            },
            {
                id : 8,
                field : "grade",                
                operator : "contains",
                value : ""
            },
            {
                id : 9,
                field : "type",
                operator : "contains",
                value : "수능"
            }
        ]
    });

    useEffect(() => {}, []);

    const handleChange5 = (e : any, newAlignment : string) => {

        if(!newAlignment){
            return;
        }

        setAlignment5(newAlignment);

        const newFilterModel = filterModel;

        if(newAlignment === "24"){

            newFilterModel.items.forEach((item : any) => {
                if(item.id === 9){
                    item.value = "수능";
                }
            })

            setFilterModel({...newFilterModel});

        }else if(newAlignment === "25"){

            newFilterModel.items.forEach((item : any) => {
                if(item.id === 9){
                    item.value = "윈터";
                }
            })

            setFilterModel({...newFilterModel});
                
            }

    }

    useEffect(() => {

        getInfo();

    }, [update]);

    useEffect(() => {

        if(!selectedRow){
            const newDisableds = [true, true, true, true, true, true, true, true, true];
            setDisableds(newDisableds);
        }else{

            var boolean = false;

            if(selectedRow.status === "취소"){
                boolean = true;
            }else{
                boolean = false;
            }

            const newDisableds = [false, false, false, false, boolean, false, false, false, false];
            setDisableds(newDisableds);
        }

    }, [selectedRow]);


    const getInfo = () => {

        setLoading(true);

        fetch("https://peetsunbae.com/waiting/getAll", {
            method: "GET",
            credentials: "include",
        }).then((res: any) => {
            res.json()
                .then((result: any) => {
                    
                    if (result.message === "success") {
                        
                        setLoading(false);
                        const data = result.data;
                        const newRows: any = [];

                        data.forEach((item: any) => {

                            const oneRow: any = {};
                            oneRow.id = item.id;

                            switch (item.location) {
                                case "gangnam": oneRow.location = "강남점"; break;
                                case "daechi": oneRow.location = "대치점"; break;
                            }

                            if(item.type1 === "winter"){
                                oneRow.type = "윈터";
                            }else{
                                oneRow.type = "수능";
                            }

                            // oneRow.genderOrder = item.genderCount;
                            oneRow.totalOrder = item.totalCount;
                            oneRow.genderOrder = item.gradeCount;
                            const createdAtString = dateToString(new Date(item.createdAt));
                            oneRow.createdAt = createdAtString;
                            oneRow.name = item.name;
                            oneRow.phoneNumber = item.phoneNumber;

                            switch (item.gender) {
                                case "male":
                                    oneRow.gender = "남학생";
                                    break;
                                case "female":
                                    oneRow.gender = "여학생";
                                    break;
                            }

                            switch (item.grade) {
                                case "n":
                                    oneRow.grade = "N수생";
                                    break;
                                case "current":
                                    oneRow.grade = "재학생";
                                    break;
                            }

                            oneRow.etc = item.etc;
                            oneRow.adminEtc = item.adminEtc;

                            if(item.sendGangnamWinterDate){
                                oneRow.gangnamWinterSend = dateToString(new Date(item.sendGangnamWinterDate));
                            }

                            if(item.sendDaechiWinterDate){
                                oneRow.daechiWinterSend = dateToString(new Date(item.sendDaechiWinterDate));
                            }

                            if (item.newSendDate) {
                                const newSendDateString = dateToString(new Date(item.newSendDate));
                                oneRow.newSendDate = newSendDateString;

                                const count = item.newSendDateArray ? item.newSendDateArray.length : "";
                                oneRow.newSendDate += ` [${count}번째 전송]`;

                            } else {
                                oneRow.newSendDate = "";
                            }

                            if(item.newSendReply === "instant"){
                                oneRow.newSendReply = "즉시 등원 가능"
                            }

                            if(item.newSendReply === "delay"){
                                oneRow.newSendReply = "등록 보류"
                            }


                            if (item.firstSendDate) {
                                const firstSendDateString = dateToString(new Date(item.firstSendDate));
                                oneRow.firstSendDate = firstSendDateString;

                                const count = item.firstSendDateArray ? item.firstSendDateArray.length : "";
                                oneRow.firstSendDate += ` [${count}번째 전송]`;

                            } else {
                                oneRow.firstSendDate = "";
                            }


                            if(item.firstSendReply === "instant"){
                                oneRow.firstSendReply = "즉시 등원 가능"
                            }else if(item.firstSendReply === "selectDate"){
                                const date = new Date(item.firstSendReplyDate);
                                oneRow.firstSendReply = `${date.getMonth() + 1}월 ${date.getDate()}일 등원 가능`
                            }

                            if (item.secondSendDate) {
                                const secondSendDateString = dateToString(new Date(item.secondSendDate));
                                oneRow.secondSendDate = secondSendDateString;

                                const count = item.secondSendDateArray ? item.secondSendDateArray.length : "";
                                oneRow.secondSendDate += ` [${count}번째 전송]`;

                            } else {
                                oneRow.secondSendDate = "";
                            }

                            if(item.secondSendReply === "instant"){
                                oneRow.secondSendReply = "즉시 등원 가능"
                            }else if(item.secondSendReply === "selectDate"){
                                const date = new Date(item.secondSendReplyDate);
                                oneRow.secondSendReply = `${date.getMonth() + 1}월 ${date.getDate()}일 등원 가능`
                            }

                            oneRow.telephoneDate = item.telephoneDate;

                            if (item.rejectFirstSendDate) {
                                const rejectFirstSendDateString = dateToString(new Date(item.rejectFirstSendDate));
                                oneRow.rejectFirstSendDate = rejectFirstSendDateString;

                                const count = item.rejectFirstSendDateArray ? item.rejectFirstSendDateArray.length : "";
                                oneRow.rejectFirstSendDate += ` [${count}번째 전송]`;
                            } else {
                                oneRow.rejectFirstSendDate = "";
                            }

                            if(item.rejectFirstSendReply === "instant"){
                                oneRow.rejectFirstSendReply = "즉시 등원 가능"
                            }else if(item.rejectFirstSendReply === "selectDate"){
                                const date = new Date(item.rejectFirstSendReplyDate);
                                oneRow.rejectFirstSendReply = `${date.getMonth() + 1}월 ${date.getDate()}일 등원 가능`
                            }

                            if (item.rejectSecondSendDate) {
                                const rejectSecondSendDateString = dateToString(new Date(item.rejectSecondSendDate));
                                oneRow.rejectSecondSendDate = rejectSecondSendDateString;

                                const count = item.rejectSecondSendDateArray ? item.rejectSecondSendDateArray.length : "";
                                oneRow.rejectSecondSendDate += ` [${count}번째 전송]`;
                            } else {
                                oneRow.rejectSecondSendDate = "";
                            }

                            if(item.rejectSecondSendReply === "instant"){
                                oneRow.rejectSecondSendReply = "즉시 등원 가능"
                            }else if(item.rejectSecondSendReply === "selectDate"){
                                const date = new Date(item.rejectSecondSendReplyDate);
                                oneRow.rejectSecondSendReply = `${date.getMonth() + 1}월 ${date.getDate()}일 등원 가능`
                            }

                            oneRow.adminEtc2 = item.adminEtc2;

                            switch (item.status) {
                                case "waiting": oneRow.status = "대기"; break;
                                case "canceled": oneRow.status = "취소"; break;
                            }

                            newRows.push(oneRow);

                        })

                        setRows([...newRows]);

                    }
                })
        })

    }

    const dateToString = (date: Date) => {
        return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":" + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":" + (date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds());
    }

    const resendInitialAlimTalk = () => {

        const newLoadings = loadings;
        newLoadings[7] = true;
        setLoadings([...newLoadings]);

        if (!selectedRow) {
            alert("선택된 항목이 없습니다.");
            return;
        }

        const id = selectedRow.id;

        if (!id) {
            alert("선택된 항목이 없습니다.");
            return;
        }

        fetch("https://peetsunbae.com/waiting/resendInitialAlimTalk", {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id
            })
        }).then((res: any) => {
            res.json()
                .then((result: any) => {

                    if (result.message === "success") {

                        const newLoadings = loadings;
                        newLoadings[7] = false;
                        setLoadings([...newLoadings]);

                        setUpdate(Math.random());

                        notify5();
                    }

                })
        })

    }

    const sendAlimTalk = (buttonKind : number) => {

        const newLoadins = loadings;
        newLoadins[buttonKind - 1] = true;
        setLoadings([...newLoadins]);

        if(!selectedRow){
            alert("선택된 항목이 없습니다.");
            return;
        }

        const id = selectedRow.id;

        if(!id){
            alert("선택된 항목이 없습니다.");
            return;
        }

        var type = "";

        switch(buttonKind){
            case 1 : type = "1_1"; break;
            case 2 : type = "1_2"; break;
            case 3 : type = "2_1"; break;
            case 4 : type = "2_2"; break;
        }

        if(!type){
            alert("type 설정이 되지 않았습니다.");
            return;
        }

        fetch("https://peetsunbae.com/waiting/sendAlimTalk", {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id,
                type
            }),
        }).then((res: any) => {
            res.json()
                .then((result: any) => {
                    

                    if(result.message === "success"){

                        const newLoadins = loadings;
                        newLoadins[buttonKind - 1] = false;
                        setLoadings([...newLoadins]);
    
                        setUpdate(Math.random());

                        notify1();
                    }


                })
        })

    }

    const notify1 = () => toast.success("전송 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored"
    });

    const notify2 = () => toast.success("취소 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored"
    });

    const notify3 = () => toast.success("삭제 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored"
    });

    const notify5 = () => toast.success("재전송 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored"
    });

    const cancelBySilent = () => {

        if(window.confirm("선택된 학생의 대기를 학생한테 알림톡 없이 취소하시겠습니까?") === false){
            return;
        }

        const id = selectedRow.id;

        if(!id){
            alert("선택된 항목이 없습니다.");
            return;
        }

        const newLoadings = loadings;
        newLoadings[6] = true;
        setLoadings([...newLoadings]);

        fetch("https://peetsunbae.com/waiting/cancelBySilent", {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id
            }),
        }).then((res: any) => {
            res.json()
                .then((result: any) => {
                    

                    if(result.message === "success"){
                        const newLoadings = loadings;
                        newLoadings[6] = false;
                        setLoadings([...newLoadings]);

                        setUpdate(Math.random());

                        notify2();
                    }

                })
        })

    }

    const cancelByNoReply = () => {

        if(window.confirm("선택된 학생의 대기를 취소하시겠습니까?") === false){
            return;
        }

        const id = selectedRow.id;

        if(!id){
            alert("선택된 항목이 없습니다.");
            return;
        }

        const newLoadings = loadings;
        newLoadings[4] = true;
        setLoadings([...newLoadings]);

        fetch("https://peetsunbae.com/waiting/cancelByNoReply", {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id
            }),
        }).then((res: any) => {
            res.json()
                .then((result: any) => {
                    

                    if(result.message === "success"){
                        const newLoadings = loadings;
                        newLoadings[4] = false;
                        setLoadings([...newLoadings]);

                        setUpdate(Math.random());

                        notify2();
                    }

                })
        })

    }

    const deleteList = () => {

        if(window.confirm("선택된 학생 대기 내역을 삭제하시겠습니까?") === false){
            return;
        }

        const id = selectedRow.id;

        if(!id){
            alert("선택된 항목이 없습니다.");
            return;
        }

        const newLoadings = loadings;
        newLoadings[5] = true;
        setLoadings([...newLoadings]);

        fetch("https://peetsunbae.com/waiting/delete", {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id
            }),
        }).then((res: any) => {
            res.json()
                .then((result: any) => {
                    

                    if(result.message === "success"){
                        const newLoadings = loadings;
                        newLoadings[5] = false;
                        setLoadings([...newLoadings]);

                        setUpdate(Math.random());

                        notify3();

                        const newDisableds = [true, true, true, true, true, true];
                        setDisableds(newDisableds);
                    }

                })
        })

    }

    const handleChange1 = (e : any, newAlignment : string) => {

        if(!newAlignment){
            return;
        }

        setAlignment1(newAlignment);

        const newFilterModel = filterModel;

        if(newAlignment === "waiting"){

            newFilterModel.items.forEach((item : any) => {
                if(item.id === 3){
                    item.value = "대기";
                }
            })

            setFilterModel({...newFilterModel});

        }else if(newAlignment === "total"){


            newFilterModel.items.forEach((item : any) => {
                if(item.id === 3){
                    item.value = "";
                }
            })

            setFilterModel({...newFilterModel});

        }

    }

    const handleChange2 = (e : any, newAlignment : string) => {

        if(!newAlignment){
            return;
        }

        setAlignment2(newAlignment);

        const newFilterModel = filterModel;

        if(newAlignment === "total"){

            newFilterModel.items.forEach((item : any) => {
                if(item.id === 4){
                    item.value = "";
                }
            })

            setFilterModel({...newFilterModel});

        }else if(newAlignment === "gangnam"){

            newFilterModel.items.forEach((item : any) => {
                if(item.id === 4){
                    item.value = "강남점";
                }
            })

            setFilterModel({...newFilterModel});

        }else if(newAlignment === "daechi"){

            newFilterModel.items.forEach((item : any) => {
                if(item.id === 4){
                    item.value = "대치점";
                }
            })

            setFilterModel({...newFilterModel});

        }


    }

    const handleChange3 = (e : any, newAlignment : string) => {

        if(!newAlignment){
            return;
        }

        setAlignment3(newAlignment);

        const newFilterModel = filterModel;

        if(newAlignment === "total"){

            newFilterModel.items.forEach((item : any) => {
                if(item.id === 6){
                    item.value = "";
                }
            })

            setFilterModel({...newFilterModel});

        }else if(newAlignment === "male"){

            newFilterModel.items.forEach((item : any) => {
                if(item.id === 6){
                    item.value = "남학생";
                }
            })

            setFilterModel({...newFilterModel});

        }else if(newAlignment === "female"){

            newFilterModel.items.forEach((item : any) => {
                if(item.id === 6){
                    item.value = "여학생";
                }
            })

            setFilterModel({...newFilterModel});

        }


    }

    const handleChange4 = (e : any, newAlignment : string) => {

        if(!newAlignment){
            return;
        }

        setAlignment4(newAlignment);

        const newFilterModel = filterModel;

        if(newAlignment === "total"){

            newFilterModel.items.forEach((item : any) => {
                if(item.id === 8){
                    item.value = "";
                }
            })

            setFilterModel({...newFilterModel});

        }else if(newAlignment === "current"){

            newFilterModel.items.forEach((item : any) => {
                if(item.id === 8){
                    item.value = "재학생";
                }
            })

            setFilterModel({...newFilterModel});

        }else if(newAlignment === "n"){

            newFilterModel.items.forEach((item : any) => {
                if(item.id === 8){
                    item.value = "N수생";
                }
            })

            setFilterModel({...newFilterModel});

        }


    }

    const handleEditCommit = React.useCallback(
        async (newRow: GridRowModel, old: any) => {
            var field = "";


            

            for (const [key, value] of Object.entries(newRow)) {
                if (value !== old[key]) {
                    field = key;
                }
            }

            const value = newRow[field] ? newRow[field] : null;

            if (!field) {
                
                return newRow;
            }

            if(field !== "adminEtc" && field !== "adminEtc2" && field !== "telephoneDate"){
                return;
            }

            
            
            



            const data = {
                id: newRow.id,
                field,
                value
            }

            

            fetch("https://peetsunbae.com/waiting/writeetc", {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify(data)
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        if (result.message === "success") {
                            setUpdate(Math.random());
                        }
                    })
            })

            return newRow;


        }, []
    );

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        
        alert(error.message);
    }, []);

    const handleTextFieldChange = (e: any) => {

        setNameSearch(e.target.value);

        const newFilterModel = filterModel;

        if(!e.target.value){

            newFilterModel.items.forEach((item: any) => {
                if (item.id === 5) {
                    item.value = "";
                }

                if (item.id === 7) {
                    item.value = "";
                }
            })

            setFilterModel({ ...newFilterModel });


        }else if(isNumericString(e.target.value)){
            
            newFilterModel.items.forEach((item: any) => {
                if (item.id === 5) {
                    item.value = "";
                }
            })

            newFilterModel.items.forEach((item: any) => {
                if (item.id === 7) {
                    item.value = e.target.value;
                }
            })

            setFilterModel({ ...newFilterModel });

        
        }else{

            newFilterModel.items.forEach((item: any) => {
                if (item.id === 5) {
                    item.value = e.target.value;
                }
            })

            newFilterModel.items.forEach((item: any) => {
                if (item.id === 7) {
                    item.value = "";
                }
            })

            setFilterModel({ ...newFilterModel });

        }


        // const newFilterModel = filterModel;

        // newFilterModel.items.forEach((item: any) => {
        //     if (item.id === 5) {
        //         item.value = e.target.value;
        //     }
        // })

        // setFilterModel({ ...newFilterModel });

    }

    function isNumericString(input : string) {
        return !isNaN(Number(input));
      }

    

    const sendNewLocationAlimtalk = () => {

        const id = selectedRow.id;

        if (!id) {
            alert("선택된 항목이 없습니다.");
            return;
        }

        const newLoadings = loadings;
        newLoadings[8] = true;
        setLoadings([...newLoadings]);

        fetch("https://peetsunbae.com/waiting/sendNewLocationAlimtalk", {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id
            })
        }).then((res: any) => {
            res.json()
                .then((result: any) => {

                    if (result.message === "success") {

                        const newLoadings = loadings;
                        newLoadings[8] = false;
                        setLoadings([...newLoadings]);

                        setUpdate(Math.random());

                        notify1();
                    }

                })
        })

    }

    const sendLastWaitingAlimtalk = (kind : string) => {


        const id = selectedRow.id;

        if (!id) {
            alert("선택된 항목이 없습니다.");
            return;
        }

        var index = 0;

        switch(kind){

            case "last" : index = 9; break;
            case "gangnam" : index = 10; break;
            case "daechi" : index = 11; break;

        }

        const newLoadings = loadings;
        newLoadings[index] = true;
        setLoadings([...newLoadings]);

        fetch("https://peetsunbae.com/waiting/sendLastWaitingAlimtalk", {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id, kind
            })
        }).then((res: any) => {
            res.json()
                .then((result: any) => {

                    if (result.message === "success") {

                        const newLoadings = loadings;
                        newLoadings[index] = false;
                        setLoadings([...newLoadings]);

                        setUpdate(Math.random());

                        notify1();
                    }

                })
        })


    }

    const preExam = () => {

        const id = selectedRow.id;

        if (!id) {
            alert("선택된 항목이 없습니다.");
            return;
        }

        const newLoadings = loadings;
        newLoadings[12] = true;
        setLoadings([...newLoadings]);

        fetch("https://peetsunbae.com/waiting/preexam", {
            method : "post",
            credentials : "include",
            headers : {
                "Content-Type" : "application/json"
            },
            body : JSON.stringify({
                id
            })
        }).then((res : any) => {
            res.json()
                .then((result : any) => {

                    if(result.message === "success"){

                        const newLoadings = loadings;
                        newLoadings[12] = false;
                        setLoadings([...newLoadings]);

                        setUpdate(Math.random());

                        notify1();

                    }
                })
        })

    }


    return (
        <div>
            <div style={{ width: "1650px", marginTop: "24px" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <ToggleButtonGroup
                            color="primary"
                            value={alignment1}
                            exclusive
                            onChange={handleChange1}
                            aria-label="Platform"
                        >
                            <ToggleButton value="waiting">대기중</ToggleButton>
                            <ToggleButton value="total">전체</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    <div
                    style={{display : "flex", width : "450px", justifyContent : "space-between", alignItems : "center"}}
                    >
                        <div>
                            <Button style={{marginRight : "6px"}} variant="soft" disabled={disableds[0]} loading={loadings[9]} onClick={() => {sendLastWaitingAlimtalk("last");}}>
                                 라스트 대기문의
                            </Button>
                            <Button style={{marginRight : "6px"}} variant="soft" disabled={disableds[0]} loading={loadings[10]} onClick={() => {sendLastWaitingAlimtalk("gangnam");}}>
                                강남 윈터스쿨
                            </Button>
                            <Button variant="soft" disabled={disableds[0]} loading={loadings[11]} onClick={()=>{sendLastWaitingAlimtalk("daechi");}}>
                                대치 윈터스쿨
                            </Button>
                            
                        </div>
                        <div style={{ display: "flex", alignItems: "flex-end" }}>
                            {selectedRow && selectedRow.name}
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "12px" }}>
                    <div>
                        <div>
                            <ToggleButtonGroup
                                color="primary"
                                value={alignment5}
                                exclusive
                                onChange={handleChange5}
                                aria-label="Platform"
                            >
                                <ToggleButton value="24">24수능</ToggleButton>
                                <ToggleButton value="25">25윈터</ToggleButton>
                            </ToggleButtonGroup>
                            <div style={{ display: "inline-block", marginLeft: "24px" }}>
                            <ToggleButtonGroup
                                color="primary"
                                value={alignment2}
                                exclusive
                                onChange={handleChange2}
                                aria-label="Platform"
                            >
                                <ToggleButton value="total">전체</ToggleButton>
                                <ToggleButton value="gangnam">강남</ToggleButton>
                                <ToggleButton value="daechi">대치</ToggleButton>
                            </ToggleButtonGroup>
                            </div>
                            <div style={{ display: "inline-block", marginLeft: "24px" }}>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={alignment4}
                                    exclusive
                                    onChange={handleChange4}
                                    aria-label="Platform"
                                >
                                    <ToggleButton value="total">전체</ToggleButton>
                                    <ToggleButton value="current">재학생</ToggleButton>
                                    <ToggleButton value="n">N수생</ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                            <div style={{ display: "inline-block", marginLeft: "24px" }}>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={alignment3}
                                    exclusive
                                    onChange={handleChange3}
                                    aria-label="Platform"
                                >
                                    <ToggleButton value="total">전체</ToggleButton>
                                    <ToggleButton value="male">남학생</ToggleButton>
                                    <ToggleButton value="female">여학생</ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                            <div style={{display : "inline-block", marginLeft : "24px", marginTop : "12px"}}>
                                <TextField variant="standard" placeholder="이름/연락처 검색" value={nameSearch} onChange={(e : any) => { handleTextFieldChange(e) }} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <CssVarsProvider>
                            <Button disabled={disableds[7]} variant="soft" sx={{ marginRight: "8px" }} loading={loadings[7]} onClick={() => {resendInitialAlimTalk()}}>
                                순번 재전송
                            </Button>
                            <Button disabled={disableds[0]} variant="soft" sx={{ marginRight: "8px" }} loading={loadings[0]} onClick={() => {sendAlimTalk(1)}}>
                                1차 전송
                            </Button>
                            <Button disabled={disableds[1]} variant="soft" color="success" sx={{ marginRight: "16px" }} loading={loadings[1]} onClick={() => {sendAlimTalk(2)}}>
                                2차 전송
                            </Button>
                            <Button disabled={disableds[2]} variant="soft" sx={{ marginRight: "8px" }} loading={loadings[2]} onClick={() => {sendAlimTalk(3)}}>
                                보류1차 전송
                            </Button>
                            <Button disabled={disableds[3]} variant="soft" color="success" sx={{ marginRight: "16px" }} loading={loadings[3]} onClick={() => {sendAlimTalk(4)}}>
                                보류2차 전송
                            </Button>
                            <Button disabled={disableds[4]} variant="soft" color="danger" sx={{ marginRight: "16px" }}  loading={loadings[4]} onClick={() => {cancelByNoReply()}}>
                                무응답 대기 취소
                            </Button>
                            <Button disabled={disableds[4]} variant="soft" color="danger" sx={{ marginRight: "16px" }}  loading={loadings[6]} onClick={() => {cancelBySilent()}}>
                                조용히 대기 취소
                            </Button>
                            <Button disabled={disableds[5]} variant="soft" color="danger" loading={loadings[5]} onClick={() => {deleteList()}}>
                                대기 삭제
                            </Button>
                            <Button variant="soft" disabled={disableds[0]} loading={loadings[12]} onClick={()=>{preExam();}}>
                                25정규사전조사
                            </Button>
                            
                        </CssVarsProvider>
                    </div>
                </div>
            </div>
            <div className={classes.root} style={{ height: 650, width: '1650px', backgroundColor: "white", marginTop: "12px" }}>
                <DataGridPremium
                    
                    rows={rows}
                    columns={columns}
                    density="compact"
                    getCellClassName={(params) => {
                        if (params.field === "status") {
                            if (params.row.value === "취소") {
                                return "canceled"
                            }
                        }
                        if (params.field === "firstSendDate") {
                            if(params.row.genderOrder < 11 && !params.value){
                                return "notSend1"
                            }
                            if(params.value && !params.row.firstSendReply){
                                const current = new Date();
                                const date = new Date(params.value.split("[")[0]);
                                const dueDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 23, 59, 59);

                                if(current.getTime() > dueDate.getTime()){
                                    return "notReply1"
                                }
                            }
                        }
                        if(params.field === "secondSendDate"){
                            if(params.row.genderOrder < 6 && !params.value){
                                return "notSend1"
                            }
                            if(params.value && !params.row.secondSendReply){
                                const current = new Date();
                                const date = new Date(params.value.split("[")[0]);
                                const dueDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 23, 59, 59);

                                if(current.getTime() > dueDate.getTime()){
                                    return "notReply1"
                                }
                            }
                        }
                        if(params.field === "rejectFirstSendDate"){
                            if(params.value && !params.row.rejectFirstSendReply){
                                const current = new Date();
                                const date = new Date(params.value.split("[")[0]);
                                const dueDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 23, 59, 59);

                                if(current.getTime() > dueDate.getTime()){
                                    return "notReply1"
                                }
                            }
                        }
                        if(params.field === "rejectSecondSendDate"){
                            if(params.value && !params.row.rejectSecondSendReply){
                                const current = new Date();
                                const date = new Date(params.value.split("[")[0]);
                                const dueDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 23, 59, 59);

                                if(current.getTime() > dueDate.getTime()){
                                    return "notReply1"
                                }
                            }
                        }

                        return "";
                    }}
                    filterModel={filterModel}
                    onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                    columnGroupingModel={columnGroupingModel}
                    experimentalFeatures={{
                        columnGrouping: true
                    }}
                    onRowClick={(params) => {
                        
                        setSelectedRow(params.row);
                    }} 
                    processRowUpdate={handleEditCommit}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                />
            </div>

        </div>
    )
}

export default WaitingList;