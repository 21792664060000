import React, { useState, useEffect } from "react";
import { createStyles, makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
import { DataGridPremium, DataGridPro, GridColDef, GridToolbar} from "@mui/x-data-grid-premium";
import renderCellExpand from '../data/rendercellexpand';
import { render } from "react-dom";
import { Button, ButtonGroup } from "@mui/material";
import styles from "../../componentsStyle/chatforteacher.module.css";




const defaultTheme = createTheme();
const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

        return {
            root: {
                '& .super-app-theme--teacherRead': {
                    color: "red",
                    // backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .super-app-theme--studentRead': {
                    color: "blue",
                    // backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .super-app-theme--teacherUnread': {
                    color: "red",
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .super-app-theme--studentUnread': {
                    color: "blue",
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
            },
        };
    },
    { defaultTheme },
);


const columns: GridColDef[] = [
    { field : "chatRoomId", headerName : "채팅방 ID", width : 80, filterable : true},
    { field: 'date', headerName: '날짜', width: 120, filterable: true, editable: false },
    { field: 'time', headerName: '시간', width: 120, filterable: false, editable: false },
    { field: 'from', headerName: 'FROM', width: 120, filterable: true, editable: false },
    { field: 'to', headerName: 'TO', width: 120, filterable: true, editable: false },
    { field: 'message', headerName: '내용', width: 500, filterable: true, editable: false },
    { field: 'isRead', headerName: '읽음여부', width: 120, filterable: false, editable: false },
];


const TotalMessagesTable: React.FC = (props: any) => {

    const classes = useStyles2();
    const [dateInfo, setDateInfo] = useState<any>();
    const [index, setIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState<any>([]);


    const handleClick = (row: any) => {

    }

    const previousDay = () => {
        var newIndex = index;
        newIndex++;
        setIndex(newIndex);
    }

    const nextDay = () => {
        var newIndex = index;
        if(index > 0){
            newIndex--;
            setIndex(newIndex);
        }
    }

    const getTotalMessage = () => {

        setLoading(true);

        fetch(`https://peetsunbae.com/dashboard/envelope/totalmessage?index=${index}`, {
            method: "GET",
            credentials: "include"
        }).then((response: any) => {
            response.json()
                .then((result: any) => {

                    if (result.message === "success") {
                        setDateInfo(result.date);



                        const data = result.data;

                        if (data.length === 0) {

                            return;
                        }

                        const newRows: any = [];

                        data.forEach((eachData: any) => {
                            const oneRow: any = {};

                            oneRow.id = eachData.id;
                            oneRow.chatRoomId = eachData.chatRoomId;
                            oneRow.direction = eachData.direction;

                            var newDate = new Date(eachData.createdAt);
                            oneRow.date = `${newDate.getMonth() + 1}월 ${newDate.getDate()}일`;
                            const today = new Date();
                            if (newDate.getMonth() === today.getMonth() && newDate.getDate() === today.getDate()) {
                                oneRow.date = "오늘";
                            }
                            oneRow.time = `${newDate.getHours()}시 ${newDate.getMinutes()}분`;
                            oneRow.from = eachData.fromUserName;

                            if (eachData.direction === "student") {
                                oneRow.to = eachData.studentName;
                            } else if (eachData.direction === "teacher") {
                                oneRow.to = "선생님"
                            }

                            oneRow.message = eachData.message;

                            if (eachData.isRead === 0) {
                                oneRow.isRead = "안 읽음"
                            } else if (eachData.isRead === 1) {
                                oneRow.isRead = "읽음"
                            }

                            newRows.push(oneRow);
                        })

                        setRows([...newRows]);
                    }else{
                        setRows([]);
                        setDateInfo("");
                    }
                    setLoading(false);
                })
        })

    }

    useEffect(() => {
        getTotalMessage();

    }, [index]);

    return (
        <div>

            <div style={{ marginTop: "0px", marginBottom: "24px", display: "flex", justifyContent: "space-between", paddingLeft : "36px", paddingRight : "36px" }}>
                <div>
                    <div>
                        <ButtonGroup variant="outlined" aria-label="outlined button group">
                            <Button onClick={previousDay}>◁</Button>
                            <Button onClick={nextDay}>▷</Button>
                        </ButtonGroup>
                    </div>

                </div>
                <div>
                    <div className={styles.targetdate}>
                        {dateInfo &&
                            `${dateInfo.lastMonth}월 ${dateInfo.lastDate}일 - ${dateInfo.firstMonth}월 ${dateInfo.firstDate}일`
                        }
                    </div>

                </div>
            </div>


            <div className={classes.root} style={{ height: 550, width: '100%', backgroundColor: "white", paddingLeft: "36px", paddingRight: "36px" }}>
                <DataGridPremium
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    onRowClick={handleClick}
                    density="compact"
                    components={{Toolbar : GridToolbar}}
                    getRowClassName={(params: any) => {
                        if (params.row.direction === "teacher") {
                            if (params.row.isRead === "읽음") {
                                return "super-app-theme--teacherRead";
                            } else {
                                return "super-app-theme--teacherUnread";
                            }
                        } else {
                            if (params.row.isRead === "읽음") {
                                return "super-app-theme--studentRead";
                            } else {
                                return "super-app-theme--studentUnread";
                            }
                        }
                    }}
                />
            </div>
        </div>
    );
}

export default TotalMessagesTable;
