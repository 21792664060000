import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import styles from "./dial.module.css";
import { throttle } from "lodash";


var firstPositionValue1 : any;
var gapValue1 : any;
var windowSize1 : any;
var eachPosition1 : any;
var currentScrollPosition1 : any;

var firstPositionValue2 : any;
var gapValue2 : any;
var windowSize2 : any;
var eachPosition2 : any;
var currentScrollPosition2 : any;


const Dial2: React.FC<any> = forwardRef((props, ref) => {
    
    useImperativeHandle(ref, () => ({
        function1 (number1 : number, number2 : number) {
            goToScroll1(number1);
            goToScroll2(number2);
        },
        function2 () {
            opacityControl1();
            opacityControl2();
        }
    }));


    const goToScroll1 = (number : number) => {

        
        
        if(props.timeSelectRef3.current.length > 95){

            


            const firstCurrent = props.timeSelectRef3.current[1];
            if(!firstCurrent){
                return;
            }
            const firstY = firstCurrent.getBoundingClientRect().y;
            const firstHeight = firstCurrent.offsetHeight;
            
            

            const secondCurrent = props.timeSelectRef3.current[2];
            // if(!secondCurrent){
            //     return;
            // }
            const secondY = secondCurrent.getBoundingClientRect().y;
            const secondHeight = secondCurrent.offsetHeight;

            const timeSelectBoxCurrent = props.timeSelectBoxRef3.current;
            if(!timeSelectBoxCurrent){
                return;
            }
            const timeSelectY = timeSelectBoxCurrent.getBoundingClientRect().y;
            const timeSelectHeight = timeSelectBoxCurrent.clientHeight;
            windowSize1 = timeSelectHeight;

            const interval = secondY - firstY;
            gapValue1 = interval;
            
            
            
            const firstPosition = firstY + firstHeight / 2 - timeSelectY - timeSelectHeight/2;
            firstPositionValue1 = firstPosition;
            

            const zeroPosition = firstPosition - interval;
            
            eachPosition1 = [];

            var currentPosition = zeroPosition;
            

            for(var i = 0; i < 96; i++){
                eachPosition1.push(currentPosition);
                currentPosition = currentPosition + interval;
            }

            

            var nineposition = firstPosition + 35 * interval;

            if(props.kindMenu === "early"){
                nineposition = nineposition + 44 * interval;
            }

            if(number !== 0){
                nineposition = firstPosition + (number - 1) * interval;
            }

            

            

            props.timeSelectBoxRef3.current.scrollTo({top : nineposition, behavior : "instant"});
        }
    }


    const opacityControl1 = () => {

        
        if(props.timeSelectBoxRef3 && props.timeSelectBoxRef3.current){
            
            

            var i = 0;

            const handleScroll = (e : any) => {
                // 
                const currentScrollPostion = props.timeSelectBoxRef3.current.scrollTop;
                currentScrollPosition1 = currentScrollPostion;
                if(firstPositionValue1 && gapValue1 && windowSize1 && eachPosition1){

                    eachPosition1.forEach((eachValue : any, index : number) => {
                        const targetValue = eachValue - currentScrollPostion;
                        const positiveTargetValue = Math.abs(targetValue);
                        const threshold = windowSize1 / 2;
                        
                        if(positiveTargetValue < threshold){
                            const number : any = 1 - (positiveTargetValue / threshold);
                            props.timeSelectRef3.current[index].style.opacity = number;
                        }
                        if(i !== index && positiveTargetValue < gapValue1 / 2){
                            i = index;
                            
                            props.handleSelectedTimeIndex3(i);
                        }
                        
                    })

                    if(currentScrollPosition2 - currentScrollPosition1 < gapValue1){
                        props.timeSelectBoxRef4.current.scrollTo({top : currentScrollPosition1 + gapValue1, behavior : "instant"});
                    }

                }

            }

            const throttledFn = throttle(handleScroll, 50);
            
            const instance = props.timeSelectBoxRef3.current;

            instance.addEventListener("scroll", throttledFn);

            return () => {
                instance.removeEventListener("scroll", throttledFn);
            };

        }


    }



    const goToScroll2 = (number : number) => {

        
        
        if(props.timeSelectRef4.current.length > 95){

            


            const firstCurrent = props.timeSelectRef4.current[1];
            if(!firstCurrent){
                return;
            }
            const firstY = firstCurrent.getBoundingClientRect().y;
            const firstHeight = firstCurrent.offsetHeight;
            
            

            const secondCurrent = props.timeSelectRef4.current[2];
            // if(!secondCurrent){
            //     return;
            // }
            const secondY = secondCurrent.getBoundingClientRect().y;
            const secondHeight = secondCurrent.offsetHeight;

            const timeSelectBoxCurrent = props.timeSelectBoxRef4.current;
            if(!timeSelectBoxCurrent){
                return;
            }
            const timeSelectY = timeSelectBoxCurrent.getBoundingClientRect().y;
            const timeSelectHeight = timeSelectBoxCurrent.clientHeight;
            windowSize2 = timeSelectHeight;

            const interval = secondY - firstY;
            gapValue2 = interval;
            
            
            
            const firstPosition = firstY + firstHeight / 2 - timeSelectY - timeSelectHeight/2;
            firstPositionValue2 = firstPosition;
            

            const zeroPosition = firstPosition - interval;
            
            eachPosition2 = [];

            var currentPosition = zeroPosition;
            

            for(var i = 0; i < 96; i++){
                eachPosition2.push(currentPosition);
                currentPosition = currentPosition + interval;
            }

            

            var nineposition = firstPosition + 35 * interval;

            if(props.kindMenu === "early"){
                nineposition = nineposition + 44 * interval;
            }

            if(number !== 0){
                nineposition = firstPosition + (number - 1) * interval;
            }

            

            

            props.timeSelectBoxRef4.current.scrollTo({top : nineposition, behavior : "instant"});
        }
    }


    const opacityControl2 = () => {

        
        if(props.timeSelectBoxRef4 && props.timeSelectBoxRef4.current){
            
            

            var i = 0;

            const handleScroll = (e : any) => {
                // 
                const currentScrollPostion = props.timeSelectBoxRef4.current.scrollTop;
                currentScrollPosition2 = currentScrollPostion;
                if(firstPositionValue2 && gapValue2 && windowSize2 && eachPosition2){

                    eachPosition2.forEach((eachValue : any, index : number) => {
                        const targetValue = eachValue - currentScrollPostion;
                        const positiveTargetValue = Math.abs(targetValue);
                        const threshold = windowSize2 / 2;
                        
                        if(positiveTargetValue < threshold){
                            const number : any = 1 - (positiveTargetValue / threshold);
                            props.timeSelectRef4.current[index].style.opacity = number;
                        }
                        if(i !== index && positiveTargetValue < gapValue2 / 2){
                            i = index;
                            
                            props.handleSelectedTimeIndex4(i);
                        }
                    })

                    if(currentScrollPosition2 - currentScrollPosition1 < gapValue1){
                        props.timeSelectBoxRef3.current.scrollTo({top : currentScrollPosition2 - gapValue2, behavior : "instant"});
                    }
                }

            }

            const throttledFn = throttle(handleScroll, 50);
            
            const instance = props.timeSelectBoxRef4.current;

            instance.addEventListener("scroll", throttledFn);

            return () => {
                instance.removeEventListener("scroll", throttledFn);
            };

        }


    }





    return (
        <div className={styles.timeSelectWrapper2}>



            <div className={styles.timeSelectBox} ref={props.timeSelectBoxRef3}>

                {
                    new Array(96).fill(undefined).map((each: any, index: number) => {
                        var totalMinutes = 15 * index;

                        var ampm = "am";
                        var hours = 0;
                        var minutes: any = 0;

                        if (totalMinutes >= 720) {
                            ampm = "pm"
                            totalMinutes = totalMinutes - 720;
                        }

                        hours = Math.floor(totalMinutes / 60);

                        if (hours === 0) {
                            hours = 12;
                        }

                        minutes = totalMinutes % 60;
                        if (minutes < 10) {
                            minutes = "0" + minutes;
                        }

                        return (
                            <div key={ampm + hours + minutes} className={styles.timeSelectElement} ref=
                                {
                                    // index === 1 ? firstRef : index === 2 ? secondRef : index === 36 ? nineRef : voidRef
                                    (el) => { props.timeSelectRef3.current[index] = el }
                                }
                            >
                                {ampm === "am" ? "오전" : "오후"}&nbsp;{hours}:{minutes}
                            </div>
                        );


                    })
                }



            </div>

            <div className={styles.text}>
                부터
            </div>


            <div className={styles.timeSelectBox} ref={props.timeSelectBoxRef4}>

                {
                    new Array(96).fill(undefined).map((each: any, index: number) => {
                        var totalMinutes = 15 * index;

                        var ampm = "am";
                        var hours = 0;
                        var minutes: any = 0;

                        if (totalMinutes >= 720) {
                            ampm = "pm"
                            totalMinutes = totalMinutes - 720;
                        }

                        hours = Math.floor(totalMinutes / 60);

                        if (hours === 0) {
                            hours = 12;
                        }

                        minutes = totalMinutes % 60;
                        if (minutes < 10) {
                            minutes = "0" + minutes;
                        }

                        return (
                            <div key={ampm + hours + minutes} className={styles.timeSelectElement} ref=
                                {
                                    // index === 1 ? firstRef : index === 2 ? secondRef : index === 36 ? nineRef : voidRef
                                    (el) => { props.timeSelectRef4.current[index] = el }
                                }
                            >
                                {ampm === "am" ? "오전" : "오후"}&nbsp;{hours}:{minutes}
                            </div>
                        );


                    })
                }



            </div>



            <div className={styles.layer}>
            </div>

        </div>
    );
})

export default Dial2;