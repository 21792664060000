import { Button, Divider } from "@mui/joy";
import React, { useEffect, useState } from "react";
import {
    DataGridPremium,
    GridColDef,
    GridRowsProp,
    GridRowSelectionModel,
    useGridApiRef,
    GridGroupNode,
    GRID_ROOT_GROUP_ID,
    GridCellSelectionModel,
    GridFilterModel,
    GridLogicOperator,
    GridRowModel,
    GridRowClassNameParams,
} from '@mui/x-data-grid-premium';
import { makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { fil, th } from "date-fns/locale";
import { renderCellExpand } from "../../infos/suddenData";


const defaultTheme = createTheme();

const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);


        return {
            root: {
                '& .suspicious_in': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    color: "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .suspicious': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .once': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .once_in': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    color: "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    }
                },
                '& .never': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .never_in': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    color: "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .clickable': {
                    cursor: "pointer",
                    '&:hover': {
                        color: "blue"
                    }
                },
                '& .phone_problem': {
                    color: "red"
                },
                '& .phone_noproblem': {
                    color: "black"
                },
                '& .phone_memo': {
                    color: "black"
                },
                '& .status2': {
                    backgroudColor: "red"
                },
                '& .status1': {
                    backgroudColor: "red"
                },
                '& .status4': {
                    backgroudColor: "red"
                },
                '& .status5': {
                    backgroudColor: "red"
                },
                '& .accept': {
                    color : "blue"
                },
                '& .inAccept': {
                    color : "red"
                }
            },
        };
    },
    { defaultTheme },
);



const DemeritRegularData: React.FC<any> = (props) => {

    const [rows, setRows] = useState<GridRowsProp>([]);
    const [targetDateMonth, setTargetDateMonth] = useState<number>(0);
    const [columns, setColumns] = useState<GridColDef[]>([
        { field : "parentpermit", headerName : "학부모승인", width : 100, filterable : false, sortable : false},
        { field: "day", headerName: "요일", width: 100 },
        { field: "description", headerName: "내용", width: 650, renderCell : renderCellExpand },
        { field : "memo", headerName : "월별 메모", width : 200, renderCell : renderCellExpand, editable : true }
    ]);
    const classes = useStyles2();
    const apiRef = useGridApiRef();


    useEffect(() => {

        letsStart();

    }, [props.regularScheduleData, props.targetDate, props.regularScheduleMemoData]);


    const letsStart = async () => {

        try {

            if(!props.regularScheduleData){
                setRows([]);
                return;
            }

            if(!props.targetDate){
                setRows([]);
                return;
            }

            makeRows();

        } catch (e) {
            
        }

    }

    const makeRows = async () => {

        if(!props.regularScheduleData){
            setRows([]);
            return;
        }

        if(!props.targetDate){
            setRows([]);
            return;
        }

        
        

        //targetDate를 이용해 요일을 sunday, monday 등의 형식으로 만들어준다.
        const targetDateDay = props.targetDate.getDay();

        let targetDateDayString = "";
        let targetDateDayKoreanString = "";

        switch(targetDateDay){
            case 0:
                targetDateDayString = "sunday";
                targetDateDayKoreanString = "일요일";
                break;
            case 1:
                targetDateDayString = "monday";
                targetDateDayKoreanString = "월요일";
                break;
            case 2:
                targetDateDayString = "tuesday";
                targetDateDayKoreanString = "화요일";
                break;
            case 3:
                targetDateDayString = "wednesday";
                targetDateDayKoreanString = "수요일";
                break;
            case 4:
                targetDateDayString = "thursday";
                targetDateDayKoreanString = "목요일";
                break;
            case 5:
                targetDateDayString = "friday";
                targetDateDayKoreanString = "금요일";
                break;
            case 6:
                targetDateDayString = "saturday";
                targetDateDayKoreanString = "토요일";
                break;
        }

        if(targetDateDayString === ""){
            setRows([]);
            return;
        }

        var parentpermitstring = "";

        if(props.regularScheduleData.parentpermit === 0){
            parentpermitstring = "미승인";
        }

        if(props.regularScheduleData.parentpermit === 1){
            parentpermitstring = "승인";
        }

        if(props.regularScheduleData.parentpermit === 2){
            parentpermitstring = "승인거절"
        }

        const newRows: any = [];

        const firstRow: any = {};
        firstRow.id = 1;
        firstRow.sqlId = props.regularScheduleData.id;
        firstRow.day = targetDateDayKoreanString;
        firstRow.description = props.regularScheduleData.data[targetDateDayString];
        firstRow.targetDateDayString = targetDateDayString;
        firstRow.parentpermit = parentpermitstring;


        newRows.push(firstRow);

        const secondRow: any = {};
        secondRow.id = 2;
        secondRow.sqlId = props.regularScheduleData.id;
        secondRow.day = "etc";
        secondRow.description = props.regularScheduleData.data.etc;
        secondRow.targetDateDayString = "etc";
        secondRow.parentpermit = parentpermitstring;

        newRows.push(secondRow);

        newRows.forEach((row: any) => {

            if(!props.regularScheduleMemoData){
                return;
            }

            
            

            const memoData = props.regularScheduleMemoData;

            memoData.forEach((memo: any) => {

                if(memo.day === row.targetDateDayString){
                    
                    row.memo = memo.description;
                }

            });

        });

        
        setRows([...newRows]);

    }

    const handleEditCommit = React.useCallback(
        async (newRow: GridRowModel, old: any) => {

            var field = "";

            

            for (const [key, value] of Object.entries(newRow)) {
                if (value !== old[key]) {
                    field = key;
                }
            }

            const value = newRow[field] ? newRow[field] : null;
            const id = newRow.sqlId;
            const day = newRow.targetDateDayString;

            if (!field) {
                
                return newRow;
            }

            if (!id) {
                
                return newRow;
            }

            if(field !== "memo"){
                
                return newRow;
            }

            const data = {
                value,
                id,
                field,
                day
            }

            

            fetch("https://peetsunbae.com/dashboard/report/demerit/monthlymemo", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
                credentials: "include",
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        
                        if (result.message === "success") {
                            
                        } else {
                            alert("서버에 문제가 있습니다. 다시 시도해주세요.");
                        }
                    })
            }).catch((error: any) => {
                
                alert("서버에 문제가 있습니다. 다시 시도해주세요.");
            });

            return newRow;

        }, []
    );

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        
        alert(error.message);
    }, []);



    return (
        <div>
            <div>
                <div className={classes.root} style={{
                    width: "1000px",
                    height: "130px",
                }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingBottom: "10px",
                            fontFamily: "Apple_SB",
                            paddingTop: "58px",
                        }}
                    >
                        정기일정
                    </div>
                    <DataGridPremium
                        sx={{
                            backgroundColor: "#ffffff",
                        }}
                        rows={rows}
                        columns={columns}
                        density="compact"
                        apiRef={apiRef}
                        hideFooter={true}
                        onRowSelectionModelChange={(newSelectionModel) => {
                            const data: any = apiRef.current.getSelectedRows();
                            if (!data) {
                                return;
                            }
                        }}
                        processRowUpdate={handleEditCommit}
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                        getCellClassName={(props : any) => {

                            if(props.field === "parentpermit"){
                                if(props.value === "승인"){
                                    //return "accept";
                                }else{
                                    //return "inAccept";
                                }
                            }

                            return "";
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default DemeritRegularData;