//한자리 수 일경우 앞에 0을 붙여주는 함수
export const addZeroIfOne = (num: number) => {
  return num < 10 ? `0${num}` : num;
};

export const dateToDateString = (date: Date, needTime : Boolean = false, onlyTime : Boolean = false) => {

  var string = "";


  if(onlyTime){

    const hours = addZeroIfOne(date.getHours());
    const minutes = addZeroIfOne(date.getMinutes());
    const seconds = addZeroIfOne(date.getSeconds());

    string = `${hours}:${minutes}:${seconds}`;

    return string;

  }
  
  const year = date.getFullYear();
  const month = addZeroIfOne(date.getMonth() + 1);
  const day = addZeroIfOne(date.getDate());

  string = `${year}-${month}-${day}`;

  if(needTime){

    const hours = addZeroIfOne(date.getHours());
    const minutes = addZeroIfOne(date.getMinutes());
    const seconds = addZeroIfOne(date.getSeconds());

    string += ` ${hours}:${minutes}:${seconds}`;

  }

  return string;

}

const dayToKorean = (day: number) => {
  
    switch(day){
      case 0:
        return "일";
      case 1:
        return "월";
      case 2:
        return "화";
      case 3:
        return "수";
      case 4:
        return "목";
      case 5:
        return "금";
      case 6:
        return "토";
    }
  
}
