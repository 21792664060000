interface QuestionList {
    korean : string,
    english : string,
    academy : string
}

export const questionList : QuestionList[] = [
    {
        korean : "강남점",
        english : "gangnam",
        academy : "sunbae"
    },
    {
        korean : "대치점 전체",
        english : "daechiTotal",
        academy : "sunbae"
    },
    {
        korean : "대치점",
        english : "daechi",
        academy : "sunbae"
    },
    {
        korean : "대치3층",
        english : "daechi2",
        academy : "sunbae"
    },
    {
        korean : "대치6층",
        english : "daechi3",
        academy : "sunbae"
    },
    {
        korean : "대치 2,3층",
        english : "daechi_12",
        academy : "sunbae"
    },
    {
        korean : "대치 3,6층",
        english : "daechi_23",
        academy : "sunbae"
    },
    {
        korean : "대치 2,6층",
        english : "daechi_13",
        academy : "sunbae"
    },
    {
        korean : "송도_전체",
        english : "songdo_all",
        academy : "mom"
    },
    {
        korean : "송도_지정석",
        english : "songdo_fixed",
        academy : "mom"
    },
    {
        korean : "송도_자유석",
        english : "songdo_free",
        academy : "mom"
    },
    {
        korean : "몰입공간",
        english : "molip",
        academy : "molip"
    },
    {
        korean : "분당점",
        english : "bundang",
        academy : "bundangsunbae"
    },
    {
        korean : "송파점",
        english : "songpa",
        academy : "sunbae"
    }
]