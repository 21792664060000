import React, {useEffect, useState} from 'react';
import { Button, Fab, IconButton, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Box, Tab, Tabs } from "@mui/material";
import ManageConsultWrite from './pages/manageconsultwrite';
import ViewConsultList from './pages/viewconsultlist';
import WriteMentorDescription from './pages/writementordescription';
import MentorDescriptionWriteList from './pages/mentordescriptionwritelist';

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ManageConsult : React.FC<any> = (props) => {

    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div>
            <div style={{ width: "100%", margin: "0 auto" }}>
                <Box sx={{ width: "100%", marginBottom: "0px" }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                            <Tab label="멘토투두 보기" {...a11yProps(0)} />
                            <Tab label="멘토 설명 작성하기" {...a11yProps(1)} />
                            <Tab label="멘토 설명 작성현황 보기" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                </Box>
            </div>

            {
                value === 0 &&
                <div>
                    <ViewConsultList user={props.user} />
                </div>
            }
            {
                value === 1 &&
                <div>
                    <WriteMentorDescription user={props.user} />
                </div>
            }
            {
                value === 2 &&
                <div>
                    <MentorDescriptionWriteList user={props.user} />
                </div>
            }
        </div>
    )
}

export default ManageConsult;