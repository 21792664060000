
import React, { useEffect, useState, useRef, useCallback } from "react";
import styles from "./imageviewer.module.css";
import { ReactComponent as Xmark } from "../../../svg/xmark-regular.svg"
import { throttle } from "lodash";
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from "react-zoom-pan-pinch";

import {
    IonModal,
} from '@ionic/react';







const ImageViewer: React.FC<any> = (props) => {

    var moveX = 0;
    var moveY = 0;


    var translateValueX = 0;
    var translateValueY = 0;

    //손가락 두개 찍었을 때 이전 두 손가락 사이의 거리
    var previousScaleDistance = 0;

    //그림 중앙 좌표
    var imageCenterX = 0;
    var imageCenterY = 0

    //zoom 시작하는 손가락 위치
    var fingerCenterX = 0;
    var fingerCenterY = 0;

    var transformCenterX = 0;
    var transformCenterY = 0;

    //그림 중앙과 손가락 중앙 사이의 거리
    var bothGapX = 0;
    var bothGapY = 0;


    const screenDiagonal = Math.floor(Math.sqrt((window.innerHeight * window.innerHeight) + (window.innerWidth) * (window.innerWidth)));

    var scaleValue = 1;
    var adjustValue = 5;

    const zoomRef = useRef<ReactZoomPanPinchRef>(null);

    const headerRef = useRef<any>(null);
    const imageRef = useRef<any>(null);
    const [imageMaxHeight, setImageMaxHeight] = useState(0);
    const [imageMaxWidth, setImageMaxWidth] = useState(0);
    const [imageDirection, setImageDirection] = useState("h");



    const [xPosition, setXPosition] = useState(0);
    const [yPosition, setYPosition] = useState(0);

    const [isAndroid, setIsAndroid] = useState(false);

    const [translateValue, setTranslateValue] = useState("translate(0px, 0px)");

    const calcBodySize = () => {

        if (!props.src) {
            return;
        }

        const newImage = new Image();
        newImage.src = props.src;



        newImage.onload = (e) => {

            
            
            

            const imageWidth = newImage.width;
            const imageHeight = newImage.height;

            if (!headerRef || !headerRef.current) {
                
                return;
            }
            

            const totalHeight = window.innerHeight;
            const totalWidth = window.innerWidth;
            const headerHeight = headerRef.current.offsetHeight;
            const valueBottom = +getComputedStyle(document.documentElement).getPropertyValue("--sab").split("p")[0];
            const valueTop = +getComputedStyle(document.documentElement).getPropertyValue("--sat").split("p")[0];

            if (valueTop === 0) {
                setIsAndroid(true);
            }

            const maxHeight = totalHeight - headerHeight - valueBottom - valueTop;
            const maxWidth = totalWidth;

            const imageRatio = imageWidth / imageHeight;
            const screenRatio = maxWidth / maxHeight;

            var direction;

            if (imageRatio >= screenRatio) {
                direction = "h";
                setImageDirection(direction);
                // imageRef.current.style.width = `${maxWidth}px`;
            } else {
                direction = "v"
                setImageDirection(direction);
                // imageRef.current.style.height = `${maxHeight}px`;
            }

            

            setImageMaxHeight(maxHeight);
            setImageMaxWidth(maxWidth);

        }

    }

    // const bodyRef = useCallback((node) => {
    //     if (!node) {
    //         return;
    //     }



    // }, []);

    useEffect(() => {

        setTimeout(() => {
            calcBodySize();
        }, 10);

    }, [props.src]);

    const handleTouchEnd = (e: any) => {

        moveX = 0;
        moveY = 0;
        previousScaleDistance = 0;
        imageCenterX = 0
        imageCenterY = 0;
        fingerCenterX = 0;
        fingerCenterY = 0;
        bothGapX = 0;
        bothGapY = 0;
        fingerCenterX = 0;
        fingerCenterY = 0;
    }

    useEffect(() => {

        moveX = 0;
        moveY = 0;
        previousScaleDistance = 0;
        scaleValue = 1;
        translateValueX = 0;
        translateValueY = 0;
        imageCenterX = 0;
        imageCenterY = 0;
        fingerCenterX = 0;
        fingerCenterY = 0;
        bothGapX = 0;
        bothGapY = 0;
        fingerCenterX = 0;
        fingerCenterY = 0;

    }, []);

    useEffect(() => {

        
        

    }, []);

    // const handleTouchStart = (e : any) => {

    //     if(e.touches.length === 2){
    //         

    //         const imageClientX = imageRef.current.getBoundingClientRect().x;
    //         const imageClientY = imageRef.current.getBoundingClientRect().y;
    //         const imageWidth = imageRef.current.offsetWidth;
    //         const imageHeight = imageRef.current.offsetHeight;

    //         imageCenterX = Math.floor(imageClientX + (imageWidth / 2));
    //         imageCenterY = Math.floor(imageClientY + (imageHeight / 2));

    //         // imageCenterX = Math.floor(window.innerWidth / 2);
    //         // imageCenterY = Math.floor(window.innerHeight / 2);

    //         
    //         

    //         fingerCenterX = Math.floor((e.touches[0].clientX + e.touches[1].clientX) / 2);
    //         fingerCenterY = Math.floor((e.touches[0].clientY + e.touches[1].clientY) / 2);

    //         
    //         

    //         bothGapX = fingerCenterX - imageCenterX;
    //         bothGapY = fingerCenterY - imageCenterY;

    //         // transformCenterX = ((fingerCenterX) / window.innerWidth) * 100;
    //         // transformCenterY = ((fingerCenterY) / window.innerHeight) * 100;

    //         // 
    //         // 
    //         // 
    //     }

    // }


    // const handleTouch = (e : any) => {

    //     if(e.touches.length === 1){

    //         if(moveX && moveY){
    //             const xGap = e.touches[0].clientX - moveX;
    //             const yGap = e.touches[0].clientY - moveY;
    //             
    //             
    //             

    //             //둘 중하나라도 0이 아니면
    //             if(xGap || yGap){

    //                 if(!imageRef || !imageRef.current){
    //                     
    //                     return;
    //                 }

    //                 translateValueX += xGap;
    //                 translateValueY += yGap;

    //                 imageRef.current.style.transform = `translate(${translateValueX}px, ${translateValueY}px) scale(${scaleValue})`;
    //             }
    //         }

    //         moveX = e.touches[0].clientX;
    //         moveY = e.touches[0].clientY;

    //     }

    //     if(e.touches.length === 2){

    //         

    //         const currentX1 = e.touches[0].clientX;
    //         const currentY1 = e.touches[0].clientY;
    //         const currentX2 = e.touches[1].clientX;
    //         const currentY2 = e.touches[1].clientY;

    //         const currentDistance =  Math.floor(Math.sqrt(((currentX1 - currentX2) * (currentX1 - currentX2)) + ((currentY1 - currentY2) * (currentY1 - currentY2)))) ;

    //         if(previousScaleDistance){


    //             const howMuchLarge = currentDistance - previousScaleDistance;
    //             const howMuchLargeRatio = ((screenDiagonal + (howMuchLarge * adjustValue))/screenDiagonal);
    //             const howMuchGap = howMuchLargeRatio - 1;
    //             
    //             scaleValue +=  howMuchGap ;

    //             // translateValueX -= (howMuchGap * bothGapX);
    //             // translateValueY -= (howMuchGap * bothGapY);


    //             


    //             imageRef.current.style.transform = `scale(${scaleValue}) translate(${translateValueX}px, ${translateValueY}px)`;

    //         }

    //         previousScaleDistance =  currentDistance;
    //     }

    // }

    // const throttledFn = throttle(handleTouch, 100);

    const transformOptions = {
        initialScale: 0.4,
        minScale: 0.5,
        maxScale: 2
    }

    const handleZoom = (ref : ReactZoomPanPinchRef, e: TouchEvent | MouseEvent) => {

        

        const scale = ref.state.scale;

        const percent = 100 * scale;

    }


    return (
        <IonModal
            isOpen={props.isModalOpen}
            onDidDismiss={props.handleClose}
        >
            <div className={styles.blackBackground}>
                <div>
                    <div className={styles.header}>
                        <div style={{ paddingTop: "env(safe-area-inset-top)" }}>
                        </div>
                        <div className={`${styles.headerDiv} ${isAndroid ? styles.android : ""}`} ref={headerRef}>
                            <div className={styles.headerContents}>
                                <div className={styles.xmarkDiv} onClick={props.handleClose} >
                                    <Xmark className={styles.xmark}>

                                    </Xmark>
                                </div>
                                <div className={styles.title}>
                                    이미지 뷰어
                                </div>
                                <div>

                                </div>
                            </div>

                        </div>
                    </div>


                    <div className={styles.body}>
                        <div className={styles.modalBodyDiv} style={{ height: `${imageMaxHeight}px`,overflow: "hidden" }} onTouchEnd={handleTouchEnd}>
                            <div className={styles.modalBody} style={{ height : "100%"}} >
                                <TransformWrapper
                                centerOnInit={true}
                                >
                                    <TransformComponent
                                    wrapperStyle={{height : "100%"}}
                                    >
                                        <div style={{width : "100%", height : "100%"}}>
                                            <img ref={imageRef} src={props.src} className={styles.modalImg}  style={{maxWidth : `${imageMaxWidth}px`, maxHeight : `${imageMaxHeight}px` }} />
                                        </div>
                                    </TransformComponent>
                                </TransformWrapper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </IonModal>
    );
}

export default ImageViewer;