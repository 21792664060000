import React, {useEffect, useState, useRef} from "react";
import styles from "./mobilecheckwrite.module.css";
import { useLocation } from "react-router-dom";

import { ReactComponent as ArrowLeftLight } from "../../../src/svg/arrow-left-light.svg";
import { ReactComponent as ChevronRight } from "../../../src/svg/chevron-right-light.svg";
import { ReactComponent as XmarkRegular } from "../../../src/svg/xmark-regular.svg";
import { ReactComponent as PlusRegular } from "../../../src/svg/plus-regular.svg";

import { useHistory } from "react-router-dom";
import { Capacitor } from "@capacitor/core";
import { ReactComponent as ImageSvg } from "../../svg/image-outline.svg";
import { IonApp } from '@ionic/react';
import { CssVarsProvider } from '@mui/joy/styles';


import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Person from '@mui/icons-material/Person';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import TimelineIcon from '@mui/icons-material/Timeline';
import AbcIcon from '@mui/icons-material/Abc';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

import { Camera, CameraResultType } from "@capacitor/camera";
import { NativeSettings, AndroidSettings, IOSSettings } from "capacitor-native-settings";
import { useIonAlert, IonLoading } from "@ionic/react";
import { Filesystem } from "@capacitor/filesystem";

import { Preferences } from "@capacitor/preferences";

import ImageViewer from "../etc/imageviewer/imageviewer";

import { IonModal, IonContent } from '@ionic/react';
import AspectRatio from "@mui/joy/AspectRatio";
import ImageEditor from "../etc/imageeditor/imageeditor";
import { get } from "lodash";
import { getFetch } from "../../fetch/fetch";

interface info {
    teacherName : string,
    enrolledId : number,
}

const MobileCheckWrite : React.FC<any> = (props) => {

    const [showLoading, setShowLoading] = useState(false);

    const [alignment, setAlignment] = useState("");
    const [alignmentValueText, setAlignmentValueText] = useState("");

    const history = useHistory();
    const [platform, setPlatform] = useState("");
    const [open, setOpen] = useState(false);
    const modal = useRef<any>(null);
    const modal2 = useRef<any>(null);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [kindValue, setKindValue] = useState("");
    const [kindValueText, setKindValueText] = useState("");
    const [description, setDescription] = useState("");

    const [presentAlert] = useIonAlert();

    const [imageWebpathArray, setImageWebPathArray] = useState<any>([]);
    const [imageNativePathArray, setImageNativePathArray] = useState<any>([]);
    const [images, setImages] = useState<any>([]);

    const [cardHeight, setCardHeight] = useState(0);
    const cardRef = useRef<any>(null);

    const [addDivHeight, setAddDivHeight] = useState(0);
    const addDivRef = useRef<any>(null);

    const [ionModalOpen, setIonModalOpen] = useState(false);
    const [ionModalOpen2, setIonModalOpen2] = useState(false);
    const [ionModalSrc, setIonModalSrc] = useState("");
    const [ionModalSrc2, setIonModalSrc2] = useState("");

    const [title, setTitle] = useState("");

    const location : any = useLocation();

    const [sendInfo, setSendInfo] = useState<info | null>(null);



    const getMessage = async () => {

        try{

            

            setShowLoading(true);

            const result : any = await getFetch("/dashboard/checklist/studentwrite");

            setShowLoading(false);

            

            if(result.message === "success"){
                
                
                setDescription(result.description);
            }


        }catch(e){
            
            
        }

        
    }


    useEffect(() => {

        

        const {
            enrolledId,
            teacherName
        } = location.state;

        const info = {
            enrolledId,
            teacherName,
        }

        

        getMessage();
        setSendInfo(info);
        
    }, []);
    
    const handleInputChange = (e : any) => {
        setTitle(e.target.value);
    }


    //플랫폼 정보 확인
    useEffect(() => {
        const platform = Capacitor.getPlatform();

        if (platform) {
            setPlatform(platform);
        }

        
        

    }, []);



    const handleChange2 = (e: any) => {
        setAlignment(e.target.value);

        switch (e.target.value) {
            case "chemistry":
                setAlignmentValueText("국어");
                break;
            case "organic":
                setAlignmentValueText("수학");
                break;
            case "physics":
                setAlignmentValueText("영어");
                break;
            case "biology":
                setAlignmentValueText("탐구");
                break;
        }

    }

    const handleImageClick = () => {
        if (images.length > 0) {
            setIsModalOpen(true);
        } else {
            getImage();
        }
    }


    const registerImage = async (webPath : string, blob : Blob) => {

        if(!webPath || !blob){
            
            return;
        }

        const newImageWebPathArray: any = imageWebpathArray;
        newImageWebPathArray.push(webPath);
        setImageWebPathArray(newImageWebPathArray);

        const file = new File([blob], "opinionImage.png", { type: blob.type });
        

        const newImages: any = images;
        newImages.push(file);
        setImages([...newImages]);

        ionModalClose2();

    }


    const getImage = async () => {

        const permissionStatus = await Camera.checkPermissions();

        

        if (permissionStatus.camera === "denied" || permissionStatus.photos === "denied") {
            

            presentAlert({
                header: "접근 권한이 필요합니다",
                subHeader: "카메라, 사진첩 접근 권한이 필요합니다.",
                buttons: [
                    {
                        text: "Cancel",
                        role: "cancel",
                    },
                    {
                        text: "OK",
                        role: "confirm",
                        handler: () => {
                            NativeSettings.open({
                                optionAndroid: AndroidSettings.ApplicationDetails,
                                optionIOS: IOSSettings.App
                            })
                        }
                    }
                ]
            })

            return;
        }


        var image;

        try {
            image = await Camera.getPhoto({
                quality: 100,
                allowEditing: false,
                resultType: CameraResultType.Uri,
            })
        } catch (e) {
            
            return;
        }

        if (!image) {
            return;
        }
        setShowLoading(true);

        setShowLoading(false);


        if (Capacitor.isNativePlatform()) {

            if (!image.path) {
                
                return;
            }

            const newImageNativePathArray = imageNativePathArray;
            newImageNativePathArray.push(image.path);
            setImageNativePathArray([...newImageNativePathArray]);

            const base64data = await Filesystem.readFile({
                path: image.path
            });

            setIonModalSrc2("data:image/png;base64," + base64data.data);
            setIonModalOpen2(true);

            return;

        } else {

            if (!image.webPath) {
                return;
            }

            const byteArray = await fetch(image.webPath);
            const blob = await byteArray.blob();
            

            const file = new File([blob], "opinionImage.jpeg", { type: blob.type });
            

            const newImages: any = images;
            newImages.push(file);
            setImages([...newImages]);
        }

        setShowLoading(false);
    }

    const removeImage = (index: number) => {
        const newImages = images.filter((eachImage: any, imageIndex: any) =>
            index !== imageIndex
        );

        const newImageWebPathArray = imageWebpathArray.filter((eachImagePath: any, imagePathIndex: any) =>
            index !== imagePathIndex
        );

        const newImageNativePathArray = imageNativePathArray.filter((eachImagePath : any, imagePathIndex : number) => 
            index !== imagePathIndex
        );

        setImages([...newImages]);
        setImageWebPathArray([...newImageWebPathArray]);
        setImageNativePathArray([...newImageNativePathArray]);

    }

    const submit = async (e: any) => {

        if (!description || !sendInfo) {
            alert("내용을 먼저 적어주세요");
            return;
        }

        setShowLoading(true);

        // const formData = new FormData;
        var message = { enrolledId: sendInfo.enrolledId, description: description };
        // formData.append("message", JSON.stringify(message));

        // images.forEach((eachImage: any) => {
        //     formData.append('question_picture', eachImage);
        // });

        var token: any = "";

        if (window.electron) {
            token = window.electron.sendMessageApi.getToken();
        }

        if (Capacitor.isNativePlatform()) {
            const { value } = await Preferences.get({ key: "token" });
            token = value;
        }


        fetch("https://peetsunbae.com/dashboard/checklist/studentwrite", {
            method: "POST",
            headers: { "Authorization": token,
            "Content-Type": "application/json"
             },
            credentials: "include",
            body: JSON.stringify(message),
        }).then((response) => {
            response.json()
                .then((result) => {
                    if (result.message === "success") {
                        setShowLoading(false);
                        alert("성공");
                        history.push("/mobiledashboard/checklist");
                    }else{
                        setShowLoading(false);
                        alert("전송 실패");
                    }
                })
        }).catch((error) => {
            
        })

    }

    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    function niceBytes(x: any) {

        let l = 0, n = parseInt(x, 10) || 0;

        while (n >= 1024 && ++l) {
            n = n / 1024;
        }

        return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + '' + units[l]);
    }


    const ionModalClose = () => {
        setIonModalOpen(false);
    }

    const ionModalClose2 = () => {
        setIonModalOpen2(false);
    }

    const pictureClick = (index : number) => {
        setIonModalSrc(imageWebpathArray[index]);
        setIonModalOpen(true);
    }


    return (
        <IonApp>
            <div style={{ paddingTop: "env(safe-area-inset-top)" }}>
                <div className={styles.headerDiv}>
                    <div className={`${styles.header} ${platform === "ios" ? styles.ios : ""}`}>
                        <div className={styles.backBtnDiv}
                            onClick={() => { history.goBack(); }}
                        >
                            <ArrowLeftLight
                                className={styles.arrowLeft}
                            />
                        </div>

                        <div className={styles.titleText}>
                             특이사항 전송
                        </div>

                        <div className={styles.btns}>
                            <div className={styles.imageIconDiv} 
                            //onClick={handleImageClick}
                            >
                                {/* <ImageSvg className={styles.imageIcon} />
                                <div className={`${styles.badge} ${images.length ? styles.active : ""}`}>
                                    {images.length ? images.length : ""}
                                </div> */}
                            </div>
                            <div className={`${styles.submit} ${(sendInfo && description) ? styles.active : ""}`} onClick={(sendInfo && description) ? submit : () => { }}>
                                전송
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.bodyDiv}>
                    <div className={`${styles.body}`}>

                        {/* <div className={`${styles.kindSelect}`} id="open-modal2">
                            <div className={`${styles.kindSelectText} ${alignmentValueText ? styles.active : ""}`}>
                                {alignmentValueText ? alignmentValueText : "질의응답 과목 선택"}
                            </div>
                            <div className={styles.kindSelectChevron}>
                                <ChevronRight fill="rgba(0,0,0,0.4)" className={styles.chevronRight} />
                            </div>
                        </div> */}

                        <div className={`${styles.kindSelect2}`} id="open-modal">
                            <div className={styles.input}>
                                {
                                    sendInfo &&
                                    `${sendInfo.teacherName}에게 전송`
                                }
                            </div>
                        </div>

                        <div className={styles.descriptionDiv}>
                            <textarea value={description} onChange={(e: any) => { setDescription(e.target.value); }} rows={18} className={styles.textarea} placeholder="내용을 작성해주세요" />
                        </div>
                    </div>
                </div>






                {/* <IonModal
                    ref={modal2}
                    trigger="open-modal2"
                    initialBreakpoint={0.45}
                    breakpoints={[0, 0.45]}
                    handleBehavior="cycle"
                >
                    <IonContent className="ion-padding">
                        <CssVarsProvider>
                            <RadioGroup aria-label="Your plan" name="people" defaultValue="Individual" value={alignment} onChange={handleChange2}>
                                <List
                                    sx={{
                                        marginTop: "2rem",
                                        minWidth: 240,
                                        '--List-gap': '0.5rem',
                                        '--List-item-paddingY': '1rem',
                                        '--List-item-radius': '8px',
                                        '--List-decorator-size': '32px',
                                    }}
                                >
                                    {[{ lable: "국어", value: "chemistry" }, { lable: "수학", value: "organic" }, { lable: "영어", value: "physics" }, { lable: "탐구", value: "biology" }].map((item, index) => (
                                        <ListItem variant="outlined" key={item.value}>
                                            <ListItemDecorator>
                                                {[<FormatAlignLeftIcon />, <TimelineIcon />, <AbcIcon />, <ManageSearchIcon />][index]}
                                            </ListItemDecorator>
                                            <Radio
                                                overlay
                                                value={item.value}
                                                label={<span className={styles.radioItem}>{item.lable}</span>}
                                                sx={{ flexGrow: 1, flexDirection: 'row-reverse', paddingTop : "10px", paddingBottom : "10px" }}
                                                slotProps={{
                                                    action: ({ checked }) => ({
                                                        sx: (theme) => ({
                                                            ...(checked && {
                                                                inset: -1,
                                                                border: '2px solid',
                                                                borderColor: theme.vars.palette.primary[500],
                                                                borderRadius : 0
                                                            }),
                                                        }),
                                                    }),
                                                }}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </RadioGroup>
                        </CssVarsProvider>

                    </IonContent>
                </IonModal>

                <IonModal isOpen={isModalOpen}>
                    <div style={{ paddingTop: "env(safe-area-inset-top)" }}>
                        <div className={styles.headerDiv}>
                            <div className={`${styles.header} ${platform === "ios" ? styles.ios : ""}`}>
                                <div className={styles.backBtnDiv}
                                    onClick={() => { setIsModalOpen(false); }}
                                >
                                    <ArrowLeftLight
                                        className={styles.arrowLeft}
                                    />
                                </div>

                                <div className={styles.titleText}>
                                    첨부파일 &nbsp;<span className={styles.imageNumber}>{images.length}</span>
                                </div>

                                <div className={styles.btns}>

                                </div>
                            </div>
                        </div>

                        <div className={styles.bodyDiv}>
                            <div className={styles.body}>
                                {
                                    images.map((eachImage: any, index: number) => {
                                        
                                        const imageSize = niceBytes(eachImage.size);
                                        return (
                                            <div className={styles.cardWrapper}>
                                                <CssVarsProvider>
                                                    <AspectRatio ratio="100/19" variant="plain">
                                                        <div key={index} className={`${styles.eachCard} ${images.length - 1 === index ? styles.last : ""}`}>
                                                            <div className={styles.eachImageDiv}>
                                                                <img src={imageWebpathArray[index]} className={styles.eachImage} onClick={(e: any) => { pictureClick(index); }} />
                                                            </div>
                                                            <div className={styles.eachImageText}>
                                                                <div className={styles.eachImageName}>
                                                                    첨부파일_{index + 1}.{eachImage.name.split(".")[eachImage.name.split.length - 1]}
                                                                </div>
                                                                <div className={styles.eachImageSize}>
                                                                    {imageSize}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </AspectRatio>
                                                </CssVarsProvider>
                                                <div className={styles.cancel} onClick={() => { removeImage(index); }}>
                                                    <XmarkRegular className={styles.xmark} fill="white" />
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                                <div style={{height : "0.8rem"}}>
                                    
                                </div>
                                <CssVarsProvider>
                                    <AspectRatio ratio="100/14" variant="plain">
                                        <div onClick={getImage} className={styles.addDiv} >
                                            <div className={styles.addDivTextDiv}>
                                                <div className={styles.plusDiv}>
                                                    <PlusRegular fill="white" className={styles.plus} />
                                                </div>
                                                <div className={styles.addText}>
                                                    사진 추가하기
                                                </div>
                                            </div>
                                        </div>
                                    </AspectRatio>
                                </CssVarsProvider>

                            </div>
                        </div>
                        <IonLoading
                            cssClass='my-custom-class'
                            isOpen={showLoading}
                            onDidDismiss={() => setShowLoading(false)}
                            message={'잠시만 기다려주세요'}
                        />

                        <ImageViewer
                            isModalOpen={ionModalOpen}
                            handleClose={ionModalClose}
                            title=""
                            src={ionModalSrc}
                        />
                    </div>
                </IonModal>





                <ImageEditor 
                isModalOpen={ionModalOpen2}
                handleClose={ionModalClose2}
                src={ionModalSrc2}
                registerImage={registerImage}
                /> */}

                



            </div>

            <IonLoading
                cssClass='my-custom-class'
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'잠시만 기다려주세요'}
            />
        </IonApp >
    )
}

export default MobileCheckWrite;