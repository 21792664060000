import { Box, Modal } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import UploadIcon from '@mui/icons-material/Upload';
import { Button, CircularProgress } from "@mui/joy";
import { set } from "lodash";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ImageUploadModal = (props: any) => {

    const [isExist, setIsExist] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [isDragOver, setIsDragOver] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        if (props.src) {
            setIsExist(true);
        } else {
            setIsExist(false);
        }

    }, []);



    //새로운 selectedFile이 들어올때마다 handleFileUpload을 실행한다.
    useEffect(() => {
        handleFileUpload();
    }, [selectedFile]);

    useEffect(() => {
        const handlePaste = (e: any) => {

            if (!props.open) {
                
                return;
            }

            const item = e.clipboardData?.items[0];
            if (item?.type.indexOf('image') === 0) {
                setSelectedFile(item.getAsFile());
            }
        }

        window.addEventListener('paste', handlePaste);
        return () => window.removeEventListener('paste', handlePaste);
    }, [props.open]);


    const handleFileUpload = async () => {

        if (!props.id) {
            return;
        }

        if (!props.open) {
            return;
        }

        if (selectedFile) {

            //selectedFile이 이미지가 아닐 경우 return
            if (selectedFile.type.indexOf('image') === -1) {
                return;
            }

            setLoading(true);

            const kind = props.kind;



            const formData = new FormData();
            formData.append('file', selectedFile);

            const message = {
                id: props.id,
                kind: kind
            }

            formData.append('message', JSON.stringify(message));

            

            try {

                const resposne = await fetch("https://peetsunbae.com/dashboard/avatar/new/img", {
                    method: "POST",
                    credentials: "include",
                    body: formData
                });

                const result = await resposne.json();

                

                if (result.message === "success") {
                    setLoading(false);
                    props.success();
                    props.handleClose();
                    props.update();
                }

            } catch (e) {
                
            }

        }
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedFile(e.target.files ? e.target.files[0] : null);
    }

    const handleFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (e.dataTransfer.items) {
            if (e.dataTransfer.items[0].kind === 'file') {
                setSelectedFile(e.dataTransfer.items[0].getAsFile());
            }
        }
    }

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragOver(true);
    }

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragOver(false);
    }



    return (
        <Modal
            open={props.open}
            onClose={props.handleClose}

        >
            <Box sx={style}>
                <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}
                >
                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "24px",
                            fontFamily: "Apple_R"
                        }}
                    >
                        이미지 업로드
                    </div>
                    <div
                        style={{
                            flex: 0.5,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "12px",
                            fontFamily: "Apple_L"
                        }}
                    >
                        (드래그 드롭, 복사 붙여넣기, 파일 선택)
                    </div>
                    <div
                        style={{
                            flex: 5,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        onDrop={handleFileDrop}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                    >
                        {
                            !loading &&
                            <>
                                <UploadIcon style={{ fontSize: "64px", cursor: "pointer" }} onClick={() => fileInputRef.current?.click()} />
                                <input type="file" accept="image/*" ref={fileInputRef} onChange={handleFileChange} style={{ display: "none" }} />
                            </>
                        }
                        {
                            loading &&
                            <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <CircularProgress size="lg" />
                            </div>
                        }
                    </div>
                    <div style={{ flex: 0.5 }}>

                    </div>
                    <div
                        style={{
                            flex: 1,
                            textAlign: "center",
                            fontFamily: "Apple_R",
                            fontSize: "14px",
                        }}
                    >
                        반드시, 책상/책꽂이를 깨끗히<br></br>물티슈 및 매직블럭으로 청소 후 사진을 찍어주세요. 
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default ImageUploadModal;