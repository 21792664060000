import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
import { DataGridPremium, GridColDef, GridFilterModel, useGridApiRef } from "@mui/x-data-grid-premium";
import { Button } from '@mui/joy';
import { postFetch } from '../../../fetch/fetch';
import { Backdrop, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const defaultTheme = createTheme();

const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

        return {
            root: {
                '& .super-app-theme--처리완료': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .super-app-theme--확인': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .super-app-theme--미확인': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .clickable': {
                    cursor: "pointer",
                    '&:hover': {
                        color: "blue"
                    }
                },
                '& .status3': {

                },
                '& .inactivated': {
                    backgroundColor: getBackgroundColor(theme.palette.grey[500]),
                },
                '& .complete': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                },
                '& .incomplete': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                },
                '& .passivecomplete': {
                    backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                },
                '& .status5': {
                    backgroudColor: "red"
                }
            },
        };
    },
    { defaultTheme },
);

const columns: GridColDef[] = [
    { field: 'restaurantName', headerName: '식당 이름', width: 100 },
    { field: 'mealName', headerName: '메뉴 이름', width: 200 },
    { field: 'mealPrice', headerName: '가격', width: 100 },
    { field: 'userName', headerName: '등록자', width: 100 },
    { field: 'createdAt', headerName: '등록일', width: 200 },
];

const RestaurantMenuList = () => {

    const [rows, setRows] = useState<any>([]);
    const classes = useStyles2();
    const [update, setUpdate] = useState(0);
    const [selectedIds, setSelectedIds] = useState<any>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getRegisteredMeals();
    }, [update]);

    const getRegisteredMeals = () => {

        setLoading(true);

        fetch("https://peetsunbae.com/dashboard/restaurant/registeredTotalMeals", {
            method: "GET",
            credentials: "include"
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    
                    if (result.message === "success") {
                        const data = result.data;


                        const newRows: any = [];

                        data.forEach((each: any) => {
                            const oneRow: any = {};
                            oneRow.id = each.id;
                            oneRow.label = `[${each.restaurantName}] ${each.mealName}`;
                            oneRow.restaurantName = each.restaurantName;
                            oneRow.mealName = each.mealName;
                            oneRow.mealPrice = each.mealPrice;
                            oneRow.userName = each.userName;

                            const date = new Date(each.DateInserted);

                            const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

                            oneRow.createdAt = dateString;

                            newRows.push(oneRow);
                        })

                        setRows([...newRows]);

                    }

                    setLoading(false);
                })
        })

    }

    const letsDelete = async () => {

        try {

            if (selectedIds.length === 0) {
                return;
            }

            const length = selectedIds.length;

            const answer = window.confirm(`${length}개의 메뉴를 삭제하시겠습니까?`);

            if (!answer) {
                return;
            }

            setLoading(true);

            const result: any = await postFetch("/dashboard/restaurant/deleteMeals", { selectedIds });

            

            if (result.message === "success") {

                
                

                // setLoading(false);
                notify1();
                setUpdate(Math.random());
            }

        } catch (e) {
            
        }



    }

    const notify1 = () => toast.success("삭제 성공!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "colored"
    });


    return (
        <div>
            <div>
                <div style={{ width: "1500px", marginTop: "36px", display: "flex", justifyContent: "flex-end" }}>
                    <div>
                        <Button
                            disabled={selectedIds.length === 0}
                            loading={loading}
                            onClick={letsDelete}
                        >
                            삭제하기
                        </Button>
                    </div>
                </div>
                <div className={classes.root} style={{ height: 650, width: '1500px', backgroundColor: "white", marginTop: "12px" }}>
                    <DataGridPremium
                        rows={rows}
                        columns={columns}
                        checkboxSelection
                        density='compact'
                        onRowSelectionModelChange={(newSelection) => {
                            
                            setSelectedIds([...newSelection]);
                        }}
                        loading={loading}
                    />
                </div>
            </div>

            {/* <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop> */}


        </div>
    )

}

export default RestaurantMenuList;