import React, { useEffect, useState } from "react";
import {
    DataGridPremium,
    GridColDef,
    GridRowsProp,
    GridRowSelectionModel,
    useGridApiRef,
    GridGroupNode,
    GRID_ROOT_GROUP_ID,
    GridCellSelectionModel,
    GridFilterModel,
    GridLogicOperator,
    GridRowModel,
    GridRowClassNameParams,
} from '@mui/x-data-grid-premium';
import { makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';


const defaultTheme = createTheme();

const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);


        return {
            root: {
                '& .super-app-theme--처리완료': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .once': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .once_in': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    color : "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    }
                },
                '& .never': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .never_in': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    color : "#9FA6AD",
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .clickable': {
                    cursor: "pointer",
                    '&:hover': {
                        color: "blue"
                    }
                },
                '& .enter': {
                    color : "blue"
                },
                '& .exit': {
                    color : "red"
                },
                '& .phone_memo': {
                    color : "black"
                },
                '& .status2': {
                    backgroudColor: "red"
                },
                '& .status1': {
                    backgroudColor: "red"
                },
                '& .status4': {
                    backgroudColor: "red"
                },
                '& .status5': {
                    backgroudColor: "red"
                }
            },
        };
    },
    { defaultTheme },
);

const noData : any = [
    {
        id : 1, direction : "데이터가 없습니다"
    }
]

const AccessData : React.FC<any> =(props) => {

    const classes = useStyles2();
    const [rows, setRows] = useState<GridRowsProp[]>([]);
    const [columns, setColumns] = useState<GridColDef[]>([
        { field : "direction", headerName : "방향", width : 100 },
        { field : "time", headerName : "시간", width : 150 },
        { field : "location", headerName : "장소", width : 150}
    ]);

    useEffect(() => {

        if(!props.accessControlData){
            setRows([...noData]);
            return;
        }

        makeRows();

    }, [props.accessControlData]);

    const makeRows = () => {

        const newRows : any = [];

        if(!props.accessControlData){
            setRows([]);
            return;
        }

        //props.accessControlData의 time이 큰 것들이 앞으로 오도록 정렬

        props.accessControlData.sort((a : any, b : any) => {
            return (+b.time) - (+a.time);
        });
        
        props.accessControlData.forEach((eachData : any) => {

            const oneRow : any = {};

            oneRow.id = eachData.id;

            switch(eachData.direction){
                case "outside" :
                    oneRow.direction = "enter";
                    break;
                case "inside" :
                    oneRow.direction = "exit";
                    break;
            }

            const time = new Date(+eachData.time);

            //AM/PM hh:mm:ss꼴로 만들기
            const isAMPM = time.getHours() < 12 ? "AM" : "PM";
            const hours = time.getHours() < 13 ? time.getHours() : time.getHours() - 12;
            const hoursString = hours < 10 ? "0" + hours : hours;
            const minutes = time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes();
            const seconds = time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds();

            oneRow.time = isAMPM + " " + hoursString + ":" + minutes + ":" + seconds;

            if(eachData.other){
                oneRow.location = eachData.koreanKinds;

                if(oneRow.location === "대치점"){
                    oneRow.location = "대치2층";
                }
            }

            newRows.push(oneRow);

        })

        if(newRows.length === 0){
            newRows.push({
                id : 1, time : "데이터가 없습니다"
            })
        }

        setRows([...newRows]);

    }


    return (
        <div className={classes.root}
        style={{
            width : "500px",
            height : "400px"
        }}
        >
                        <div
            style={{
                display : "flex",
                justifyContent : "center",
                paddingBottom : "10px",
                fontFamily : "Apple_SB"
            }}
            >
                출입기록
            </div>
            <DataGridPremium
            sx={{
                backgroundColor : "white"
            }}
            loading={props.loading}
            rows={rows}
            columns={columns}
            density="compact"
            hideFooter
            getCellClassName={(props : any) => {

                if(props.field === "direction"){
                    if(props.value === "enter"){
                        return "enter";
                    }else if(props.value === "exit"){
                        return "exit";
                    }
                }

                return "";
            }}

            />
        </div>
    )

}

export default AccessData;