import React, {useEffect, useState} from "react";
import { createStyles, makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
import {
    DataGridPremium,
    GridColDef,
    GridRowsProp,
    GridRowSelectionModel,
    useGridApiRef,
    GridGroupNode,
    GRID_ROOT_GROUP_ID,
    GridCellSelectionModel,
    GridFilterModel,
    GridLogicOperator,
    GridRowModel,
    GridToolbar,
    GridRowClassNameParams,
    GridTreeNode
} from '@mui/x-data-grid-premium';
import EditIcon from '@mui/icons-material/Edit';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Button } from "@mui/joy";
import { Autocomplete, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import totalLocationData from "../../../../data/locationdata";


const defaultTheme = createTheme();
const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

        return {
            root: {
                '& .completed': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .super-app-theme--확인': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .super-app-theme--미확인': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .duplicate': {
                    color : "#ff7f00"
                },
                '& .incompleted': {
                    color : "red"
                },
            },
        };
    },
    { defaultTheme },
);


const RequestChange : React.FC<any> = (props : any) => {

    const apiRef = useGridApiRef();
    const [selectedRow, setSelectedRow] = useState<any>();
    const [rows, setRows] = useState<GridRowsProp[]>([]);
    const [update, setUpdate] = useState(0);
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [disabled, setDisabled] = useState(true);
    const [disabled2, setDisabled2] = useState(true);
    const [users, setUsers] = useState<any>([]);
    const classes = useStyles2();
    const [newTotalLocationData, setNewTotalLocationData] = useState<any>([]);

    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [alignment, setAlignment] = useState("incomplete");
    const [alignment2, setAlignment2] = useState("total");
    const [searchText, setSearchText] = useState("");

    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [
            {
                id: 1,
                field: "name",
                operator: "contains",
                value: ""
            },
            {
                id: 2,
                field: "status",
                operator: "is",
                value: "0"
            },
            {
                id : 3,
                field : "location",
                operator : "isAnyOf",
                value : [""]
            }
        ]
    });

    const columns: GridColDef[] = [
        { field: 'name', headerName: '신청 학생', width: 70 },
        { field: 'location', headerName: '호점', width: 70 },
        { field: 'seat', headerName: '현재 좌석 번호', width: 150 },
        { field : "etc", headerName : "특이사항", width : 150, editable : true, renderCell : (params) => {

            if(params.value){
                return (
                    <div>
                        {params.value}
                    </div>
                )
            }else{

                //정가운데에 edit icon 넣기
                return (
                    <div style={{display : "flex", alignItems : "center", justifyContent : "center"}}>
                        <EditIcon color="disabled" />
                    </div>
                )
            }

        }},
        { field: 'description', headerName: '내용', width: 350, editable: true, renderCell : (params) => { 

            if(params.value){
                return (
                    <div>
                        {params.value}
                    </div>
                )
            }else{

                //정가운데에 edit icon 넣기
                return (
                    <div style={{display : "flex", alignItems : "center", justifyContent : "center"}}>
                        <EditIcon color="disabled" />
                    </div>
                )
            }
        } },
        { field: 'appliedDate', headerName: '신청일', width: 150, editable : true, type : "date", renderCell: (params) => {
            if (!params.value) {
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <CalendarTodayIcon style={{ cursor: "pointer" }} fontSize="small" color="disabled" />
                    </div>
                )
            } else {
                const date = new Date(params.value);
                const dateString = `${date.getMonth() + 1}월 ${date.getDate()}일`;

                return (
                    <div>
                        {dateString}
                    </div>
                )
            }
        } },
        { field: 'expiredDate', headerName: '경과일', width: 100 },
        { field: 'status', headerName: '처리현황', width: 100, editable : true, filterable : true, type : "singleSelect", valueOptions : [{value : "0", label : "미완료"}, {value : "1", label : "처리완료"}, {value : "2", label : "보류중"}] },
        { field: 'createdAt', headerName: '생성날짜', width: 100 },
        { field: 'teacherName', headerName: '생성한 사람', width: 100 }
    ]

    useEffect(() => {

        const newLocation : any[] = [];

        totalLocationData.forEach((each) => {

            newLocation.push(each);

            if(each.english === "gangnam"){
                const oneRow = {
                    english : "daechiTotal",
                    korean : "대치점 전체",
                    academy : "sunbae"
                }

                newLocation.push(oneRow);
            }

        });

        

        setNewTotalLocationData(newLocation);

    }, []);

    const handleAlignmentChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {

        if (!newAlignment) {
            return;
        }

        setAlignment(newAlignment);

        //filtermodel중 id가 2인 것을 찾아서 newAlignment가 incomplete면 "미완료", total이면 ""로 바꾸기

        const newFilterModel = filterModel;

        newFilterModel.items.forEach((each : any) => {
            if(each.id === 2){
                if(newAlignment === "incomplete"){
                    each.value = "0";
                }else{
                    each.value = "";
                }
            }
        })

        setFilterModel({...newFilterModel});

    }

    const handleAlignmentChange2 = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {

        if (!newAlignment) {
            return;
        }

        

        setAlignment2(newAlignment);

        //filtermodel중 id가 2인 것을 찾아서 newAlignment가 incomplete면 "미완료", total이면 ""로 바꾸기

        const newFilterModel = filterModel;

        // newFilterModel.items.forEach((each : any) => {
        //     if(each.id === 3){
        //         if(newAlignment === "total"){
        //             each.value = ["", "강남점", "대치2층", "대치3층", "대치6층"];
        //         }else if(newAlignment === "gangnam"){
        //             each.value = ["강남점"];
        //         }else if(newAlignment === "daechi"){
        //             each.value = ["대치2층"];
        //         }else if(newAlignment === "daechi2"){
        //             each.value = ["대치3층"];
        //         }else if(newAlignment === "daechi3"){
        //             each.value = ["대치6층"];
        //         }else if(newAlignment === "daechiTotal"){
        //             each.value = ["대치2층", "대치3층", "대치6층"];
        //         }
        //     }
        // })

        newTotalLocationData.forEach((each : any) => {

            if(each.english === newAlignment){
             
                newFilterModel.items.forEach((each2 : any) => {
                    if(each2.id === 3){

                        const array : any = [];

                        

                        if(newAlignment === "total"){

                            array.push("-");
                            //array.push(null);
                            // array.push(undefined);

                            totalLocationData.forEach((each3) => {

                                if(each3.english !== "total"){
                                    array.push(each3.korean);
                                }

                            });

                        }else if(newAlignment === "daechiTotal"){

                            

                            totalLocationData.forEach((each3) => {

                                if(each3.english.includes("daechi")){
                                    
                                    array.push(each3.korean);
                                }

                            })

                        }else{
                            array.push(each.korean);
                        }

                        each2.value = array;


                    }
                });

            }
        });

        setFilterModel({...newFilterModel});

    }


    const onchange = async (e: any, value: any) => {
        if (!users) {
            return;
        }

        

        
        setSelectedUser(value);
    }

    useEffect(() => {

        if (selectedUser) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }

    }, [selectedUser]);

    useEffect(() => {

        if (selectedRow) {
            setDisabled2(false);
        } else {
            setDisabled2(true);
        }

    }, [selectedRow]);

    useEffect(() => {
        getRequestList();
    }, [update]);

    const getRequestList = async () => {

        try{

            const response = await fetch("https://peetsunbae.com/dashboard/avatar/change/request", {
                method: "GET",
                credentials: "include"
            });

            const result = await response.json();

            

            if(result.message === "success"){

                const data = result.data;

                const newRows : any = [];

                data.forEach((each : any, index : number) => {

                    const oneRow : any = {};

                    oneRow.id = each.id;
                    oneRow.userId = each.userId;
                    oneRow.name = each.studentName;
                    oneRow.location = each.location;

                    if(!oneRow.location){
                        oneRow.location = "-";
                    }

                    // if(oneRow.location === "대치점"){
                    //     oneRow.location = "대치2층";
                    // }

                    oneRow.seat = each.seat;
                    oneRow.description = each.description;
                    
                    if(each.appliedDate){
                        const date = new Date(each.appliedDate);
                        oneRow.appliedDate = date;
                    }

                    if(each.appliedDate){

                        //each.expiredDate에는 each.appliedDate로부터 현재까지 경과된 일수가 들어있다.
                        const appliedDate = new Date(each.appliedDate);
                        const currentDate = new Date();

                        const diff = currentDate.getTime() - appliedDate.getTime();

                        const diffDate = diff / (1000 * 3600 * 24);

                        oneRow.expiredDate = Math.floor(diffDate) + "일";

                        oneRow.expiredDateNumber = Math.floor(diffDate);

                    }else{
                        oneRow.expiredDateNumber = 9999999999;
                    }


                    oneRow.etc = each.etc;
                    oneRow.status = each.status;

                    if(each.createdAt){
                        const date = new Date(each.createdAt);
                        // 월/일 시:분
                        const dateString = `${date.getMonth() + 1}/${date.getDate()} ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;
                        oneRow.createdAt = dateString;
                    }

                    oneRow.teacherName = each.teacherName;

                    newRows.push(oneRow);

                })

                //expiredDateNumber를 기준으로 내림차순 정렬
                newRows.sort((a : any, b : any) => {
                    return b.expiredDateNumber - a.expiredDateNumber;
                })

                setRows([...newRows]);

            }

        }catch(e){
            
        }

    }



    useEffect(() => {

        
        async function start() {

            var token = "";

            if (window.electron) {
                token = await window.electron.sendMessageApi.getToken();
            }

            fetch("https://peetsunbae.com/dashboard/avatar/change/users", {
                method: "GET",
                headers: { "Authorization": token },
                credentials: "include",
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        
                        const rows: any = [];
                        result.data.forEach((each: any, index: number) => {
                            var data: any = {};
                            data.id = each.id;
                            data.label = `${each.name} (${each.location})`;
                            rows.push(data);
                        })
                        setUsers([...rows]);

                    })
            })
        }

        start();

    }, [update]);

    const deleteRow = async () => {

        if (!selectedRow) {
            return;
        }

        const id = selectedRow.sqlId;

        if (!id) {
            return;
        }

        try {

            if(window.confirm("정말로 삭제하시겠습니까?") === false){
                return;
            }

            setLoading2(true);

            const response = await fetch("https://peetsunbae.com/dashboard/avatar/change/request", {
                method: "DELETE",
                credentials: "include",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    id
                })
            });

            const result = await response.json();

            if (result.message === "success") {
                setUpdate(Math.random());
                setLoading2(false);
            }

        } catch (e) {
            
        }

    }

    const submit = async () => {

        if (!selectedUser) {
            return;
        }

        const userId = selectedUser.id;

        if(!userId) {
            return;
        }

        try{

            setLoading1(true);

            const response = await fetch("https://peetsunbae.com/dashboard/avatar/change/request", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                credentials: "include",
                body: JSON.stringify({
                    userId : userId
                })
            });

            const result = await response.json();

            

            if(result.message === "success"){

                setUpdate(Math.random());
                setSelectedUser(null);
                setLoading1(false);

            }

        }catch(e){
            
        }

    }

    const handleEditCommit = React.useCallback(
        async (newRow: GridRowModel, old: any) => {


            var field = "";

            

            for (const [key, value] of Object.entries(newRow)) {
                if (value !== old[key]) {
                    field = key;
                }
            }

            const value = newRow[field] ? newRow[field] : null;
            const id = newRow.id;
            const userId = newRow.userId;

            
            
            
            
            

            if (!field) {
                
                return newRow;
            }

            if (!id) {
                
                return newRow;
            }

            const data = {
                value,
                id,
                userId,
                field
            }

            

            try {

                const response = await fetch("https://peetsunbae.com/dashboard/avatar/change/request", {
                    method: "PATCH",
                    credentials: "include",
                    headers: {
                        "content-type": "application/json"
                    },
                    body: JSON.stringify(data)
                });

                const result = await response.json();

                if (result.message === "success") {
                    setUpdate(Math.random());
                }

            } catch (e) {
                
            }

            return newRow;

        }, []
    );

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        
        alert(error.message);
    }, []);

    const handleSearchTextChange = (e : any) => {

        setSearchText(e.target.value);

        const newFilterModel = filterModel;

        newFilterModel.items.forEach((each : any) => {
            if(each.id === 1){
                each.value = e.target.value;
            }
        })

        setFilterModel({...newFilterModel});

    }

    useEffect(() => {

        const totalFilter = [""];

        totalLocationData.forEach((each) => {

            if (each.english === "total") {
                return;
            }

            totalFilter.push(each.korean);
        });

        const newFilterModel = filterModel;

        newFilterModel.items.forEach((each: any) => {
            if (each.id === 3) {
                each.value = totalFilter;
            }
        })

        setFilterModel({ ...newFilterModel });

    }, []);

    return (
        <div>
            <div
            style={{
                fontFamily: "Apple_B",
                fontSize: "24px",
                marginBottom: "24px"
            }}
            >
                자리 이동 신청리스트
            </div>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
            }}>
                <div style={{
                    marginBottom: "12px",
                }}>
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleAlignmentChange}
                        aria-label="Platform"
                        sx={{ marginRight: "36px" }}
                    >
                        <ToggleButton value="incomplete">미완료</ToggleButton>
                        <ToggleButton value="total">전체</ToggleButton>
                    </ToggleButtonGroup>

                    <ToggleButtonGroup
                        color="primary"
                        value={alignment2}
                        exclusive
                        onChange={handleAlignmentChange2}
                        aria-label="Platform"
                        sx={{ marginRight: "36px" }}
                    >
                        {/* <ToggleButton value="total">전체</ToggleButton>
                        <ToggleButton value="gangnam">강남점</ToggleButton>
                        <ToggleButton value="daechiTotal">대치점</ToggleButton>
                        <ToggleButton value="daechi">대치2층</ToggleButton>
                        <ToggleButton value="daechi2">대치3층</ToggleButton>
                        <ToggleButton value="daechi3">대치6층</ToggleButton> */}
                        {
                            props.user && newTotalLocationData.filter((each : any) => each.academy.includes(props.user.academy)).map((each : any, index : number) => {
                                return (
                                    <ToggleButton value={each.english} key={index}>{each.korean}</ToggleButton>
                                )
                            })
                        }
                    </ToggleButtonGroup>
                </div>
                <div>
                    <TextField sx={{ fontSize: "24px", marginLeft : "12px" }} label="이름 검색" variant="standard" value={searchText} onChange={handleSearchTextChange} />
                </div>
            </div>
            
            <div style={{
                display: "flex",
                justifyContent: "space-between",
            }}>
                <div style={{
                    display: "flex",
                }}>
                    <Autocomplete
                        onChange={onchange}
                        value={selectedUser}
                        disablePortal
                        id="combo-box-demo"
                        options={users}
                        sx={{ width: "250px", marginRight: "12px" }}
                        renderInput={(params) => <TextField {...params} placeholder="학생을 선택하세요" />}
                    />
                    <Button variant="soft" disabled={disabled} onClick={submit} loading={loading1}>
                        추가하기
                    </Button>
                </div>
                <div>
                    <Button variant="soft" color="danger" disabled={disabled2} onClick={deleteRow} loading={loading2}>
                        삭제하기
                    </Button>
                </div>
            </div>
            <div className={classes.root} style={{ height: 500, width: '100%', backgroundColor: "white", marginTop: "8px" }}>
                <DataGridPremium
                    rows={rows}
                    columns={columns}
                    density="compact"
                    slots={{ toolbar: GridToolbar }}
                    processRowUpdate={handleEditCommit}
                    filterModel={filterModel}
                    onFilterModelChange={(model) => {
                        
                        setFilterModel(model);
                    }}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                    apiRef={apiRef}
                    onRowSelectionModelChange={(newSelectionModel) => {
                        const data: any = apiRef.current.getSelectedRows();
                        if (!data) {
                            return;
                        }
                        
                        

                        

                        data.forEach((value: any, index: number) => {

                            const newSelectedInfo = {
                                sqlId: value.id,
                                name : value.name,
                                userId : value.userId
                            }

                            
                            setSelectedRow(newSelectedInfo);
                        })
                    }}
                    getCellClassName={(params: any) => {

                        if (params.field === "status") {
                            if (params.value === "0") {
                                return "incompleted";
                            } else if (params.value === "1") {
                                return "completed";
                            } else if (params.value === "2") {
                                return "";
                            }
                        }

                        return ""
                    }}
                />
            </div>
        </div>

    );
}

export default RequestChange;