import { GridCellParams, GridRenderCellParams, DataGridPro, GridRowsProp, GridColDef, GridToolbar, useGridApiRef, GridFilterModel, GridRowModel, GridRowOrderChangeParams, DataGridPremium, } from '@mui/x-data-grid-premium';

import React, { useCallback, useEffect, useState } from "react";
import styles from "./consultchecklist.module.css";
import { createStyles, makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
import { getFetch } from '../../../fetch/fetch';
import { Button, CssVarsProvider, Textarea } from '@mui/joy';
import Check from '@mui/icons-material/Check';
import { Add, Close, DeleteOutlineOutlined, Done, KeyboardArrowLeft, KeyboardArrowRight, PanoramaFishEye } from '@mui/icons-material';
import { Autocomplete, Badge, LinearProgress, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { debounce, set } from 'lodash';
import { is } from 'date-fns/locale';
import { addZeroIfOne } from '../../components/controls/etc/etcfunctions';



const defaultTheme = createTheme();

const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

        return {
            root: {
                '& .super-app-theme--처리완료': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .super-app-theme--확인': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .super-app-theme--미확인': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
            },
        };
    },
    { defaultTheme },
);




const ConsultCheckList: React.FC<any> = (props) => {
    const [lastSaveTime, setLastSaveTime] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [loading2, setLoading2] = useState<boolean>(false);
    const [loading3, setLoading3] = useState<boolean>(false);
    const [rows, setRows] = useState<any>([]);
    const [columns, setColumns] = useState<GridColDef[]>([
        { field: 'name', headerName: '이름', width: 80, filterable: true, editable: false, headerAlign: "center" },
        {
            field: 'consult', headerName: '상담 진행 여부', width: 140, headerAlign: "center",
            renderCell: (params) => (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                    <ToggleButtonGroup
                        size='small'
                        exclusive
                        value={params.value}
                        onChange={(e, value) => {
                            controlRows(params.id, "consult", value);
                        }}
                        color='primary'
                    >
                        <ToggleButton value={true}>
                            <PanoramaFishEye />
                        </ToggleButton>
                        <ToggleButton value={false}>
                            <Close />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
            )
        },
        {
            field: "etc", headerName: "특이사항", width: 150, editable: true, headerAlign: "center", align: "center",
            renderCell: (params) => {

                if (!params.value) {
                    return (
                        <div style={{ position: "relative" }}>
                            <EditIcon color={params.row.consult ? "action" : "error"} />
                            {
                                !params.row.consult && (
                                    <div style={{
                                        position: "absolute",
                                        width: "14px",
                                        height: "14px",
                                        fontSize: "11px",
                                        borderRadius: "50%",
                                        color: "rgb(211, 47, 47)",
                                        border: "1px solid rgb(211, 47, 47)",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        top: "-29%",
                                        right: "-50%",
                                        fontWeight: 600
                                    }}>
                                        !
                                    </div>
                                )
                            }
                        </div>
                    )
                } else {
                    return (
                        <div>
                            {params.value}
                        </div>
                    )
                }

            }
        },
        {
            field: 'report', headerName: '상담일지저장', width: 140, headerAlign: "center", align: "center", editable: true,
            renderCell: (params) => {

                if (params.row.consult === false) {
                    return "-"
                }


                if (params.value) {

                    const date = new Date(params.row.report);
                    const isReportNotDate = isNaN(date.getTime());
                    const isReportDate = !isReportNotDate;

                    if (isReportDate) {
                        params.row.report = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${addZeroIfOne(date.getHours())}:${addZeroIfOne(date.getMinutes())}:${addZeroIfOne(date.getSeconds())}`
                    }

                    return (
                        <div>
                            {params.row.report}
                        </div>
                    )
                } else {
                    return (
                        <div style={{
                            position: "relative",
                        }}>
                            <EditIcon color="error" />
                            <div style={{
                                position: "absolute",
                                width: "14px",
                                height: "14px",
                                fontSize: "11px",
                                borderRadius: "50%",
                                color: "rgb(211, 47, 47)",
                                border: "1px solid rgb(211, 47, 47)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                top: "-29%",
                                right: "-50%",
                                fontWeight: 600
                            }}>
                                !
                            </div>
                        </div>
                    )
                }
            }
        },
        {
            field: 'send', headerName: '학부모 전송 여부', width: 140, headerAlign: "center", align: "center", editable: true,
            renderCell: (params) => {

                if (params.row.consult === false) {
                    return "-"
                }

                if (params.value) {

                    const date = new Date(params.value);
                    const isReportNotDate = isNaN(date.getTime());
                    const isReportDate = !isReportNotDate;

                    if (isReportDate) {
                        params.value = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${addZeroIfOne(date.getHours())}:${addZeroIfOne(date.getMinutes())}:${addZeroIfOne(date.getSeconds())}`
                    }

                    return (
                        <div>
                            {params.value}
                        </div>
                    )
                } else {
                    return (
                        <div style={{
                            position: "relative",
                        }}>
                            <EditIcon color="error" />
                            <div style={{
                                position: "absolute",
                                width: "14px",
                                height: "14px",
                                fontSize: "11px",
                                borderRadius: "50%",
                                color: "rgb(211, 47, 47)",
                                border: "1px solid rgb(211, 47, 47)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                top: "-29%",
                                right: "-50%",
                                fontWeight: 600
                            }}>
                                !
                            </div>
                        </div>
                    )
                }
            }
        },
        {
            field: 'word', headerName: '영단어 출제', width: 140, headerAlign: "center", align: "center", editable: true,
            renderCell: (params) => {

                if (params.row.consult === false) {
                    return "-"
                }

                if (params.value) {

                    const date = new Date(params.value);
                    const isReportNotDate = isNaN(date.getTime());
                    const isReportDate = !isReportNotDate;

                    if (isReportDate) {
                        params.value = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${addZeroIfOne(date.getHours())}:${addZeroIfOne(date.getMinutes())}:${addZeroIfOne(date.getSeconds())}`
                    }

                    return (
                        <div>
                            {params.value}
                        </div>
                    )
                } else {
                    return (
                        <div style={{
                            position: "relative",
                        }}>
                            <EditIcon color="error" />
                            <div style={{
                                position: "absolute",
                                width: "14px",
                                height: "14px",
                                fontSize: "11px",
                                borderRadius: "50%",
                                color: "rgb(211, 47, 47)",
                                border: "1px solid rgb(211, 47, 47)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                top: "-29%",
                                right: "-50%",
                                fontWeight: 600
                            }}>
                                !
                            </div>
                        </div>
                    )
                }
            }
        },
        {
            field: 'test', headerName: '주간 테스트', width: 140, headerAlign: "center", align: "center", editable: true,
            renderCell: (params) => {

                if (params.row.consult === false) {
                    return "-"
                }

                if (params.value) {

                    const date = new Date(params.value);
                    const isReportNotDate = isNaN(date.getTime());
                    const isReportDate = !isReportNotDate;

                    if (isReportDate) {
                        params.value = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${addZeroIfOne(date.getHours())}:${addZeroIfOne(date.getMinutes())}:${addZeroIfOne(date.getSeconds())}`
                    }

                    return (
                        <div>
                            {params.value}
                        </div>
                    )
                } else {
                    return (
                        <div style={{
                            position: "relative",
                        }}>
                            <EditIcon color="error" />
                            <div style={{
                                position: "absolute",
                                width: "14px",
                                height: "14px",
                                fontSize: "11px",
                                borderRadius: "50%",
                                color: "rgb(211, 47, 47)",
                                border: "1px solid rgb(211, 47, 47)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                top: "-29%",
                                right: "-50%",
                                fontWeight: 600
                            }}>
                                !
                            </div>
                        </div>
                    )
                }
            }
        },
        {
            field: 'print', headerName: '폴더 프린트 첨부', width: 140, headerAlign: "center", align: "center",
            renderCell: (params) => {
                if (params.row.consult === false) {
                    return "-"
                } else {
                    return (
                        <div style={{ position: "relative" }}>
                            <ToggleButtonGroup
                                size='small'
                                exclusive
                                value={params.value}
                                onChange={(e, value) => {

                                    controlRows(params.id, "print", value);
                                }}
                                color='primary'
                            >
                                <ToggleButton value={true}>
                                    <PanoramaFishEye />
                                </ToggleButton>
                                <ToggleButton value={false}>
                                    <Close />
                                </ToggleButton>
                            </ToggleButtonGroup>
                            {
                                !params.value && (
                                    <div style={{
                                        position: "absolute",
                                        width: "14px",
                                        height: "14px",
                                        fontSize: "11px",
                                        borderRadius: "50%",
                                        color: "rgb(211, 47, 47)",
                                        border: "1px solid rgb(211, 47, 47)",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        top: "-15%",
                                        right: "-15%",
                                        fontWeight: 600
                                    }}>
                                        !
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            }
        }
    ]);


    const classes = useStyles2();
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [disabled2, setDisabled2] = useState<boolean>(true);
    const [rowSelectionModel, setRowSelectionModel] = useState<any>([]);
    const apiRef = useGridApiRef();
    const [index, setIndex] = useState<number>(0);
    const [targetDate, setTargetDate] = useState<Date>();
    const [mySubject, setMySubject] = useState<string>("");
    const [didOnlineQuestions, setDidOnlineQuestions] = useState<boolean>(false);
    const [whyNotDoneOnlineQuestions, setWhyNotDoneOnlineQuestions] = useState<string>("");
    const [opinion, setOpinion] = useState<string>("");

    useEffect(() => {

        if (!props.user) {
            return;
        }

        getMySubject();

    }, [props.user]);


    const getMySubject = async () => {

        try {

            const response = await fetch("https://peetsunbae.com/dashboard/question/offlinequestion/getteachersubject", {
                method: "GET",
                credentials: "include"
            });

            const result = await response.json();

            

            if (result.message === "success") {

                const data = result.data;
                const userId = props.user.id;
                const myData = data.find((eachData: any) => eachData.userId === userId);
                setMySubject(myData.subject);

            }

        } catch (e) {
            
        }

    }

    useEffect(() => {

        const newTargetDate = new Date();
        //2시간 이전으로 설정
        newTargetDate.setHours(newTargetDate.getHours() - 2);
        setTargetDate(newTargetDate);

    }, []);

    useEffect(() => {

        const finalResult = judgeFinalSubmitDisable();
        setDisabled2(finalResult);

    }, [rows, didOnlineQuestions, whyNotDoneOnlineQuestions]);

    const judgeFinalSubmitDisable = () => {

        

        var finalDisable = false;

        rows.forEach((eachRow: any) => {

            if (eachRow.consult === false) {

                if (!eachRow.etc) {
                    
                    finalDisable = true;
                }

            } else {

                if (!eachRow.report || !eachRow.send || !eachRow.word || !eachRow.test || !eachRow.print) {
                    
                    finalDisable = true;
                }
            }

        })

        if(!didOnlineQuestions && !whyNotDoneOnlineQuestions){
            
            finalDisable = true;
        }

        if (rows.length === 0) {
            
            finalDisable = true;
        }

        return finalDisable;

    }


    useEffect(() => {

        getInfo();

    }, [index]);

    const getInfo = async () => {

        try {

            setLoading(true);

            const response = await fetch("https://peetsunbae.com/dashboard/chart/consultchecklist?index=" + index, {
                method: "GET",
                credentials: "include"
            });

            const result = await response.json();

            

            if (result.message === "success") {

                if(!result.data){
                    //전부 default 값으로 설정
                    setDidOnlineQuestions(false);
                    setWhyNotDoneOnlineQuestions("");
                    setOpinion("");
                    setRows([]);
                    setLoading(false);
                    return;
                }

                if(!result.data.didOnlineQuestions){
                    setDidOnlineQuestions(false);
                }else{
                    setDidOnlineQuestions(true);
                }

                setWhyNotDoneOnlineQuestions(result.data.whyNotDoneOnlineQuestions);
                setOpinion(result.data.opinion);

                if(!result.data.whyNotDoneOnlineQuestions){
                    setWhyNotDoneOnlineQuestions("");
                }

                if(!result.data.opinion){
                    setOpinion("");
                }

                if (result.data) {
                    const data = result.data.data;
                    setRows([...data]);

                    const newTargetDate = new Date(result.data.createdAt);
                    setTargetDate(newTargetDate);
                } else {
                    setRows([]);
                }

            }

            setLoading(false);

        } catch (e) {
            
            setLoading(false);
        }


    }


    useEffect(() => {

        if (rowSelectionModel.length === 0) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }

    }, [rowSelectionModel]);

    const start = async () => {

    }

    const onchange = (e: any, value: any) => {

        

        setSelectedUser(value);

    }


    useEffect(() => {

        start();

    }, []);

    useEffect(() => {

        addStudent();

    }, [selectedUser]);

    const addStudent = async () => {

        if (!selectedUser) return;

        //이미 추가된 학생이면 return
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].id === selectedUser.id) {
                setSelectedUser(null);
                return;
            }
        }

        const newRows = [...rows];

        const newRow: any = {};

        newRow.id = selectedUser.id;
        newRow.name = selectedUser.label;
        newRow.consult = false;
        newRow.print = false;

        setLoading(true);
        const result = await getInfoForSpecificUser(selectedUser.id);

        if (result) {
            newRow.report = result.report;
            newRow.send = result.send;
            newRow.word = result.word;
            newRow.test = result.test;
        }

        if (newRow.report) {
            newRow.consult = true;
        }

        setLoading(false);

        newRows.push(newRow);
        

        setRows([...newRows]);
        setSelectedUser(null);

    }

    const getInfoForSpecificUser = async (id: any) => {

        try {

            const response = await fetch("https://peetsunbae.com/dashboard/chart/consultchecklistofstudent?userId=" + id, {
                method: "GET",
                credentials: "include"
            });

            const result = await response.json();

            if (result.message === "success") {

                const data = result.data;

                return data;

            }

        } catch (e) {
            
        }

    }

    const letsDelete = () => {

        //선택된 학생이 없으면 return
        if (rowSelectionModel.length === 0) return;

        const newRows = rows.filter((row: any) => {

            var isNotInclude = true;

            for (let i = 0; i < rowSelectionModel.length; i++) {
                if (row.id === rowSelectionModel[i]) {
                    isNotInclude = false;
                    break;
                }
            }

            return isNotInclude;

        });

        setRows([...newRows]);
        setRowSelectionModel([]);

        if (newRows.length === 0) {
            sendVoidArray();
        }

    }

    const controlRows = (id: any, field: string, value: any) => {

        if (value === null || value === undefined) return;

        setRows((prevState: any) => {

            const newRows = prevState.map((row: any) => {
                if (row.id === id) {
                    row[field] = value;
                }
                return row;
            });

            return newRows;

        })


    }

    const handleCommit = async (newRow : GridRowModel, old : any) => {

        var field = "";
        

        for (const [key, value] of Object.entries(newRow)) {
            if (value !== old[key]) {
                field = key;
                
            }
        }

        const id = newRow.id;
        const value = newRow[field] ? newRow[field] : "";
        const newRows = [...rows];

        for (let i = 0; i < newRows.length; i++) {
            if (newRows[i].id === id) {
                newRows[i][field] = value;
                break;
            }
        }

        setRows([...newRows]);

        return newRow;
    }

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        
        alert(error.message);
    }, []);

    const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
        
        const { row, oldIndex, targetIndex } = params;

        const newRows = [...rows];

        newRows.splice(oldIndex, 1);
        newRows.splice(targetIndex, 0, row);

        setRows([...newRows]);
    };

    const sendRowInfo = async (rows : any, didOnlineQuestions : any, whyNotDoneOnlineQuestions : any, opinion : any) => {

        

        const newRows = [...rows];

        

        if (newRows.length === 0){
            
            
            return
        }
        if (index !== 0){
            
            return;
        }

        try {

            setLoading2(true);

            const data = {
                rows : newRows,
                didOnlineQuestions,
                whyNotDoneOnlineQuestions,
                opinion
            }

            const response = await fetch("https://peetsunbae.com/dashboard/chart/consultchecklist", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify(data)
            })

            const result = await response.json();

            if (result.message === "success") {

                const date = new Date();
                const dateString = date.getFullYear() + "년 " + (date.getMonth() + 1) + "월 " + date.getDate() + "일 " + date.getHours() + "시 " + date.getMinutes() + "분 " + date.getSeconds() + "초";

                setLastSaveTime(dateString);

            } else {
                alert("상담 체크리스트 정보를 저장하는데 실패했습니다.");
            }

            setLoading2(false);

        } catch (e) {
            
        }

    }

    const debounceSendRowInfo = useCallback(debounce((rows, didOnlineQuestions, whyNotDoneOnlineQuestions, opinion) => {
        sendRowInfo(rows, didOnlineQuestions, whyNotDoneOnlineQuestions, opinion);
    }, 350), []);

    useEffect(() => {

        if(index !== 0){
            
            return;
        }

        
        //sendRowInfo();
        debounceSendRowInfo(rows, didOnlineQuestions, whyNotDoneOnlineQuestions, opinion);
    }, [rows, didOnlineQuestions, whyNotDoneOnlineQuestions, opinion]);

    const sendVoidArray = async () => {

        try {

            setLoading2(true);

            const response = await fetch("https://peetsunbae.com/dashboard/chart/consultchecklist", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    rows: [],
                    didOnlineQuestions,
                    whyNotDoneOnlineQuestions,
                    opinion
                })
            })

            const result = await response.json();

            if (result.message === "success") {

                const date = new Date();
                const dateString = date.getFullYear() + "년 " + (date.getMonth() + 1) + "월 " + date.getDate() + "일 " + date.getHours() + "시 " + date.getMinutes() + "분 " + date.getSeconds() + "초";

                setLastSaveTime(dateString);

            } else {
                alert("상담 체크리스트 정보를 저장하는데 실패했습니다.");
            }

            setLoading2(false);

        } catch (e) {
            
        }

    }

    const submit = async () => {

        try {

            setLoading3(true);

            const response = await fetch("https://peetsunbae.com/dashboard/chart/consultchecklistsubmit", {
                method: "POST",
                credentials: "include"
            });

            const result = await response.json();

            if (result.message === "success") {
                props.notify();
                props.handleClose();
            }


            setLoading3(false);

        } catch (e) {
            props.notify2();
            
        }
    }

    const handleIndexPlusMinus = (plus: boolean) => {

        if (plus) {
            setIndex(index + 1);
        } else {
            if (index === 0) return;
            setIndex(index - 1);
        }

    }


    return (
        <div className={styles.main}>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
            }}>
                <div>
                    <div className={styles.title}>
                        체크리스트 작성 순서
                    </div>
                    <div className={styles.description}>
                        <div>
                            I) 당일 변동상황과 상관 없이, 카카오톡 전체 공지방에 가장 최근의 스케쥴 순서대로 학생 명단 배치
                        </div>
                        <div>
                            II) 불참 학생의 경우 특이사항 기입 (ex. 당일 상담취소, 학생 결석)
                        </div>
                        <div>
                            III) '!'으로 표시된 모든 칸을 채우고 최종 제출
                        </div>
                    </div>
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    paddingRight: "6px"
                }}>
                    <div style={{
                        fontFamily: "Apple_SB",
                    }}>
                        {targetDate && targetDate.getFullYear() + "년 " + (targetDate.getMonth() + 1) + "월 " + targetDate.getDate() + "일"}
                    </div>
                    <div style={{
                        marginTop: "8px"
                    }}>
                        <Button variant='outlined' size="sm" sx={{ marginRight: "4px" }} color='neutral'
                            onClick={() => {
                                handleIndexPlusMinus(true);
                            }}
                        >
                            <KeyboardArrowLeft />
                        </Button>
                        <Button variant='outlined' size="sm" color='neutral' disabled={index === 0}
                            onClick={() => {
                                handleIndexPlusMinus(false);
                            }}
                        >
                            <KeyboardArrowRight />
                        </Button>
                    </div>

                </div>
            </div>

            <div style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "12px",
                justifyContent: "space-between"
            }}>
                <div style={{
                    display: "flex",
                    alignItems: "center"
                }}>
                    <Autocomplete
                        onChange={onchange}
                        disablePortal
                        id="combo-box-demo"
                        options={props.users}
                        loading={
                            props.users.length === 0
                        }
                        disabled={index !== 0}
                        sx={{ width: 226, marginRight: "12px" }}
                        renderInput={(params) => <TextField {...params} label="이름"
                            //엔터키로 추가하기
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    
                                    addStudent();
                                }
                            }}
                        />}
                        value={selectedUser}
                    />
                    {/* <Button startDecorator={<Add />} disabled={disabled} onClick={() => { addStudent(); }}>추가하기</Button> */}
                </div>
                <div>
                    <Button variant='outlined' color="neutral" size='sm' disabled={disabled} onClick={() => {
                        letsDelete();
                    }}>
                        <DeleteOutlineOutlined />
                    </Button>
                </div>
            </div>
            <div style={{
                height: "5px",
                display: "flex",
                width: "100%",
                alignItems: "flex-end"
            }}>
                {
                    loading3 && <LinearProgress sx={{
                        width: "100%"
                    }} />
                }
            </div>
            <div className={classes.root} style={{ height: 500, width: '100%', backgroundColor: "white" }}>
                <DataGridPremium
                    columns={columns}
                    rows={rows}
                    checkboxSelection={true}
                    rowReordering
                    disableRowSelectionOnClick
                    rowSelectionModel={rowSelectionModel}
                    onRowSelectionModelChange={(newSelectionModel) => {
                        
                        setRowSelectionModel(newSelectionModel);
                    }}
                    apiRef={apiRef}
                    processRowUpdate={handleCommit}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                    onRowOrderChange={handleRowOrderChange}
                    loading={loading}
                    isCellEditable={(params) => {
                        const field = params.field;
                        if (params.field === "etc") return true;
                        if (!params.row.consult) return false;
                        if (index !== 0) return false;
                        else if (params.field === "report" || params.field === "send" || params.field === "word" || params.field === "test") {
                            
                            const value : any = params.value;
                            if (value) {

                                const date = new Date(value);
                                const isDate = !isNaN(date.getTime());
                                var justString = "";

                                if (isDate) {
                                    justString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${addZeroIfOne(date.getHours())}:${addZeroIfOne(date.getMinutes())}:${addZeroIfOne(date.getSeconds())}`
                                }



                                if (isDate && !justString.includes("00:00")) {
                                    return false;
                                } else {
                                    return true;
                                }

                            } else {
                                return true;
                            }
                        } else {
                            return false
                        }
                    }}
                />
            </div>
            <div style={{
                display: "flex",
                marginTop: "24px"
            }}>
                <div style={{
                    flex: 1,
                    marginRight: "24px"
                }}>
                    <div style={{
                        fontFamily: "Apple_SB",
                    }}>
                        온라인 질의응답
                    </div>
                    <div style={{
                        fontFamily: "Apple_R",
                        marginTop: "8px"
                    }}>
                        게시판의 <span style={{
                            color : "#3d50b0"
                        }}>"{mySubject}"</span> 과목 질문들이 모두 답변 완료 됐나요?
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-end"
                    }}>
                        <div style={{ position: "relative" }}>
                            <ToggleButtonGroup
                                size='small'
                                exclusive
                                value={didOnlineQuestions}
                                onChange={(e, value) => {
                                    if(index !== 0){
                                        return;
                                    }
                                    setDidOnlineQuestions(value);
                                }}
                                color='primary'
                            >
                                <ToggleButton value={true}>
                                    <PanoramaFishEye />
                                </ToggleButton>
                                <ToggleButton value={false}>
                                    <Close />
                                </ToggleButton>
                            </ToggleButtonGroup>
                            
                        </div>
                    </div>
                    <div style={{
                        marginTop : "8px",
                        position: "relative"
                    }}>
                        <Textarea variant="outlined" placeholder="완료 못 한 이유를 적어주세요" minRows={3} disabled={didOnlineQuestions || (index !== 0)}
                        value={whyNotDoneOnlineQuestions} onChange={(e) => {
                            setWhyNotDoneOnlineQuestions(e.target.value);
                        }}
                        />
                        {
                                (!didOnlineQuestions && !whyNotDoneOnlineQuestions) && (
                                    <div style={{
                                        position: "absolute",
                                        width: "14px",
                                        height: "14px",
                                        fontSize: "11px",
                                        borderRadius: "50%",
                                        color: "rgb(211, 47, 47)",
                                        border: "1px solid rgb(211, 47, 47)",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        top: "-7%",
                                        right: "-1%",
                                        fontWeight: 600
                                    }}>
                                        !
                                    </div>
                                )
                            }
                    </div>
                </div>
                <div style={{
                    flex: 1,
                    marginLeft: "24px"
                }}>
                    <div style={{
                        fontFamily: "Apple_SB",
                    }}>
                        업무 중 특이사항(건의사항) - 선택사항
                    </div>
                    <div style={{
                        marginTop : "28px"
                    }}>
                        <Textarea variant="outlined" placeholder="건의사항이 있으면 편하게 적어주세요. 큰 도움이 됩니다." minRows={5}
                        value={opinion} onChange={(e) => {
                            setOpinion(e.target.value);
                        }}
                        disabled={index !== 0}
                        />
                    </div>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "24px" }}>
                <div>
                    <div style={{
                        fontFamily: "Apple_R",
                        fontSize: "12px",
                    }}>
                        {
                            loading2 && "저장 중..."
                        }
                        {
                            !loading2 && lastSaveTime && "마지막 저장 시간 : " + lastSaveTime
                        }
                    </div>
                </div>
                {
                    index === 0 && (
                        <Button startDecorator={<Done />} disabled={disabled2} onClick={submit}>
                            {props.user && props.user.name + " 선생님 체크리스트"} 최종 제출하기
                        </Button>
                    )
                }
            </div>
        </div>
    );
}

export default ConsultCheckList;